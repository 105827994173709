import { LIST_TUTORIALS, GET_TUTORIAL, BEFORE_TUTORIAL } from '../../../redux/types';
const initialState = {

    listTutorialRes: null,
    listTutorialsAuth: false,

    getTutorialRes: null,
    getTutorialAuth: false,

}

export default function (state = initialState, action) {
    switch (action.type) {

        case LIST_TUTORIALS:
            return {
                ...state,
                listTutorialRes: action.payload,
                listTutorialsAuth: true
            }
        case GET_TUTORIAL:
            return {
                ...state,
                getTutorialRes: action.payload,
                getTutorialAuth: true
            }
        case BEFORE_TUTORIAL:
            return {
                ...state,

                listTutorialRes: null,
                listTutorialsAuth: false,

                getTutorialRes: null,
                getTutorialAuth: false
            }

        default:
            return {
                ...state
            }
    }
}