import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { subscribePlan } from '../package.actions'
import { selectPlanSection } from '../../ApplicationTour/TourCompIdentifiers'
import { ENV } from '../../../../config/config'
import { useTranslation } from 'react-i18next'

const PackageCard = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const userId = ENV.getUserKeys('_id')?._id

    const [active, setActive] = useState(false)
    const [plan, setPlan] = useState({})
    const currentLanguage = localStorage.getItem('selectedLang')

    const navigateToCardDetails = (planTenure, planId) => {
        if (userId)
            navigate('/card-details', { state: { planTenure, planId } })
        else {
            localStorage.setItem('redirectTo', 'packages')
            navigate('/login')
        }
    };

    useEffect(() => {
        if (props.plan)
            setPlan(props.plan)

    }, [props.plan])

    useEffect(() => {
        setActive(props.isPlanActive)

    }, [props.isPlanActive])

    const subscribe = (planId) => {
        props.setLoader(true)
        dispatch(subscribePlan({ planId, lng: currentLanguage }, true))
    }

    return (
        <div className='package-card'>
            <div>
                <p className='packageTitle'>{plan.name}</p>
                <ul className='packageCardHeader'>

                    {
                        props.planTenure ?
                            plan.monthlyPrice || plan.annualPrice ?
                                <div>
                                    <h3 className='packageH3'>${props.planTenure === 1 ? plan.monthlyPrice : props.planTenure === 2 ? plan.annualPrice : ''}
                                        <sub className='h3Sub'>{props.planTenure === 1 ? `/${t('common.month')}` : props.planTenure === 2 ? `${t('common.year')}` : ''}</sub></h3>
                                </div> : null
                            :
                            <>
                                <div>
                                    <h3 className='packageH3'>${plan.monthlyPrice ? plan.monthlyPrice : 0}
                                        <sub className='h3Sub'>{`/${t('common.month')}`}</sub></h3>
                                </div>
                                {
                                    plan.monthlyPrice && plan.annualPrice ?
                                        <div>
                                            <h3 className='packageH3'>${plan.annualPrice}
                                                <sub className='h3Sub'>{`/${t('common.year')}`}</sub></h3>
                                        </div> : null
                                }
                            </>
                    }
                </ul>
                <ul className='package-ul'>
                    {
                        plan.videoLengthMins ?
                            <li><p>{t('packageCard.uploadVideo.text1')} {plan.videoLengthMins} {t('packageCard.uploadVideo.text2')}</p></li> :
                            null
                    }
                    {
                        plan.numberOfVideos ?
                            <li><p>{t('packageCard.noOfVideos.text1')} {plan.numberOfVideos} {t('packageCard.noOfVideos.text2')}</p></li> :
                            null
                    }
                    {
                        plan.videoSizeMbs ?
                            <li><p>{t('packageCard.videoSize')} {plan.videoSizeMbs} {t('common.mbs')}</p>
                            </li> :
                            null
                    }
                    {
                        plan.editObjectsAnnotation ?
                            <li><p>{t('packageCard.editAnn')}</p>
                            </li> :
                            null
                    }
                </ul>
            </div>
            <div className="d-flex w-100 justify-content-center">
                {
                    !props.planTenure ?
                        !active ?
                            (plan.type !== 1 || plan.monthlyPrice || plan.annualPrice) ?
                                <Dropdown className='package-select-dropdown'>
                                    <Dropdown.Toggle className='orange-btn mt-0 w-100' variant="" id="dropdown-basic">
                                        <span /* id={selectPlanSection} */>{t('common.select')}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='w-100'>
                                        <Dropdown.Item onClick={e => navigateToCardDetails(1, plan._id)} >{t('packages.listingKeys.monthly')}</Dropdown.Item>
                                        <Dropdown.Item onClick={e => navigateToCardDetails(2, plan._id)} >{t('packages.listingKeys.yearly')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> :
                                <div>
                                    <button
                                        // id={selectPlanSection}
                                        className='orange-btn mt-0 w-100'
                                        onClick={e => subscribe(plan._id)}
                                    >
                                        {t('common.select')}
                                    </button>
                                </div>
                            :
                            <div>
                                <button
                                    disabled={true}
                                    className='mt-0 w-100'
                                >
                                    {t('packageCard.subscribed')}
                                </button>
                            </div>
                        : null

                }
            </div>
        </div>
    )
}

export default PackageCard