import { SUBSCRIBE_NEWSLETTER, BEFORE_NEWSLETTERS } from "../../../redux/types"
const initialState = {
    subscribedAuth: false
}

export default function newsletterRed(state = initialState, action) {
    switch (action.type) {
        case SUBSCRIBE_NEWSLETTER:
            return {
                ...state,
                subscribedAuth: true
            }
        case BEFORE_NEWSLETTERS:
            return {
                ...state,
                subscribedAuth: false
            }
        default:
            return {
                ...state
            }
    }
}