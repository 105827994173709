import React, { useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import VideoUrlSection from './VideoUrl'
import moment from 'moment'
import DatePicker from "react-datepicker";
import { FaCalendarDay } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import '../../Models/Models.css'

const Visibility = (props) => {

    const endDate = useRef(null)
    const startDate = useRef(null)

    return (
        <>
            <div className='modalPage'>

                <div className=" themeModal" >
                    <div className='seletFile modalBox'>
                        <Row>
                            <Col md={8} className='mb-md-0 mb-3'>
                                <h4 className='modal-H4'>{props.t('editVideoDetails.visibility.heading')}</h4>
                                <p className='opacity-40'>{props.t('editVideoDetails.visibility.paragrapgh1')}</p>
                                <div className='visibilityList'>
                                    <div className='visibilityBox themeRadio'>
                                        <ul>
                                            <li>
                                                <div>
                                                    <div className='customInput'>
                                                        <div className='radioFlex'>
                                                            <input
                                                                type="radio"
                                                                name="visibilityType"
                                                                value={1}
                                                                checked={props.videoDetails.visibilityType == '1'}
                                                                onChange={props.onChangeHandler}
                                                            />
                                                        </div>
                                                        <div>
                                                            <h5 className='h5Title'>{props.t('editVideoDetails.visibility.saveOrPublish')}</h5>
                                                            <p className='opacity-40'>{props.t('editVideoDetails.visibility.saveOrPublishDesc')}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        props.videoDetails.visibilityType == '1' ?
                                                            <ul className='outer-UL'>
                                                                <li>
                                                                    <div className='customInput'>
                                                                        <div className='radioFlex'>
                                                                            <input type="radio"
                                                                                name="publishType"
                                                                                value={1}
                                                                                checked={props.videoDetails.publishType == '1'}
                                                                                onChange={props.onChangeHandler} />
                                                                        </div>
                                                                        <div>
                                                                            <h5 className='h5Title'>{props.t('myVideos.visibilityTypes.private')}</h5>
                                                                            <p className='opacity-40 mb-0'>{props.t('editVideoDetails.visibility.privateDesc')}</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='customInput'>
                                                                        <div className='radioFlex'
                                                                        ><input type="radio"
                                                                            name="publishType"
                                                                            value={2}
                                                                            checked={props.videoDetails.publishType == '2'}
                                                                            onChange={props.onChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <h5 className='h5Title'>{props.t('myVideos.visibilityTypes.unlisted')}</h5>
                                                                            <p className='opacity-40 mb-0'>{props.t('editVideoDetails.visibility.unlistedDesc')}</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='customInput'>
                                                                        <div className='radioFlex'>
                                                                            <input type="radio"
                                                                                name="publishType"
                                                                                value={3}
                                                                                checked={props.videoDetails.publishType == '3'}
                                                                                onChange={props.onChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <h5 className='h5Title'>{props.t('myVideos.visibilityTypes.public')}</h5>
                                                                            <p className='opacity-40 mb-0'>{props.t('editVideoDetails.visibility.publicDesc')}</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul> : null
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='visibilityBox'>
                                        <ul>
                                            <li>
                                                <div>
                                                    <div className='customInput'>
                                                        <div className='radioFlex'>
                                                            <input type="radio"
                                                                name="visibilityType"
                                                                value={2}
                                                                checked={props.videoDetails.visibilityType == '2'}
                                                                onChange={props.onChangeHandler} />
                                                        </div>
                                                        <div>
                                                            <h5 className='h5Title'>{props.t('editVideoDetails.visibility.schedule')}</h5>
                                                            <p className='opacity-40'>{props.t('editVideoDetails.visibility.scheduleDesc')}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        props.videoDetails.visibilityType == '2' ?

                                                            <ul className='outer-UL'>
                                                                <li>
                                                                    <div className=''>
                                                                        <p className='opacity-40'>{props.t('editVideoDetails.visibility.startDate')}</p>
                                                                        <label className='date-picker-wrapper'>
                                                                            <DatePicker
                                                                                ref={startDate}
                                                                                className="form-control"
                                                                                dateFormat="dd-MM-yyyy"
                                                                                selected={props.videoDetails.publishStartDate ? new Date(props.videoDetails.publishStartDate) : ''}
                                                                                onChange={(date) => props.dateChangeHandler(date, 'publishStartDate')}
                                                                                minDate={moment(new Date()).toDate()}
                                                                                placeholderText="Select Start Date"
                                                                            />
                                                                            <FaCalendarDay />
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        props.errors.publishStartDate &&
                                                                        <span className='submit-errors text-danger'>{props.errors.publishStartDate}</span>
                                                                    }
                                                                </li>
                                                                <li>
                                                                    <div className=''>
                                                                        <p className='opacity-40'>{props.t('editVideoDetails.visibility.endDate')}</p>
                                                                        <label className='date-picker-wrapper'>
                                                                            <DatePicker
                                                                                ref={endDate}
                                                                                className="form-control"
                                                                                dateFormat="dd-MM-yyyy"
                                                                                selected={props.videoDetails.publishEndDate ? new Date(props.videoDetails.publishEndDate) : ''}
                                                                                onChange={(date) => props.dateChangeHandler(date, 'publishEndDate')}
                                                                                minDate={moment(new Date()).toDate()}
                                                                                placeholderText="Select End Date"
                                                                            />
                                                                            <FaCalendarDay />

                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        props.errors.publishEndDate &&
                                                                        <span className='submit-errors text-danger'>{props.errors.publishEndDate}</span>
                                                                    }
                                                                </li>
                                                            </ul>
                                                            : null
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </Col>
                            <Col md={4}>
                                <VideoUrlSection video={props.videoDetails} inputs={props.fileInputs} />
                            </Col>
                        </Row>
                    </div >
                </div >
            </div >
        </>
    )
}
export default Visibility