import React from 'react';
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { beforeChannels, getChannelVideos } from '../../MyChannels/channels.actions';
import SmallLoader from '../../../../utils/SmallLoader/SmallLoader';
import moment from 'moment';
import '../../HomePage/Explore/Explore.css'
import { useTranslation } from 'react-i18next';
import { viewChannelVideos } from '../../ApplicationTour/TourCompIdentifiers';

const ChannelVideos = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const channelSlug = (useParams())?.channelSlug
    const [videos, setVideos] = useState([])
    const [loader, setLoader] = useState(true)
    const [total, setTotal] = useState();
    const [pagination, setPagination] = useState({})
    const [loadMore, setLoadMore] = useState(false)
    const currentLanguage = localStorage.getItem('selectedLang')
    const channelSelector = useSelector((state) => state.channels)

    useEffect(() => {
        if (channelSlug)
            dispatch(getChannelVideos(`channelSlug=${encodeURIComponent(channelSlug)}&lng=${currentLanguage}`))
    }, [channelSlug])

    useEffect(() => {
        if (channelSelector.getChannelVideosAuth) {
            let { channelVideos, paginationData } = channelSelector.getChannelVideosData?.data
            setVideos(loadMore ? videos.concat(channelVideos) : channelVideos)
            setPagination(paginationData)
            setTotal(paginationData.total)
            setLoader(false)
            dispatch(beforeChannels())
        }
    }, [channelSelector.getChannelVideosAuth])

    const loadMoreVideos = () => {
        setLoadMore(true)
        setLoader(true)

        const { page } = pagination
        dispatch(getChannelVideos(`channelSlug=${encodeURIComponent(channelSlug)}&page=${page + 1}`))
    }


    return (
        loader ? <SmallLoader width={40} height={40} /> :
            <>

                <div className='explore explore-sec pt-0 px40 channel-vedios-wrapper'>
                    <div id={viewChannelVideos} className="heading-block mb-3">
                        <h3>{t('channelDetails.channelVideos')}</h3>
                    </div>
                    <div className="video-slider-block top-videos">                       
                        {videos?.length > 0 ?
                            // <div>
                                videos.map((item, i) => (
                                    // <div key={i}>
                                    <div className="video-holder" key={i}>
                                        <div className="img-holder">
                                            <img src={item.thumbnail} alt={item.name} />
                                            <div className="play-icon-holder d-flex justify-content-center align-items-center" onClick={() => navigate(`/media-player/${item?.slug}/${item?.channel?.name}`)}>
                                                <FontAwesomeIcon icon={faCirclePlay} alt="" />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="author-pic">
                                                <img className="img-fluid" src={item.owner.profileImage} alt={item.owner.name} />
                                            </div>
                                            <div className="flex-fill d-flex flex-column video-info">
                                                <strong className="video-title">{item.title}</strong>
                                                <span className="channel-name">{item.channel.name}</span>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span className="channel-name">{item?.views ? item.views : 0} {item?.views > 1 ? t('common.views') : t('common.view')}</span>
                                                    <span className="video-cat">{moment(item.createdAt).format('MMM DD, YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                ))
                            // </div>
                            : <div className="no-record-found d-flex justify-content-center align-items-center">{t('common.noRecord')}</div>
                        }
                    </div>

                    {(total > videos?.length) ?
                        (<div className='text-center w-100 d-block'>
                            <button className="orange-btn load-more-btn text-uppercase mx-auto" onClick={loadMoreVideos}>{t('common.loadMoreBtn')}</button>
                        </div>)
                        :
                        ("")}
                </div>

            </>
    )
}

export default ChannelVideos 
