import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Tab, Tabs } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import DatePicker from "react-datepicker";
import { getPlan, beforePlans, subscribePlan } from '../package.actions';
import { useSelector, useDispatch } from 'react-redux';
import PackageCard from '../Package/PackageCard';
import { ENV } from '../../../../config/config';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { validateName } from '../../../../utils/Validations/validations';
import { Images } from '../../../../assets/assets'
import "react-datepicker/dist/react-datepicker.css";
import '../../Forms/Forms.css'
import './CardDetails.css'

import { useTranslation } from 'react-i18next';
const CardDetails = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { state } = useLocation();
    const planId = state?.planId
    const planTenure = state?.planTenure
    const fields = {
        firstName: '', lastName: '',
        fullName: '',
        cardNumber: '',
        cardCvc: '',
        cardExpiry: ''
    }
    const userId = ENV.getUserKeys('_id')?._id
    const [paymentData, setData] = useState({ ...fields })

    const [paymentErr, setErr] = useState({ ...fields })
    const [loader, setLoader] = useState(true)
    const [submitted, setSubmitted] = useState(false)
    const [plan, setPlan] = useState({})
    const [key, setKey] = useState('stripe');

    const currentLanguage = localStorage.getItem('selectedLang')
    const plansSelector = useSelector((state) => state.packages)
    const errorSelector = useSelector((state) => state.error)

    useEffect(() => {
        if (errorSelector)
            setLoader(false)
    }, [errorSelector])

    useEffect(() => {
        if (planId && userId) {
            dispatch(getPlan(planId, `lng=${currentLanguage}`))
        }
        else {
            navigate('/packages')
        }

    }, [])

    useEffect(() => {
        if (plansSelector.getPlanAuth) {
            setLoader(false)
            setPlan(plansSelector.getPlanData?.plan)
            dispatch(beforePlans())
        }
    }, [plansSelector.getPlanAuth])

    useEffect(() => {
        if (plansSelector.subscribePlanAuth) {
            let { subscribedPlan } = plansSelector.subscribePlan
            setLoader(false)
            if (subscribedPlan)
                navigate(`/payment-successful?invoice=${subscribedPlan.id}`)
            else
                navigate('/packages')
            dispatch(beforePlans())
        }
    }, [plansSelector.subscribePlanAuth])


    const onChangeHandler = (e) => {
        let errors = { ...paymentErr }
        let { name, value } = e.target
        if (submitted) {
            if (!value)
                errors = { ...errors, [name]: 'This field is required!' }
            else
                errors = { ...errors, [name]: '' }

        }

        setErr({ ...paymentErr, ...errors })

        if (name === 'cardNumber' || name === 'cardCvc')
            value = value.replace(/[^0-9]/g, '');

        if (name === 'cardNumber')
            value = value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();

        if (name === 'cardNumber' && value.length > 16)
            value = value.substr(0, 19);

        if (name === 'cardCvc' && value.length > 4)
            value = value.substr(0, 4);

        setData({ ...paymentData, [name]: value })
    }

    const dateChangeHandler = (date) => {
        let dateErr = ''
        if (submitted) {
            if (date)
                dateErr = ''
            else
                dateErr = t('common.fieldRequired')
        }

        setErr({ ...paymentErr, cardExpiry: dateErr })
        setData({ ...paymentData, cardExpiry: date })
    }

    const subscribe = () => {
        setSubmitted(true)

        let errors = {}
        let msg = t('common.fieldRequired')

        if (key == "authorize") {
            if (!paymentData.firstName)
                errors = { ...errors, firstName: msg }
            else {
                paymentData.firstName = paymentData.firstName.replace(/\s+/g, " ").trim()
                let err = validateName(t, paymentData.firstName)
                if (err)
                    errors = { ...errors, firstName: err }
            }

            if (!paymentData.lastName)
                errors = { ...errors, lastName: msg }
            else {
                paymentData.lastName = paymentData.lastName.replace(/\s+/g, " ").trim()
                let err = validateName(t, paymentData.lastName)
                if (err)
                    errors = { ...errors, lastName: err }
            }
        }
        else {
            if (!paymentData.fullName)
                errors = { ...errors, fullName: msg }
            else {
                paymentData.fullName = paymentData.fullName.replace(/\s+/g, " ").trim()
                let err = validateName(t, paymentData.fullName)
                if (err)
                    errors = { ...errors, fullName: err }
            }
        }

        if (!paymentData.cardNumber)
            errors = { ...errors, cardNumber: msg }

        if (!paymentData.cardCvc)
            errors = { ...errors, cardCvc: msg }

        if (!paymentData.cardExpiry)
            errors = { ...errors, cardExpiry: msg }

        if (errors.firstName || errors.lastName || errors.fullName || errors.cardNumber || errors.cardCvc || errors.cardExpiry) {
            setErr({ ...paymentErr, ...errors })
            return
        }

        setLoader(true)
        dispatch(subscribePlan({ ...paymentData, planId, planTenure, lng: currentLanguage, key }))
    }

    const cardForms = (key = '') => {
        return (
            <div className='h-100'>
                <Form className='card-form h-100'>
                    <div className='formHeader'>
                        <div className='d-flex justify-content-center payment-icon-holder mb-3'>
                            <div className="img-container">
                                {key === 'authorize' ?
                                    <img src={Images.paymentAuth} alt="Authorise.net Icon" />
                                    :
                                    <img src={Images.paymentSt} alt="Strip Icon" />
                                }
                            </div>
                        </div>
                        <h4>{t('cardDetails.heading')}</h4>
                    </div>
                    {
                        key === 'authorize' ?
                            <>
                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                    <Form.Label><div className='d-flex align-items-center card-info-from-label'>{t('cardDetails.firstName')}<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                    <input type="text" className="form-control" name="firstName" value={paymentData.firstName} onChange={onChangeHandler} placeholder="John"></input>
                                </Form.Group>
                                {
                                    paymentErr && paymentErr.firstName &&
                                    <span className='submit-errors text-danger'>{paymentErr.firstName}</span>
                                }
                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                    <Form.Label><div className='d-flex align-items-center card-info-from-label'>{t('cardDetails.lastName')}<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                    <input type="text" className="form-control" name="lastName" value={paymentData.lastName} onChange={onChangeHandler} placeholder="Smith"></input>
                                </Form.Group>
                                {
                                    paymentErr && paymentErr.lastName &&
                                    <span className='submit-errors text-danger'>{paymentErr.lastName}</span>
                                }
                            </>
                            :
                            <>
                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                    <Form.Label><div className='d-flex align-items-center card-info-from-label'>{t('cardDetails.cardName')}<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                    <input type="text" className="form-control" name="fullName" value={paymentData.fullName} onChange={onChangeHandler} placeholder="John Smith"></input>
                                </Form.Group>
                                {
                                    paymentErr && paymentErr.fullName &&
                                    <span className='submit-errors text-danger'>{paymentErr.fullName}</span>
                                }
                            </>
                    }
                    <Form.Group className='formGroup' controlId="formBasicEmail">
                        <Form.Label><div className='d-flex align-items-center card-info-from-label'>{t('cardDetails.cardNumber')}<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                        <input type="text" className="form-control" name="cardNumber" value={paymentData.cardNumber} onChange={onChangeHandler} placeholder="0000 0000 0000 0000"></input>
                    </Form.Group>
                    {
                        paymentErr && paymentErr.cardNumber &&
                        <span className='submit-errors text-danger'>{paymentErr.cardNumber}</span>
                    }
                    <div className='d-flex date-cvc'>
                        <Form.Group className='formGroup me-3 w-100' controlId="formBasicEmail">
                            <Form.Label><div className='d-flex align-items-center card-info-from-label'>{t('cardDetails.expiryDate')}<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                            <div className='inputDiv mb-2'>
                                <DatePicker
                                    className="form-control"
                                    placeholderText="Expiry Date (MM/YY)"
                                    selected={paymentData.cardExpiry}
                                    onChange={dateChangeHandler}
                                    showMonthYearPicker
                                    dateFormat="MM/yyyy"
                                    minDate={new Date()}
                                />
                            </div>
                            {
                                paymentErr && paymentErr.cardExpiry &&
                                <span className='submit-errors text-danger'>{paymentErr.cardExpiry}</span>
                            }
                        </Form.Group>
                        <Form.Group className='formGroup' controlId="formBasicEmail">
                            <Form.Label><div className='d-flex align-items-center card-info-from-label'>{t('cardDetails.cvc')}<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                            <input type="text" className="form-control" name="cardCvc" value={paymentData.cardCvc} onChange={onChangeHandler} placeholder="912"></input>
                            {
                                paymentErr && paymentErr.cardCvc &&
                                <span className='submit-errors text-danger'>{paymentErr.cardCvc}</span>
                            }
                        </Form.Group>
                    </div>
                    <div className='theme-capcha'>
                        <button type="button" className='orange-btn text-uppercase' onClick={subscribe}>{t('cardDetails.payNow')}</button>
                    </div>

                </Form>
            </div>
        )
    }

    const tabChangeHandler = (k) => {
        if (key !== k) {
            setKey(k)
            setErr({ ...fields })
            setData({ ...fields })
        }
    }
    return (
        <>
            {loader && <FullPageLoader />}
            {
                plan &&
                <section className='theme-form Login card-details-sec'>
                    <div className='biiview-form'>
                        <Container fluid className='formContainer'>
                            <Row className='justify-content-center custom-card-width'>
                                <Col md={6}>
                                    <div className='biiview-form pt-0'>
                                        <Container fluid className='formContainer'>
                                            <div className='wrapForm'>
                                                <Tabs
                                                    activeKey={key}
                                                    onSelect={tabChangeHandler}
                                                >
                                                    <Tab eventKey="stripe" title={t('cardDetails.stripe')}>
                                                        {cardForms()}
                                                    </Tab>
                                                    <Tab eventKey="authorize" title={t('cardDetails.authorizeNet')}>
                                                        {cardForms('authorize')}
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </Container>
                                    </div>


                                </Col>
                                <Col md={6}>
                                    <PackageCard plan={plan} planTenure={planTenure} />

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>

            }
        </>
    )
}

export default CardDetails