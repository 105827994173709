import React from 'react';
import './FullPageLoader.css';

function FullPageLoader() {
    return (
        <React.Fragment>
            <div className="fullpage-loader-holder">
                <h1 data-text="BiiView ...">BiiView ...</h1>
            </div>
        </React.Fragment>
    );
};

export default FullPageLoader;