import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import { listBillingHistory, downloadInvoice, beforeBillingHistory } from './billingHistory.actions';
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import 'rc-pagination/assets/index.css';
import { ENV } from '../../../config/config';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { downloadInvoiceIcon, viewInvoiceIcon } from '../ApplicationTour/TourCompIdentifiers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFile } from '@fortawesome/free-solid-svg-icons';
import './ViewInvoice.css'
import { useTranslation } from 'react-i18next'

const BillingAndInvoice = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const userId = ENV.getUserKeys('_id')?._id

    const [billingHistory, setHistory] = useState([])
    const [pagination, setPagination] = useState({})
    const [loader, setLoader] = useState(true)
    const currentLanguage = localStorage.getItem('selectedLang')
    const billingSelector = useSelector((state) => state.billingHistory)

    useEffect(() => {
        if (userId && i18n.language) {
            dispatch(listBillingHistory(`lng=${currentLanguage}`))
        }
    }, [userId, i18n.language])

    useEffect(() => {
        if (billingSelector.listBillingHistoryAuth) {
            let { history, paginationData } = billingSelector.listBillingHistoryData
            setHistory(history)
            setPagination(paginationData)
            setLoader(false)
            dispatch(beforeBillingHistory())
        }
    }, [billingSelector.listBillingHistoryAuth])

    useEffect(() => {
        if (billingSelector.downloadInvoiceAuth) {
            let { pdfPath } = billingSelector.downloadInvoiceData
            setLoader(false)
            window.open(pdfPath, '_blank')
            dispatch(beforeBillingHistory())
        }
    }, [billingSelector.downloadInvoiceAuth])

    const onPageChange = (page) => {
        setLoader(true)
        dispatch(listBillingHistory(`page=${page}&lng=${currentLanguage}`))
    }

    const downloadInvoiceHandler = (id) => {
        setLoader(true)
        dispatch(downloadInvoice(id, `lng=${currentLanguage}`))
    }

    return (
        <>
            {loader && <FullPageLoader />}
            <div className='dashboardPage payment-history'>
                <div className='dashboardHeader px40'>
                    <h2 className='text-uppercase'>{t('billingInvoices.heading')}</h2>
                </div>
                <div className='view-invoice-table px40'>
                    <div className='themeTable'>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>{t('billingInvoices.listing.date')}</th>
                                    <th>{t('billingInvoices.listing.type')}</th>
                                    <th>{t('billingInvoices.listing.invoiceId')}</th>
                                    <th>{t('billingInvoices.listing.planName')}</th>
                                    <th>{t('billingInvoices.listing.amount')}</th>
                                    <th>{t('billingInvoices.listing.action')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    billingHistory && billingHistory.length ?
                                        billingHistory.map((history, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{moment(new Date(history.createdAt)).format('MMMM DD, YYYY')}</td>
                                                    <td>{t('billingInvoices.listing.invoice')}</td>
                                                    <td>{history.invoiceId}</td>
                                                    <td>{history.plan?.name}</td>
                                                    <td>${history.price}</td>
                                                    <td>
                                                        <div className='d-flex align-items-center view-invoice-btns'>
                                                            <div className='me-2'>
                                                                <Link id={viewInvoiceIcon} to={`/invoice/${history.invoiceId}`} target="_blank" rel='noreferrer'>
                                                                    <Button variant=''>
                                                                        {/* <Image src={EyeIcon} alt="view" /> */}
                                                                        <FontAwesomeIcon icon={faEye} />
                                                                    </Button>
                                                                </Link>
                                                            </div>
                                                            <div className='me-2'>
                                                                <Button id={downloadInvoiceIcon} variant='' onClick={() => downloadInvoiceHandler(history._id)}>
                                                                    {/* <Image src={FileIcon} alt="view" /> */}
                                                                    <FontAwesomeIcon icon={faFile} />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan={6}>
                                                <div className='no-record-found d-flex justify-content-center align-items-center'>
                                                    {t('billingInvoices.listing.noHistoryFound')}
                                                </div>
                                            </td>
                                        </tr>
                                }

                            </tbody>
                        </Table>
                        {
                            pagination && pagination.pages &&
                            <Pagination
                                className="m-3"
                                defaultCurrent={1}
                                pageSize // items per page
                                current={parseInt(pagination.page)} // current active page
                                total={pagination.pages} // total pages
                                onChange={onPageChange}
                                locale={localeInfo}
                                hideOnSinglePage={true}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillingAndInvoice