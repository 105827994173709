import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faPaperPlane, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import "./CommunityQuestions.css"
import { Link } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ckEditorCustomConfig } from '../../CkEditor/ckEditorConfig'
import { useDispatch, useSelector } from 'react-redux'
import { beforeCommunity, deleteQuestion, editQuestion, listQuestions, upDownVote } from '../community.actions'
import moment from 'moment'
import FullPageLoader from '../../FullPageLoader/FullPageLoader'
import Swal from 'sweetalert2'
import { ENV } from '../../../../config/config'
import { formatNumber } from '../../../../utils/Shared Functions/shared'
import { useTranslation } from 'react-i18next'

function CommunityPosts() {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const communtitySelector = useSelector((state) => state.community)
	const currentLanguage = localStorage.getItem('selectedLang')

	const [questions, setQuestions] = useState([])
	const [questionData, setQuestionData] = useState(null)
	const [errors, setErrors] = useState({})
	const [loader, setLoader] = useState(true)
	const [pagination, setPagination] = useState({})
	const [show, setShow] = useState(false)
	const [showMore, setShowMore] = useState([])
	const [upvotes, setUpvotes] = useState([])
	const [downvotes, setDownvotes] = useState([])
	const userId = ENV.getUserKeys('_id')?._id
	const [loadMore, setLoadMore] = useState(false)

	useEffect(() => {
		dispatch(listQuestions())
	}, [])

	useEffect(() => {
		if (communtitySelector.listQuestionsAuth) {
			let { questionsList, pagination } = communtitySelector.listQuestionsData?.data
			setQuestions(loadMore ? questions.concat(questionsList) : questionsList)

			if (questionsList?.length) {
				setShowMore(new Array(questionsList.length).fill(false))
				setUpvotes(new Array(questionsList.length).fill(false))
				setDownvotes(new Array(questionsList.length).fill(false))
			}

			setPagination(pagination)
			setLoader(false)
			setLoadMore(false)
			dispatch(beforeCommunity())
		}
	}, [communtitySelector.listQuestionsAuth])

	useEffect(() => {
		if (communtitySelector.questionVoteAuth) {
			let { questionId, totalVotes, type, userVoted } = communtitySelector.questionVoteData?.data
			if (questionId) {
				let questionsCopy = [...questions]
				questionsCopy = questionsCopy.map((question) => {
					if (question._id === questionId)
						return { ...question, [type === 1 ? 'totalUpvotes' : 'totalDownvotes']: { total: totalVotes }, [type === 1 ? 'userUpvoted' : 'userDownvoted']: userVoted }
					else
						return question
				})
				setQuestions([...questionsCopy])
			}

			dispatch(beforeCommunity())

		}
	}, [communtitySelector.questionVoteAuth])

	useEffect(() => {
		if (communtitySelector.postQuestionAuth) {
			setLoader(false)
			setShow(false)
			setQuestionData(null)
			dispatch(listQuestions())
		}
	}, [communtitySelector.postQuestionAuth])

	useEffect(() => {
		if (communtitySelector.editQuestionAuth) {
			setLoader(false)
			setShow(false)
			setQuestionData(null)
			dispatch(listQuestions())
		}
	}, [communtitySelector.editQuestionAuth])

	useEffect(() => {
		if (communtitySelector.deleteQuestionAuth) {
			setLoader(false)
			dispatch(listQuestions())
		}
	}, [communtitySelector.deleteQuestionAuth])


	const onEditorChange = (event, editor) => {
		let data = editor.getData();
		setQuestionData({ ...questionData, question: data })
	}

	const editQuestionHandler = () => {
		let errs = {}

		if (!questionData.title)
			errs.title = t('common.fieldRequired')

		if (!questionData.question)
			errs.title =t('common.fieldRequired')

		setErrors({ ...errs })

		if (Object.keys(errors)?.length)
			return

		setLoader(true)
		dispatch(editQuestion({ title: questionData.title, question: questionData.question, _id: questionData._id, lng: currentLanguage }))
	}

	const deleteQuestionHandler = (id) => {
		Swal.fire({
			title: t('community.deleteQuestion'),
			html: t('community.deleteQuesConfirm'),
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: t('common.yes'),
			cancelButtonText: t('common.no')
		}).then(async (result) => {
			if (result.value) {
				setLoader(true)
				dispatch(deleteQuestion(id, `lng=${currentLanguage}`))
			}
		})
	}

	const setShowMoreHandler = (index, val) => {
		let copy = [...showMore]
		copy[index] = val
		setShowMore([...copy])
	}

	const questionVotingHandler = ({ _id, userUpvoted, userDownvoted }, type, value, index) => {
		if (type === 1 && userUpvoted)
			value = false

		if (type === 2 && userDownvoted)
			value = false

		if (type === 1 && (downvotes[index] || userDownvoted))
			return

		if (type === 2 && (upvotes[index] || userUpvoted))
			return

		if (type === 1) {
			let copy = [...upvotes]
			copy[index] = value
			setUpvotes([...copy])
		}
		else if (type === 2) {
			let copy = [...downvotes]
			copy[index] = value
			setDownvotes([...copy])
		}
		dispatch(upDownVote({ id: _id, type, isRemoved: !value, lng: currentLanguage }))
	}

	const loadMoreQuestions = () => {
		setLoadMore(true)
		setLoader(true)

		const { page } = pagination
		dispatch(listQuestions(`page=${page + 1}`))
	}

	return (
		loader ? <FullPageLoader /> :
			<div>
				<Container>
					{
						questions?.length ?
							questions.map((question, index) => (
								<section id='community-post' key={index}>
									<div className='community-posts-wrapper mx-auto'>
										<div className='community-posts'>
											<div className='community-user-details-wrapper d-flex align-items-center'>
												<div className='community-author-img'>
													<img src={question.author.profileImage} alt='' className='img-fluid' />
												</div>
												<div className='comunity-author-nameDetails'>
													<h6 className='community-name-follow'>
														{question.author.name}
													</h6>
													<div className='experience'>
														<span>{question.author.designation}</span>
														<span>{moment(question.createdAt).format('DD MMM, YYYY')}</span>
													</div>
												</div>
											</div>
											<div className='post-heading-discription'>
												<div className='post-heading-discription-title-holder'><Link to={`/question/${question.slug}`} state={{ questionId: question._id }}>{question.title}</Link></div>
												<div dangerouslySetInnerHTML={{ __html: showMore[index] ? question.question : question.question.length > 100 ? `${question.question.slice(0, 20)}...` : question.question }}></div>
											</div>
											<div className='show-more-less-btn-holder'>
												{
													showMore[index] ?
														<button className='btn btn-primary d-inline-block' onClick={() => setShowMoreHandler(index, false)}>{t('common.showLess')}</button> :
														question.question.length > 100 &&
														<button className='btn btn-primary d-inline-block' onClick={() => setShowMoreHandler(index, true)}>{t('common.showMore')}</button>
												}
											</div>
										</div>
										<div className='community-posts'>
											<div className='experience'>
												{question?.views && <span>{formatNumber(question?.views)} {formatNumber(question?.views)?.length > 1 ? t('common.views') : t('common.view')}</span>}
											</div>
											<div className='votes-btn-wrapper d-flex justify-content-between'>
												<div>
													<button disabled={question?.author?._id === userId} className={`d-inline-block`} style={{ backgroundColor: question?.userUpvoted ? '#FD6F21' : upvotes[index] === true ? '#FD6F21' : 'transparent', color: question?.userUpvoted ? '#fff' : upvotes[index] === true ? '#fff' : '#FD6F21', cursor: question?.author?._id === userId ? 'not-allowed' : 'pointer' }} onClick={() => questionVotingHandler(question, 1, upvotes[index] ? false : true, index)}> <FontAwesomeIcon icon={faArrowUp} /><span className={`d-inline-block ms-2`}>{question?.totalUpvotes?.total || ''}</span></button>
													<button disabled={question?.author?._id === userId} className={`d-inline-block`} style={{ backgroundColor: downvotes[index] === true || question?.userDownvoted ? '#FD6F21' : 'transparent', color: downvotes[index] === true || question?.userDownvoted ? '#fff' : '#FD6F21', cursor: question?.author?._id === userId ? 'not-allowed' : 'pointer' }} onClick={() => questionVotingHandler(question, 2, downvotes[index] ? false : true, index)}> <FontAwesomeIcon icon={faArrowDown} /><span className={`d-inline-block ms-2`}>{question?.totalDownvotes?.total || ''}</span></button>
												</div>
												<div>
													<button className='btn btn-primary d-inline-block theme-btn orange-btn' onClick={() => { setQuestionData(question); setShow(true) }}> <FontAwesomeIcon icon={faEdit} /></button>
													{question.author?._id === userId && <button className='btn btn-primary d-inline-block theme-btn orange-btn' onClick={() => deleteQuestionHandler(question._id)}><FontAwesomeIcon icon={faTrash} /></button>}
												</div>
											</div>
										</div>
									</div>
								</section>
							)) :
							<p>{t('community.ask')}</p>
					}
					{
						questionData &&
						<Modal className='ask-question-modal' show={show} onHide={() => setShow(false)} centered>
							<Modal.Header closeButton>
								<Modal.Title>{t('community.editQuestion')}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form className='community-ask-question-form'>
									<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
										<Form.Label>{t('common.title')} *</Form.Label>
										<Form.Control type="text" value={questionData.title} onChange={(e) => setQuestionData({ ...questionData, title: e.target.value })} placeholder={t('community.quesTitlePlaceholder')} />
										{
											errors && errors.title &&
											<span className='text-danger'>{errors.title}</span>
										}
									</Form.Group>
									<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										<Form.Label>{t('common.description')}</Form.Label>
										<CKEditor
											id="blogpost-editor"
											editor={ClassicEditor}
											config={ckEditorCustomConfig}
											onError={(error) => {
												console.log(error);
											}}
											data={questionData.question || ''}
											content={questionData.question || ''}
											onChange={(event, editor) => onEditorChange(event, editor)}
										/>
										{
											errors && errors.question &&
											<span className='text-danger'>{errors.question}</span>
										}
									</Form.Group>
								</Form>
							</Modal.Body>
							<Modal.Footer>
								<Button className='theme-btn orange-btn d-inline-block' variant="secondary" onClick={() => setShow(false)}>
									{t('common.close')}
								</Button>
								<Button className='orange-main-button d-inline-block' variant="primary" onClick={editQuestionHandler}>
									{t('community.editQuestion')}
								</Button>
							</Modal.Footer>
						</Modal>
					}
				</Container>
				{(pagination.total > questions?.length) ?
					(<div className='text-center w-100 d-block'>
						<button className="orange-btn load-more-btn text-uppercase mx-auto" onClick={loadMoreQuestions}>{t('common.loadMoreBtn')}</button>
					</div>)
					:
					null}
			</div>
	)
}

export default CommunityPosts