import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import $ from "jquery";
import { ENV } from "../../../../config/config";
import { AiFillCaretLeft, AiFillCaretRight, AiFillForward, AiFillBackward } from 'react-icons/ai'
import { IoMdAdd } from "react-icons/io";
import VideoJS from "../../Player/VideoJS";
import { Rnd } from "react-rnd";
import { addBoundingObjects, beforeBoundingObjects, getBoundingObjects } from "./video.actions";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const AddBoundingObjects = (props) => {
  const { t } = useTranslation()
  const [metadata, setMetadata] = useState({});
  const [data, setData] = useState(null)
  const [videoOptions, setVideoOptions] = useState({
    autoplay: true,
    controls: false,
    loop: false,
    responsive: true,
    metadata: true,
    sources: []
    // sources: [{
    //   src: 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8',
    //   type: 'application/x-mpegURL'

    // }]
  })
  const [currentFrameData, setCurrentData] = useState({}) // frame details with converted dimensions
  const [frameMetadata, setFrameMetadata] = useState({ time: '', objects: [] }) // inital frame details
  const [updatedMetadata, setUpdated] = useState({}) // formatted frame details to update in metadata
  const [objectId, setId] = useState('') // id of object in current frame
  const [videoDimensions, setVidDimensions] = useState({}) // video width & height
  const [showDelIcon, setShowDel] = useState(false)
  const [vidForwardSec, setSeconds] = useState('')
  const [vidDuration, setDuration] = useState('')
  const [errors, setErrors] = useState({})
  const [isVideoReady, setVidReady] = useState(false)
  const [currentTime, setCurrentTime] = useState(0) // milliseconds
  const timeInterval = 0.001 // milliseconds 
  const boxWidth = 50
  const boxHeight = 50
  const currentLanguage = localStorage.getItem('selectedLang')
  let playerRef = React.useRef(null);

  useEffect(() => {
    if (Object.keys(props.selectedVideo)?.length) {
      setVideoOptions({
        autoplay: true,
        controls: true,
        loop: false,
        responsive: true,
        metadata: true,
        sources: [{
          src: 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8',
          type: 'application/x-mpegURL'

        }]
      })
    }

  }, [props.selectedVideo])

  useEffect(() => {
    if (playerRef.current)
      playerRef.current?.pause()
    props.setLoader(false)
  })

  useEffect(() => {
    if (props.video.getBoundingObjectsAuth) {
      let objectsMetadata = props.video.getBoundingObjects.metadata
      if (Object.keys(objectsMetadata)?.length) {
        let title = props.selectedVideo?.video?.split('videos/')[1]
        setMetadata(objectsMetadata)
        setVideoOptions({
          ...videoOptions,
          sources: [{
            src: `${ENV.streamingUrl}${title}/output.m3u8`,
            type: 'application/x-mpegURL'
          }]
        })
        const initialObjects = objectsMetadata[0] ? objectsMetadata[0]["objects"] : []
        if (Object.keys(objectsMetadata)[0] == currentTime)
          setFrameMetadata({ ...frameMetadata, time: parseInt(Object.keys(objectsMetadata)[0]), objects: [...initialObjects] })

        props.beforeBoundingObjects()
      }
    }

  }, [props.video.getBoundingObjectsAuth])

  useEffect(() => {
    if (props.video.addBoundingObjectsAuth) {
      handleClose()
      props.setLoader(false)
      props.beforeBoundingObjects()
    }
  }, [props.video.addBoundingObjectsAuth])

  useEffect(() => {
    let videoELem = $("video")[0];
    if (videoELem) {
      videoELem.onloadedmetadata = () => {
        if (videoELem.readyState >= 1 && !isVideoReady) {
          setVidReady(true)
        }
      }
    }
  })

  // calculate dimensions on window resizing
  useEffect(() => {
    window.onresize = function () {

      let videoELem = $("video")[0];
      if (videoELem && videoELem.src) {

        const width = videoELem.videoWidth
        const height = videoELem.videoHeight

        setVidDimensions({
          width,
          height
        })
      }
    }
  }, [])

  useEffect(() => {
    if (videoOptions?.sources[0]?.src && Object.keys(videoDimensions)?.length) {
      calculateDimensions()
    }

  }, [videoOptions, videoDimensions]);

  useEffect(() => {
    if (data && frameMetadata && frameMetadata?.objects?.length) {
      getCurrentFrameObjects()
    }

  }, [data, frameMetadata])

  const calculateDimensions = () => {

    let playerWidth = ''
    let playerHeight = ''

    playerWidth = $("video-js").width();
    playerHeight = $("video-js").height();

    let rendered_height = playerHeight
    const videoWidth = videoDimensions.width;
    const videoHeight = videoDimensions.height;

    let heightDifference = videoHeight - rendered_height;
    let calRenderedWidth = 0

    if (heightDifference < 0)
      heightDifference *= -1;

    let percentChangeInHeight = (heightDifference / videoHeight) * 100;

    if (rendered_height > videoHeight) {
      calRenderedWidth = videoWidth + (percentChangeInHeight * videoWidth) / 100;
    }
    else {
      calRenderedWidth = videoWidth - (percentChangeInHeight * videoWidth) / 100;
    }

    let verticalBlanckSpaceEachSide = (playerWidth - calRenderedWidth) / 2;

    setData({
      renderedWidth: calRenderedWidth,
      renderedHeight: rendered_height,
      offsetX: verticalBlanckSpaceEachSide,
      offsetY: 0
    })

  }


  const getCurrentFrameObjects = async () => {
    let objects = [...frameMetadata?.objects]
    let time = frameMetadata.time

    if (objects?.length) {

      let frameObjects = []

      for (let i = 0; i < objects.length; i++) {
        let x = objects[i].x;
        let y = objects[i].y;

        let w = objects[i].w;
        let h = objects[i].h;

        let { elemWidth, elemHeight, startX, startY } = getObjectData({ x, y, w, h })

        let obj = { id: objects[i].id, elemWidth, elemHeight, startX, startY }

        frameObjects.push({ ...obj })

      }

      setCurrentData({ time, objects: [...frameObjects] })
    }
    else {
      setCurrentData({ time, objects: [] })
    }
  }

  const getObjectData = (payload, type = 1) => {

    let { renderedWidth, renderedHeight, offsetX, offsetY } = data
    let returnObj = {}

    if (type === 1) {

      payload.x = parseFloat(payload.x)
      payload.y = parseFloat(payload.y)
      payload.w = parseFloat(payload.w)
      payload.h = parseFloat(payload.h)

      let startX = parseFloat((renderedWidth * payload.x) + offsetX).toFixed(3)
      let startY = parseFloat((renderedHeight * payload.y) + offsetY).toFixed(3);


      let endX = (renderedWidth * payload.w) + offsetX
      let endY = (renderedHeight * payload.h) + offsetY

      let elemWidth = parseFloat(endX - startX).toFixed(3)
      let elemHeight = parseFloat(endY - startY).toFixed(3)

      returnObj = { elemWidth, elemHeight, startX, startY }

    }
    else if (type === 2) {

      payload.startX = parseFloat(payload.startX)
      payload.startY = parseFloat(payload.startY)
      payload.elemWidth = parseFloat(payload.elemWidth)
      payload.elemHeight = parseFloat(payload.elemHeight)

      let x = parseFloat((payload.startX - offsetX) / renderedWidth).toFixed(3)
      let y = parseFloat((payload.startY - offsetY) / renderedHeight).toFixed(3)

      returnObj = { x, y }

      if (payload.elemHeight && payload.elemWidth) {
        let endX = payload.elemWidth + payload.startX
        let endY = payload.elemHeight + payload.startY

        let w = parseFloat((endX - offsetX) / renderedWidth).toFixed(3)
        let h = parseFloat((endY - offsetY) / renderedHeight).toFixed(3)

        returnObj.w = w
        returnObj.h = h
      }

    }

    return returnObj
  }

  const handleNextButton = (forwardSeconds = 0) => {
    let interval = Number(parseFloat(forwardSeconds ? forwardSeconds : timeInterval).toFixed(3))
    let timeToSet = Number(parseFloat(currentTime + interval).toFixed(4))

    if ((timeToSet / 1000) > vidDuration) {
      timeToSet = vidDuration
    }

    if (updatedMetadata[timeToSet]?.objects?.length) {
      setFrameMetadata({ ...frameMetadata, time: timeToSet, objects: [...updatedMetadata[timeToSet]?.objects] })
    }
    else if (metadata[timeToSet]?.objects?.length) {
      setFrameMetadata({ ...frameMetadata, time: timeToSet, objects: [...metadata[timeToSet]?.objects] })
    }
    else {
      setFrameMetadata({ time: timeToSet, objects: [] })
      setCurrentData({})
    }

    setCurrentTime(timeToSet)
    playerRef.current.currentTime(timeToSet / 1000);

  }

  const handlePrevButton = (rewindSeconds = 0) => {
    let interval = Number(parseFloat(rewindSeconds ? rewindSeconds : timeInterval).toFixed(3))
    let timeToSet = Number(parseFloat(currentTime - interval).toFixed(4))

    if (timeToSet < 0)
      timeToSet = 0

    setCurrentTime(timeToSet)
    playerRef.current.currentTime((timeToSet / 1000));

    if (updatedMetadata[timeToSet]?.objects?.length) {
      setFrameMetadata({ ...frameMetadata, time: timeToSet, objects: [...updatedMetadata[timeToSet]?.objects] })
    }
    else if (metadata[timeToSet]?.objects?.length) {
      setFrameMetadata({ ...frameMetadata, time: timeToSet, objects: [...metadata[timeToSet]?.objects] })
    }
    else {
      setFrameMetadata({ time: '', objects: [] })
      setCurrentData({})
    }
  }

  const resetStates = () => {
    setId('')
    setShowDel(false)
    setErrors({})
    setSeconds('')
  }

  const forwardRewindVideo = (type) => {
    if (vidForwardSec) {
      let milliseconds = parseFloat(vidForwardSec)
      let seconds = milliseconds / 1000

      if ((milliseconds < 0)) {
        milliseconds = 0
      }

      if ((seconds > vidDuration) && type == 1) {
        let diff = vidDuration - currentTime
        seconds = Number(parseFloat(diff).toFixed(3))
        milliseconds = seconds * 1000
      }

      setSeconds(milliseconds)

      if (milliseconds > 0) {
        if (type == 1)
          handleNextButton(milliseconds)
        else if (type == 2)
          handlePrevButton(milliseconds)

      }
    }
  }

  const handleClose = () => {

    setData({})
    setMetadata(null)
    setVidReady(false)
    setUpdated({})

    props.setShow(false);
  };

  const mergeMetadata = () => {
    let mergedMetadata = { ...metadata }
    Object.keys(updatedMetadata).forEach((timeKey) => {
      if (mergedMetadata[timeKey] && mergedMetadata[timeKey]?.objects?.length) {
        mergedMetadata[timeKey].objects = [...updatedMetadata[timeKey].objects]
      }
      else {
        mergedMetadata = { ...mergedMetadata, [timeKey]: { objects: [...updatedMetadata[timeKey].objects] } }
      }
    })

    return mergedMetadata
  }

  const saveDetails = () => {
    if (props.selectedVideo?._id) {
      let payload = { videoId: props.selectedVideo.video.split('videos/')[1].split('.')[0] }
      let flag = false

      if (Object.keys(metadata)?.length) {
        let mergedMetadata = mergeMetadata()
        payload.updatedFrames = mergedMetadata
        flag = true
      }
      else if (updatedMetadata && Object.keys(updatedMetadata).length) {
        payload.updatedFrames = updatedMetadata
        flag = true
      }

      if (flag) {
        payload.lng = currentLanguage
        props.addBoundingObjects(props.selectedVideo?._id, payload)
        props.setLoader(true)
      }
      else
        toast.info(t('editAnnotation.noChanges'))
    }
  }

  const handleDragEnd = async (e, positions, id) => {

    let { x, y } = positions
    let width = parseFloat(e.target.style.width)
    let height = parseFloat(e.target.style.height)

    let frameObjects = [...frameMetadata.objects]
    let object = { ...frameObjects[id] }
    let values = getObjectData({ startX: x, startY: y, elemWidth: width, elemHeight: height }, 2)

    object.x = values.x
    object.y = values.y
    object.w = values.w
    object.h = values.h
    frameObjects[id] = object

    setFrameMetadata({ ...frameMetadata, objects: [...frameObjects] })
    setUpdated({ ...updatedMetadata, [frameMetadata.time]: { objects: [...frameObjects] } })
  }


  const handleResizeStop = (ref, position, id) => {

    let { width, height } = ref.style
    let { x, y } = position

    let frameObjects = [...frameMetadata.objects]
    let object = { ...frameObjects[id] }
    let values = getObjectData({ startX: x, startY: y, elemWidth: width, elemHeight: height }, 2)

    object.x = values.x
    object.y = values.y
    object.w = values.w
    object.h = values.h
    frameObjects[id] = object

    setFrameMetadata({ ...frameMetadata, objects: [...frameObjects] })
    setUpdated({ ...updatedMetadata, [frameMetadata.time]: { objects: [...frameObjects] } })
  }

  const handlePlayerReady = (player) => {

    playerRef.current = player;

    player.on("loadedmetadata", function (e) {
      setVidDimensions({
        height: player.videoHeight(),
        width: player.videoWidth()
      })
    })

    player.on('timeupdate', () => {
      setDuration(playerRef.current.duration())
    });

  }

  const deleteObjectHandler = () => {

    let filteredObjects = []
    if (currentFrameData?.objects) {
      filteredObjects = frameMetadata.objects.filter((o, ind) => o.id != objectId).map((ob, ind) => { return { ...ob, id: ind.toString() } })
      setFrameMetadata({ ...frameMetadata, objects: [...filteredObjects] })

      if (!filteredObjects.length) {
        filteredObjects = currentFrameData.objects.filter((o, ind) => o.id != objectId).map((ob, ind) => { return { ...ob, id: ind.toString() } })
        setCurrentData({ ...currentFrameData, objects: [...filteredObjects] })
      }
    }

    if (updatedMetadata[currentTime]?.objects) {
      filteredObjects = updatedMetadata[currentTime]?.objects.filter((o) => o.id != objectId).map((ob, ind) => { return { ...ob, id: ind.toString() } })
      setUpdated({ ...updatedMetadata, [currentTime]: { ...updatedMetadata[currentTime], objects: [...filteredObjects] } })
    }

    resetStates()
  }

  const secondsChangeHandler = (e) => {
    let value = e.target.value
    let err = ""

    if (value) {
      if (value < 0)
        err = t('editAnnotation.time')
      else
        err = ""
    }

    setErrors({ ...errors, seconds: err })
    setSeconds(value)

  }

  const closeModalHandler = () => {
    if (Object.keys(updatedMetadata)?.length) {
      Swal.fire({
        title: t('editAnnotation.saveChanges'),
        html: t('editAnnotation.saveChangesMessage'),
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('editAnnotation.saveChangesMessage'),
        cancelButtonText: t('editAnnotation.cancelButtonText')
      }).then(async (result) => {
        if (result.value) {
          saveDetails()
        }
        else {
          handleClose()
        }
      })
    }
    else {
      handleClose()
    }
  }

  const addBoundingBox = () => {
    let { renderedWidth, renderedHeight, offsetX, offsetY } = data
    let centerX = (offsetX + renderedWidth) / 2
    let centerY = (offsetY + renderedHeight) / 2
    let newObject = {}

    if (currentFrameData?.objects?.length) {

      let lastObject = currentFrameData.objects[currentFrameData.objects?.length - 1]

      newObject = {
        id: parseInt(lastObject.id) + 1,
        elemWidth: boxWidth,
        elemHeight: boxHeight,
        startX: centerX,
        startY: centerY
      }

    }
    else {
      newObject = {
        id: 0,
        elemWidth: boxWidth,
        elemHeight: boxHeight,
        startX: centerX,
        startY: centerY

      }

    }

    let { x, y, w, h } = getObjectData({ startX: newObject.startX, startY: newObject.startY, elemWidth: newObject.elemWidth, elemHeight: newObject.elemHeight }, 2)
    if (frameMetadata && frameMetadata?.objects?.length) {
      setFrameMetadata({
        ...frameMetadata,
        objects: [...frameMetadata.objects, { id: newObject.id, x, y, w, h }]
      })
    }
    else {
      setFrameMetadata({
        time: currentTime,
        objects: [{ id: newObject.id, x, y, w, h }]
      })
    }
    setUpdated({ ...updatedMetadata, [currentTime]: { objects: [...frameMetadata?.objects, { id: newObject.id, x, y, w, h }] } })

  }

  return (
    <div className="modalPage">
      <div className="modal themeModal show">
        <Modal
          show={props.show}
          onHide={closeModalHandler}
          className="medium-Model video-modal"
          centered
        >
          <div className="animatedTab">
            <Modal.Header closeButton>
              <Modal.Title>{t('common.videoTime')} {currentTime} {t('editAnnotation.milliseconds')} {`(${t('editAnnotation.s')})`}</Modal.Title>
            </Modal.Header>
            <div className="videoModal">
              {
                isVideoReady ?
                  <Row className="text-white video-dragger-holder">
                    <Col className="d-flex align-items-center mb-3">
                      <label className="mb-0"><span className="text-white">Go To</span></label>
                      {
                        currentTime > 0 &&
                        <span className="icon-holder cursor-pointer" onClick={() => forwardRewindVideo(2)} >
                          <AiFillBackward className={!vidForwardSec || vidForwardSec <= 0 ? 'disabled-icon' : ''} />
                        </span>
                      }
                      <input className="form-control" type="number" value={vidForwardSec} onChange={secondsChangeHandler} placeholder="milliseconds"></input>
                      {(currentTime / 1000) < vidDuration &&
                        <span className="icon-holder cursor-pointer" onClick={() => forwardRewindVideo(1)}>
                          <AiFillForward className={!vidForwardSec || vidForwardSec <= 0 ? 'disabled-icon' : ''} />
                        </span>
                      }
                      {
                        errors && errors.seconds ?
                          <span className="text-danger ">{errors.seconds}</span> :
                          null
                      }
                    </Col>
                  </Row> :
                  null
              }
              <Row className="">
                <Col lg={12}>
                  <div className="d-flex justify-content-between align-items-center">

                    <div className="for-rev-icons">
                      {
                        isVideoReady ?
                          currentTime > 0 &&
                          <AiFillCaretLeft className="cursor-pointer" onClick={() => { handlePrevButton() }} />
                          : null
                      }
                    </div>
                    <div id="custom-player" className="v-custom-player mb-4 mx-2">
                      {
                        isVideoReady && currentFrameData && currentFrameData.objects?.length ?
                          currentFrameData.objects.map((d) => {
                            return (
                              <Rnd
                                onClick={() => { setId(d.id); setShowDel(true); }}
                                id={d.id}
                                bounds="#custom-player"
                                size={{ width: d.elemWidth > 0 ? parseFloat(d.elemWidth) : 0, height: d.elemHeight > 0 ? parseFloat(d.elemHeight) : 0 }}
                                position={{ x: d.startX > 0 ? parseFloat(d.startX) : 0, y: d.startY > 0 ? parseFloat(d.startY) : 0 }}
                                style={{
                                  zIndex: 100,
                                  position: 'absolute',
                                  outline: d.id == objectId ? "green solid 2px" : "#dc3545 solid 2px",
                                }}

                                onDragStop={(e, positions) => handleDragEnd(e, positions, d.id)}
                                onResize={(e, direction, ref, delta, position, id) => handleResizeStop(ref, position, d.id)}
                              >
                                <span style={{ position: "absolute", left: 0, top: 0, background: d.id == objectId ? "green" : "red", color: "#fff", fontWeight: "bold", fontSize: '14px' }}>{d.la}</span>
                              </Rnd>
                            )
                          })
                          : null
                      }
                      {/* </div> */}
                      <div id="custom-video">
                        <VideoJS className='video-js ' options={videoOptions} onReady={(player) => handlePlayerReady(player)} />
                      </div>
                    </div>

                    <div className="for-rev-icons">
                      {
                        isVideoReady ?
                          (currentTime / 1000) < vidDuration &&
                          <AiFillCaretRight className="cursor-pointer" onClick={() => { handleNextButton() }} />
                          : null
                      }
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-4 flex-column px-4 edit-delete-icons">
                    <div className="d-flex justify-content-end align-items-center w-100">
                      {
                        isVideoReady &&
                        <span className="icon-holder cursor-pointer" onClick={addBoundingBox}>
                          <IoMdAdd />
                        </span>
                      }
                      {
                        showDelIcon ?
                          <span className="icon-holder cursor-pointer" onClick={() => deleteObjectHandler()}>
                            <FaTrash />
                          </span> : null
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Modal.Footer className="py-0 px-4 justify-content-end">
              <button className="ml-2 orange-btn text-uppercase btn-filled btn-save" onClick={saveDetails}>
                {t('common.saveBtn')}
              </button>
              <button type="button" className="orange-btn text-uppercase ml-2 btn-filled" onClick={closeModalHandler}>
                {t('common.close')}
              </button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  video: state.videos,
});

export default connect(mapStateToProps, {
  addBoundingObjects, beforeBoundingObjects, getBoundingObjects
})(AddBoundingObjects);