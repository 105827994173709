import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { ENV } from "../config/config";
import en from './translations/en/translation.json';
import ar from './translations/ar/translation.json';

const resources = {
    [ENV.languages.english.code]: {
        translation: en
    },
    [ENV.languages.arabic.code]: {
        translation: ar
    }
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        resources,
        fallbackLng: ENV.languages.english.code,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;
