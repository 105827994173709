import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { beforeChannelAnalytics } from '../../MyChannels/channels.actions';

const Audience = (props) => {
    const dispatch = useDispatch()

    const colors = ['#5470C6', '#FD6F21'];
    const tabColors = {
        'grey': '#393C48',
        'black': '#fff'
    }
    const [options, setOptions] = useState({
        color: colors,
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                type: 'line',
                color: colors[1],
                symbol: "circle",
            }
        ]
    });

    const [clickedStatOption, setClickedOption] = useState(1);
    const [analytics, setAnalytics] = useState({})
    const channelSelector = useSelector((state) => state.channels)

    useEffect(() => {
        if (channelSelector.channelAnalyticsAuth) {
            let data = channelSelector?.channelAnalyticsData
            if (data?.channelAnalytics && data?.statType == '2') {
                setAnalytics(data?.channelAnalytics)
            }

            props.setLoader(false)
            setClickedOption(1)
            dispatch(beforeChannelAnalytics())
        }
    }, [channelSelector.channelAnalyticsAuth])

    useEffect(() => {
        if (clickedStatOption && analytics) {
            props.setLoader(false)
            let optionsData = { ...options }
            let { returningAudienceStats, uniqueAudienceStats, identifiedObjects } = analytics
            let xAxisDates = []
            let yAxisValues = []

            let key = clickedStatOption === 1 ? returningAudienceStats : clickedStatOption === 2 ? uniqueAudienceStats : clickedStatOption === 3 ? identifiedObjects : null
            let dateIntervals = []
            let seriesData = []

            if (key)
                if (key.data?.length) {
                    let indexes = []
                    key.data.map((stat) => {

                        let statDate = moment(new Date(stat.date)).format('DD-MMM-YY')
                        xAxisDates.push(statDate)
                        yAxisValues.push(stat.total);

                        dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate, 1, yAxisValues.length)
                        seriesData = new Array(dateIntervals.length).fill(0)
                        dateIntervals.map((dt, indx) => {
                            if (dt === statDate)
                                indexes.push(indx)
                        })
                    })
                    indexes.map((i, ind) => {
                        seriesData[i] = yAxisValues[ind]
                    })
                }
                else {
                    dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate)
                    seriesData = new Array(dateIntervals.length).fill(0)
                }

            optionsData.xAxis = { ...optionsData.xAxis, data: dateIntervals }
            optionsData.series = [
                {
                    ...optionsData.series[0],
                    name: clickedStatOption === 1 ? 'Returning Audience' : clickedStatOption === 2 ? 'Returning Audience' : clickedStatOption === 2 ? 'Recognized Objects' : '',
                    data: seriesData,
                }
            ]

            setOptions(optionsData)
        }
    }, [clickedStatOption, analytics])

    const statOptionsChangeHandler = (option) => {
        if (option !== clickedStatOption) {
            setClickedOption(option)
            props.setLoader(true)
        }
    }

    return (
        <>
            <div className='dashboardPage'>
                <div className='analyticContent'>
                    <div className='analyticGraph'>
                        <div className='analyticsBar'>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 1 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(1)}>
                                <span>{props.t('common.audience.returningViewers')}</span>
                                <h4>{analytics.returningAudienceStats ? analytics.returningAudienceStats?.total : 0}</h4>
                            </div>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 2 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(2)}>
                                <span>{props.t('common.audience.uniqueViewers')}</span>
                                <h4>{analytics.uniqueAudienceStats ? analytics.uniqueAudienceStats?.total : 0}</h4>
                            </div>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 3 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(3)}>
                                <span>{props.t('common.recognisedObjects')}</span>
                                <h4>{analytics.identifiedObjects ? analytics.identifiedObjects?.total : 0}</h4>
                            </div>
                        </div>
                        <div className='graphItems mt-4 mx-4'>
                            <ul>
                                <li className='orange'><div className='orange'><p>{props.t('common.audience.returningViewers')}</p></div></li>
                                <li><div className='blue'><p>{props.t('common.audience.uniqueViewers')}</p></div></li>
                            </ul>
                        </div>
                        <ReactECharts option={options} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Audience