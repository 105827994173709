import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Join = () => {
	const { t } = useTranslation()
    return (
        <>
            <section className='join-sec'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h1>{t('aboutUs.becomeTeamPart.becomePart')} <span className='theme d-block'>{t('aboutUs.becomeTeamPart.ourTeam')}</span></h1>
                        </Col>
                        <Col lg={6}>
                            <p>{t('aboutUs.becomeTeamPart.beContributor')}</p>
                            <Link to='/positions' className='d-inline-block align-top mt-0 orange-main-button text-uppercase'>{t('aboutUs.becomeTeamPart.explorePositions')}</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Join