import React, { useEffect } from 'react'
import { useState } from 'react'
import { Form, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ENV } from '../../../../../config/config'
import SmallLoader from '../../../../../utils/SmallLoader/SmallLoader'
import { validateUrl } from '../../../../../utils/Validations/validations' 
import '../../Models/Models.css'

const { allowedMediaTypes, defaultPlaceholderImage } = ENV

const SocialSettings = (props) => {
    const { t } = useTranslation()
    const [bannerImage, setBannerImage] = useState('')

    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div className='general'>
                        <h4>{t('settings.social.heading')}</h4>
                        <div className='fileGroupList'>
                            <p className='fileLabel'>{t('settings.social.facebookUrl')}</p>
                            <Form.Control
                                type="text"
                                name="facebookUrl"
                                value={props?.facebookUrl || ''}
                                onChange={(e) => props.setData({ facebookUrl: e.target.value })}
                            >
                            </Form.Control>
                            {
                                props.errors && props.errors.facebookUrl &&
                                <span className='submit-errors text-danger'>{props.errors.facebookUrl}</span>
                            }
                        </div>
                        <div className='fileGroupList'>
                            <p className='fileLabel'>{t('settings.social.linkedInUrl')}</p>
                            <Form.Control
                                type="text"
                                name="linkedinUrl"
                                value={props?.linkedinUrl || ''}
                                onChange={(e) => props.setData({ linkedinUrl: e.target.value })}
                            >
                            </Form.Control>
                            {
                                props.errors && props.errors.linkedinUrl &&
                                <span className='submit-errors text-danger'>{props.errors.linkedinUrl}</span>
                            }
                        </div>
                        <div className='fileGroupList'>
                            <p className='fileLabel'>{t('settings.social.instagramUrl')}</p>
                            <Form.Control
                                type="text"
                                name="instagramUrl"
                                value={props?.instagramUrl || ''}
                                onChange={(e) => props.setData({ instagramUrl: e.target.value })}
                            >
                            </Form.Control>
                            {
                                props.errors && props.errors.instagramUrl &&
                                <span className='submit-errors text-danger'>{props.errors.instagramUrl}</span>
                            }
                        </div>
                        <div className='fileGroupList'>
                            <p className='fileLabel'>{t('settings.social.websiteUrl')}</p>
                            <Form.Control
                                type="text"
                                name="websiteUrl"
                                value={props?.websiteUrl || ''}
                                onChange={(e) => props.setData({ websiteUrl: e.target.value })}
                            >
                            </Form.Control>
                            {
                                props.errors && props.errors.websiteUrl &&
                                <span className='submit-errors text-danger'>{props.errors.websiteUrl}</span>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default SocialSettings