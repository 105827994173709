import React, { Suspense, useState } from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Getter from './Getter';
import routes from './routes'
import { ENV } from './config/config';
import FullPageLoader from './components/Frontend/FullPageLoader/FullPageLoader';

const App = (props) => {

    if (document.getElementById("all-the-static-content")) {
        document.getElementById("all-the-static-content").style.display = "none"
    }

    return (
        <HelmetProvider>
             <Suspense fallback={<FullPageLoader />}>
                <Getter />
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            exact={route.exact}
                            path={route.path}
                            element={
                                <RouteHandler
                                    route={route}
                                    props={props}
                                />
                            }
                            key={index}
                        />
                    ))}
                </Routes>
            </Suspense>
        </HelmetProvider>
    );
};

const RouteHandler = ({ route, props }) => {
    if (route.layout) {
        return (
            <route.layout
                {...props}
                title={route.name}
                description={route.description}
            >
                <ContentWithHelmet {...route} {...props} />
            </route.layout>
        );
    }
    return <ContentWithHelmet {...route} {...props} />;
};

const ContentWithHelmet = ({ component: Component, name, description, ...props }) => {
    return (
        <>
            <Helmet>
                <title>{name}</title>
                {/* {description && ( */}
                <meta name="description" content={description || ENV.defaultMetaDescription} />
                {/* )} */}
            </Helmet>
            <Component {...props} />
        </>
    );
};

export default App;