import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { FaFacebookF, FaLinkedin, FaPinterest, FaTwitter, FaYoutube } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux'
import { createContact, beforeContact } from './contact.actions';
import GoogleMapReact from 'google-map-react';
import { getSiteSettings, beforeSiteSettings, beforeMapSettigs } from '../SiteSettings/siteSettings.actions';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { validateEmail, validateName, validatePhone } from '../../../utils/Validations/validations';
import { ENV } from '../../../config/config';
import { useTranslation } from 'react-i18next'
const { googleMapApiKey } = ENV

const GetInTouch = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [contactData, setData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    })
    const [loader, setLoader] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [siteSettings, setSettings] = useState({})
    const [errors, setErrors] = useState({})
    const [location, setLocation] = useState({
        lat: 0,
        lng: 0,
    })
    const [mapsData, setMapData] = useState()
    const settingsSelector = useSelector((state) => state.siteSettings)
    const contactSelector = useSelector((state) => state.contact)
    const currentLanguage = localStorage.getItem('selectedLang')
    useEffect(() => {
        dispatch(getSiteSettings(`lng=${currentLanguage}`))
    }, [])

    useEffect(() => {
        if (settingsSelector.getSiteSettingsAuth) {
            let { settings } = settingsSelector.getSiteSettingsData
            setSettings(settings)
            setLocation({ ...settings?.location })
            dispatch(beforeSiteSettings())
        }
    }, [settingsSelector.getSiteSettingsAuth])

    useEffect(() => {
        if (Object.keys(location)?.length) {
            if (location.lat && location.lng && mapsData) {
                loadLocation(mapsData.map, mapsData.maps)
            }
        }
    }, [location])

    useEffect(() => {
        if (contactSelector.createContactAuth) {
            setLoader(false)
            setData({
                name: '',
                email: '',
                phone: '',
                message: '',
                subject: ''
            })
            dispatch(beforeContact())
        }
    }, [contactSelector.createContactAuth])

    const onChangeHandler = (e) => {
        if (submitted) {
            if (e.target.value)
                setErrors({ ...errors, [e.target.name]: '' })

            else
                setErrors({ ...errors, [e.target.name]: t('common.fieldRequired') })


        }
        setData({ ...contactData, [e.target.name]: e.target.value })
    }

    const submit = () => {
        setSubmitted(true)

        let nameErr = ''
        let emailErr = ''
        let subjErr = ''
        let msgErr = ''
        let phoneErr = ''
        let msg = t('common.fieldRequired')

        if (!contactData.name)
            nameErr = msg
        else {
            contactData.name = contactData.name.replace(/\s+/g, " ").trim()
            nameErr = validateName(t, contactData.name)
        }

        if (!contactData.email)
            emailErr = msg
        else
            emailErr = validateEmail(t, contactData.email)

        if (!contactData.subject)
            subjErr = msg
        else if (contactData.subject.length > 50)
            subjErr = t('getInTouch.subjectErr')

        if (!contactData.message)
            msgErr = msg

        if (contactData.phone) {
            phoneErr = validatePhone(t, contactData.phone)
        }

        contactData.lng = currentLanguage

        setErrors({ name: nameErr, email: emailErr, subject: subjErr, message: msgErr, phone: phoneErr })

        if (nameErr || emailErr || subjErr || msgErr || phoneErr)
            return

        setLoader(true)
        dispatch(createContact(contactData))

    };
    const loadLocation = (map, maps) => {
        setMapData({ map, maps })

        let marker = new maps.Marker({
            position: { lat: location.lat, lng: location.lng },
            map,
            draggable: false,
            title: siteSettings?.address
        });

        map.setCenter(location);
        marker.setPosition(location);

    };

    return (
        <>
            {loader && <FullPageLoader />}
            <section className='getintouch-sec'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h1>{t('getInTouch.heading')}</h1>
                            <p>{t('getInTouch.message')}</p>
                            {
                                siteSettings &&
                                <div className='details'>
                                    {
                                        siteSettings.email ?
                                            <a className='detail' href={`mailto:${siteSettings.email}`}><span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 25">
                                                <path id="Icon_material-email" data-name="Icon material-email" d="M31.8,6H6.2A3.159,3.159,0,0,0,3.016,9.125L3,27.875A3.172,3.172,0,0,0,6.2,31H31.8A3.172,3.172,0,0,0,35,27.875V9.125A3.172,3.172,0,0,0,31.8,6Zm0,6.25L19,20.062,6.2,12.25V9.125L19,16.938,31.8,9.125Z" transform="translate(-3 -6)" fill="#fff" />
                                            </svg></span> {siteSettings.email}</a>
                                            : null
                                    }
                                    {
                                        siteSettings.phone ?
                                            <a className='detail' href={`tel:${siteSettings.phone}`}><span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                                                <path id="Icon_zocial-call" data-name="Icon zocial-call" d="M3.334,9.225a2.353,2.353,0,0,1,.6-1.279l4.8-4.795q.561-.479.84.16l3.881,7.272a1.045,1.045,0,0,1-.2,1.239L11.5,13.581a1.987,1.987,0,0,0-.56,1.239,6.1,6.1,0,0,0,1.24,3.077,26.277,26.277,0,0,0,2.44,3.2l1.24,1.277c.374.373.853.833,1.441,1.378a24.151,24.151,0,0,0,2.9,2.158,6.494,6.494,0,0,0,3.18,1.338,1.743,1.743,0,0,0,1.28-.519l2.082-2.078a.88.88,0,0,1,1.2-.16l7,4.115a.584.584,0,0,1,.32.42.51.51,0,0,1-.16.459l-4.8,4.795a2.352,2.352,0,0,1-1.279.6,9.69,9.69,0,0,1-4.422-.539,20.017,20.017,0,0,1-4.559-2.178q-2.1-1.358-3.9-2.756t-2.88-2.4l-1.04-1q-.4-.4-1.06-1.1t-2.3-2.756a40.127,40.127,0,0,1-2.84-4.015,23.411,23.411,0,0,1-2.08-4.455A9.466,9.466,0,0,1,3.334,9.225Z" transform="translate(-3.267 -2.947)" fill="rgba(255,255,255,1)" />
                                            </svg>
                                            </span> {siteSettings.phone}</a>
                                            : null
                                    }
                                    {
                                        siteSettings.address ?
                                            <a className='detail d-flex' href="#company-address"><span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 22 32">
                                                <path id="Icon_material-location-on" data-name="Icon material-location-on" d="M18.5,3a11.093,11.093,0,0,0-11,11.2c0,8.4,11,20.8,11,20.8s11-12.4,11-20.8A11.093,11.093,0,0,0,18.5,3Zm0,15.2a4,4,0,1,1,3.929-4A3.966,3.966,0,0,1,18.5,18.2Z" transform="translate(-7.5 -3)" fill="rgba(255,255,255,1)" />
                                            </svg>
                                            </span> {siteSettings.address}</a>
                                            : null
                                    }
                                </div>
                            }
                            <div className='socialIcon'>
                                {
                                    siteSettings &&
                                    <ul>
                                        {   
                                            siteSettings.facebook ?
                                                <li><a href={siteSettings.facebook} target="_blank" rel='noreferrer'><FaFacebookF /></a></li> : null

                                        }
                                        {
                                            siteSettings.twitter ?
                                                <li><a href={siteSettings.twitter} target="_blank" rel='noreferrer'><FaTwitter /></a></li> : null

                                        }
                                        {
                                            siteSettings.linkedin ?
                                                <li><a href={siteSettings.linkedin} target="_blank" rel='noreferrer'><FaLinkedin /></a></li> : null
                                        }
                                        {
                                            siteSettings.pinterest ?
                                                <li><a href={siteSettings.pinterest} target="_blank" rel='noreferrer'><FaPinterest /></a></li> : null

                                        }
                                        {
                                            siteSettings.youtube ?
                                                <li><a href={siteSettings.youtube} target="_blank" rel='noreferrer'><FaYoutube /></a></li> : null
                                        }
                                    </ul>
                                }
                            </div>
                            <div className='contactus-map' id="company-address">
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: googleMapApiKey, libraries: ['places'] }}
                                    defaultCenter={location}
                                    defaultZoom={11}
                                    draggable={true}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => loadLocation(map, maps)}
                                >
                                </GoogleMapReact>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='help-sec'>
                                <div className='form-wrapper'>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{t('common.name')} <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control type="text" name='name' value={contactData.name} onChange={onChangeHandler} />
                                            {
                                                errors && errors.name &&
                                                <span className='text-danger'>{errors.name}</span>
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{t('common.email')} <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control type="email" name='email' value={contactData.email} onChange={onChangeHandler} />
                                            {
                                                errors && errors.email &&
                                                <span className='text-danger'>{errors.email}</span>
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{t('common.phoneNumber')}</Form.Label>
                                            <Form.Control type="text" name='phone' value={contactData.phone} onChange={onChangeHandler} />
                                            {
                                                errors && errors.phone &&
                                                <span className='text-danger'>{errors.phone}</span>
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{t('getInTouch.subject')} <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control type="text" name='subject' value={contactData.subject} onChange={onChangeHandler} />
                                            {
                                                errors && errors.subject &&
                                                <span className='text-danger'>{errors.subject}</span>
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{t('common.yourMessage')} <span className='text-danger'>*</span></Form.Label>
                                            <textarea className='w-100' rows={3} name='message' value={contactData.message} onChange={onChangeHandler}></textarea>
                                            {
                                                errors && errors.message &&
                                                <span className='text-danger'>{errors.message}</span>
                                            }
                                        </Form.Group>
                                        <div className='submit-btn text-end'>
                                            <Button variant="" onClick={submit}>{t('common.submit')}</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GetInTouch