import React from 'react'
import GetInTouch from './GetInTouch'
import Query from './Query'
import './Contact.css'

const Contactus = () => {
  return (
    <>
        <Query />
        <GetInTouch />
    </>
  )
}

export default Contactus