import React, { useRef } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import VideoUrlSection from './VideoUrl'
import { ENV } from '../../../../../config/config'
import { Tooltip } from 'react-tooltip'
import { BsInfoCircleFill } from 'react-icons/bs'
import '../../Models/Models.css'

const { defaultPlaceholderImage, allowedMediaTypes } = ENV

const Details = (props) => {

    const thumbnailsRef = useRef(null)

    return (
        <>
            <div className='modalPage'>

                <div className=" themeModal" >
                    <div className='seletFile modalBox'>
                        <Row>
                            <Col md={8} className='mb-md-0 mb-3'>
                                <h4 className='modal-H4'>{props.t('editVideoDetails.details.heading')}</h4>
                                <div className='fileGroup'>
                                    <div className='fileGroupList'>
                                        <p className='fileLabel'><div className='d-flex align-items-center'>{props.t('editVideoDetails.details.title')}<span className="text-danger danger-asterik"> *</span></div></p>
                                        <Form.Control type="text" name="title" onChange={props.onChangeHandler} value={props.videoDetails.title}></Form.Control>
                                        {
                                            props.errors && props.errors.title &&
                                            <span className='submit-errors text-danger'>{props.errors.title}</span>
                                        }
                                    </div>
                                    <div className='fileGroupList'>
                                        <p className='fileLabel'><div className='d-flex align-items-center'>{props.t('editVideoDetails.details.description')}<span className="text-danger danger-asterik"> *</span></div></p>
                                        <Form.Control className='w-100' as="textarea" name="description" rows={3} onChange={props.onChangeHandler} placeholder={props.t('editVideoDetails.details.descPlaceholder')} value={props.videoDetails.description}>
                                        </Form.Control>
                                        {
                                            props.errors && props.errors.description &&
                                            <span className='submit-errors text-danger'>{props.errors.description}</span>
                                        }
                                    </div>
                                    <div className='fileGroupList'>
                                        <p className='fileLabel'><div className='d-flex align-items-center'>{props.t('editVideoDetails.details.keywords')}</div></p>
                                        <Form.Control className='w-100' as="textarea" name="keywords" rows={3} onChange={props.onChangeHandler} placeholder='#myVideo, #biiview' value={props.videoDetails.keywords}>
                                        </Form.Control>
                                        {
                                            props.errors && props.errors.keywords &&
                                            <span className='submit-errors text-danger'>{props.errors.keywords}</span>
                                        }
                                        <p className='fileLabel opacity-40'>{props.t('common.commaSeparated')}</p>
                                        <p className='fileLabel opacity-40'>{props.t('editVideoDetails.details.keywordsNote')}</p>
                                    </div>
                                    <div className='fileGroupList'>
                                        <p className='fileLabel'><div className='d-flex align-items-center'>{props.t('editVideoDetails.details.metaTitle')}</div></p>
                                        <Form.Control className='w-100' as="textarea" name="metaTitle" rows={3} onChange={props.onChangeHandler} placeholder={props.t('editVideoDetails.details.metaTitlePlaceholder')} value={props.videoDetails.metaTitle}>
                                        </Form.Control>
                                    </div>
                                    <div className='fileGroupList'>
                                        <p className='fileLabel'><div className='d-flex align-items-center'>{props.t('editVideoDetails.details.metaDesc')}</div></p>
                                        <Form.Control className='w-100' as="textarea" name="metaDescription" rows={3} onChange={props.onChangeHandler} placeholder={props.t('editVideoDetails.details.metaDescPlaceholder')} value={props.videoDetails.metaDescription}>
                                        </Form.Control>
                                    </div>
                                </div>
                                <div className='thumbnail'>
                                    <h4 className='modal-H4'>{props.t('editVideoDetails.details.frames')} <span className="text-danger danger-asterik"> *</span><span className="mb-0 ms-3" id="thumbnail-info"><BsInfoCircleFill /></span></h4>
                                    <Tooltip
                                        anchorId="thumbnail-info"
                                        place="right"
                                        content={`${props.t('common.supportedTypes')}${allowedMediaTypes.images.map((ext) => `.${ext}`).join(', ')}`}
                                    />
                                    <Form.Control
                                        type="file"
                                        ref={thumbnailsRef}
                                        varient="info"
                                        accept=".png,.jpeg,.jpg"
                                        onChange={(e) => props.onFileChange(e.target.files, 4, e.target.id)}
                                        id="thumbnails"
                                        className='thumbnail-form-control'
                                    >
                                    </Form.Control>
                                    <div className='thumbnailImg'>
                                        {
                                            ((props.videoDetails.thumbnails || props.fileInputs.thumbnails?.length) /* && !props.errors.thumbnails */) ?
                                                <div className=''>
                                                    {
                                                        (props.videoDetails.thumbnails || props.fileInputs.thumbnails).map((thumbnail, index) => {
                                                            return <img key={index} className='videoImg' src={thumbnail} alt='thumbnail'
                                                                onClick={() => {
                                                                    thumbnailsRef.current.click(); props.setImgIdx(index)
                                                                }}
                                                            />
                                                        })
                                                    }
                                                </div> :
                                                <div className='thumbnailImg'>
                                                    <img id="thumbnails-img" className='videoImg' src={defaultPlaceholderImage} alt='thumbnail' />
                                                </div>

                                        }
                                    </div>
                                    {
                                        props.errors.thumbnails &&
                                        <span className='submit-errors text-danger'>{props.errors.thumbnails}</span>
                                    }
                                </div>
                            </Col>
                            <Col md={4}>
                                <VideoUrlSection video={props.videoDetails} inputs={props.fileInputs} t={props.t} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Details