import { toast } from 'react-toastify';
import { emptyError } from '../../../redux/shared/error/error.action';
import { LIST_TUTORIALS, GET_TUTORIAL, BEFORE_TUTORIAL, GET_ERRORS } from '../../../redux/types';
import { ENV } from '../../../config/config';

export const beforeTutorial = () => {
    return {
        type: BEFORE_TUTORIAL
    }
}

export const listTutorials = (qs = '') => dispatch => {
    toast.dismiss()
    dispatch(emptyError());
    let url = `${ENV.url}publisher/tutorials/list`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_TUTORIALS,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: "tutorials-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "tutorials-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getTutorial = (qs = '') => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}publisher/tutorials/get`;
    if (qs)
        url += `?${qs}`

    console.log(url)

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_TUTORIAL,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};