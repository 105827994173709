import { toast } from 'react-toastify';
import { emptyError } from '../../../redux/shared/error/error.action';
import { GET_ERRORS, BEFORE_CHANNEL_ROLE, GET_CHANNEL_ROLE, LIST_CHANNEL_ROLES } from '../../../redux/types';
import { ENV } from '../../../config/config';

export const beforeChannelRole = () => {
    return {
        type: BEFORE_CHANNEL_ROLE
    }
}

export const getChannelRole = (qs = '') => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}channels/roles/get`;
    if(qs)
        url = `${url}?${qs}`
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_CHANNEL_ROLE,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: "roles-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const listChannelRoles = (qs = '') => dispatch => {
    // toast.dismiss();
    dispatch(emptyError());
  
    let url = `${ENV.url}channels/roles/list`;
  
    if (qs)
      url = `${url}?${qs}`
  
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': ENV.Authorization,
        'x-auth-token': ENV.x_auth_token,
      },
    }).then(res => res.json()).then(data => {
      if (data.success) {
        dispatch({
          type: LIST_CHANNEL_ROLES,
          payload: data
        })
      } else {
        toast.error(data.message, {
          toastId: "channels-error"
        })
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    }).catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error
      })
    })
  };
