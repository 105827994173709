import { toast } from 'react-toastify';
import { emptyError } from '../../../redux/shared/error/error.action';
import { LIST_TEAM_MEMBERS, BEFORE_TEAM_MEMBER, GET_ERRORS, BEFORE_ABOUT_STATS, LIST_ABOUT_STATS } from '../../../redux/types';
import { ENV } from '../../../config/config';


// Team 
export const beforeTeam = () => {
    return {
        type: BEFORE_TEAM_MEMBER
    }
}

export const listTeamMembers = (qs = '') => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}team/list`;
    if(qs)
        url += `?${qs}`
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_TEAM_MEMBERS,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

// about us stats

export const beforeAboutStats = () => {
    return {
        type: BEFORE_ABOUT_STATS
    }
}

export const listAboutStats = () => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}about-us/stats`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_ABOUT_STATS,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};