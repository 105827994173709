import React from 'react'
import { useState, useEffect } from 'react'
import { AiOutlineAlignCenter } from "react-icons/ai"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { RiLogoutCircleRLine } from "react-icons/ri";
import SettingsModal from '../Settings/SettingsModal'
import { ENV } from '../../../../config/config'
import { useDispatch, useSelector } from 'react-redux'
import { beforeChannelRole } from '../../ChannelRoles/channelRoles.actions'
import { beforeChannels, getChannel } from '../../MyChannels/channels.actions'
import { useSearchParams } from 'react-router-dom'
import { getVideo, beforeVideo } from '../Videos/video.actions'
import { beforeCounter } from '../../Notifications/notifications.actions'
import { Tooltip } from 'react-tooltip'
import { channelAnalyticsLink, contactAdmin, exploreVideosLink, memberDashboardLink, myChannelLink, myVideosLink, packagesLink, paymentHistoryLink, profileLink, publisherTutorials, settingsLink, subtitlesLink } from '../../ApplicationTour/TourCompIdentifiers'
import { Images } from '../../../../assets/assets'
import './MemberDashboardSidebar.css'
import '../DashboardPage/DasboardPage.css'
import { useTranslation } from 'react-i18next';
import { clearStorage } from '../../../../utils/Shared Functions/shared'

const { userPlaceholderImage, defaultPlaceholderImage } = ENV

const DashboardSidebar = ({ dashboardBar, setDashboardBar, channel }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { slug } = useParams()
    // const [params, setParams] = useSearchParams()
    const currentLanguage = localStorage.getItem('selectedLang')
    const channelId = channel || localStorage.getItem('channelId')
    const channelName = localStorage.getItem('channelName')

    const [channelData, setChannelData] = useState({})
    const [show, setShow] = useState(false)
    const [memberRole, setMemberRole] = useState()
    const [video, setVideo] = useState({})
    const [settingsModal, setSettingsModel] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const location = window.location.pathname.split('/')[1]

    const rolesSelector = useSelector((state) => state.roles)
    const channelSelector = useSelector((state) => state.channels)
    const videoSelector = useSelector((state) => state.videos)

    const logoutHandler = () => {
        clearStorage()
        dispatch(beforeCounter())
        navigate('/login')
    }

    const menuShow = () => {
        setShowMenu(!showMenu);
    }

    useEffect(() => {
        if (slug) {
            dispatch(getVideo(slug, `all=${true}&lng=${currentLanguage}`))
        }
    }, [slug])

    useEffect(() => {
        if (videoSelector.getVideoAuth) {
            setVideo(videoSelector.getVideoData.video)
            dispatch(beforeVideo())
        }
    }, [videoSelector.getVideoAuth])

    useEffect(() => {
        if (rolesSelector.channelRoleAuth) {
            let role = rolesSelector.channelRoleData.memberRole
            setMemberRole(role)
            dispatch(beforeChannelRole())
        }
    }, [rolesSelector.channelRoleAuth])


    useEffect(() => {
        if (settingsModal)
            setSettingsModel(false)

    }, [window.location.pathname])

    useEffect(() => {
        if (channelId)
            dispatch(getChannel(`channelId=${channelId}`))
    }, [])

    useEffect(() => {
        if (channelSelector.getChannelAuth) {
            let data = channelSelector.getChannelData?.channel
            setChannelData(data)
            dispatch(beforeChannels())
        }
    }, [channelSelector.getChannelAuth])

    const backHandler = () => {
        navigate('/my-videos')
        setVideo({})
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 576) {
                setDashboardBar(true);
            }
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the initial state
        handleResize();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className='dashboardBody'>
                <div className='dashboard-Sidebar'>
                    <div className={dashboardBar ? 'dsahboardLogo logoTrue' : ' dsahboardLogo-change logoTrue'}
                        onClick={() => setDashboardBar(!dashboardBar)}><AiOutlineAlignCenter></AiOutlineAlignCenter></div>
                    {
                        dashboardBar ?
                            <div className={`dashboardFlex ${showMenu ? "show" : ""}`} onMouseOver={() => setShow(!show)}>
                                <div className="mobile-button" onClick={menuShow}>
                                    <AiOutlineAlignCenter />
                                </div>
                                <div>
                                    <div className='autoBar'>
                                        {
                                            location === 'video-analytics' || location === 'subtitles' ?
                                                <div className='sidebar-video-wrapper'>
                                                    <div className='d-flex align-items-center mb-3'>
                                                        <img src={Images.ArrowLeft} alt='arrow-left' className='img-fluid pointer' onClick={backHandler} />
                                                        <p className='ms-3 mb-0'>{t('myVideos.heading')}</p>
                                                    </div>
                                                    <div className='video-wrapper-main'>
                                                        <div className='video-wrapper'>
                                                            <div className='image mb-3'>
                                                                <img src={video?.thumbnails && video?.thumbnails[0] || defaultPlaceholderImage} alt='video' className='img-fluid mb-2' />
                                                            </div>
                                                            <p className='mb-1'>{t('memberDashboardSidebar.yourVideo')}</p>
                                                            {
                                                                video?.title ? <p className='opacity-p'>{video.title}</p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className='user'>
                                                    <div className='userLink'>
                                                        <div className='userBlock'>
                                                            <div className='userImg'><img className='img-fluid' src={channelData?.channelImage || userPlaceholderImage} alt={channelData?.name}></img></div>
                                                            <h3>{channelData?.name}</h3>
                                                            <p>{channelData?.uploadedVideosCount} {channelData?.uploadedVideosCount > 1 ? t('myVideos.listings.videos') : t('subtitles.video')} {t('memberDashboardSidebar.uploaded')}</p>
                                                        </div>
                                                        <ul className='dashboard-ul'>
                                                            {
                                                                memberRole && memberRole.viewChannelDashboard &&
                                                                <li id={memberDashboardLink}>
                                                                    <Link to={`/publisher-dashboard/${channelName}`} className={`${window.location.pathname?.toLowerCase().includes('/publisher-dashboard') ? 'active' : ''}`}>
                                                                        <img className='img-fluid me-3' src={Images.dashboardIcon} alt='dashboard' />
                                                                        <span>{t('memberDashboardSidebar.dashboard')}</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {
                                                                memberRole && memberRole.viewChannelVideos &&
                                                                <li id={myVideosLink}>
                                                                    <Link to='/my-videos' className={`${window.location.pathname?.toLowerCase() === '/my-videos' ? 'active' : ''}`}>
                                                                        <img className='img-fluid me-3' src={Images.MyVideosIcon} alt='my-videos' />
                                                                        <span>{t('memberDashboardSidebar.myVideos')}</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            <li id={exploreVideosLink}>
                                                                <Link to='/' className={`${window.location.pathname?.toLowerCase() === '/' ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={Images.contentIcon} alt='explore videos' />
                                                                    <span>{t('memberDashboardSidebar.exploreVideos')}</span>
                                                                </Link>
                                                            </li>
                                                            {
                                                                memberRole && memberRole.viewChannelAnalytics &&
                                                                <li id={channelAnalyticsLink}>
                                                                    <Link to='/channel-analytics' className={`${window.location.pathname?.toLowerCase() === '/channel-analytics' ? 'active' : ''}`}>
                                                                        <img className='img-fluid me-3' src={Images.analyticIcon} alt='analytics' />
                                                                        <span>{t('memberDashboardSidebar.channelAnalytics')}</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            <li id={settingsLink}>
                                                                <Link to={``} onClick={() => setSettingsModel(true)} className={`${settingsModal ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={Images.settingIcon} alt='settings' />
                                                                    <span>{t('memberDashboardSidebar.settings')}</span>
                                                                </Link>
                                                            </li>
                                                            <li id={paymentHistoryLink}>
                                                                <Link to='/payment-history' className={`${window.location.pathname?.toLowerCase() === '/payment-history' ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={Images.payment} alt='payment' />
                                                                    <span>{t('memberDashboardSidebar.paymentHistory')}</span>
                                                                </Link>
                                                            </li>
                                                            <li id={myChannelLink}>
                                                                <Link to='/channels' className={`${window.location.pathname?.toLowerCase() === '/channels' ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={Images.MyChannelIcon} alt='channels' />
                                                                    <span>{t('memberDashboardSidebar.myChannels')}</span>
                                                                </Link>
                                                            </li>
                                                            <li id={packagesLink}>
                                                                <Link to='/packages' className={`${window.location.pathname?.toLowerCase() === '/packages' ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={Images.PackagesIcon} alt='packages' />
                                                                    <span>{t('memberDashboardSidebar.packages')}</span>
                                                                </Link>
                                                            </li>
                                                            <li id={profileLink}>
                                                                <Link to='/profile' className={`${window.location.pathname?.toLowerCase() === '/profile' ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={Images.ProfileLineIcon} alt='profile' />
                                                                    <span>{t('memberDashboardSidebar.profile')}</span>
                                                                </Link>
                                                            </li>
                                                            {
                                                                memberRole && memberRole.viewContactAdmin &&
                                                                <li id={contactAdmin}>
                                                                    <Link to='/contact-admin' className={`${window.location.pathname?.toLowerCase() === '/contact-admin' ? 'active' : ''}`}>
                                                                        <img className='img-fluid me-3' src={Images.payment} alt='contact-admin' />
                                                                        <span>{t('memberDashboardSidebar.contactAdmin')}</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {
                                                                <li id={publisherTutorials}>
                                                                    <Link to='/tutorials' className={`${window.location.pathname?.toLowerCase() === '/tutorials' ? 'active' : ''}`}>
                                                                        <img className='img-fluid me-3' src={Images.payment} alt='publisher-tutorials' />
                                                                        <span>Publisher Tutorials</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className='logout-button-div d-flex justify-content-center'><button type="button" className="theme-btn orange-btn dbLogoutBtn" onClick={logoutHandler}>{t('memberDashboardSidebar.signOut')}</button></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='dashboard-sidebar MobileSidebar'>
                                <div>
                                    <div className='autoBar'>
                                        <div className='user'>
                                            <div className='userLink'>
                                                <ul className='dashboard-ul'>
                                                    {memberRole && memberRole.viewChannelDashboard &&
                                                        <li id={memberDashboardLink}>
                                                            <Link id="dashboard" to={`/publisher-dashboard/${channelName}`} className={`${window.location.pathname?.toLowerCase().includes('/publisher-dashboard') ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={Images.dashboardIcon} alt='dashboard' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="dashboard"
                                                                place="right"
                                                                content="Dashboard"
                                                            />
                                                        </li>
                                                    }
                                                    {
                                                        memberRole && memberRole.viewChannelVideos &&
                                                        <li id={myVideosLink}>
                                                            <Link id="my-videos" to='/my-videos' className={`${window.location.pathname?.toLowerCase() === '/my-videos' ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={Images.MyVideosIcon} alt='my-videos' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="my-videos"
                                                                place="right"
                                                                content="My Videos"
                                                            />
                                                        </li>
                                                    }
                                                    <li id={exploreVideosLink}>
                                                        <Link id="explore" to='/' className={`${window.location.pathname?.toLowerCase() === '/' ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={Images.contentIcon} alt='explore-videos' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="explore"
                                                            place="right"
                                                            content="Explore Videos"
                                                        />
                                                    </li>
                                                    {
                                                        memberRole && memberRole.viewChannelAnalytics &&
                                                        <li id={channelAnalyticsLink}>
                                                            <Link id="channel-analytics" to='/channel-analytics' className={`${window.location.pathname?.toLowerCase() === '/channel-analytics' ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={Images.analyticIcon} alt='analytics' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="channel-analytics"
                                                                place="right"
                                                                content="Channel Analytics"
                                                            />
                                                        </li>
                                                    }
                                                    {
                                                        memberRole && memberRole.viewVideoSubtitles &&
                                                        <li id={subtitlesLink}>
                                                            <Link id="subtitles" to='/subtitles' className={`${window.location.pathname?.toLowerCase() === '/subtitles' ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={Images.analyticIcon} alt='subtitles' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="subtitles"
                                                                place="right"
                                                                content="Subtitles"
                                                            />
                                                        </li>
                                                    }
                                                    <li id={settingsLink}>
                                                        <Link id="settings" to='/' onClick={() => setSettingsModel(true)} className={`${settingsModal ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={Images.settingIcon} alt='settings' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="settings"
                                                            place="right"
                                                            content="Settings"
                                                        />
                                                    </li>
                                                    <li id={paymentHistoryLink}>
                                                        <Link id="payment-history" to='/payment-history' className={`${window.location.pathname?.toLowerCase() === '/payment-history' ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={Images.subtitleIcon} alt='subtitles' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="payment-history"
                                                            place="right"
                                                            content="Payment History"
                                                        />
                                                    </li>
                                                    <li id={myChannelLink}>
                                                        <Link id="channels" to='/channels' className={`${window.location.pathname?.toLowerCase() === '/channels' ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={Images.MyChannelIcon} alt='payment-history' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="channels"
                                                            place="right"
                                                            content="My Channels"
                                                        />
                                                    </li>
                                                    <li id={packagesLink}>
                                                        <Link id="packages" to='/packages' className={`${window.location.pathname?.toLowerCase() === '/packages' ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={Images.PackagesIcon} alt='subtitles' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="packages"
                                                            place="right"
                                                            content="Packages"
                                                        />
                                                    </li>
                                                    <li id={profileLink}>
                                                        <Link id="profile" to='/profile' className={`${window.location.pathname?.toLowerCase() === '/profile' ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={Images.ProfileLineIcon} alt='subtitles' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="profile"
                                                            place="right"
                                                            content="Profile"
                                                        />
                                                    </li>
                                                    {
                                                        memberRole && memberRole.viewContactAdmin &&
                                                        <li id={contactAdmin}>
                                                            <Link to='/contact-admin' className={`${window.location.pathname?.toLowerCase() === '/contact-admin' ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={Images.ProfileLineIcon} alt='contact-admin' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="contact-admin"
                                                                place="right"
                                                                content="Contact Admin"
                                                            />
                                                        </li>

                                                    }
                                                    {
                                                        <li id={publisherTutorials}>
                                                            <Link to='/tutorials' className={`${window.location.pathname?.toLowerCase() === '/tutorials' ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={Images.ProfileLineIcon} alt='publisher-tutorials' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="publisher-tutorials"
                                                                place="right"
                                                                content="Publisher Tutorials"
                                                            />
                                                        </li>

                                                    }
                                                </ul>
                                            </div>
                                            <div><button type="button" className="theme-btn orange-btn text-uppercase px-2 py-1 dbLogoutBtnMob m-auto" onClick={logoutHandler}><RiLogoutCircleRLine /></button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                {
                    settingsModal &&
                    <SettingsModal settingsModal={settingsModal} setSettingsModel={setSettingsModel} />
                }
            </div>
        </>
    )
}
export default DashboardSidebar