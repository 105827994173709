import React, { useEffect } from "react";
import { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { HiUpload } from "react-icons/hi";
import SelectFile from "./SelectFile";
import { ENV } from "../../../../../config/config";
import { getChannel, beforeChannels } from "../../../MyChannels/channels.actions";
import { useDispatch, useSelector } from "react-redux";
import "../../Models/Models.css";
import { useTranslation } from "react-i18next";

const { allowedMediaTypes } = ENV;

const UploadVideo = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [files, setFiles] = useState(null);
  const [videoData, setVideoData] = useState({})
  const currentLanguage = localStorage.getItem('selectedLang')
  const channelSelector = useSelector((state) => state.channels)

  const handleClose = () => {
    props.setShow(false)
    seterrorMsgs({
      flag: false,
      notVideo: "",
      type: "",
    })
  };
  const [selectFile, setSelectFile] = useState(false);
  const [channelData, setData] = useState({})
  const [errorMsgs, seterrorMsgs] = useState({
    flag: false,
    notVideo: "",
    type: "",
  });
  const channelId = localStorage.getItem('channelId')
  const inputRef = useRef();

  useEffect(() => {
    if (props.show && channelId) {
      dispatch(getChannel(`channelId=${channelId}&lng=${currentLanguage}`))

    }
  }, [props.show])

  useEffect(() => {
    if (channelSelector.getChannelAuth) {
      setData(channelSelector?.getChannelData?.channel)
      dispatch(beforeChannels())
    }
  }, [channelSelector.getChannelAuth])

  useEffect(() => {
    if (selectFile)
      props.setShow(false);

  }, [selectFile])

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e) => {
    let errordata = {};

    e.preventDefault();
    const allowedVideoTypes = allowedMediaTypes.videos;
    let fileExt = e.dataTransfer.files[0].name.split('.').pop();

    if (allowedVideoTypes.includes(fileExt)) {
      setFiles(e.dataTransfer.files[0]);
      setSelectFile(true);
    } else {
      errordata.notVideo = t('uploadVideo.notSupported');
      errordata.type = e.dataTransfer.files[0].type;
      seterrorMsgs(errordata);
    }
  };

  const handleClicked = (e) => {

    // get video duration by creating video element
    let video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      setVideoData({
        ...videoData,
        videoLengthInMins: (video.duration / 60).toFixed(2),
        videoSizeInMbs: (e.target.files[0].size / (1000 * 1000)).toFixed(2)
      })

    }
    video.src = URL.createObjectURL(e.target.files[0]);

    let errordata = {};
    e.preventDefault();
    const allowedVideoTypes = allowedMediaTypes.videos;
    let fileExt = e.target.files[0].name.split('.').pop();

    if (allowedVideoTypes.includes(fileExt)) {
      setFiles(e.target.files[0]);
      setSelectFile(true);
    } else {
      errordata.notVideo = t('uploadVideo.notSupported');
      errordata.type = e.target.files[0].type;
      seterrorMsgs(errordata);
    }
  };

  return (
    <>
      <div className="modalPage">
        <div className="modal themeModal show">
          <Modal
            className="uploadvideo"
            show={props.show}
            onHide={handleClose}
            id="wrapper"
            centered
          >
            <div className="scrollbar" id="style-default">
              <div className="force-overflow"></div>
            </div>
            <Modal.Header closeButton>
              <Modal.Title className="mb-0">{t('uploadVideo.heading')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="wrapUpload text-center"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                type=".mp4"
              >
                <div className="uploadBtn">
                  <HiUpload />
                </div>
                <h4 className="modal-H4">{t('uploadVideo.drag&Drop')}</h4>
                <p className="mb-2 modalLightP opacity-40">
                  {t('uploadVideo.videoMsg')}
                </p>
                <input
                  type="file"
                  accept=".mp4"
                  onChange={handleClicked}
                  ref={inputRef}
                  hidden
                ></input>
                <div>
                  <button
                    className="orange-btn text-uppercase mx-auto"
                    onClick={(e) => inputRef.current.click()}
                  >
                    {t('uploadVideo.selectFile')}
                  </button>
                </div>
                {errorMsgs.notVideo ? (
                  <span className="text-danger submit-errors">
                    {errorMsgs.notVideo}
                    {errorMsgs.type}
                  </span>
                ) : (
                  <span />
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      {
        selectFile &&
        <SelectFile
          show={selectFile}
          setShow={setSelectFile}
          files={files}
          videoData={videoData}
          channelData={channelData}
          setLoader={props.setLoader}
        />
      }
    </>
  );
};
export default UploadVideo;
