//layouts
import Auth from './components/Frontend/Layouts/Auth';
import Dashboard from './components/Frontend/Layouts/Dashboard';
import Public from './components/Frontend/Layouts/Public';
import MemberDashboard from './components/Frontend/Layouts/MemberDashboard';

//components
import Home from './components/Frontend/HomePage/Home';
import Signup from './components/Frontend/UserComponents/Signup/Signup';
import Login from './components/Frontend/UserComponents/Login/Login';
import Package from './components/Frontend/Packages/Package/Package';
import CardDetails from './components/Frontend/Packages/CardDetails/CardDetails';
import ForgotPass from './components/Frontend/UserComponents/ForgotPass/ForgotPass';
import ResetPassword from './components/Frontend/UserComponents/ResetPassword/resetPassword';
import DashboardHome from './components/Frontend/UserDashboard/DashboardHome';
import ChannelAnalytics from './components/Frontend/MemberDashboard/ChannelAnalytics/ChannelAnalytics';
import DashboardPage from './components/Frontend/MemberDashboard/DashboardPage/DashboardPage';
import MyVideos from './components/Frontend/MemberDashboard/Videos/MyVideos';
import Subtitles from './components/Frontend/MemberDashboard/Subtitles/Subtitles';
import About from './components/Frontend/About/About';
import Contact from './components/Frontend/Contact/Contact';
import TermsConditions from './components/Frontend/Terms&Conditions';
import PrivacyPolicy from './components/Frontend/PrivacyPolicy';
import BlogDetails from './components/Frontend/Blogs/BlogPosts/blogPosts';
import Blogs from './components/Frontend/Blogs/Blogs/blogs';
import VideoSubtitlePage from './components/Frontend/MemberDashboard/Subtitles/VideoSubtitlePage';
import VideoAnalytics from './components/Frontend/MemberDashboard/VideoAnalyticsComponent/VideoAnalytics/VideoAnalytics';
import Player from './components/Frontend/Player/Player';
import Profile from './components/Frontend/UserComponents/Profile/profile';
import ExploreVideos from './components/Frontend/HomePage/Explore/ExploreVideos';
import PaymentHistory from './components/Frontend/PaymentHistory/PaymentHistory';
import BillingAndInvoices from './components/Frontend/BillingAndInvoices/BillingHistory';
import ViewInvoice from './components/Frontend/BillingAndInvoices/ViewInvoice';
import PaymentSuccess from './components/Frontend/PaymentSuccessfull/PaymentSuccess';
import Invite from './components/Frontend/Invites/invites';
import NotFound from './components/Frontend/NotFound/NotFound';
import MyChannels from './components/Frontend/MyChannels/MyChannels';
import FAQs from './components/Frontend/FAQs/Faqs';
import PlayVideo from './components/Frontend/MemberDashboard/Videos/PlayVideo';
import PlayVlog from './components/Frontend/Vlogs/vlogsPlayer';
import Positions from './components/Frontend/Positions/Positions';
import Informative from './components/Frontend/Informative/Informative';
import InfoContent from './components/Frontend/Informative/InfoContent';
import ContactAdmin from './components/Frontend/Contact Admin/ContactAdmin';
import HowItWorks from './components/Frontend/HowItWorks/HowItWorks';
import UnderConstruction from './components/Frontend/UnderConstruction/UnderConstruction';
import UserFavourites from './components/Frontend/UserComponents/Favourites/UserFavourites';
import UserWatchHistory from './components/Frontend/UserComponents/WatchHistory/WatchHistory';
import Vlogs from './components/Frontend/Vlogs/Vlogs'
import ChannelDetails from './components/Frontend/ChannelDetails/ChannelDetails';
import Community from './components/Frontend/Community/Community';
import CommunityQuestionsDetails from './components/Frontend/Community/CommunityQuestionsDetails/CommunityQuestionsDetails';
import PublisherTutorials from './components/Frontend/PublisherTutorials/PublisherTutorials';
import Tutorial from './components/Frontend/PublisherTutorials/Tutorial';

const routes = [
  // { path: "/", exact: true, name: "Home", layout: Public, component: Home },
  { path: "/", exact: true, name: "BiiView – AI-powered online marketplace for all businesses", description: "BiiView is the world's best-in-class AI platform built for lightning-fast visual commerce & product marketing through object recognition from videos at scale.", layout: MemberDashboard, component: ExploreVideos },
  { path: "/signup", exact: true, name: "Join Biiview", description: "Embark on a journey of discovery – Join Biiview today! Experience a revolutionary platform where creativity meets interaction. Unlock exciting possibilities right away.", layout: Auth, component: Signup },
  { path: "/login", exact: true, name: "Sign in on Biiview", description: "Seamless access to a world of possibilities awaits! Sign in to Biiview & unlock a personalized experience. Become part of a community & discover tempting content.", layout: Auth, component: Login },
  { path: "/forgot-password", exact: true, name: "Forgot Your Password?", description: "Lost access to your Biiview account? No worries! Easily recover your password and regain control. Visit our 'Forgot Your Password?' page for seamless recovery process.", layout: Auth, component: ForgotPass },
  { path: "/reset-password/:token", exact: true, name: "Reset Your Password Here", description: "Secure your Biiview account with ease – reset your password here. Follow a simple and secure process to regain access and continue enjoying a seamless experience.", layout: Auth, component: ResetPassword },
  { path: "/packages", exact: true, name: "Biiview Pricing - From Free to Diamond", description: "Probe the perfect plan for you on Biiview! Discover our flexible pricing options, ranging from Free to Diamond. Unleash the full potential of your experience.", layout: MemberDashboard, component: Package },
  { path: "/card-details", exact: true, name: "Card details", description: "Safeguarding your information is our top priority. Explore the 'Card Details' page for secure transactions. We ensure your privacy with robust security measures. ", layout: Auth, component: CardDetails },
  // { path: "/dashboard", exact: true, name: "Dashboard", layout: Dashboard, component: DashboardHome },
  { path: "/explore/:url", exact: true, name: "Explore all videos - Biiview", description: "Discover engaging videos on BiiView! Click, explore, and interact with detailed product information. Improve your viewing experience. Explore all videos today!", layout: MemberDashboard, component: DashboardHome },
  { path: "/publisher-dashboard/:channelId", exact: true, name: "Publisher Dashboard", description: "Empower your content journey with our Publisher Dashboard. Elevate control, insights, and engagement as you navigate through an intuitive interface. Join today.", layout: MemberDashboard, component: DashboardPage },
  { path: "/my-videos", exact: true, name: "BiiView - My Videos", description: "BiiView My Videos | Description", layout: MemberDashboard, component: MyVideos },
  { path: "/channel-analytics", exact: true, name: "Channel analytics", description: "Uncover insights, track performance, and shape your content strategy with Channel Analytics. Dive into comprehensive data on your audience, engagement, & growth.", layout: MemberDashboard, component: ChannelAnalytics },
  { path: "/subtitles/:slug", exact: true, name: "BiiView - Subtitles", layout: MemberDashboard, component: Subtitles },
  { path: "/about-us", exact: true, name: "About Us - Biiview", description: "Welcome to BiiView - Where Innovation Meets Entertainment. Explore our platform designed for interactive video experiences. Discover, engage, and redefine your content journey with us.", layout: Public, component: About },
  { path: "/how-it-works", exact: true, name: "What is Biiview and How it Works?", description: "Discover the innovation behind Biiview & unravel the magic of interactive content sharing. Explore the unique features & learn how it revamps video-sharing venture.", layout: Public, component: HowItWorks },
  { path: "/faqs", exact: true, name: "Biiview Help - Frequently Asked Questions", description: "Navigate your Biiview experience with ease! Welcome to Biiview Help - Frequently Asked Questions. Find answers to common queries & troubleshoot concerns effortlessly.", layout: Public, component: FAQs },
  { path: "/informative", exact: true, name: "Watch Informative Videos - Biiview", layout: Public, component: Informative },
  { path: "/informative/:slug", exact: true, name: "Watch Informative Videos - Biiview", description: "Glide into knowledge with Informative Videos of BiiView. Explore a wealth of insights and engaging content. Elevate your learning experience – start watching today!", layout: Public, component: InfoContent },
  { path: "/contact-us", exact: true, name: "Contact us", layout: Public, component: Contact, description: "Glide into knowledge with Informative Videos of BiiView. Explore a wealth of insights and engaging content. Elevate your learning experience – start watching today!" },
  // { path: "/terms-and-conditions", exact: true, name: "Terms and Conditions", layout: Public, component: TermsConditions },
  { path: "/terms-and-conditions", exact: true, name: "Terms and Conditions", description: "Explore the Legal Landscape: Biiview Terms and Conditions. Delve into the guidelines that govern your interaction with our platform. Your trust matters to us.", layout: Auth, component: UnderConstruction },
  // { path: "/privacy-policy", exact: true, name: "Privacy Policy", layout: Public, component: PrivacyPolicy },
  { path: "/privacy-policy", exact: true, name: "Biiview Privacy Policiy", description: "Your Privacy, Our Priority: Biiview Privacy Policy. Delve into the details that safeguard your personal details. Define the boundaries of our commitment to your privacy.", layout: Auth, component: UnderConstruction },
  { path: "/blog/:customUrl?", exact: true, name: "BiiView - Blog Details", layout: Public, component: BlogDetails },
  { path: "/blogs", exact: true, name: "Biiview official blog - Online marketplace latest news & updated", layout: Public, component: Blogs, description: "Explore the dynamic world of online marketplaces through BiiView's official blog. Stay informed with the latest updates shaping the digital marketplace landscape." },
  { path: "/media-player/:id/:channel", exact: true, name: "BiiView - Player", layout: MemberDashboard, component: Player },
  { path: "/play-video/:slug", exact: true, name: "BiiView – Play Video", layout: MemberDashboard, component: PlayVideo },
  { path: "/video-subtitles", exact: true, name: "BiiView – Video Subtitle", layout: MemberDashboard, component: VideoSubtitlePage },
  { path: "/video-analytics/:slug", exact: true, name: "Videos analytics", description: "Uncover the Insights: Video Analytics on Biiview. Elevate your content strategy with in-depth analytics. Track engagement and audience behavior at ease.", layout: MemberDashboard, component: VideoAnalytics },
  { path: "/profile", exact: true, name: "BiiView – Profile", layout: MemberDashboard, component: Profile },
  { path: "/payment-history", exact: true, name: "View Payment History", description: "Trace Your Financial Journey: View Payment History on Biiview. Access a detailed record of your financial transactions, providing transparency and control.", layout: MemberDashboard, component: PaymentHistory },
  { path: "/invoices", exact: true, name: "Pay Your Invoices", description: "Streamlined Finances: Pay Your Invoices on Biiview. Manage your financial interactions effortlessly by accessing the convenient invoice payment feature.", layout: MemberDashboard, component: BillingAndInvoices },
  { path: "/invoice/:id", exact: true, name: "Invoice details", description: "Financial Transparency: Invoice Details on Biiview. Access a comprehensive breakdown of your financial transactions with Invoice Details & explore detailed records.", component: ViewInvoice },
  { path: "/invite-user/:inviteKey", exact: true, name: "BiiView – Invitation", layout: Auth, component: Invite },
  { path: "/payment-successful", exact: true, name: "Payment Successful", description: "Transaction Triumph: Payment Successful on Biiview. Your payment has been processed seamlessly, unlocking a world of premium features and exclusive content.", layout: Auth, component: PaymentSuccess },
  { path: "/channels", exact: true, name: "All Channels on Biiview", description: "Explore Diversity: All Channels on Biiview. Immerse yourself in a world of variety as you navigate through an extensive array of channels on our platform.", layout: MemberDashboard, component: MyChannels },
  { path: "/positions", exact: true, name: "BiiView – Positions", layout: Auth, component: Positions },
  // { path: "/contact-admin", exact: true, name: "Contact Admin", layout: MemberDashboard, component: ContactAdmin },
  { path: "/watch-history", exact: true, name: "Watch History", description: "Relive Your Journey: Watch History on Biiview. Rediscover your favorite moments by exploring your personalized watch history. Dive into a curated timeline of videos.", layout: MemberDashboard, component: UserWatchHistory },
  { path: "/contact-admin", exact: true, name: "Contact admin - Biiview", description: "Connect with Us: Contact Biiview Admin. Have questions, feedback, or need assistance? Reach out to our dedicated administrators for prompt support. Let us assist you. ", layout: MemberDashboard, component: ContactAdmin },
  { path: "/my-favourites", exact: true, name: "Favourite Videos - Biiview", description: "Curate Your Content Universe: Favourite Videos on Biiview. Dive into a personalized collection of videos that resonate with you. Your gateway to customized content.", layout: MemberDashboard, component: UserFavourites },
  { path: "/vlogs", exact: true, name: "Biiview vlogs", description: "Explore a captivating world of storytelling on BiiView Vlogs, where creativity meets seamless interaction. A collection of engaging vlogs for a unique experience.", layout: Public, component: Vlogs },
  { path: "/vlog/:id", exact: true, name: "BiiView – Play Vlog", layout: Public, component: PlayVlog },
  { path: "/community", exact: true, name: "BiiView –Community", component: Community },
  { path: "/question/:slug", exact: true, name: "BiiView – Community Questions Details", component: CommunityQuestionsDetails },
  { path: "/channel-details/:channelSlug", exact: true, name: "BiiView – Channel Details", layout: MemberDashboard, component: ChannelDetails },
  { path: "/tutorials", exact: true, name: "BiiView – Publisher Tutorials", layout: MemberDashboard, component: PublisherTutorials },
  { path: "/tutorial/:id", exact: true, name: "BiiView – Watch Tutorial", layout: MemberDashboard, component: Tutorial },
  { path: "/*", exact: true, name: "BiiView – Not Found", layout: Auth, component: NotFound },
]

export default routes;