import React from 'react';
import { useState, useRef, useEffect } from "react";
import { TiArrowSortedDown } from "react-icons/ti"
import { countries } from "countries-list";
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import '../../Models/Models.css'

const BasicInfo = (props) => {
    const { t } = useTranslation()
    const [options, setOptions] = useState();
    const [country, setCountry] = useState();

    const countriesList = Object.keys(countries).map((key) => countries[key].name)

    useEffect(() => {
        if (countriesList) {
            const items = countriesList.map(item => ({
                value: item,
                label: item
            }));

            setOptions(items)
        }
    }, [])


    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div>
                        <div className='general'>
                            <div className='modalSelect mb-2'>
                                <div className="custom-react-select flex-fill">
                                    <Select
                                        options={options}
                                        value={options && options.length > 0 ? options.find(option => option.value === props.country) : null} // Setting the selected option based on category state
                                        onChange={(e) => props.setData({ countryResidence: e.value })} // Update category state with the selected option's value
                                        classNamePrefix="triage-select"
                                    />
                                </div>
                            </div>
                            <p>{t('settings.channel.basicInfo.countryText')}</p>
                        </div>
                        <div className='general mb-0'>
                            <div className='mb-2 innerTab-input-holder'>
                                <div className="innerTab-input-group">
                                    <input type="text" className="form-control" placeholder="#myChannel, #biiview" aria-label="Username" value={props.keywords} onChange={(e) => props.setData({ keywords: e.target.value })} />
                                </div>
                            </div>
                            {
                                props.errors && props.errors.keywords &&
                                <span className='submit-errors text-danger'>{props.errors.keywords}</span>
                            }
                            <p>{t('common.commaSeparated')}</p>
                            <p>{t('settings.channel.basicInfo.note')}</p>
                        </div>
                        <div className='general mb-0'>
                            <div className='mb-2 innerTab-input-holder'>
                                <div className="innerTab-input-group">
                                    <textarea className="form-control" rows={3} placeholder = {t('settings.channel.basicInfo.placeholder')} name='aboutChannel' value={props.aboutChannel} onChange={(e) => props.setData({ aboutChannel: e.target.value })}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default BasicInfo