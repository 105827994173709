import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tabs/style/react-tabs.css'
import './App.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import { Slide } from 'react-toastify';
import './i18next/i18next'

// const [isActive, setActive] = useState(false);

// const toggleClass = () => {
// 	setActive(!isActive);
// };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<ToastContainer
			position={toast.POSITION.TOP_RIGHT}
			pauseOnHover={true}
			newestOnTop={true}
			autoClose={3000}
			toastStyle={{ backgroundColor: "#222430", color: '#fff', boxShadow: '#fd6f21 0px 0px 14px 0px', fontSize: '15px' }}
			transition={Slide}
		/>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
