import { GET_ERRORS, BEFORE_COMMUNITY, GET_QUESTION_DETAILS, LIST_QUESTIONS, POST_QUESTION, QUESTION_VOTE, EDIT_QUESTION, DELETE_QUESTION, EDIT_ANSWER, DELETE_ANSWER, POST_ANSWER, ANSWER_VOTE } from '../../../redux/types';
import { ENV } from '../../../config/config';
import { emptyError } from '../../../redux/shared/error/error.action'
import { toast } from 'react-toastify';


export const beforeCommunity = () => {
    return {
        type: BEFORE_COMMUNITY
    }
}

export const postQuestion = (body) => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    let url = `${ENV.url}community/questions/post`;

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''

        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: POST_QUESTION,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getQuestionDetails = (id, qs = '') => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}community/questions/get/${id}`;
    if (qs)
        url = `${url}?${qs}`

    const accessToken = ENV.getUserKeys('accessToken')?.accessToken

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_QUESTION_DETAILS,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const listQuestions = (qs = '') => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}community/questions/list`;
    if (qs)
        url = `${url}?${qs}`

    const { accessToken } = ENV.getUserKeys('accessToken')

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_QUESTIONS,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const upDownVote = (body) => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    let url = `${ENV.url}community/questions/vote`;

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''

        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: QUESTION_VOTE,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};


export const editQuestion = (body) => dispatch => {

    dispatch(emptyError());
    let url = `${ENV.url}community/questions/edit`;

    fetch(url, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: EDIT_QUESTION })
            dispatch({
                type: EDIT_QUESTION,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        if (errors.response && errors.response.data) {
            const { data } = errors.response
            if (data.message)
                toast.error(data.message, { toastId: EDIT_QUESTION })
        }
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};


export const deleteQuestion = (id, qs = '') => dispatch => {

    dispatch(emptyError());
    let url = `${ENV.url}community/questions/delete/${id}`;
    if (qs)
        url += `?${qs}`
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: DELETE_QUESTION })
            dispatch({
                type: DELETE_QUESTION,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        if (errors.response && errors.response.data) {
            const { data } = errors.response
            if (data.message)
                toast.error(data.message, { toastId: DELETE_QUESTION })
        }

        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// answers 


export const editAnswer = (body) => dispatch => {

    dispatch(emptyError());
    let url = `${ENV.url}community/answers/edit`;

    fetch(url, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: EDIT_ANSWER })
            dispatch({
                type: EDIT_ANSWER,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        if (errors.response && errors.response.data) {
            const { data } = errors.response
            if (data.message)
                toast.error(data.message, { toastId: EDIT_ANSWER })
        }
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};


export const deleteAnswer = (id, qs = '') => dispatch => {

    dispatch(emptyError());
    let url = `${ENV.url}community/answers/delete/${id}`;
    if (qs) url += `?${qs}`

    fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: DELETE_ANSWER })
            dispatch({
                type: DELETE_ANSWER,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        if (errors.response && errors.response.data) {
            const { data } = errors.response
            if (data.message)
                toast.error(data.message, { toastId: DELETE_ANSWER })
        }

        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const postAnswer = (body) => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    let url = `${ENV.url}community/answers/post`;

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''

        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message, { toastId: POST_ANSWER })
            dispatch({
                type: POST_ANSWER,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: POST_ANSWER })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        if (errors.response && errors.response.data) {
            const { data } = errors.response
            if (data.message)
                toast.error(data.message, { toastId: POST_ANSWER })
        }

        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const answerUpDownVote = (body) => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    let url = `${ENV.url}community/answers/vote`;

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''

        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: ANSWER_VOTE,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: ANSWER_VOTE })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};