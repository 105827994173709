import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Accordion, Form, Button } from 'react-bootstrap'
import { FaPlus, FaMinus } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux'
import { validateEmail, validateName, validatePhone } from '../../../utils/Validations/validations';
import { createContact, beforeContact } from '../Contact/contact.actions';
import { listFaqs, beforeFaq } from '../FAQs/faqs.actions';
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { useTranslation } from 'react-i18next'

const Help = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentLanguage = localStorage.getItem('selectedLang')

    const [faqs, setFaqs] = useState([])
    const [contactData, setData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    })
    const [loader, setLoader] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [errors, setErrors] = useState({})
    const [totalFaqs, setTotal] = useState(0)

    const contactSelector = useSelector((state) => state.contact)
    const faqsSelector = useSelector((state) => state.faqs)

    useEffect(() => {
        if (i18n.language)
            dispatch(listFaqs(`lng=${currentLanguage}`))
    }, [i18n.language])

    useEffect(() => {
        if (faqsSelector.faqsAuth) {
            let faqsData = faqsSelector.faqsData?.faqs
            setTotal(faqsData?.length)
            setFaqs(faqsData?.length > 3 ? faqsData?.slice(0, 3) : faqsData)
            setLoader(false)
            dispatch(beforeFaq())
        }
    }, [faqsSelector.faqsAuth])

    useEffect(() => {
        if (contactSelector.createContactAuth) {
            setData({
                name: '',
                email: '',
                phone: '',
                message: ''
            })
            setLoader(false)
            dispatch(beforeContact())
        }
    }, [contactSelector.createContactAuth])

    const onChangeHandler = (e) => {
        if (submitted) {
            if (e.target.value)
                setErrors({ ...errors, [e.target.name]: '' })

            else
                setErrors({ ...errors, [e.target.name]: t('common.fieldRequired') })


        }
        setData({ ...contactData, [e.target.name]: e.target.value })
    }

    const submit = () => {
        setSubmitted(true)

        let nameErr = ''
        let emailErr = ''
        let msgErr = ''
        let phoneErr = ''
        let msg = t('common.fieldRequired')

        if (!contactData.name)
            nameErr = msg
        else {
            contactData.name = contactData.name.replace(/\s+/g, " ").trim()
            nameErr = validateName(t, contactData.name)
        }

        if (!contactData.email)
            emailErr = msg
        else
            emailErr = validateEmail(t, contactData.email)

        if (!contactData.message)
            msgErr = msg

        if (contactData.phone) {
            phoneErr = validatePhone(t, contactData.phone)
        }


        setErrors({ name: nameErr, email: emailErr, message: msgErr, phone: phoneErr })

        if (nameErr || emailErr || msgErr || phoneErr)
            return

        setLoader(true)
        dispatch(createContact({ ...contactData, aboutUsPage: true }))

    };


    return (
        <>
            {loader && <FullPageLoader />}
            <section className='help-sec pb-0'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h2 className='help-title'>{t('aboutUs.helpDesk.heading.weAreHere')} <span className='theme d-block'>{t('aboutUs.helpDesk.heading.helpYou')}.</span></h2>
                            <Accordion defaultActiveKey="0">
                                {
                                    faqs && faqs?.length ?
                                        faqs.map((faq, index) => (
                                            <Accordion.Item eventKey={index}>
                                                <Accordion.Header>
                                                    <span className='plus-icon'><FaPlus /></span>
                                                    <span className='minus-icon'><FaMinus /></span>
                                                    {faq.title}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='mb-0 faqs-content' dangerouslySetInnerHTML={{ __html: faq.desc }}>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                        :
                                        <div className='no-record-found d-flex justify-content-center align-items-center'>{t('aboutUs.helpDesk.noFaqs')}</div>
                                }
                            </Accordion>
                            {
                                totalFaqs > 3 ?
                                    <Button className="orange-btn text-uppercase mt-5 mb-3" onClick={() => navigate('/faqs')}>{t('common.readMoreBtn')}</Button>
                                    : null
                            }
                        </Col>
                        <Col lg={6}>
                            <div className='form-wrapper'>
                                <h2 className='H2 text-center mb-3'>{t('aboutUs.helpDesk.contactForm.heading.quick')} <span className='theme'>{t('aboutUs.helpDesk.contactForm.heading.help')}</span>.</h2>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>{t('common.name')} <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="text" name='name' value={contactData.name} onChange={onChangeHandler} />
                                        {
                                            errors && errors.name &&
                                            <span className='text-danger'>{errors.name}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>{t('common.email')} <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="email" name='email' value={contactData.email} onChange={onChangeHandler} />
                                        {
                                            errors && errors.email &&
                                            <span className='text-danger'>{errors.email}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>{t('common.phoneNumber')}</Form.Label>
                                        <Form.Control type="text" name='phone' value={contactData.phone} placeholder=''
                                            onChange={onChangeHandler} />
                                        {
                                            errors && errors.phone &&
                                            <span className='text-danger'>{errors.phone}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>{t('common.yourMessage')} <span className='text-danger'>*</span></Form.Label>
                                        <textarea className='w-100' rows={3} name='message' value={contactData.message} onChange={onChangeHandler}></textarea>
                                        {
                                            errors && errors.message &&
                                            <span className='text-danger'>{errors.message}</span>
                                        }
                                    </Form.Group>
                                    <div className='submit-btn text-end'>
                                        <Button variant="" onClick={submit}>{t('common.submit')}</Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Help