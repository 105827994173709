import React, { useEffect } from 'react';
import { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import VideoSubtitles from './VideoSubtitles';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { listSubtitles, beforeSubtitle } from './subtitles.actions';
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { useTranslation } from 'react-i18next';
import VideoEle from '../Videos/EditVideoDetails/VideoEle';
import { ENV } from '../../../../config/config';
import 'react-tabs/style/react-tabs.css';
import 'rc-pagination/assets/index.css';
import './Subtitles.css';
import { beforeVideo, uploadSubtitleFile } from '../Videos/video.actions';
import { BsInfoCircleFill } from 'react-icons/bs'
import { Tooltip } from 'react-tooltip'
import Select from 'react-select'

const { allowedMediaTypes, subtitleLanguages, defaultPlaceholderImage } = ENV

const Subtitles = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const subtitleSelector = useSelector((state) => state.subtitles)
    const videoSelector = useSelector((state) => state.videos)

    const [videoSubtitles, setVideoSubtitles] = useState(false)
    const [paginationData, setPagination] = useState({})
    const [videoDetails, setDetails] = useState({})
    const [vidId, setId] = useState('')
    const [loader, setLoader] = useState(true)
    const [openModal, setOpen] = useState(false)
    const [params, setParams] = useSearchParams()
    const [showSubtitleInput, setInput] = useState(false)
    const [subtitleFile, setFile] = useState('')
    const { slug } = useParams()
    const [subtitleLangOptions, setLangOptions] = useState([])
    const [selected, setSelected] = useState('')
    const [errors, setErrors] = useState('')
    const currentLanguage = localStorage.getItem('selectedLang')
    
    useEffect(() => {
        if (subtitleLanguages?.length) {
            let options = subtitleLanguages.map((lang) => {
                return { value: lang.code, label: lang.label }
            })
            setLangOptions(options)
        }
    }, [])

    useEffect(() => {
        if (slug) {
            setId(slug)
            dispatch(listSubtitles(`id=${slug}`))
        }
    }, [slug])

    useEffect(() => {
        if (subtitleSelector.listSubtitlesAuth) {
            let { subtitles, pagination, details } = subtitleSelector?.listSubtitlesData?.data
            setVideoSubtitles(subtitles)
            setPagination(pagination)
            setDetails(details)
            setLoader(false)
            dispatch(beforeSubtitle())
        }
    }, [subtitleSelector.listSubtitlesAuth])

    useEffect(() => {
        if (videoSelector.uploadSubtitleAuth) {
            setLoader(false)
            setOpen(false)
            dispatch(listSubtitles(`id=${slug}`))
            dispatch(beforeVideo())
        }
    }, [videoSelector.uploadSubtitleAuth])

    const onPageChange = (page) => {
        setLoader(true)
        let qs = `page=${page}&id=${vidId}`
        dispatch(listSubtitles(qs))
    }

    const onFileChange = (e) => {
        const allowedSubtitleTypes = allowedMediaTypes.subtitles
        let file = e.target.files[0]

        if (file) {
            setFile(file)

            // let fileExt = file.name.split('.').pop();
            let splittedStr = file.name.split('.')
            let lastExt = splittedStr[splittedStr.length - 1]
            let firstExt = splittedStr[splittedStr.length - 2]
            let fileExt = `${firstExt}.${lastExt}`

            if (allowedSubtitleTypes.indexOf(fileExt) <= -1) {
                setErrors({ ...errors, subtitle: `${t('common.supportedTypes')} ${allowedSubtitleTypes.join(', ')}` })
                return
            }

            let reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = function (e) {
                let result = e.target.result
                setErrors({ ...errors, subtitle: '' })
            }
        }
    }

    const resetSubtitle = () => {
        setInput(false)
        setErrors({ ...errors, subtitle: '' })
    }

    const uploadSubtitles = () => {
        let formData = new FormData()
        formData.append('slug', slug)
        formData.append('isFileSubtitle', true)
        formData.append('lng', currentLanguage)
        if (selected) {
            formData.append('langCode', selected.value)
            formData.append('langLabel', selected.label)
        }
        formData.append('subtitleFile', subtitleFile)

        setLoader(true)
        dispatch(uploadSubtitleFile(formData, true))
    }

    return (
        loader ? <FullPageLoader /> :
            <>
                <div className='dashboardPage'>
                    <div className='dashboardHeader px40'>
                        <h2 className='text-uppercase'>{t('subtitles.heading')}</h2>
                        <Button onClick={() => setOpen(true)}>{t('subtitles.addSubs')}</Button>
                    </div>
                    <div className='subtitles themeAnalytics px40'>
                        <Tabs>
                            <div className='tabFlex'>
                                <TabList>
                                    <Tab>{t('mainSidebar.all')}</Tab>
                                </TabList>
                            </div>

                            <TabPanel className='subTabPanel'>
                                <div className='themeTable'>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th><p>{t('subtitles.video')}</p></th>
                                                <th><p>{t('subtitles.languages')}</p></th>
                                                <th><p>{t('subtitles.link')}</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                videoSubtitles?.length ?
                                                    videoSubtitles.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                    <button className='cuetomModelBtn' /* onClick={() => setVideoSubtitles(!videoSubtitles)} */>
                                                                        <div className='videoInfo videoPara'>
                                                                            <div className='videoImg'>
                                                                                <img className='img-fluid' src={videoDetails?.thumbnails[0]} alt={t('subtitles.video')}></img>
                                                                            </div>

                                                                            <div>
                                                                                <p className='analytic-P'>{videoDetails?.title}</p>
                                                                                <p className='light-p'>{videoDetails?.desc}</p>
                                                                            </div>
                                                                        </div>
                                                                    </button>

                                                                </td>
                                                                <td className='td-area'><p>{item?.language?.label}</p></td>
                                                                <td className='td-area'><a href={item?.cloudUrl} target="_blank" rel='noreferrer'>{item?.cloudUrl}</a></td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className='no-record-found d-flex justify-content-center align-items-center'>
                                                                {t('subtitles.notFound')}
                                                            </div>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </TabPanel>

                        </Tabs>
                    </div>
                </div>
                {
                    paginationData && paginationData.pages &&
                    < Pagination
                        className="m-3"
                        defaultCurrent={1}
                        pageSize // items per page
                        current={paginationData.page} // current active page
                        total={paginationData.pages} // total pages
                        onChange={onPageChange}
                        locale={localeInfo}
                        hideOnSinglePage={true}
                    />
                }
                {
                    openModal &&
                    <Modal className='channel-info-modal' show={openModal} onHide={() => setOpen(false)} centered>
                        <div className='model-content-wrapper'>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {t('subtitles.addSubs')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h4 className='modal-H4'>{t('editVideoDetails.videoElements.heading')}</h4>
                                <p className='opacity-40'>{t('editVideoDetails.videoElements.cards&EndScreen')}</p>
                                <div className='addGroup'>
                                    <div className='addGroupList'>
                                        <div className='addBlock'>
                                            <h5 className='h5Title'>{t('editVideoDetails.videoElements.addSubtitles')}</h5>
                                            <p className='opacity-40 mb-0'>{t('editVideoDetails.videoElements.subtitleParagraph')}</p>
                                        </div>
                                        <div className='d-flex align-items-center add-subtitle-holder'>
                                            {
                                                showSubtitleInput ?
                                                    <button className="link orangeLink p-0" onClick={resetSubtitle}>x</button> :
                                                    <button className="link orangeLink text-uppercase p-0" onClick={() => setInput(!showSubtitleInput)}>{t('editVideoDetails.videoElements.add')}</button>

                                            }
                                            <p className='mb-0 ms-3' id="subtitle-info"><BsInfoCircleFill /></p>
                                            <Tooltip
                                                anchorId="subtitle-info"
                                                place="top"
                                                content={`${t('common.supportedTypes')}: ${allowedMediaTypes.subtitles.map((ext) => `.${ext}`).join(', ')}`}
                                            />
                                        </div>
                                    </div>
                                    {
                                        (showSubtitleInput) ?
                                            <>
                                                <Form.Control
                                                    type="file"
                                                    varient="info"
                                                    accept=".png,.jpeg,.jpg"
                                                    onChange={onFileChange}
                                                    id="subtitle"
                                                    className='thumbnail-form-control'
                                                />
                                            </>
                                            : null
                                    }
                                    {
                                        subtitleFile ?
                                            <>
                                                <div className='thumbnailImg'>
                                                    <img className='videoImg' src={defaultPlaceholderImage} alt='thumbnail' />
                                                </div>
                                                <label id="subtitleLabel" className="text-white thumbnailImg-label">{t('editVideoDetails.videoElements.fileSelected')}</label>
                                            </>
                                            : null
                                    }
                                    {
                                        errors.subtitle &&
                                        <span className='submit-errors text-danger'>{errors.subtitle}</span>
                                    }

                                    <div className='select-items custom-react-select w-100'>
                                        <label style={{ fontSize: '16px' }}>{t('editVideoDetails.videoElements.subtitleLanguage')}</label>
                                        <Select classNamePrefix="triage-select" className='iconDD barDD'
                                            placeholder={t('editVideoDetails.videoElements.subtitlePlaeholder')}
                                            options={subtitleLangOptions}
                                            value={selected}
                                            onChange={(e) => setSelected({ value: e.value, label: e.label })}
                                        />
                                    </div>
                                </div>

                            </Modal.Body>
                            <Modal.Footer className='d-flex justify-content-start'>
                                <Button onClick={uploadSubtitles}>{t('common.saveBtn')}</Button>
                            </Modal.Footer>
                        </div>
                    </Modal>
                }
            </>
    )
}
export default Subtitles