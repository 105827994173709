import React, { useState, useEffect } from 'react';
import { ENV } from '../../../config/config';
import { getVideo, beforeVideo } from '../MemberDashboard/Videos/video.actions';
import Favourite from '../UserComponents/Favourites/FavouriteComponent';
import { addRemoveLikeDislike, beforeLikeDislike } from '../MemberDashboard/Videos/video.actions';
import SocialShare from '../SocialShare/SocialShare';
import { formatNumber } from '../../../utils/Shared Functions/shared';
import './player.css';
import moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

const { defaultPlaceholderImage, statTypes } = ENV

const VideoDetails = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const descriptionLength = 350
    const currentLanguage = localStorage.getItem('selectedLang')
    const { t, i18n } = useTranslation()
    const [videoDetails, setDetails] = useState()
    const [showDesc, setShowDesc] = useState(false)
    const [videoLiked, setVideoLiked] = useState(false)
    const [videoDisliked, setVideoDislike] = useState(false)
    const [likeCount, setLikeCount] = useState('')
    const [dislikeCount, setDislikeCount] = useState('')

    const videosSelector = useSelector((state) => state.videos)

    useEffect(() => {
        if (props.slug /* && (props.userId || props.adminKey) */) {
            let qs = `lng=${currentLanguage}`
            qs += props.userId ? `&userId=${props.userId}` : ''
            dispatch(getVideo(props.slug, qs))
        }
    }, [props.slug])

    useEffect(() => {
        if (videosSelector.getVideoAuth) {
            let { video, data } = videosSelector.getVideoData

            if (video.subtitles?.length)
                props.setVideoSubtitle(video.subtitles)

            if (video.metaTitle)
                props.setMetaTitle(video.metaTitle)

            if (video.metaDescription)
                props.setMetaDesc(video.metaDescription)

            if (video.hideVideo) {
                navigate('/404')
            }

            if (data?.totalLikes)
                setLikeCount(data?.totalLikes)

            if (data?.totalDislikes)
                setDislikeCount(data?.totalDislikes)

            setVideoLiked(data?.userLiked)
            setVideoDislike(data?.userDisliked)

            setDetails(video)
            dispatch(beforeVideo())

        }
    }, [videosSelector.getVideoAuth])

    const likeDislikeVideoHandler = (type, value) => {

        if (type === 1 && videoDisliked)
            return

        if (type === 2 && videoLiked)
            return

        let payload = { slug: props.slug, userId: props.userId, likeDislikeRemoved: !value }
        if (type === 1) {
            setVideoLiked(value)
            payload.type = ENV.statTypes.videoLiked
        }
        else if (type === 2) {
            setVideoDislike(value)
            payload.type = ENV.statTypes.videoDisliked
        }

        dispatch(addRemoveLikeDislike({ ...payload, lng: currentLanguage }))

    }

    useEffect(() => {
        if (videosSelector.addRemoveLikeDislikeAuth) {
            let { userLikedCount, userDislikedCount, total, type } = videosSelector.addRemoveLikeDislikeData?.data

            if (type === 5) {
                setLikeCount(total)
                setVideoLiked(userLikedCount ? true : false)

            }
            else if (type === 6) {
                setDislikeCount(total)
                setVideoDislike(userDislikedCount ? true : false)
            }

            dispatch(beforeLikeDislike())
        }

    }, [videosSelector.addRemoveLikeDislikeAuth])

    return (
        <>
            {
                videoDetails &&
                <div className='video-site-content'>
                    <div className='d-flex flex-column flex-xxl-row  align-items-center'>
                        <h2>{videoDetails.title}</h2>
                    </div>
                    <p>{videoDetails.totalViews || 0} {videoDetails?.totalViews > 1 ? 'Views' : 'View'} </p>
                    <div className='v-user-details d-flex align-items-center flex-column flex-xxl-row  justify-content-between'>
                        <div className='d-flex mb-3'>
                            <div className='img me-3'>
                                <img src={videoDetails.channel?.image || defaultPlaceholderImage} className="img-fluid" alt='channel' />
                            </div>
                            <div className="d-flex flex-column">
                                <Link className='user-name mb-1' to={`/channel-details/${videoDetails.channel?.slug}`}>{videoDetails.channel?.name || 'N/A'}</Link>
                                {
                                    videoDetails.publishDate ?
                                        <span className='publish-date mb-0'>{t('videoDetails.publishedOn')}  {moment(new Date(videoDetails.publishDate)).format('MM/DD/YYYY')}</span>
                                        : null
                                }
                            </div>

                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='like-dislike-btn d-flex align-items-center'>
                                {
                                    videoLiked ?
                                        <button onClick={() => likeDislikeVideoHandler(1, false)}><span><FontAwesomeIcon icon={faThumbsUp} /> {likeCount > 0 ? formatNumber(likeCount) : ''}</span></button> :
                                        <button onClick={() => likeDislikeVideoHandler(1, true)}><span><FontAwesomeIcon style={{ color: 'gray' }} icon={faThumbsUp} /> {likeCount > 0 ? formatNumber(likeCount) : ''}</span></button>
                                }
                                {
                                    videoDisliked ?
                                        <button onClick={() => likeDislikeVideoHandler(2, false)}><FontAwesomeIcon icon={faThumbsDown} />{dislikeCount > 0 ? formatNumber(dislikeCount) : ''}</button> :
                                        <button onClick={() => likeDislikeVideoHandler(2, true)}><FontAwesomeIcon style={{ color: 'gray' }} icon={faThumbsDown} />{dislikeCount > 0 ? formatNumber(dislikeCount) : ''}</button>
                                }
                            </div>
                            <SocialShare shareUrl={`${ENV.baseUrl}/media-player/${videoDetails?.slug}/${videoDetails.channel?.name.toLowerCase()}`} title={videoDetails?.title} slug={videoDetails?.slug}/>

                            <Favourite
                                _id={videoDetails._id}
                                count={videoDetails.favouritesCount}
                                userFavourite={videoDetails.userFavourite}
                                content={videoDetails}
                                setContent={setDetails}
                                pageKey="video-details"
                            />
                        </div>
                    </div>
                    <div className='v-description'>
                        <p>{t('videoDetails.description')}</p>
                        {
                            videoDetails.description?.length > descriptionLength ?
                                <>
                                    <span>
                                        {videoDetails.description.slice(0, descriptionLength)}
                                    </span>
                                    {
                                        !showDesc ?
                                            <span>
                                                <button className="link orangeLinkBtn" onClick={() => setShowDesc(true)}>{t('common.readMoreBtn')}</button>
                                            </span>
                                            :
                                            <span>
                                                {videoDetails.description.slice(descriptionLength, videoDetails.description.length)}
                                                <button className="link orangeLinkBtn" onClick={() => setShowDesc(false)}>{t('common.readLessBtn')}</button>
                                            </span>
                                    }
                                </>
                                :
                                <span>
                                    {videoDetails.description}
                                </span>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default VideoDetails