import React from 'react';
import './NotFound.css'
import { useTranslation } from 'react-i18next';

function NotFound() {
    const { t } = useTranslation()
    return (
        <div id="notfound" className='d-flex justify-content-center align-items-center'>
            <div className="notfound">
                <div className="notfound-404">
                    <strong className="sub-heading">{t('pageNotFound.text1')}</strong>
                    <h1>404</h1>
                </div>
                <p className='mb-0'>{t('pageNotFound.text2')}</p>
            </div>
        </div>
    );
}
export default NotFound;