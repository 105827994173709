import React, { useState, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import Content from './Content'
import { useDispatch, useSelector } from 'react-redux'
import Audience from './Audience'
import Research from '../VideoAnalyticsComponent/Research'
import ReactECharts from 'echarts-for-react'
import { getChannelAnalytics, beforeChannelAnalytics } from '../../MyChannels/channels.actions'
import moment from 'moment'
import FullPageLoader from '../../../Frontend/FullPageLoader/FullPageLoader'
import { getDates, getDateIntervals } from '../../../../utils/Shared Functions/shared'
import SmallLoader from '../../../../utils/SmallLoader/SmallLoader'
import { analyticsSelect, audienceTab, contentTab, overviewTab, realtimeSelect, researchTab } from '../../ApplicationTour/TourCompIdentifiers'
import DateTimeFilters from '../../../../utils/Filters/Date&TimeFilters'
import CustomSearchModal from '../../../../utils/Filters/CustomSearchModal'
import { useTranslation } from 'react-i18next'
import 'react-clock/dist/Clock.css';
import '../VideoAnalyticsComponent/VideoAnalytics/VidoeAnalytics.css'

const ChannelAnalytics = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const channelId = localStorage.getItem('channelId')
    const [clickedStatOption, setClickedOption] = useState(1);
    const [analytics, setAnalytics] = useState({})
    const [searchCustom, setCustom] = useState(false)
    const [customModal, setModal] = useState(false)
    const [selectedTab, setTab] = useState(0)
    const [loader, setLoader] = useState(false)
    const [realtimeLoader, setRealtimeLoader] = useState(true)
    const [realtimeViews, setRealtimeViews] = useState(0)
    const [date, setDate] = useState({
        startDate: '',
        endDate: ''
    })
    const [startTime, setStartTime] = useState(moment(new Date()).startOf('day').format('HH:mm'));
    const [endTime, setEndTime] = useState(moment(new Date()).endOf('day').format('HH:mm'));

    const colors = ['#FD6F21'];
    const tabColors = {
        'grey': '#393C48',
        'black': '#0D0F17'
    }
    const [options, setOptions] = useState({
        color: colors,
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                type: 'line',
                color: colors[1],
                symbol: "circle",
            }
        ]
    })

    const durationList = [
        { value: '24', label: t('common.daysFilter.24hrs'), unit: 'hours' },
        { value: '7', label: t('common.daysFilter.7days'), unit: 'days' },
        { value: '15', label: t('common.daysFilter.15days'), unit: 'days' },
        { value: '30', label: t('common.daysFilter.30days'), unit: 'days' },
        { value: 'custom', label: t('common.daysFilter.custom') }
    ];

    const realtimeDurationList = [
        { value: '60', label: t('common.realtimeSelect.60mins'), unit: 'minutes' },
        { value: '6', label: t('common.realtimeSelect.6hrs'), unit: 'hours' },
        { value: '24', label: t('common.realtimeSelect.24hrs'), unit: 'hours' },
        { value: '7', label: t('common.realtimeSelect.7days'), unit: 'days' },
    ];

    const [selected, setSelected] = useState(durationList[0])
    const [realtimeSelected, setRealtimeSelected] = useState(realtimeDurationList[0])

    const channelSelector = useSelector((state) => state.channels)

    useEffect(() => {
        if (realtimeSelected.value && channelId) {
            let duration = `${realtimeSelected.value}-${realtimeSelected.unit}`
            fetchAnalytics(`duration=${duration}`, true)
        }

    }, [realtimeSelected])

    useEffect(() => {
        if (selectedTab === 0)
            setRealtimeSelected(realtimeDurationList[0])
    }, [selectedTab])


    useEffect(() => {
        if (channelSelector.channelAnalyticsAuth) {
            let data = channelSelector?.channelAnalyticsData
            if (data?.channelAnalytics && data?.statType == 0) {
                setAnalytics(data?.channelAnalytics)
            }
            else if (data?.totalViews !== null) {
                setTimeout(() => setRealtimeLoader(false), 200)
                setRealtimeViews(data?.totalViews)
            }
            setLoader(false)
            dispatch(beforeChannelAnalytics())
        }
    }, [channelSelector.channelAnalyticsAuth])

    useEffect(() => {
        if (selectedTab !== 3 && channelId) {
            let duration = `${selected.value}-${selected.unit}`
            fetchAnalytics(`duration=${duration}`)
        }
    }, [selectedTab])

    useEffect(() => {
        if (selected.value) {
            let { startDate, endDate } = getDates(selected)
            setDate({ startDate, endDate })
        }
    }, [selected])

    const onSearchHandler = () => {
        if (selected.value) {
            setClickedOption(1)

            if (selected.value !== 'custom') {
                setCustom(false)
                if (selectedTab !== 3 && channelId) {
                    let duration = `${selected.value}-${selected.unit}`
                    fetchAnalytics(`duration=${duration}`)
                }
            }
            else {
                fetchAnalytics(`startDate=${moment(new Date(date.startDate))}&endDate=${moment(new Date(date.endDate))}`)
            }
        }
    }

    const resetFiltersHandler = () => {
        let start = moment(new Date()).startOf('day').format('HH:mm')
        let end = moment(new Date()).endOf('day').format('HH:mm')
        let { startDate, endDate } = getDates(durationList[0])
        let duration = `${durationList[0].value}-${durationList[0].unit}`
        let qs = `channelId=${channelId}&statType=${0}&startTime=${start}&endTime=${end}&duration=${duration}`

        dispatch(getChannelAnalytics(qs))

        setLoader(true)
        setDate({ startDate, endDate })
        setSelected(durationList[0])
        setTab(0)
        setStartTime(start)
        setEndTime(end)
    }

    const fetchAnalytics = (queryString, realtimeStat = false) => {
        let qs = `channelId=${channelId}&startTime=${startTime}&endTime=${endTime}&${queryString}`
        if (realtimeStat) {
            qs = `${qs}&statType=realtime`
            setRealtimeLoader(true)
        }
        else {
            qs = `${qs}&statType=${selectedTab}`
            setLoader(true)
        }

        dispatch(getChannelAnalytics(qs))

    }

    useEffect(() => {
        if (clickedStatOption && analytics) {
            setLoader(false)
            let optionsData = { ...options }
            let { views, watchTime, identifiedObjects } = analytics
            let xAxisDates = []
            let yAxisValues = []

            let key = clickedStatOption === 1 ? views : clickedStatOption === 2 ? watchTime : clickedStatOption === 3 ? identifiedObjects : null
            let dateIntervals = []
            let seriesData = []

            if (key)
                if (key.data?.length) {
                    let indexes = []
                    key.data.forEach((stat) => {

                        let statDate = moment(new Date(stat.date)).format('DD-MMM-YY')
                        xAxisDates.push(statDate)
                        yAxisValues.push(stat.total);

                        dateIntervals = getDateIntervals(date.startDate, date.endDate, yAxisValues.length)
                        seriesData = new Array(dateIntervals.length).fill(0)
                        dateIntervals.forEach((dt, indx) => {
                            if (dt === statDate)
                                indexes.push(indx)
                        })
                    })

                    indexes.forEach((i, ind) => {
                        seriesData[i] = yAxisValues[ind]
                    })
                }
                else {
                    dateIntervals = getDateIntervals(date.startDate, date.endDate)
                    seriesData = new Array(dateIntervals.length).fill(0)
                }

            optionsData.xAxis = { ...optionsData.xAxis, data: dateIntervals }
            optionsData.series = [
                {
                    ...optionsData.series[0],
                    name: clickedStatOption === 1 ? t('common.views') : clickedStatOption === 2 ? t('common.avgViewDuration') : clickedStatOption === 3 ? t('common.recognizedObjects') : '',
                    data: seriesData
                }
            ]

            setOptions(optionsData)
        }
    }, [clickedStatOption, analytics])

    useEffect(() => {
        if (searchCustom && selectedTab !== 4) {
            fetchAnalytics(`startDate=${moment(new Date(date.startDate))}&endDate=${moment(new Date(date.endDate))}`)
        }
    }, [searchCustom, selectedTab])

    const statOptionsChangeHandler = (option) => {
        if (option !== clickedStatOption) {
            setClickedOption(option)
            setLoader(true)
        }
    }

    const durationChangeHandler = (e) => {
        if ((e.value === 'custom') || e.value !== selected.value) {

            if (e.value === 'custom') {
                setModal(true)
                setCustom(false)
            }

            setSelected({ value: e.value, label: e.label, unit: e.unit })
        }
    }

    const dateTimeFiltersProps = {
        date, startTime, endTime, setStartTime, setEndTime, selected, resetFiltersHandler, onSearchHandler, durationChangeHandler
    }

    const customSearchProps = {
        date, setDate, startTime, endTime, setStartTime, setEndTime, setCustom, setSelected, selectedTab, setLoader, setModal, customModal, durationList
    }

    return (
        <>
            {loader && <FullPageLoader />}
            <div className='dashboardPage'>
                <div className='dashboardHeader px40'>
                    <h2 className='text-uppercase'>{t('channelAnalytics.heading')}</h2>
                </div>
                <div className='videoAnalytics themeAnalytics px40'>
                    <DateTimeFilters {...dateTimeFiltersProps} />
                    <Tabs activeKey={selectedTab} onSelect={(k) => setTab(k)}>
                        <div className='tabFlex tabs-filters me-0'>
                            <TabList className='border-0 mb-0'>
                                <Tab><span id={overviewTab}>{t('channelAnalytics.tabs.overview')}</span></Tab>
                                <Tab><span id={contentTab}>{t('channelAnalytics.tabs.content')}</span></Tab>
                                <Tab><span id={audienceTab}>{t('channelAnalytics.tabs.audience')}</span></Tab>
                                <Tab><span id={researchTab}>{t('channelAnalytics.tabs.research')}</span></Tab>
                            </TabList>
                        </div>
                        <TabPanel>
                            <Row>
                                <Col lg={8} className=''>
                                    <div className='analyticGraph'>
                                        <div className='analyticsBar'>
                                            <div className={`analyticsBarBox pointer ${clickedStatOption === 1 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(1)}>
                                                <span>{t('common.views')}</span>
                                                <h4>{analytics.views ? analytics.views?.total : 0}</h4>
                                            </div>
                                            <div className={`analyticsBarBox pointer ${clickedStatOption === 2 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(2)}>
                                                <span>{t('common.overview.watchTime')}</span>
                                                <h4>{analytics.watchTime ? analytics.watchTime?.total : 0}</h4>
                                            </div>
                                            <div className={`analyticsBarBox pointer ${clickedStatOption === 3 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(3)}>
                                                <span>{t('common.recognisedObjects')}</span>
                                                <h4>{analytics.identifiedObjects ? analytics.identifiedObjects?.total : 0}</h4>
                                            </div>
                                        </div>

                                        <ReactECharts option={options} />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className='box h-auto'>
                                        <div className='realtimeBox'>
                                            <div id={realtimeSelect}>
                                                <h5>{t('common.realtime')}</h5>
                                                <p>{t('common.updatingLive')}</p>
                                            </div>
                                            <div>
                                                {
                                                    realtimeLoader ?
                                                        <h5>
                                                            <SmallLoader wrapperClass="align-items-center mb-2" />
                                                        </h5>
                                                        :
                                                        <h5>{realtimeViews}</h5>

                                                }
                                                <div className='searchByDays align-items-center'>
                                                    <div className='md-16 me-5 mb-0'>
                                                        <div className='select-items custom-react-select'>
                                                            <Select classNamePrefix="triage-select" className='iconDD barDD'
                                                                placeholder='Select Type'
                                                                options={realtimeDurationList}
                                                                value={realtimeSelected}
                                                                onChange={(e) => setRealtimeSelected({ value: e.value, label: e.label, unit: e.unit })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='realtimeBox-btm'>
                                            <p className='mb-0'>{`${realtimeSelected.value} (${realtimeSelected.unit})`}</p>
                                            <p className='mb-0'>{t('common.now')}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel>
                            <Content
                                t={t}
                                setLoader={setLoader}
                                getDateIntervals={getDateIntervals}
                                date={date}
                            />
                        </TabPanel>
                        <TabPanel>
                            <Audience
                                t={t}
                                setLoader={setLoader}
                                getDateIntervals={getDateIntervals}
                                date={date}
                            />
                        </TabPanel>
                        <TabPanel>
                            <Research t={t} date={date} searchCustom={searchCustom} selected={selected} />
                        </TabPanel>

                    </Tabs>
                </div>
            </div>
            <CustomSearchModal {...customSearchProps} />
        </>
    )
}
export default ChannelAnalytics