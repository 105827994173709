import React, { useState, useEffect } from 'react'
import ReactECharts from 'echarts-for-react'
import { useDispatch, useSelector } from 'react-redux'
import { beforeVideoAnalytics } from '../Videos/video.actions'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import './siteTabs.css'

const Engagement = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const colors = ['#5470C6', '#FD6F21'];
    const tabColors = {
        'grey': '#393C48',
        'black': '#0D0F17'
    }
    const [options, setOptions] = useState({
        color: colors,
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                type: 'line',
                color: colors[1],
                symbol: "circle"
            }
        ]
    })

    const [clickedStatOption, setClickedOption] = useState(1);
    const [analytics, setAnalytics] = useState({})
    const videosSelector = useSelector((state) => state.videos)

    useEffect(() => {
        if (videosSelector.videoAnalyticsAuth) {
            let data = videosSelector?.videoAnalyticsData
            if (data?.videoAnalytics && data?.statType == '2') {
                setAnalytics(data?.videoAnalytics)
            }

            props.setLoader(false)
            setClickedOption(1)
            dispatch(beforeVideoAnalytics())
        }
    }, [videosSelector.videoAnalyticsAuth])

    useEffect(() => {
        if (clickedStatOption && analytics) {
            props.setLoader(false)
            let optionsData = { ...options }
            let { watchTime, avgViewDurationStats } = analytics
            let xAxisDates = []
            let yAxisValues = []

            let key = clickedStatOption === 1 ? watchTime : clickedStatOption === 2 ? avgViewDurationStats : null
            let dateIntervals = []
            let seriesData = []

            if (key)
                if (key.data?.length) {
                    let indexes = []
                    key.data.forEach((stat) => {

                        let statDate = moment(new Date(stat.date)).format('DD-MMM-YY')
                        xAxisDates.push(statDate)
                        yAxisValues.push(stat.total);

                        dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate, 1, yAxisValues.length)
                        seriesData = new Array(dateIntervals.length).fill(0)
                        dateIntervals.forEach((dt, indx) => {
                            if (dt === statDate)
                                indexes.push(indx)
                        })
                    })
                    indexes.forEach((i, ind) => {
                        seriesData[i] = yAxisValues[ind]
                    })
                }
                else {
                    dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate)
                    seriesData = new Array(dateIntervals.length).fill(0)
                }

            optionsData.xAxis = { ...optionsData.xAxis, data: dateIntervals }
            optionsData.series = [
                {
                    ...optionsData.series[0],
                    name: clickedStatOption === 1 ? t('videoAnalytics.engagement.watchTime') : clickedStatOption === 2 ? t('common.avgViewDuration') : null,
                    data: seriesData
                }
            ]

            setOptions(optionsData)
        }
    }, [clickedStatOption, analytics])

    const statOptionsChangeHandler = (option) => {
        if (option !== clickedStatOption) {
            setClickedOption(option)
            props.setLoader(true)
        }
    }

    return (
        <>
            <div className='dashboardPage'>
                <div className='analyticContent'>
                    <div className='analyticGraph'>
                        {
                            analytics &&
                            <div className='analyticsBar'>
                                <div className={`analyticsBarBox pointer ${clickedStatOption === 1 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(1)}>
                                    <span>{t('common.overview.watchTime')}</span>
                                    <h4>{analytics.watchTime ? analytics.watchTime?.total : 0}</h4>
                                </div>
                                <div className={`analyticsBarBox pointer ${clickedStatOption === 2 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(2)}>
                                    <span>{t('common.avgViewDuration')}</span>
                                    <h4>{analytics.avgViewDurationStats ? analytics.avgViewDurationStats?.total : 0}</h4>
                                </div>
                            </div>
                        }
                        <ReactECharts option={options} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Engagement