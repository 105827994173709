import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import Join from './Join'
import Overview from './Overview'
import Team from './Team'
import Together from './Together'
import Help from './Help'
import Reviews from './Reviews'
import './About.css'
import { useTranslation } from 'react-i18next'

const Aboutus = () => {
	const { t } = useTranslation()

	return (
		<div className="content-page">
			<section className='together-sec mission-banner'>
				<Container className="customBox">
					<Row>
						<Col lg={6}>
							<div className='wrapper'>
								<h4 className='H2'>{t('aboutUs.ourMission')}</h4>
								<p>{t('aboutUs.ourMissionText')}</p>
							</div>
						</Col>
						<Col lg="6">
							<div className='wrapper'>
								<h4 className='H2'>{t('aboutUs.whoAreWe')}</h4>
								<p>{t('aboutUs.whoAreWeText')}</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className='content-section'>
				<Container className='customBox'>
					<Row className="align-items-center">
						<Col lg={6}>
							<h1 className='mb-5 mb-lg-0 border-heading'>{t('aboutUs.biiviewPerspective.text1')} <span className="theme">{t('aboutUs.biiviewPerspective.text2')}</span>, {t('aboutUs.biiviewPerspective.text3')} <span className="theme">{t('aboutUs.biiviewPerspective.text4')}</span></h1>
						</Col>
						<Col lg={6} className="d-flex align-items-end">
							<h3 className='mb-0'>{t('aboutUs.biiviewDescription')}</h3>
						</Col>
					</Row>
				</Container>
			</section>
			<section className='content-section'>
				<Container className='customBox'>
					<Row className='align-items-center'>
						<Col lg={6} className="order-0 order-lg-1">
							<h2 className='mb-5 mb-lg-0 border-heading'>{t('aboutUs.exceptionalTechnology.userExperience')} <span className='theme'>{t('aboutUs.exceptionalTechnology.cuttingEdge')}</span>.</h2>
						</Col>
						<Col lg={6} className="order-1 order-lg-0">
							<h3 className='mb-4'>{t('aboutUs.visionaryTool')}</h3>
							<p>{t('aboutUs.features')}</p>
							<p>{t('aboutUs.purpose')}</p>
						</Col>
					</Row>
				</Container>
			</section>
			<Team />
			<Reviews />
			<Join />
			<Help />
		</div>
	)
}

export default Aboutus