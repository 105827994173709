import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { ENV } from "../../../../../config/config";
import { inviteUser, getChannelUsers, beforeInvite, deleteUser } from '../../../Invites/invites.actions'
import { useSelector, useDispatch } from 'react-redux'
import { beforeChannelRole, getChannelRole, listChannelRoles } from '../../../ChannelRoles/channelRoles.actions'
import { RiDeleteBin4Line } from "react-icons/ri";
import Swal from 'sweetalert2';
import Select from 'react-select';
import '../../Models/Models.css'
import "./Permissions.css"
import { useTranslation } from 'react-i18next'

const { userPlaceholderImage } = ENV

const Permissions = ({ setShow, ownerId }) => {
    const { t, i18n } = useTranslation()
    const [role, setRole] = useState();
    const [channelRoles, setRoles] = useState([])
    const [memberRole, setMemberRole] = useState()
    const [inviteTo, setInviteTo] = useState();
    const { _id, email } = ENV.getUserKeys();
    const [msg, setMsg] = useState({})
    const [channelUsers, setChannelUsers] = useState();
    const [channelName, setName] = useState('')
    const channelId = localStorage.getItem('channelId')
    const dispatch = useDispatch();
    const currentLanguage = localStorage.getItem('selectedLang')

    const inviteSelector = useSelector((state) => state.invites)
    const rolesSelector = useSelector((state) => state.roles)

    useEffect(() => {
        if (channelId && i18n.language) {
            let qs = `lng=${currentLanguage}`
            dispatch(listChannelRoles(qs))
            dispatch(getChannelRole(`channelId=${channelId}&userId=${_id}&${qs}`))
            dispatch(getChannelUsers(`channelId=${channelId}&${qs}`))
        }
    }, [i18n.language])

    useEffect(() => {
        if (rolesSelector.channelRoleAuth) {
            let role = rolesSelector.channelRoleData.memberRole
            setMemberRole(role)
            dispatch(beforeChannelRole())
        }
    }, [rolesSelector.channelRoleAuth])

    useEffect(() => {
        if (rolesSelector.channelRolesAuth) {
            const items = rolesSelector?.channelRolesData?.channelRoles
                ?.map(item => ({
                    default: item.default,
                    value: item._id,
                    label: item.title
                }))
                ?.filter(item => item.default === false);

            setRoles(items)
            dispatch(beforeChannelRole())
        }
    }, [rolesSelector.channelRolesAuth])

    useEffect(() => {
        if (inviteSelector.channelUsersAuth) {
            setName(inviteSelector.channelUsers?.data[0]?.channel?.name)
            setChannelUsers(inviteSelector.channelUsers.data)
            dispatch(beforeInvite())
        }
    }, [inviteSelector.channelUsersAuth])

    useEffect(() => {
        if (inviteSelector.deleteChannelUserAuth) {
            dispatch(beforeInvite())
            dispatch(getChannelUsers(`channelId=${channelId}`))
        }
    }, [inviteSelector.deleteChannelUserAuth])

    const invite = () => {
        if (!role) {
            setMsg({ ...msg, role: t('settings.permissions.roleRequired') })
            return;
        }
        if (!inviteTo) {
            setMsg({ ...msg, email: t('settings.permissions.emailRequired') })
            return;
        }
        setShow(false)
        dispatch(inviteUser({ _id, inviteFrom: email, inviteTo, role, channelId: localStorage.getItem('channelId'), lng: currentLanguage }))
    }

    const deleteChannelUser = (userId, channelId) => {
        Swal.fire({
            title: t('settings.permissions.delPublisher'),
            html: t('settings.permissions.deleteMsg'),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no')
        }).then(async (result) => {
            if (result.value) {
                // setLoader(true)
                dispatch(deleteUser({ userId, channelId, lng: currentLanguage }))
            }
        })
    };

    return (
        <>
            <div className='modalPage user-permissions h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div className='general'>
                        <h4>{t('settings.permissions.heading')} {channelName ? channelName : 'Channel'}</h4>
                        <p className='max-95'>{t('settings.permissions.description')}</p>
                        {
                            memberRole && memberRole?.addMembersInChannel ?
                                <div className='dashboardHeader ps-0 d-sm-flex'>
                                    <div className="fileGroupList me-2 mb-2">
                                        <Form.Group>
                                            <Form.Control
                                                name="email"
                                                value={inviteTo}
                                                placeholder={t('settings.permissions.emailPlaceholder')}
                                                type="email"
                                                onChange={(e) => setInviteTo(e.target.value)}
                                                className="mb-0"
                                            ></Form.Control>
                                        </Form.Group>
                                        <span className={msg?.email ? 'text-danger' : "d-none"}>{msg?.email}</span>
                                    </div>
                                    <div className='fileGroupList me-2 mb-2'>
                                        <div className='select-items custom-form-select h-100'>
                                            <div className="custom-react-select flex-fill">
                                                {
                                                    channelRoles && channelRoles.length ?
                                                        <Select
                                                            options={channelRoles}
                                                            value={channelRoles && channelRoles.length > 0 ? channelRoles.find(option => option.value === role) : null}
                                                            onChange={(e) => setRole(e.value)}
                                                            classNamePrefix="triage-select"
                                                        />
                                                        :
                                                        null
                                                }
                                            </div>
                                            <span className={msg?.role ? 'text-danger' : "d-none"}>{msg?.role}</span>
                                        </div>
                                    </div>

                                    <button className="text-uppercase mt-0 theme-button mb-2"
                                        hidden={!email || !role}
                                        onClick={invite}
                                    > {t('settings.permissions.invite')}
                                    </button>

                                </div> : null
                        }
                        {
                            memberRole && memberRole?.viewChannelMembers ?
                                <div className='modalTable'>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th><p>{t('settings.permissions.listing.name')}</p></th>
                                                <th><p>{t('settings.permissions.listing.role')}</p></th>
                                                <th><p>{t('settings.permissions.listing.action')}</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {channelUsers && channelUsers.map((val, key) => (
                                                <tr key={key}>
                                                    <td>
                                                        <div className='permissionRoles'>
                                                            <div className='roleProfile'>
                                                                <figure className='roleImg'><img className='img-fluid' src={val.profileImage || userPlaceholderImage} alt={val?.fullName}></img></figure>
                                                                <div>
                                                                    <p className='userName mb-0'>{val.fullName}</p>
                                                                    <p className='roleEmail mb-0'>{val.email}</p>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className='role mb-0'>{val.role.title}
                                                        </p>
                                                    </td>
                                                    {
                                                        memberRole?.removeMemberFromChannel &&
                                                        <td>
                                                            <p className='role mb-0'>
                                                                {(val.channel?.ownerId === ownerId || val.userId === _id) ?
                                                                    ""
                                                                    :
                                                                    <span onClick={(e) => deleteChannelUser(val.userId, val.channelId)}>
                                                                        <RiDeleteBin4Line />
                                                                    </span>}
                                                            </p>
                                                        </td>
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div> : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default Permissions