import { toast } from 'react-toastify';
import { emptyError } from '../../../redux/shared/error/error.action';
import { GET_ERRORS, LIST_PLANS, GET_PLAN, SUBSCRIBE_PLAN, CANCEL_SUBSCRIPTION, BEFORE_PLANS } from '../../../redux/types';
import { ENV } from '../../../config/config';
import { clearStorage } from '../../../utils/Shared Functions/shared';

export const beforePlans = () => {
    return {
        type: BEFORE_PLANS
    }
}

export const getPlan = (id, qs = '') => dispatch => {
    toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}plans/get/${id}`;
    if (qs) url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_PLAN,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: "get-plan-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const listPlans = (qs = '') => dispatch => {
    // toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}plans/list`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_PLANS,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: "plans-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const subscribePlan = (payload, freePlan = false) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}subscription/${payload.key === "authorize" ? 'subscribe/authorize' : 'subscribe/stripe' }/`;

    if (freePlan)
        url = `${url}free`

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(payload)

    }).then(res => res.json()).then(data => {
        if (data.success) {
            if (data.user?.newUser) {
                clearStorage()
            }

            toast.success(data.message, { toastId: "subscribe-success" })
            dispatch({
                type: SUBSCRIBE_PLAN,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "subscribe-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const cancelSubscription = (qs) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}subscription/cancel/`;
    if (qs) url += `?${qs}`
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },

    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: "cancel-sub-success" })
            dispatch({
                type: CANCEL_SUBSCRIPTION,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "cancel-sub-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};