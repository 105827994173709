import moment from 'moment'

export const getDates = (selectedOption) => {
    let { value, unit } = selectedOption
    let startDate = moment(new Date()).subtract(parseInt(value), unit)
    let endDate = moment(new Date())

    return {
        startDate: startDate.format('MMM DD, YYYY'),
        endDate: endDate.format('MMM DD, YYYY')
    }
}

export const getDateIntervals = (start, end, dataLength = 0) => {
    let dateInterval = [];
    if (start && end) {
        start = moment(new Date(start))
        end = moment(new Date(end))

        if (dataLength > 0) {
            while (start <= end) {
                dateInterval.push(start.format('DD-MMM-YY'));
                start.add(1, 'days');
            }
        }
        else {
            dateInterval.push(start.format('DD-MMM-YY'), end.format('DD-MMM-YY'));
        }

    }
    return dateInterval;
};

export const clearStorage = () => {
    const currentLanguage = localStorage.getItem('selectedLang');
    localStorage.clear();
    localStorage.setItem('selectedLang', currentLanguage);
}

export const formatNumber = (count) => {
    if (count >= 1000000) {
        return (count / 1000000).toFixed(1) + 'M';
    } if (count >= 1000) {
        return (count / 1000).toFixed(1) + 'K';
    } else {
        return count
    }
};
