import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { FaFacebookF, FaLinkedin, FaPinterest, FaTwitter, FaYoutube } from 'react-icons/fa';
import { getSiteSettings, beforeSiteSettings } from '../SiteSettings/siteSettings.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Images } from '../../../assets/assets';
import './Footer.css'
import { useTranslation } from 'react-i18next';
import { subscribeNewsletter } from '../Newsletter/newsletter.actions';
import { validateEmail } from '../../../utils/Validations/validations';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { BEFORE_NEWSLETTERS } from '../../../redux/types';

const Footer = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const errorSelector = useSelector((state) => state.error)
	const newsletterSelector = useSelector((state) => state.newsletter)

	const [email, setEmail] = useState('')
	const [error, setError] = useState('')
	const [loader, setLoader] = useState(false)
	const date = new Date();
	let year = date.getFullYear();
	const settingsSelector = useSelector((state) => state.siteSettings)
	const currentLanguage = localStorage.getItem('selectedLang')
	const [siteSettings, setSettings] = useState({})

	useEffect(() => {
		dispatch(getSiteSettings(`lng=${currentLanguage}`))
	}, [])

	useEffect(() => {
		// when an error is received
		if (errorSelector) {
			setLoader(false)
		}
	}, [errorSelector])

	useEffect(() => {
		if (newsletterSelector.subscribedAuth) {
			setLoader(false)
			setEmail('')
			dispatch({ type: BEFORE_NEWSLETTERS })
		}
	}, [newsletterSelector.subscribedAuth])

	useEffect(() => {
		if (settingsSelector.getSiteSettingsAuth) {
			let { settings } = settingsSelector.getSiteSettingsData
			setSettings(settings)
			dispatch(beforeSiteSettings())
		}
	}, [settingsSelector.getSiteSettingsAuth])

	const subscribeToNewsletter = () => {
		let err = ''
		if (!email)
			err = 'Please enter email!'
		else {
			err = validateEmail(t, email)
		}

		setError(err)
		if (err)
			return
		setLoader(true)
		dispatch(subscribeNewsletter({ email }))
	}

	return (
		loader ? <FullPageLoader /> :
			<footer className='footer'>
				<Container fluid className='customBox'>
					<div className='footerTop'>
						<strong className='footerLogo d-inline-block align-top'>
							<Link className="d-inline-block align-top" to='/'><img className='img-fluid' src={Images.logo} alt='Biiview'></img></Link>
						</strong>
						<div className='socialIcon'>
							<ul className='p-0'>
								{
									siteSettings?.facebook && <li className="facebook">
										<a href={siteSettings.facebook} target="_blank" rel='noreferrer'><FaFacebookF /></a>
									</li>
								}
								{
									siteSettings?.twitter && <li className="twitter">
										<a href={siteSettings.twitter} target="_blank" rel='noreferrer'><FaTwitter /></a>
									</li>
								}
								{
									siteSettings?.linkedIn && <li className="linkedin">
										<a href={siteSettings.linkedIn} target="_blank" rel='noreferrer'><FaLinkedin /></a>
									</li>
								}
								{
									siteSettings?.pinterest && <li className="pinterest">
										<a href={siteSettings.pinterest} target="_blank" rel='noreferrer'><FaPinterest /></a>
									</li>
								}
								{
									siteSettings?.youtube && <li className="youtube">
										<a href={siteSettings.youtube} target="_blank" rel='noreferrer'><FaYoutube /></a>
									</li>
								}
							</ul>
						</div>
						<div className="subscription-form-holder d-flex justify-content-center">
							<div className="subscription-form-block d-flex flex-column align-items-start">
								<form className="subscription-form d-flex justify-content-between flex-column flex-sm-row align-items-end">
									<div className="input-holder">
										<label className="mb-2 ">{t('footer.subscribeToNewsletter')}</label>
										<input type="email" onChange={(e) => setEmail(e.target.value)} placeholder={t('footer.emailPlaceholder')} />
										{
											error && <span className='submit-errors text-danger'>{error}</span>
										}
									</div>
									<button onClick={subscribeToNewsletter} className="d-flex justify-content-center align-items-center" type="button">{t('footer.subscribe')}</button>
								</form>
							</div>
						</div>
						<div className='footerLink'>
							<ul className='p-0'>
								<li><Link to="/" className='link'>{t('footer.links.home')}</Link></li>
								<li><Link to="/about-us" className='link'>{t('footer.links.aboutUs')}</Link></li>
								<li><Link to="/blogs" className='link'>{t('footer.links.blogs')}</Link></li>
								<li><Link to="/contact-us" className='link'>{t('footer.links.contactUs')}</Link></li>
								<li><Link to="/terms-and-conditions" className='link'>{t('footer.links.terms&Conditions')}</Link></li>
								<li><Link to="/privacy-policy" className='link'>{t('footer.links.privacyPolicy')}</Link></li>
								<li><Link to="/informative" className='link'>{t('footer.links.informative')}</Link></li>
							</ul>
						</div>
					</div>
				</Container>
				<div className='footerBtm'>
					<Container fluid className='customBox'>
						<p className='copyright'>&copy; {year} BiiView. {t('footer.rightsReserved')}</p>
					</Container>
				</div>
				<a className="asoft-logo" href="https://www.arhamsoft.com" target='_blank'>
					<img className="img-fluid" src={Images.logoArhamsoft} alt='ArhamSoft' />
				</a>
			</footer>
	)
}
export default Footer