import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import { Images } from '../../../assets/assets'
import "./HowItWorks.css"

function HowItWorks() {
	return (
		<div id="how-it-works">
			
			<div className="works">
				<div className="workReltive">
				<Container className='customBox'>
				<div className='clockwise style360'>
					<div className='shapesAbs'>
						<div  className='d-flex justify-content-center shape1'>
							<div className='wrap-style360img'>
								<div className='position-relative'>
									<figure className='mb-0 hide'>
										<img className='img-fluid' src={Images.cut1} alt='biiview'></img>
									</figure>
									<figure className='mb-0 display'>
										<img className='img-fluid hoverImg' src={Images.hoverImg1} 
										alt='biiview'>
										</img> <img className='img-fluid bii' src={Images.hover1} 
										alt='biiview'> 
										</img> 
									</figure>
									<div className='pieText'>
										<h3>Metadata</h3>
										<p className='pie-P'>Metadata provides a concise summary of basic information about the recognized object data.</p>
									</div>
								</div>
							</div>
							<div className='wrap-style360img'>
								<div className='position-relative'>
									<figure className='mb-0 hide'>
										<img className='img-fluid' src={Images.cut2} alt='biiview'></img>
									</figure>
									<figure className='mb-0 display'>
										<img className='img-fluid hoverImg' src={Images.hoverImg2} alt='biiview'>
										</img> <img className='img-fluid bii' src={Images.hover2} alt='biiview'> 
										</img> 
									</figure>
									<div className='pieText'>
										<h3>Video Uploading</h3>
										<p className='pie-P'>Users have the ability to upload videos for object detection and annotation based on their selected subscription package.</p>
									</div>
								</div>
							</div>
						</div>
						<div  className='d-flex justify-content-center shape2'>
							<div className='wrap-style360img'>
								<div className='position-relative'>
									<figure className='mb-0 hide'>
										<img className='img-fluid' src={Images.cut7} alt='biiview'></img>
									</figure>
									<figure className='mb-0 display'>
										<img className='img-fluid hoverImg labelImg' src={Images.hoverImg7}
										alt='biiview'>
										</img> <img className='img-fluid bii' src={Images.hover7} alt='biiview'> 
										</img> 
									</figure>
									<div className='pieText'>
										<h3>Data Labeling</h3>
										<p className='pie-P'>Assign labels 10x faster to a series of objects extracted from a single video. Users can give their own labels to the anonymous objects.</p>
									</div>
								</div>
							</div>
							<div className='wrap-style360img'>
								<div className='position-relative'>
									<figure className='mb-0 hide'>
										<img className='img-fluid' src={Images.cut3} alt='biiview'></img>
									</figure>
									<figure className='mb-0 display'>
										<img className='img-fluid hoverImg' src={Images.hoverImg3} alt='biiview'>
										</img> <img className='img-fluid bii' src={Images.hover3} alt='biiview'> 
										</img> 
									</figure>
									<div className='pieText'>
									<h3>Categorization</h3>
									<p className='pie-P'>Each frame within the video is classified individually and autonomously, without any dependence on other frames.</p>
									</div>
								</div>
							</div>
						</div>
						<div  className='d-flex justify-content-center shape3'>
							<div className='wrap-style360img'>
								<div className='position-relative'>
									<figure className='mb-0 hide'>
										<img className='img-fluid bii2' src={Images.cut6} alt='biiview'></img>
									</figure>
									<figure className='mb-0 display'>
										<img className='img-fluid hoverImg' src={Images.hoverImg6} alt='biiview'>
										</img> <img className='img-fluid bii bii2' src={Images.hover6}
										alt='biiview'> 
										</img> 
									</figure>
									<div className='pieText'>
										<h3>Object Recognitions</h3>
										<p className='pie-P'>Object recognition encompasses a set of interconnected tasks aimed at identifying objects present in digital video content.</p>
									</div>
								</div>
							</div>
							<div className='wrap-style360img'>
								<div className='position-relative'>
									<figure className='mb-0 hide'>
										<img className='img-fluid bii2' src={Images.cut4} alt='biiview'></img>
									</figure>
									<figure className='mb-0 display'>
										<img className='img-fluid hoverImg' src={Images.hoverImg4} 
										alt='biiview'>
										</img> <img className='img-fluid bii bii2' src={Images.hover4} 
										alt='biiview'> 
										</img> 
									</figure>
									<div className='pieText'>
										<h3>Visual Framing</h3>
										<p className='pie-P'>Framing involves the arrangement of elements within the frame, determining the composition and structure of the visual content.</p>
									</div>
								</div>
							</div>
						</div>
						<div className='d-flex justify-content-center shape4'>
							<div className='wrap-style360img text-center'>
								<div className='position-relative'>
									<figure className='mb-0 hide'>
										<img className='img-fluid bii3' src={Images.cut5} alt='biiview'></img>
									</figure>
									<figure className='mb-0 display'>
										<img className='img-fluid hoverImg' src={Images.hoverImg5} alt='biiview'>
										</img> <img className='img-fluid bii bii3' src={Images.hover5} 
										alt='biiview'> 
										</img> 
									</figure>
									<div className='pieText'>
										<h3>Object Identification</h3>
										<p className='pie-P'>Each object instance within the video possesses a distinct and unchanging identity that distinguishes it from other objects.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='dark'>
						<div className='pie-circle'>
							<div className='pie-circle1'></div>
							<div className='pie-circle2'></div>
							<div className='pie-circle3'></div>
							<div className='pie-circle4'></div>
							<div className='pie-circle5'></div>
						</div>
					</div>
				</div>
				<Row className='mobile-works row-cols-1'>
					<Col>
						<div className="pieText"><h3>Metadata</h3><p className="pie-P">Metadata summarises basic information about data,  making finding &amp; working with particular instances of data easier.</p></div>
					</Col>
					<Col><div className="pieText"><h3>Video Uploading</h3><p className="pie-P">User can upload the video up to 2048 MBs.</p></div></Col>
					<Col><div className="pieText"><h3>Labeling</h3><p className="pie-P">Labeling is a series of images that have been extracted from a single video. Users can either provide their own label for video frames.</p></div></Col>
					<Col><div className="pieText"><h3>Classifications</h3><p className="pie-P">Classify each frame individually &amp; independently of each other.</p></div></Col>
					<Col><div className="pieText"><h3>Object Recognitions</h3><p className="pie-P">Object recognition refers to a collection of related tasks for identifying objects in digital photographs.</p></div></Col>
				</Row>
			</Container>
				</div>
			</div>
		</div>
	)
}

export default HowItWorks