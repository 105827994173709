import React from 'react';
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ENV } from './../../../config/config';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { beforeTutorial, listTutorials } from './publisherTutorials.actions';

const PublisherTutorials = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [loader, setLoader] = useState(true)
    const [pagination, setPagination] = useState(null)
    const [publisherTutorials, setTutorials] = useState([])
    const [loadMoreBtn, setLoadMoreBtn] = useState(false)
    const currentLanguage = localStorage.getItem('selectedLang')
    const tutorialsSelector = useSelector((state) => state.tutorials)

    useEffect(() => {
        fetchTutorials();
    }, [])

    useEffect(() => {
        if (tutorialsSelector.listTutorialsAuth) {
            let { tutorials, pagination } = tutorialsSelector.listTutorialRes?.data

            setTutorials(tutorials)
            setPagination(pagination)

            if (loader)
                setLoader(false)

            beforeTutorial()

        }

    }, [tutorialsSelector.listTutorialsAuth])

    // handle show load more button state when pagination is set
    useEffect(() => {
        if (pagination)
            setLoadMoreBtn((publisherTutorials && pagination.total > 0 && pagination.total > publisherTutorials.length && publisherTutorials.length > 0))
    }, [pagination])

    const loadMore = () => {
        const { page } = pagination
        if (loader)
            setLoader(false)

        fetchTutorials(page + 1)
    }

    const fetchTutorials = (page = 1, limit = 12) => {
        const qs = ENV.objectToQueryString({ page, limit, lng: currentLanguage })
        dispatch(listTutorials(qs))
    }

    return (
        <>
            {loader && <FullPageLoader />}
            <div className='vlogs-wrapper explore explore-sec '>
                <div className="dashboardHeader">
                    <h3 className='Vlogs-heading'>{t('memberDashboardSidebar.publisherTutorials')}</h3>
                </div>
                <div className="vlogs-vedios">
                    <div className="d-flex flex-wrap">
                        {publisherTutorials?.length > 0 ? (
                            publisherTutorials.map((tutorial, index) => {
                                return (
                                    <div className="video-holder vlog-vedio-holder" key={index} onClick={() => navigate(`/tutorial/${tutorial._id}`)}>
                                        <div className="img-holder vlog-img-holder">
                                            <img src={tutorial.thumbnail} alt={tutorial.title} />
                                            <div className="play-icon-holder d-flex justify-content-center align-items-center">
                                                <FontAwesomeIcon icon={faCirclePlay} alt="" />
                                            </div>
                                        </div>
                                        <strong className="video-title">{tutorial.title}</strong>
                                    </div>
                                )
                            })
                        )
                            :
                            <div className="no-record-found d-flex justify-content-center align-items-center">{t('common.noRecord')}</div>
                        }
                    </div>
                </div>
                {
                    loadMoreBtn &&
                    <div className='text-center w-100 d-block'>
                        <button className="orange-btn load-more-btn text-uppercase mx-auto" onClick={() => loadMore()}>{t('common.loadMoreBtn')}</button>
                    </div>
                }
            </div>
        </>
    )
}

export default PublisherTutorials