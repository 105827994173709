import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Dropdown } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import moment from 'moment';
import { ENV } from '../../../../config/config';
import { beforeUser, getUser } from '../../UserComponents/users.actions';
import { Link, useNavigate } from 'react-router-dom';
import { upsertPostComment, beforePostComment, listPostComments, deletePostComment } from '../blogs.actions';
import SmallLoader from '../../../../utils/SmallLoader/SmallLoader';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBin4Line } from 'react-icons/ri';
import Swal from 'sweetalert2'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
const CommentSection = ({ postId, listBlogPostComments, listBlogPostCommentsAuth, upsertPostCommentAuth, upsertPostCommentRes, deleteCommentAuth }) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const userId = ENV.getUserKeys('_id')?._id
    const [blogPostComments, setComments] = useState([])
    const [pagination, setPagination] = useState({})

    const [comment, setComment] = useState('')
    const [loader, setLoader] = useState(true)
    const [errors, setErrors] = useState({})
    const [user, setUser] = useState({})
    const [loadMore, setLoadMore] = useState(false);
    const [editedCommentId, setPostId] = useState('');

    const userSelector = useSelector((state) => state.user)
    const blogs = useSelector((state) => state.user)

    useEffect(() => {
        if (userId)
            dispatch(getUser(`userId=${userId}`))
    }, [])

    useEffect(() => {
        if (userSelector.getUserAuth) {
            let data = userSelector.getUserData?.data?.user
            setUser(data)
            dispatch(beforeUser())
        }
    }, [userSelector.getUserAuth])

    useEffect(() => {
        if (postId)
            dispatch(listPostComments(`postId=${postId}`))
    }, [postId])

    useEffect(() => {
        if (listBlogPostCommentsAuth) {
            let { postComments, pagination } = listBlogPostComments
            setComments(loadMore ? blogPostComments.concat(postComments) : postComments)
            setPagination(pagination)
            setLoader(false)
            setLoadMore(false)
            dispatch(beforePostComment())
        }
    }, [listBlogPostCommentsAuth])

    useEffect(() => {
        if (upsertPostCommentAuth) {
            let { postComments, total } = upsertPostCommentRes
            setComments([...postComments])
            setPagination({ ...pagination, total })
            dispatch(beforePostComment())
        }
    }, [upsertPostCommentAuth])

    useEffect(() => {
        if (deleteCommentAuth) {
            dispatch(listPostComments(`postId=${postId}`))
            dispatch(beforePostComment())
        }
    }, [deleteCommentAuth])


    const deleteCommentHandler = (commentId) => {
        Swal.fire({
            title: t('comments.delComment'),
            html: t('comments.delCommentMsg'),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no')
        }).then(async (result) => {
            if (result.value) {
                setLoader(true)
                dispatch(deletePostComment(commentId))
            }
        })
    }

    const submit = () => {
        if (comment) {

            setErrors({})
            setComment('')

            if (editedCommentId)
                dispatch(upsertPostComment({ _id: editedCommentId, postId, authorId: userId, comment, type: 2 })) // edit
            else
                dispatch(upsertPostComment({ postId, authorId: userId, comment, type: 1 })) // add

        }
        else {
            setErrors({ ...errors, comment: t('comments.typeComment') })
        }

    };

    const showMoreHandler = () => {
        setLoadMore(true)
        setLoader(true)

        const { page } = pagination
        dispatch(listPostComments(`postId=${postId}&page=${page + 1}`))
    }

    const showLessHandler = () => {
        setLoader(true)
        setLoadMore(false)

        dispatch(listPostComments(`postId=${postId}&page=${1}`))
    }


    return (
        <>
            <Row className='d-flex align-items-center justify-content-start'>
                {
                    userId ?
                        <>
                            <Col lg={12}>
                                <div className='user-comment-wrapper'>
                                    <div className='user-details'>
                                        <div className='userImg me-2'>
                                            <img src={user?.profileImage} className="img-fluid" alt={`${user?.fullName}`} />
                                        </div>
                                    </div>
                                    <div className='comment-box'>
                                        <Form.Control as="textarea" rows={3} placeholder={t('comments.placeholder')} name='name' value={comment} onChange={(e) => setComment(e.target.value)} />
                                        {
                                            errors && errors.comment &&
                                            <span className='text-danger'>{errors.comment}</span>
                                        }
                                        <div className='btn-holder'>
                                            <Button className="orange-main-button text-uppercase" variant="" onClick={submit}><FontAwesomeIcon icon={faPaperPlane} /></Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </> :
                        <Col md={5}>
                            <p>
                                <Link variant="" className='iconBtn themeEffect' to={'/login'}>{t('comments.loginToComment.text1')}</Link>{t('comments.loginToComment.text2')}
                            </p>
                        </Col>
                }

            </Row>
            <Row>
                <Col lg={12}>
                    {
                        loader ? <SmallLoader height={30} width={30} /> :
                            blogPostComments?.length ?
                                blogPostComments.map((c) => {
                                    return (
                                        <div className='comment-wrapper'>
                                            <div className='user-details-wrapper'>
                                                <div className='user-details'>
                                                    <div className='userImg me-2'>
                                                        <img src={c?.author?.profileImage} className="img-fluid" alt={`${c?.author?.name}`} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='comment-body'>
                                                <div className='d-flex justify-content-between align-items-center user-time-wrapper'>
                                                    <div>
                                                        <span>{c?.author?.name}</span>
                                                        <span className='comment-time'>{moment(c?.createdAt).fromNow()}</span>
                                                    </div>
                                                    <div className="comments-dropdown-wrapper">
                                                        {
                                                            c.author._id === userId &&
                                                            <Dropdown className="">
                                                                <Dropdown.Toggle
                                                                    className="iconBtn iconDD-Btn"
                                                                    id="-basic"
                                                                >
                                                                    <span>
                                                                        <BiDotsVerticalRounded />
                                                                    </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {
                                                                        <Dropdown.Item
                                                                            onClick={() => {
                                                                                setPostId(c._id)
                                                                                setComment(c.comment)
                                                                            }}
                                                                        >
                                                                            <span className="me-2">
                                                                                <AiOutlineEdit />
                                                                            </span>
                                                                            {t('comments.edit')}
                                                                        </Dropdown.Item>
                                                                    }
                                                                    {
                                                                        <Dropdown.Item
                                                                            onClick={() => deleteCommentHandler(c._id)}
                                                                        >
                                                                            <span className="me-2">
                                                                                <RiDeleteBin4Line />
                                                                            </span>
                                                                            {t('comments.delete')}
                                                                        </Dropdown.Item>
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        }
                                                    </div>
                                                </div>
                                                {c?.comment}
                                            </div>


                                        </div>
                                    )
                                }) :
                                <span className='empty-comment-body'>{t('comments.noComments')}</span>
                    }
                </Col>
                {
                    blogPostComments?.length ?
                        pagination.total > blogPostComments?.length ?
                            <div className='w-100 d-block'>
                                <button className="text-show-btn" onClick={showMoreHandler}>{t('common.showMore')}</button>
                            </div>
                            :
                            blogPostComments?.length > pagination.limit &&
                            <div className='w-100 d-block'>
                                <button className="text-show-btn" onClick={showLessHandler}>{t('common.showLess')}</button>
                            </div>
                        : null
                }

            </Row>
        </>
    )
}

export default CommentSection