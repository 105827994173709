import React from 'react';
import ExploreVideos from '../HomePage/Explore/ExploreVideos';
import './DashboardHome.css';



const DashboardHome = () => {
    return (
        <>
            <ExploreVideos />
        </>
    )
}
export default DashboardHome