import React, { useEffect } from 'react'
import CommunityHeader from '../CommunityHeader/CommunityHeader'
import QuestionDescription from './QuestionDescription.js/QuestionDescription'
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getQuestionDetails } from '../community.actions';
import CommunityAnswers from './CommunityAnswers';

function CommunityQuestionsDetails() {

	const dispatch = useDispatch()
	const { questionId } = (useLocation())?.state
	const currentLanguage = localStorage.getItem('selectedLang')

	useEffect(() => {
		if(questionId)
		dispatch(getQuestionDetails(questionId, `incrementView=${true}&lng=${currentLanguage}`))
	},[questionId])



	return (
		<div className='community-wrapper'>
			<CommunityHeader />
			<QuestionDescription />
			<CommunityAnswers />
		</div>
	)
}

export default CommunityQuestionsDetails