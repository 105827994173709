import { toast } from 'react-toastify';
import { emptyError } from '../../../redux/shared/error/error.action';
import {
    LIST_BLOG_CATS, GET_BLOG_CAT, BEFORE_BLOG_CAT, LIST_BLOGS, GET_BLOG, BEFORE_BLOG, BEFORE_BLOG_POST, LIST_POSTS, GET_POST, GET_ERRORS, LATEST_BLOGS,
    HIGHLIGHTED_BLOGS, FEATURED_BLOGS, EXPLORE_BLOGS, TRENDING_BLOGS, SEARCH_BLOGS, UPSERT_COMMENT, LIST_POST_COMMENTS, BEFORE_POST_COMMENTS, DELETE_COMMENT
} from '../../../redux/types';
import { ENV } from './../../../config/config';

// Blog Categories
export const beforeBlogCategory = () => {
    return {
        type: BEFORE_BLOG_CAT
    }
}

export const listBlogCategories = (qs = '', showToast = true, body = {}) => dispatch => {
    // toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blog-categories/list`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'user-platform': 2
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_BLOG_CATS,
                payload: data.data
            })
        } else {
            toast.error(data.message, {
                toastId: "blog-cats-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "blog-cats-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getBlogCategory = (id, qs) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blog-categories/get/${id}`;
    if(qs)
        url += `?${qs}`
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'user-platform': 2
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_BLOG_CAT,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

// Blogs
export const beforeBlog = () => {
    return {
        type: BEFORE_BLOG
    }
}

export const listBlogs = (qs = '', showToast = true) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blogs/list`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_BLOGS,
                payload: data.data
            })
        } else {
            toast.error(data.message, {
                toastId: "blogs-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "blogs-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getBlog = (id) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blogs/get/${id}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_BLOG,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const listSearchedBlogs = (qs = '') => dispatch => {
    toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blogs/search`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: SEARCH_BLOGS,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: "blogs-search-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "blogs-search-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

// Blog Posts
export const beforeBlogPost = () => {
    return {
        type: BEFORE_BLOG_POST
    }
}

export const listBlogPosts = (qs = '', showToast = true, body = {}) => dispatch => {
    toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blog-posts/list`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'user-platform': 2
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_POSTS,
                payload: data.data
            })
        } else {
            toast.error(data.message, {
                toastId: "blog-posts-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "blog-posts-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getBlogPost = (qs, showToast = true) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blog-posts/get?${qs}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'user-platform': 2
        }
    }).then(res => res.json()).then(data => {

        if (data.success) {
            dispatch({
                type: GET_POST,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const getLatestBlog = (qs = '', showToast = true) => dispatch => {
    // toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blogs/getlatest`;

    if(qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LATEST_BLOGS,
                payload: data.data
            })
        } else {
            toast.error(data.message, {
                toastId: "blogs-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "blogs-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const gethighlighted = (qs = '', showToast = true) => dispatch => {
    // toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blog-categories/highlighted`;
    if(qs)
        url = `${url}?${qs}`
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: HIGHLIGHTED_BLOGS,
                payload: data.data
            })
        } else {
            toast.error(data.message, {
                toastId: "blogs-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "blogs-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const getFeaturedBlog = (qs = '', showToast = true) => dispatch => {
    // toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blogs/featured`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: FEATURED_BLOGS,
                payload: data.data
            })
        } else {
            toast.error(data.message, {
                toastId: "blogs-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "blogs-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const getExploreBlogs = (qs = '', showToast = true) => dispatch => {
    // toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blogs/explore`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: EXPLORE_BLOGS,
                payload: data.data
            })
        } else {
            toast.error(data.message, {
                toastId: "blogs-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "blogs-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const getTrendingBlogs = (qs = '', showToast = true) => dispatch => {
    // toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blogs/trending`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: TRENDING_BLOGS,
                payload: data.data
            })
        } else {
            toast.error(data.message, {
                toastId: "blogs-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "blogs-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

// Blog Post Comments

export const upsertPostComment = (payload) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blog-posts/comments/upsert`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-type' : 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)

    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: UPSERT_COMMENT,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: `${UPSERT_COMMENT}-err`
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: `${UPSERT_COMMENT}-err`
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const listPostComments = (qs = '') => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blog-posts/comments/list`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_POST_COMMENTS,
                payload: data.data
            })
        } else {
            toast.error(data.message, {
                toastId: `${LIST_POST_COMMENTS}-err`
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: `${LIST_POST_COMMENTS}-err`
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const deletePostComment = (id) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}blogs/blog-posts/comments/delete/${id}`;
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },

    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: DELETE_COMMENT,
            })
        } else {
            toast.error(data.message, {
                toastId: `${DELETE_COMMENT}-err`
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: `${DELETE_COMMENT}-err`
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const beforePostComment = () => {
    return {
        type: BEFORE_POST_COMMENTS
    }
}
