import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaChevronRight } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { ENV } from '../../../../config/config';
import moment from 'moment';
import { listBlogCategories, beforeBlogCategory, getLatestBlog, beforeBlog, gethighlighted, getFeaturedBlog, getExploreBlogs, getTrendingBlogs } from '../blogs.actions';
import SmallLoader from '../../../../utils/SmallLoader/SmallLoader';
import { toast } from 'react-toastify';
import './Blogs.css'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Blogs = () => {

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [blogCategories, setCategories] = useState([]);
    const [latestBlog, setLatestBlog] = useState();
    const [highlightedBlog, setHighlightedBlog] = useState();
    const [selectedCategory, setSelectedCategory] = useState('all');

    const [featuredBlog, setFeaturedBlog] = useState();
    const [exploreBlogs, setExploreBlogs] = useState();
    const [trendingBlogs, setTrendingBlogs] = useState();
    const [exploreBlogsLoader, setExploreLoader] = useState(true)
    const [trendingBlogsLoader, setTrendingLoader] = useState(true)

    const blogsSelector = useSelector((state) => state.blogs)
    const currentLanguage = localStorage.getItem('selectedLang')

    useEffect(() => {
        if (i18n.language) {
            fetchData()
        }
    }, [i18n.language]);

    const fetchData = () => {
        let qs = ENV.objectToQueryString({ all: true })
        dispatch(listBlogCategories(`${qs}&lng=${currentLanguage}`))

        qs = `lng=${currentLanguage}`
        dispatch(getLatestBlog(qs));
        dispatch(gethighlighted(qs));
        dispatch(getFeaturedBlog(qs));
        dispatch(getExploreBlogs(qs));
        dispatch(getTrendingBlogs(qs));
    }

    useEffect(() => {
        if (blogsSelector.blogCategoriesAuth) {
            let { categories } = blogsSelector.blogCategoriesRes
            setCategories(categories)
            dispatch(beforeBlogCategory())
        }
    }, [blogsSelector.blogCategoriesAuth])

    useEffect(() => {
        if (blogsSelector.latestblogAuth) {
            let { blog } = blogsSelector.latestblogRes
            setLatestBlog(blog)
            dispatch(beforeBlog())
        }
    }, [blogsSelector.latestblogAuth])

    useEffect(() => {
        if (blogsSelector.highlightedBlogAuth) {
            let { categories } = blogsSelector.highlightedBlogRes
            setHighlightedBlog(categories)
            dispatch(beforeBlog())
        }
    }, [blogsSelector.highlightedBlogAuth])

    useEffect(() => {
        if (blogsSelector.featuredBlogAuth) {
            let { blog } = blogsSelector.featuredBlogRes
            setFeaturedBlog(blog)
            dispatch(beforeBlog())
        }
    }, [blogsSelector.featuredBlogAuth])

    useEffect(() => {
        if (blogsSelector.exploreBlogAuth) {
            let { blogs } = blogsSelector.exploreBlogRes
            setExploreBlogs(blogs)
            setExploreLoader(false)
            dispatch(beforeBlog())
        }
    }, [blogsSelector.exploreBlogAuth])

    useEffect(() => {
        if (blogsSelector.trendingBlogAuth) {
            let { blogs } = blogsSelector.trendingBlogRes
            setTrendingBlogs(blogs)
            setTrendingLoader(false)
            dispatch(beforeBlog())
        }
    }, [blogsSelector.trendingBlogAuth])

    const selectCategory = (categoryId) => {
        setExploreLoader(true)
        if (categoryId == 'all') {
            dispatch(getExploreBlogs());
        }
        else {
            const qs = ENV.objectToQueryString({ categoryId });
            dispatch(getExploreBlogs(qs));
        }
        setSelectedCategory(categoryId)
    }

    const scrollToExplore = () => {
        document.getElementById('scrollToExplore').scrollIntoView();
    }

    return (
        <>
            <section className='blogs-sec'>
                <Container className='customBox'>
                    {
                        latestBlog && latestBlog?.length ?
                            <Link to={`/blog/${latestBlog[0]?.blogPost?.customUrl}`} >
                                <div className='blogs-banner'>
                                    <div className='blog-img'>
                                        <img src={latestBlog[0].featuredImage} className='img-fluid contained-img' alt='latest' />
                                    </div>
                                    <div className='blogs-banner-content'>
                                        <h1>{latestBlog[0].title}</h1>
                                        <p className='date'>{moment(new Date(latestBlog[0].createdAt)).format('MMMM DD, YYYY')}</p>
                                    </div>
                                </div>
                            </Link> : null
                    }

                    <div className='trending-blogs'>
                        <Row>
                            <Col lg={12}>
                                <div className='trending-blogs-wrapper'>
                                    <div className='heading-links d-flex justify-content-between'>
                                        <h3 className='theme mb-0'>{t('blogs.trending')}</h3>
                                    </div>
                                    <div className='trending-news'>
                                        <Row>
                                            {
                                                trendingBlogsLoader ?
                                                    <SmallLoader width="40" height="40" /> :
                                                    trendingBlogs?.length ?
                                                        trendingBlogs.map((val, ind) => (
                                                            <Col md={4} sm={6} key={ind}>
                                                                <div className='trending-news-wrapper'>
                                                                    <div className='img'>
                                                                        <img src={val.blog.featuredImage} className='img-fluid contained-img' alt={t('blogs.trending')} />
                                                                    </div>
                                                                    <p className='date mb-2'>{moment(new Date(val.blog.createdAt)).format('MMMM DD, YYYY')}</p>
                                                                    <Link to='/' className='heading'>{val.blog.title}</Link>
                                                                    <p className='description'>{val.blog.description}</p>
                                                                    <div className='read-more-btn'>
                                                                        <Link variant="" to={`/blog/${val?.blogPost?.customUrl}`}>{t('common.readMoreBtn')}</Link>
                                                                    </div>
                                                                </div>
                                                                <div className='related-posts related-posts2'>
                                                                    <div className='related-posts-wrapper'>
                                                                        {
                                                                            <Col className="mb-3">
                                                                                <Link to={`/blog/${val.blogPost?.customUrl}`}>
                                                                                    <div className='related-post d-flex align-items-center'>
                                                                                        <div className='img'>
                                                                                            <img src={val.blogPost?.featuredImage} alt='related' className='img-fluid h-100' />
                                                                                        </div>
                                                                                        <div className='content'>
                                                                                            <p className='related-post-date mb-2'>{moment(new Date(val.blogPost?.createdAt)).format('MMMM DD, YYYY')}</p>
                                                                                            <p className='related-post-description mb-0'>{val.blogPost?.title}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                            </Col>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ))
                                                        :
                                                        <div className="no-record-found d-flex justify-content-center align-items-center">{t('blogs.noTrendingBlogs')}</div>
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='trending-blogs'>
                        <Row>
                            <Col lg={12}>
                                <div className='trending-blogs-wrapper'>
                                    <div className='heading-links d-flex justify-content-between' id='scrollToExplore'>
                                        <h3 className='theme mb-0'>{t('blogs.explore')}</h3>
                                        <ul className='trending-links d-flex align-items-end mb-0'>
                                            <li><a onClick={() => selectCategory("all")} className={`pointer ${selectedCategory == 'all' ? 'active' : ''}`}>{t('blogs.all')}</a></li>
                                            {
                                                blogCategories && blogCategories.length ?
                                                    blogCategories.map((category, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <a onClick={() => selectCategory(category._id)} className={`pointer ${selectedCategory == category._id ? 'active' : ''}`}>{category.category}</a>
                                                            </li>
                                                        )
                                                    }) :
                                                    <li>
                                                        <div className="no-record-found no-category-found d-flex justify-content-center align-items-center">{t('blogs.noCategory')}</div>
                                                    </li>
                                            }
                                        </ul>
                                    </div>

                                    <Row>
                                        {
                                            exploreBlogsLoader ?
                                                <SmallLoader width="40" height="40" />
                                                :
                                                exploreBlogs && exploreBlogs?.length ?
                                                    exploreBlogs.map((val, ind) => (
                                                        <Col md={4} className="mb-3" key={ind}>
                                                            <Link to={`/blog/${val?.blogPost ? val.blogPost?.customUrl : ''}`}>
                                                                <div className='trending-wrapper'>
                                                                    <div className='img'>
                                                                        <img src={val.featuredImage} alt='blog' className='contained-img' />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <div className='content'>
                                                                <span className='liftstyle-tag'>{val.category}</span>
                                                                <h4 className='mb-2'> <Link to={`/blog/${val?.blogPost ? val.blogPost?.customUrl : ''}`}>{val.title}</Link></h4>
                                                                <p className='date mb-0'>{moment(new Date(val.createdAt)).format('MMMM DD, YYYY')}</p>
                                                            </div>
                                                        </Col>
                                                    )) :
                                                    <div className="no-record-found d-flex justify-content-center align-items-center">{t('blogs.noBlogs')}</div>
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='perspiciatis-images'>
                        <Row>
                            {
                                featuredBlog && featuredBlog?.length ?
                                    featuredBlog.map((val, key) => (
                                        <Col className='mb-3' md={6} key={key}>
                                            <Link to={`/blog/${val?.blogPost ? val.blogPost?.customUrl : ''}`} >
                                                <div className='perspiciatis-wrapper'>
                                                    <div className='img'>
                                                        <img src={val.featuredImage} className='img-fluid w-100 h-100' alt='featured' />
                                                    </div>
                                                    <div className='content'>
                                                        <span className='date'>{moment(new Date(val.createdAt)).format('MMMM DD, YYYY')}</span>
                                                        <h3 className='heading mb-0 mt-2'>{val.title}</h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                    )) :
                                    null

                            }
                        </Row>
                    </div>
                    <div className='information-technologies'>
                        <Row>
                            {
                                highlightedBlog && highlightedBlog?.length && highlightedBlog[0]?.blog?.length ?
                                    <>
                                        <Col lg={8}>
                                            <h3 className='heading'>{highlightedBlog[0]?.category}</h3>
                                            <div className='information-technologies-wrapper'>
                                                {
                                                    highlightedBlog[0]?.blog[0]?.blogPost?.customUrl &&
                                                    <Link to={`/blog/${highlightedBlog[0]?.blog[0]?.blogPost?.customUrl}`} >
                                                        <div className='single-img'>
                                                            <div className='img'>
                                                                <img src={highlightedBlog[0]?.blog[0]?.featuredImage} className='img-fluid contained-img' alt='highlighted' />
                                                            </div>
                                                            <div className='content'>
                                                                <h2 className='heading'>{highlightedBlog[0]?.blog[0]?.title}</h2>
                                                                <span className='date'>{moment(new Date(highlightedBlog[0]?.blog[0]?.createdAt)).format('MMMM DD, YYYY')}</span>
                                                            </div>
                                                        </div>
                                                    </Link>

                                                }
                                                <div className='related-blogs related-blogs2'>
                                                    <Row>
                                                        {highlightedBlog[0]?.blog[1] &&
                                                            <Col sm={6}>
                                                                <Link to={`/blog/${highlightedBlog[0]?.blog[1]?.blogPost?.customUrl}`} >
                                                                    <div className='related-blogs-wrapper'>
                                                                        <div className='img'>
                                                                            <img src={highlightedBlog[0]?.blog[1]?.featuredImage} alt='highlighted' className='contained-img ' />
                                                                        </div>
                                                                        <div className='content'>
                                                                            <p className='blog-date mb-2'>{moment(new Date(highlightedBlog[0]?.blog[1]?.createdAt)).format('MMMM DD, YYYY')}</p>
                                                                            <p className='blog-heading mb-0'>{highlightedBlog[0]?.blog[1]?.title}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </Col>
                                                        }
                                                        {
                                                            highlightedBlog[0]?.blog[2] &&
                                                            <Col sm={6}>
                                                                <Link to={`/blog/${highlightedBlog[0]?.blog[2]?.blogPost?.customUrl}`} >
                                                                    <div className='related-blogs-wrapper'>
                                                                        <div className='img'>
                                                                            <img src={highlightedBlog[0]?.blog[2]?.featuredImage} alt='highlighted' className='contained-img' />
                                                                        </div>
                                                                        <div className='content'>
                                                                            <p className='blog-date mb-2'>{moment(new Date(highlightedBlog[0]?.blog[2]?.createdAt)).format('MMMM DD, YYYY')}</p>
                                                                            <p className='blog-heading mb-0'>{highlightedBlog[0]?.blog[2]?.title}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </> :
                                    null
                            }
                            <Col lg={4}>
                                <div className='catagories'>
                                    <h3 className='heading'>{t('common.categories')}</h3>
                                    <div className='categories-wrapper'>
                                        {
                                            blogCategories && blogCategories.map((val, ind) =>
                                                <div key={ind} className='category d-flex justify-content-between align-items-center'>
                                                    <a className='category-name d-flex align-items-center pointer' onClick={() => { selectCategory(val._id); scrollToExplore() }}>
                                                        <FaChevronRight />
                                                        <span>{val.category}</span>
                                                    </a>
                                                    <span className='category-count'>{val.blogsLength || '0'}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Blogs