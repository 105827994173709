import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const IndividualCard = (props) => {
    const { t } = useTranslation()
    const [plan, setPlan] = useState({})

    useEffect(() => {
        if (props.plan)
            setPlan(props.plan)

    }, [props.plan])


    return (
        <div className='individual-plans'>
            <h2>{plan.name}</h2>
            <ul className='individual-plans-ul ps-2'>
                {
                    plan.videoLengthMins ?
                        <li>{t('packageCard.uploadVideo.text1')} {plan.videoLengthMins} {t('packageCard.uploadVideo.text2')}</li> :
                        null
                }
                {
                    plan.numberOfVideos ?
                        <li>{t('packageCard.noOfVideos.text1')} {plan.numberOfVideos} {t('packageCard.noOfVideos.text2')}</li> :
                        null
                }
                {
                    plan.videoSizeMbs ?
                        <li>{t('packageCard.videoSize')} {plan.videoSizeMbs} {t('common.mbs')}
                        </li> :
                        null
                }
                {
                    plan.videoSizeMbs ?
                        <li>{t('packageCard.editAnn')}
                        </li> :
                        null
                }
            </ul>
        </div>
    )
}

export default IndividualCard