import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { ENV } from './../../../config/config';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { beforeTutorial, getTutorial } from './publisherTutorials.actions';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { useTranslation } from 'react-i18next';

const Tutorial = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { id } = useParams()
    const [tutorial, setTutorial] = useState({})
    const [loader, setLoader] = useState(true)
    const currentLanguage = localStorage.getItem('selectedLang')
    const tutorialsSelector = useSelector((state) => state.tutorials)

    useEffect(() => {
        if (id)
            dispatch(getTutorial(`id=${id}&lng=${currentLanguage}`))
    }, [id])

    useEffect(() => {
        if (tutorialsSelector.getTutorialAuth) {
            setTutorial(tutorialsSelector.getTutorialRes?.tutorial)
            setLoader(false)
            dispatch(beforeTutorial())
        }

    }, [tutorialsSelector.getTutorialAuth])

    return (
        loader ? <FullPageLoader /> :
            tutorial ?
                <div className='explore explore-sec vlogs-wrapper mx-auto'>
                    <h3 className='Vlogs-heading ms-auto'>{tutorial.title}</h3>
                    <div className='d-flex flex-column align-items-center '>
                        <ReactPlayer
                            url={tutorial && tutorial.tutorial}
                            controls={true}
                            playing={true}
                            width="60%"
                            height="50vh"
                        />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: tutorial.description }}></div>
                </div> :
                <h4>{t('publisherTutorials.notFound')}</h4>
    )
}

export default Tutorial