import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap';
import './CommunityQuestions.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { beforeCommunity, getQuestionDetails, postAnswer } from '../../community.actions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ckEditorCustomConfig } from '../../../CkEditor/ckEditorConfig';
import FullPageLoader from '../../../FullPageLoader/FullPageLoader';
import { useTranslation } from 'react-i18next';

function CommunityQuestions() {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [show, setShow] = useState(false);
	const [questionDetails, setDetails] = useState({})
	const [answer, setAnswer] = useState('')
	const [errors, setErrors] = useState({})
	const [loader, setLoader] = useState(false);
	const currentLanguage = localStorage.getItem('selectedLang')
	const communtitySelector = useSelector((state) => state.community)
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		if (communtitySelector.getQuestionDetailsAuth) {
			let { details } = communtitySelector.getQuestionDetails?.data?.questionDetails
			setDetails(details)
			dispatch(beforeCommunity())

		}
	}, [communtitySelector.getQuestionDetailsAuth])

	useEffect(() => {
		if (communtitySelector.postAnswerAuth) {
			setLoader(false)
			setShow(false)
			if (questionDetails.questionId)
				dispatch(getQuestionDetails(questionDetails.questionId, `lng=${currentLanguage}`))

		}
	}, [communtitySelector.postAnswerAuth])

	const onEditorChange = (event, editor) => {
		let data = editor.getData();
		setAnswer(data)
	}

	const postAnswerHandler = () => {
		let errs = {}

		if (!answer)
			errs.answer = t('community.typeAnswer')

		setErrors({ ...errs })

		if (Object.keys(errs)?.length)
			return

		setLoader(true)
		dispatch(postAnswer({ answer, questionId: questionDetails.questionId, lng: currentLanguage }))
	}

	return (
		loader ? <FullPageLoader /> :
			<section id='Community-questions'>
				<Container>
					<div className='community-posts-wrapper mx-auto'>
						<div className='Community-questions'>
							<h3>{questionDetails?.title}</h3>
							<div className='post-heading-discription'>
								<div dangerouslySetInnerHTML={{ __html: questionDetails?.question }}></div>
							</div>
							<div className='community-modal-wrapper'>
								<Button className='community-answer-btn btn theme-btn orange-btn' variant="primary" onClick={handleShow}>
									<FontAwesomeIcon icon={faPenToSquare} />
									<span>{t('community.answer')}</span>
								</Button>

								<Modal className='community-answer-modal' show={show} onHide={handleClose} centered>
									<Modal.Header closeButton>
										<Modal.Title>
											<h3>{questionDetails?.title}</h3>
										</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Form className='Community-answer-form'>
											<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
												<Form.Label>{t('community.writeAnswer')}</Form.Label>
												<CKEditor
													id="blogpost-editor"
													editor={ClassicEditor}
													config={ckEditorCustomConfig}
													data={answer || ''}
													content={answer || ''}
													onChange={(event, editor) => onEditorChange(event, editor)}
												/>
												{
													errors && errors.answer &&
													<span className='text-danger'>{errors.answer}</span>
												}
											</Form.Group>
										</Form>
									</Modal.Body>
									<Modal.Footer>
										<div className='answer-modal-footer	d-flex align-items-center justify-content-between w-100'>
											<Button className='orange-main-button text-center' variant="primary" onClick={postAnswerHandler}>
												{t('community.post')}
											</Button>
										</div>
									</Modal.Footer>
								</Modal>
							</div>
						</div>
					</div>
				</Container>
			</section>
	)
}

export default CommunityQuestions