import React, { useEffect, useState } from 'react';
import { ENV } from '../../../config/config';
import { getUser, beforeUser } from '../UserComponents/users.actions';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlinePoweroff, AiOutlineUser } from 'react-icons/ai';
import { MdPayment } from 'react-icons/md';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { beforeCounter } from '../Notifications/notifications.actions';
import { profileMenu } from '../ApplicationTour/TourCompIdentifiers';
import { Images } from '../../../assets/assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faHeart } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { clearStorage } from '../../../utils/Shared Functions/shared';
const { userPlaceholderImage } = ENV

const ProfileComponent = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const userId = ENV.getUserKeys('_id')._id
    const userType = ENV.getUserKeys('type').type

    const [user, setUser] = useState({})
    const userSelector = useSelector((state) => state.user)

    const activeRoute = (routeName) => {
        return location.pathname === routeName ? "active" : "";
    };

    useEffect(() => {
        if (userId)
            dispatch(getUser(`userId=${userId}`))
    }, [])

    useEffect(() => {
        if (userSelector.getUserAuth) {
            let data = userSelector.getUserData?.data?.user
            setUser(data)
            dispatch(beforeUser())
        }
    }, [userSelector.getUserAuth])

    const logoutHandler = () => {
        clearStorage()
        dispatch(beforeCounter())
        navigate('/login')
    }

    return (
        <>
            <Dropdown className='go-tp-profile d-flex justify-content-end align-items-center'>
                <Dropdown.Toggle className='iconBtn iconDD-Btn' id={profileMenu}>
                    <div className='userImg d-flex justify-content-center align-items-center'>
                        <img className='img-fluid' src={user?.profileImage || Images.iconUser} alt='user'></img>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu variant="dark">
                    <Dropdown.Item onClick={() => navigate('/profile')} className={activeRoute("/profile")}>
                        <p className='mb-0'>
                            <AiOutlineUser />&nbsp;
                            {t('profile.heading')}&nbsp;
                        </p>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate('/my-favourites')} className={activeRoute("/my-favourites")}>
                        <p className='mb-0'>
                            <FontAwesomeIcon icon={faHeart} />&nbsp;
                            {t('profileMenu.myFavourites')}&nbsp;
                        </p>
                    </Dropdown.Item>
                    {
                        userType && userType === 2 ?
                            <>
                                <Dropdown.Item onClick={() => navigate('/payment-history')} className={activeRoute("/payment-history")}>
                                    <p className='mb-0'>
                                        <MdPayment />&nbsp;
                                        {t('paymentHistory.heading')}&nbsp;
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate('/invoices')} className={activeRoute("/invoices")}>
                                    <p className='mb-0'>
                                        <FaFileInvoiceDollar />&nbsp;
                                        {t('profileMenu.billingInvoices')}&nbsp;
                                    </p>
                                </Dropdown.Item>
                            </>
                            : null
                    }
                    <Dropdown.Item onClick={() => navigate('/watch-history')} className={activeRoute("/watch-history")}>
                        <p className='mb-0'>
                            <FontAwesomeIcon icon={faClock} />&nbsp;
                            {t('profileMenu.watchHistory')}&nbsp;
                        </p>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className='logout-dropdown' onClick={logoutHandler}>
                        <span>
                            <AiOutlinePoweroff />&nbsp;
                            {t('memberDashboardSidebar.signOut')}&nbsp;
                        </span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

        </>
    )
}

export default ProfileComponent