import React from 'react'
import { Link } from 'react-router-dom'
import { deleteUserAccount } from '../../../UserComponents/users.actions'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { ENV } from '../../../../../config/config'
import { useTranslation } from 'react-i18next'
import '../../Models/Models.css'

const AdvanceSettings = (props) => {
    const { t } = useTranslation()
    const userId = ENV?.getUserKeys('_id')?._id
    const dispatch = useDispatch()
    const currentLanguage = localStorage.getItem('selectedLang')

    const deleteAccountHandler = () => {
        Swal.fire({
            title: t('settings.channel.advanceSettings.deleteAccountTitle'),
            html: t('settings.channel.advanceSettings.deleteAccMsg'),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('settings.channel.advanceSettings.confirmButtonText'),
            cancelButtonText: t('settings.channel.advanceSettings.cancelButtonText')
        }).then(async (result) => {
            if (result.value) {
                props.setLoader(true)
                dispatch(deleteUserAccount(userId, `lng=${currentLanguage}`))
            }
        })
    }
    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div className='advanceSetting'>
                        <div className='general mb-0'>
                            <h4>{t('settings.channel.advanceSettings.audience')}</h4>
                            <p>{t('settings.channel.advanceSettings.audienceText')}</p>
                        </div>
                        <div className='laws mb-0'>
                            <div>
                                <h5>{t('settings.channel.advanceSettings.questionHeading')}</h5>
                                <p>{t('settings.channel.advanceSettings.coppaPolicy')  }</p>
                            </div>
                            <div className='themeRadio'>
                                <ul>
                                    <li>
                                        <div className='customInput'>
                                            <div className='radioFlex'>
                                                <input
                                                    type="radio"
                                                    name="audience"
                                                    value={1}
                                                    checked={props.audience === 1}
                                                    onChange={(e) => props.setData({ audience: parseInt(e.target.value) })}
                                                />
                                            </div>
                                            <div>
                                                <p>{t('settings.channel.advanceSettings.options.yes')}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='customInput'>
                                            <div className='radioFlex'>
                                                <input
                                                    type="radio"
                                                    name="audience"
                                                    value={2}
                                                    checked={props.audience === 2}
                                                    onChange={(e) => props.setData({ audience: parseInt(e.target.value) })}
                                                />
                                            </div>
                                            <div>
                                                <p>{t('settings.channel.advanceSettings.options.no')}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='customInput'>
                                            <div className='radioFlex'>
                                                <input
                                                    type="radio"
                                                    name="audience"
                                                    value={3}
                                                    checked={props.audience === 3}
                                                    onChange={(e) => props.setData({ audience: parseInt(e.target.value) })}
                                                />
                                            </div>
                                            <div>
                                                <p>{t('settings.channel.advanceSettings.options.review')}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='manageAccounts'>
                            <h4 className='mb-3'>{t('settings.channel.advanceSettings.otherSettings')}</h4>
                            <Link to='/profile' className='blueLink'>{t('settings.channel.advanceSettings.manageAccount')}</Link>
                            <span className='blueLink' onClick={deleteAccountHandler}>{t('settings.channel.advanceSettings.deleteAccount')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdvanceSettings