import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Query = () => {
    const { t } = useTranslation()
    return (
        <>
            <section className='query-sec'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h1 className='mb-0'>{t('contactUs.heading1.text1')} <span className='theme'>{t('contactUs.heading1.text2')}</span> {t('contactUs.heading1.text3')} <span className='theme'>{t('contactUs.heading1.text4')}</span>{t('contactUs.heading1.text5')}</h1>
                        </Col>
                        <Col lg={5} className="d-flex align-items-end offset-lg-1">
                            <h3 className='mb-0'>{t('contactUs.paragraph1')}</h3>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Query