import React, { useEffect } from 'react'
import { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Modal, Form } from 'react-bootstrap'
import UploadVideo from '../Videos/UploadVideo/UploadVideo'
import { Images } from '../../../../assets/assets';

import ReactECharts from 'echarts-for-react'
import { connect } from "react-redux";
import { getTopVideos, beforeDashboard, getDashboardAnalytics } from "./dashboard.actions";
import { getChannelCreator, beforeCreator } from '../../UserComponents/users.actions'
import { beforeChannelRole } from '../../ChannelRoles/channelRoles.actions'
import { beforeVideoContentUploaded } from '../Videos/video.actions'
import { ENV } from '../../../../config/config'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import FullPageLoader from '../../FullPageLoader/FullPageLoader'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import moment from 'moment'
import { getDates, getDateIntervals, clearStorage } from '../../../../utils/Shared Functions/shared'
import SmallLoader from '../../../../utils/SmallLoader/SmallLoader'
import { FaPlayCircle } from 'react-icons/fa';
import { summarySection, topVideosSection, uploadVideoDashboard } from '../../ApplicationTour/TourCompIdentifiers'
import './DasboardPage.css'

import { useTranslation } from 'react-i18next';
const { defaultPlaceholderImage } = ENV

const DasboardPage = (props) => {
    const { t } = useTranslation();
    const userId = ENV.getUserKeys('_id')?._id
    const navigate = useNavigate()
    const channelId = localStorage.getItem('channelId')

    const [user, setUser] = useState()
    const [upload, setUpload] = useState(false)
    const [loader, setLoader] = useState(true)

    // Top Videos
    const [videos, setVideos] = useState(undefined);
    const colors = ['#F9B52B', '#5490F2', '#FD6F21'];
    const [options, setOptions] = useState({
        color: colors,
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            data: []
        },
        yAxis:
        {
            type: 'value'
        }
        ,
        series: [
            {
                type: 'line',
                smooth: true,
                data: [],
                symbol: "circle",
            },
        ]
    });
    const [memberRole, setRole] = useState()
    const [analytics, setAnalytics] = useState({})
    const durationList = [
        { value: '24', label: t('common.daysFilter.24hrs'), unit: 'hours' },
        { value: '7', label: t('common.daysFilter.7days'), unit: 'days' },
        { value: '15', label: t('common.daysFilter.15days'), unit: 'days' },
        { value: '30', label: t('common.daysFilter.30days'), unit: 'days' },
        { value: 'custom', label:t('common.daysFilter.custom') }
    ];
    const [selected, setSelected] = useState(durationList[0])
    const [clickedStatOption, setClickedOption] = useState(1);
    const [customModal, setModal] = useState(false)
    const [customDateErr, setDateErr] = useState({})
    const [customDate, setCustomDate] = useState({
        startDate: moment(new Date()).format('MMM DD, YYYY'),
        endDate: moment(new Date()).format('MMM DD, YYYY')
    })
    const [summaryLoader, setSummaryLoader] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoader(false), 2000)
    }, [])

    useEffect(() => {
        if (props.videos.videoUploadedAuth) {
            props.beforeVideoContentUploaded()
            setLoader(false)
            navigate("/my-videos");
        }
    }, [props.videos.videoUploadedAuth]);

    useEffect(() => {
        if (props.role.channelRoleAuth) {
            let role = props.role.channelRoleData.memberRole
            setRole(role)
            props.beforeChannelRole()
        }
    }, [props.role.channelRoleAuth])

    useEffect(() => {
        if (channelId) {
            if (channelId !== localStorage.getItem('channelId')) {
                clearStorage()
                navigate('/login')
            }
            else {
                if (memberRole && memberRole.viewChannelDashboard) {
                    props.getTopVideos(`channelId=${channelId}`);
                    props.getChannelCreator(`channelId=${channelId}`)
                }
            }
        }
    }, [channelId, memberRole])

    useEffect(() => {
        if (props.user.getChannelCreatorAuth) {
            let data = props.user.getChannelCreatorData?.data?.user
            setUser(data)
            props.beforeCreator()
        }
    }, [props.user.getChannelCreatorAuth])

    useEffect(() => {
        if (props.dashboard.topVideosAuth) {
            setVideos(props.dashboard.topVideos.data)
        }
    }, [props.dashboard.topVideosAuth])

    useEffect(() => {
        if (selected.value) {
            setClickedOption(1)
            if (selected.value !== 'custom') {
                let { startDate, endDate } = getDates(selected)
                setCustomDate({ startDate, endDate })
                setSummaryLoader(true)

                let duration = `${selected.value}-${selected.unit}`
                props.getDashboardAnalytics(`channelId=${channelId}&duration=${duration}`)


            }
            else {
                setModal(true)
            }
        }
    }, [selected])

    useEffect(() => {
        if (props.dashboard.dashboardAnalyticsAuth) {
            setAnalytics(props.dashboard.dashboardAnalytics?.data)
            setTimeout(() => setSummaryLoader(false), 200)
            // setTimeout(() => setGraphLoader(false), 200)
            props.beforeDashboard()
        }
    }, [props.dashboard.dashboardAnalyticsAuth])

    useEffect(() => {
        if (clickedStatOption && analytics) {
            // setGraphLoader(false)

            let optionsData = { ...options }
            let { views, recognizedObjects, processedVideos } = analytics
            let xAxisDates = []
            let yAxisValues = []

            let key = clickedStatOption === 1 ? views : clickedStatOption === 2 ? recognizedObjects : clickedStatOption === 3 ? processedVideos : null
            let dateIntervals = []
            let seriesData = []

            if (key)
                if (key.data?.length) {
                    let indexes = []
                    key.data.forEach((stat) => {

                        let statDate = moment(new Date(stat.date)).format('DD-MMM-YY')
                        xAxisDates.push(statDate)
                        yAxisValues.push(stat.total);

                        dateIntervals = getDateIntervals(customDate.startDate, customDate.endDate, yAxisValues.length)
                        seriesData = new Array(dateIntervals.length).fill(0)
                        dateIntervals.forEach((dt, indx) => {
                            if (dt === statDate)
                                indexes.push(indx)
                        })
                    })

                    indexes.forEach((i, ind) => {
                        seriesData[i] = yAxisValues[ind]
                    })
                }
                else {
                    dateIntervals = getDateIntervals(customDate.startDate, customDate.endDate)
                    seriesData = new Array(dateIntervals.length).fill(0)
                }

            optionsData.xAxis = { ...optionsData.xAxis, data: dateIntervals }
            optionsData.series = [
                {
                    ...optionsData.series[0],
                    color: colors[clickedStatOption - 1],
                    name: clickedStatOption === 1 ? 'Views' : clickedStatOption === 2 ? 'Recognized Objects' : clickedStatOption === 3 ? 'Processed Videos' : '',
                    data: seriesData
                }
            ]
            setOptions(optionsData)
        }
    }, [clickedStatOption, analytics])

    const setUploadHandler = () => {
        if (!user?.subscribedPlan?.plan ||
            !Object.keys(user.subscribedPlan?.plan).length) {

            toast.info(t('homepage.header.info'))
            if (userId === user._id)
                navigate('/packages')
        }
        else if (user?.hasPlanExpired) {
            if (user.subscribedPlan.plan.type !== 1)
                toast.info(t('homepage.header.completePaymentMsg'))
            else
                toast.info(t('homepage.header.freePlanExpired'))
        }
        else
            setUpload(true)
    }

    const customSearch = () => {
        let errs = {}
        if (!customDate.startDate)
            errs.startDate = t('common.fieldRequired')

        if (!customDate.endDate)
            errs.endDate = t('common.fieldRequired')

        if (customDate.startDate && customDate.endDate) {
            if (moment(new Date(customDate.startDate)).isAfter(customDate.endDate))
                errs.startDate = t('editVideoDetails.details.invalidDate')

        }
        setDateErr({ ...errs })

        if (errs.startDate || errs.endDate)
            return

        setModal(false)
        setSummaryLoader(true)
        props.getDashboardAnalytics(`channelId=${channelId}&startDate=${customDate.startDate}&endDate=${customDate.endDate}`)
    }

    const onCloseHandler = () => {
        setModal(false)
        setSelected(durationList[0])
    }

    const statOptionsChangeHandler = (option) => {
        if (option !== clickedStatOption) {
            setClickedOption(option)
            // setGraphLoader(true)
        }
    }

    const durationChangeHandler = (e) => {
        if ((e.value === 'custom') || e.value !== selected.value) {

            if (e.value === 'custom') {
                setModal(true)
            }

            setSelected({ value: e.value, label: e.label, unit: e.unit })
        }
    }


    return (
        <>
            {loader && <FullPageLoader />}
            <div className='dashboardPage'>
                <div className=''>
                    <div className='dashboardHeader'>
                        <h2 className='text-uppercase'>{t('memberDashboardSidebar.dashboard')}</h2>
                    </div>
                    <div className='boxMb80 dashboard-boxes'>
                        {
                            memberRole && memberRole?.createVideo &&
                            <div className='box boxOne'>
                                <h2>{t('dashboardPage.uploadVideo')}</h2>
                                <div className='upload'>
                                    <img className='img-fluid' src={Images.download} alt='upload'></img>
                                    <h4>{t('dashboardPage.heading1')}</h4>
                                    <p>{t('dashboardPage.paragraph1')} <br></br> {t('dashboardPage.paragraph2')}</p>
                                    <button id={uploadVideoDashboard} className='orange-btn text-uppercase mx-auto' onClick={setUploadHandler}>{t('dashboardPage.uploadVideo')}</button>
                                </div>
                            </div>
                        }
                        <div className='box analyticBox boxTwo'>
                            <h2 id={summarySection}>{t('dashboardPage.analytics')}</h2>
                            <div className='analytics'>
                                <div>
                                    <h4>{t('dashboardPage.summary')}</h4>
                                    {
                                        customDate.startDate && customDate.endDate ?
                                            <p className='start-end-date-text'>{customDate.startDate} - {customDate.endDate}</p> :
                                            null
                                    }
                                    <div className='searchByDays'>
                                        <div className='select-items custom-react-select w-100'>
                                            <Select classNamePrefix="triage-select" className='iconDD barDD zzzzzzz'
                                                placeholder='Select Type'
                                                options={durationList}
                                                value={selected}
                                                onChange={durationChangeHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    summaryLoader ?
                                        <SmallLoader height={30} width={30} wrapperClass="justify-content-center mt-5" />
                                        :
                                        analytics && Object.keys(analytics)?.length &&
                                        <>
                                            <div className='analyticSummary'>
                                                <ul>
                                                    <li>
                                                        <div>
                                                            <p>{t('dashboardPage.viewsTotal')}</p>
                                                        </div>
                                                        <div>
                                                            <p>{analytics.views.total}</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <p>{t('dashboardPage.viewersTotal')}</p>
                                                        </div>
                                                        <div>
                                                            <p>{analytics.viewersTotal} </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <p>{t('dashboardPage.videosUploaded')}</p>
                                                        </div>
                                                        <div>
                                                            <p>{analytics.videosUploaded}</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='analyticSummary'>
                                                <ul>
                                                    <li>
                                                        <div>
                                                            <p>{t('dashboardPage.recognisedObjects')}</p>
                                                        </div>
                                                        <div>
                                                            <p>{analytics.recognizedObjects.total}</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <p>{t('dashboardPage.processedVideos')}</p>
                                                        </div>
                                                        <div>
                                                            <p>{analytics.processedVideos.total} </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                }
                                {

                                }

                            </div>
                        </div>
                        <div className='box boxThree'>
                            <h2 id={topVideosSection}>{t('homepage.exploreVideos.topVideosHeading')}</h2>
                            <div className='wrapVideoInfo top-video-sec'>
                                <ul>
                                    {videos && videos?.length ?
                                        videos.map((val, key) => {
                                            return val ?
                                                <li key={key}>
                                                    <a href={`/media-player/${val.slug}/${val.channelName?.toLowerCase()}`} target="_blank" rel='noreferrer'>
                                                        <div className='videoInfoDiv'>
                                                            <div className='videoImg position-relative'>
                                                                <img className='img-fluid' src={(val.thumbnails && val.thumbnails[0]?.localUrl) || defaultPlaceholderImage} alt={val?.title}></img>
                                                                <div className='player-icon'><FaPlayCircle /></div>
                                                            </div>
                                                            <div className='videoContent'>
                                                                <h4>{val?.title}</h4>
                                                                <p>{val?.recognizedObjects} {t('dashboardPage.recognized')} {val?.recognizedObjects > 1 ? 'Objects' : 'Object'}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li> :
                                                null
                                        }) :
                                        <li><p>{t('dashboardPage.noTopVideos')}</p></li>
                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                    <Row className='boxMb80'>
                        <Col lg={12}>
                            <div>
                                <h2>{t('dashboardPage.graph')}</h2>
                                <div className='box h-auto'>
                                    <div className='d-flex flex-md-row flex-column justify-content-between align-item-center'>
                                        <h2>{t('dashboardPage.analytics')}</h2>
                                        <div className='graphItems'>
                                            <ul>
                                                <li className='link'><div className='yellow pointer' onClick={() => statOptionsChangeHandler(1)}><p style={{ color: clickedStatOption === 1 ? colors[0] : '' }}>{t('common.views')}</p></div></li>
                                                <li className='link'><div className='blue pointer' onClick={() => statOptionsChangeHandler(2)}><p style={{ color: clickedStatOption === 2 ? colors[1] : '' }}>{t('dashboardPage.recognisedObjects')}</p></div></li>
                                                <li className='link'><div className='orange pointer' onClick={() => statOptionsChangeHandler(3)}><p style={{ color: clickedStatOption === 3 ? colors[2] : '' }}>{t('dashboardPage.processedVideos')}</p></div></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {
                                        options && <ReactECharts option={options} />
                                    }
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
            </div>
            {<UploadVideo setShow={setUpload} show={upload} setLoader={setLoader} />}
            <div className='modalPage'>
                <div className="modal show" >
                    <Modal show={customModal} onHide={onCloseHandler} className="narrowModel" centered>
                        <Modal.Header closeButton>
                            <Modal.Title className='mb-0'>{t('dashboardPage.selectDate')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='py-lg-4 py-3'>
                            <>
                                <div className='d-flex'>
                                    <Form.Group className='formGroup me-3 w-75' controlId="formBasicEmail">
                                        <Form.Label><div className='d-flex align-items-center'>{t('common.startDate')}<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                        <div className='inputDiv'>
                                            <DatePicker
                                                className="form-control"
                                                placeholder="Start Date"
                                                selected={customDate.startDate ? new Date(customDate.startDate) : ''}
                                                onChange={(d) => setCustomDate({ ...customDate, startDate: moment(new Date(d)).format('MMM DD, YYYY') })}
                                                dateFormat="dd-MM-yyyy"
                                                maxDate={new Date()}
                                                minDate={new Date(moment().subtract(6, 'months').format('MMM DD, YYYY'))}
                                            />
                                        </div>
                                        {
                                            customDateErr && customDateErr?.startDate &&
                                            <span className='submit-errors text-danger'>{customDateErr.startDate}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className='formGroup me-3 w-75' controlId="formBasicEmail">
                                        <Form.Label><div className='d-flex align-items-center'>{t('common.endDate')}<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                        <div className='inputDiv'>
                                            <DatePicker
                                                className="form-control"
                                                placeholder="End Date"
                                                selected={customDate.endDate ? new Date(customDate.endDate) : ''}
                                                onChange={(d) => setCustomDate({ ...customDate, endDate: moment(new Date(d)).format('MMM DD, YYYY') })}
                                                dateFormat="dd-MM-yyyy"
                                                maxDate={new Date()}
                                                minDate={new Date(moment().subtract(1, 'years').format('MMM DD, YYYY'))}
                                            />
                                        </div>
                                        {
                                            customDateErr && customDateErr?.endDate &&
                                            <span className='submit-errors text-danger'>{customDateErr.endDate}</span>
                                        }
                                    </Form.Group>
                                </div>
                            </>
                        </Modal.Body>
                        <Modal.Footer className='pb-0'>
                            <div className='d-flex align-items-center'>
                                <button className="text-uppercase me-lg-3 me-2 mt-0" onClick={customSearch}>{t('common.search')}</button>
                                <button className="grey-btn text-uppercase me-lg-3 me-2 mt-0" onClick={onCloseHandler}>{t('common.close')}</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    user: state.user,
    role: state.roles,
    videos: state.videos
});

export default connect(mapStateToProps, {
    getTopVideos,
    beforeDashboard,
    beforeCreator,
    getChannelCreator,
    beforeChannelRole,
    beforeVideoContentUploaded,
    getDashboardAnalytics

})(DasboardPage);
