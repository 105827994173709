import React, { useState } from 'react';
import { useEffect } from 'react';
import { ENV } from '../../../../config/config';
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { FaPlayCircle } from 'react-icons/fa';
// import Favourite from './FavouriteComponent';
import { addRemoveFavourites, beforeFavourites, listUserFavourites } from '../users.actions';
// import { favouriteVideosSection } from '../../ApplicationTour/TourCompIdentifiers';
import { FcLike } from 'react-icons/fc';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import '../../../Frontend/Player/player.css';
import { useTranslation } from 'react-i18next';

const { defaultPlaceholderImage } = ENV

const UserFavourites = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const userId = ENV.getUserKeys('_id')?._id

    const [loader, setLoader] = useState(true);
    const [userFavourites, setFavourites] = useState([])
    const [pagination, setPagination] = useState({})
    const [loadMore, setLoadMore] = useState(false);
    const currentLanguage = localStorage.getItem('selectedLang')

    const userSelector = useSelector((state) => state.user)

    useEffect(() => {
        if (userId) {
            dispatch(listUserFavourites(`userId=${userId}&lng=${currentLanguage}`))
        }
    }, [])


    useEffect(() => {
        if (userSelector.listUserFavouritesAuth) {
            let { favourites, pagination } = userSelector.listUserFavourites?.data
            setFavourites(loadMore ? userFavourites.concat(favourites) : favourites)
            setPagination(pagination)
            setLoader(false)
            setLoadMore(false)
            dispatch(beforeFavourites())

        }
    }, [userSelector.listUserFavouritesAuth])

    const loadMoreVideos = () => {
        setLoadMore(true)
        setLoader(true)

        const { page } = pagination
        dispatch(listUserFavourites(`userId=${userId}&page=${page + 1}&lng=${currentLanguage}`))
    }

    const callback = (id) => {
        setLoader(true)
        dispatch(addRemoveFavourites({ userId, videoId: id, type: 2, lng: currentLanguage }))
    }

    useEffect(() => {
        if (userSelector.addRemoveFavouriteAuth) {
            dispatch(listUserFavourites(`userId=${userId}&page=${pagination.page + 1}&lng=${currentLanguage}`))
            setLoader(true)
            dispatch(beforeFavourites())

        }
    }, [userSelector.addRemoveFavouriteAuth])


    return (
        <>
            {
                loader &&
                <FullPageLoader />
            }
            <section className="explore explore-sec pt-0">
                <div className='v-custom-sitecards recomemded-videos'>
                    <div className="dashboardHeader">
                        <h2 id="watch-history-step" className="text-uppercase">{t('favourites.heading')}</h2>
                    </div>
                    <div className="wrapVideos">
                        {
                            userFavourites && userFavourites?.length ?
                                userFavourites.map((fav, index) => (
                                    <div className='exploreCard'>
                                        <div className='exploreImg' >
                                            <a href={`/media-player/${fav.video?.slug}/${fav.video?.channel?.name?.toLowerCase()}`} rel='noreferrer' className='position-relative'>
                                                <img className='img-fluid video-img' src={fav.video.thumbnail || defaultPlaceholderImage} alt={'thumbnail'}></img>
                                                <div className='player-icon'><FaPlayCircle /></div>
                                            </a>
                                        </div>
                                        <div className='cardFooter'>
                                            <div className="d-flex mb-3">
                                                <div className='userImg me-3'>
                                                    <img className='img-fluid' src={fav.video.channel?.image || defaultPlaceholderImage} alt={'channel'}></img>
                                                </div>
                                                <div className='videoReview'>
                                                    <p>{fav.video.title}</p>
                                                    <span>{fav.video.totalViews || 0}  {fav?.video.totalViews > 1 ? t('common.views') : t('common.view')}</span>
                                                </div>
                                            </div>
                                            <div className="make-video-favorite">
                                                <button onClick={() => callback(fav.video._id)}>
                                                    <FcLike />
                                                    <span className="count">{fav.favouritesCount || ''}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                ""
                        }
                    </div>
                    {userFavourites && userFavourites?.length ?
                        ""
                        :
                        <div className="no-record-found d-flex justify-content-center align-items-center">{t('favourites.noFavouriteVideo')}</div>
                    }
                    {
                        pagination.total > userFavourites?.length ?
                            <div className='text-center w-100 d-block'>
                                <button className="orange-btn text-uppercase mx-auto" onClick={loadMoreVideos}>{t('common.loadMoreBtn')}</button>
                            </div>
                            :
                            null
                    }
                </div>
            </section>
        </>
    );
}

export default UserFavourites