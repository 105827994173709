import React, { useEffect, useState } from 'react';
import { FcLike } from 'react-icons/fc'
import { AiOutlineHeart } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux';
import { addRemoveFavourites, beforeFavourites } from '../users.actions';
import { ENV } from '../../../../config/config';
import { useTranslation } from 'react-i18next';

const Favourite = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const userId = ENV.getUserKeys('_id')?._id
    const userSelector = useSelector((state) => state.user)
    const currentLanguage = localStorage.getItem('selectedLang')
    const [favourites, setFavourites] = useState({ _id: props._id, value: false })

    const addRemoveFavouritesHandler = (type) => {
        let payload = { userId, videoId: props._id }
        setFavourites({ ...favourites, value: type === 1 ? true : false })
        dispatch(addRemoveFavourites({ ...payload, type, lng: currentLanguage }))
    }

    useEffect(() => {
        if (userSelector.addRemoveFavouriteAuth) {
            let { total, userFavouriteCount, videoId } = userSelector.addRemoveFavourite?.data

            if (Object.keys(props.content)?.length && props.pageKey === 'video-details') {
                if (videoId === props.content._id)
                    props.setContent({ ...props.content, userFavourite: userFavouriteCount, favouritesCount: total })
            }
            else if (props.content?.length) {
                let index = props.content.findIndex(f => f._id === videoId);
                if (index >= 0) {
                    let items = [...props.content];
                    let item = { ...items[index] };
                    item = { ...item, userFavourite: userFavouriteCount, favouritesCount: total }
                    items[index] = item;
                    props.setContent([...items])

                }
            }

            dispatch(beforeFavourites())
        }
    }, [userSelector.addRemoveFavouriteAuth])

    return (
        <div className="make-video-favorite">
            {props.userFavourite || favourites.value ?
                <button onClick={() => addRemoveFavouritesHandler(2)}>
                    <FcLike />
                    <span className="count">{props.count || ''} {t('videoDetails.favourites')}</span>
                </button>
                :
                <button onClick={() => addRemoveFavouritesHandler(1)}>
                    <AiOutlineHeart />
                    <span className="count">{props.count || ''}  {t('videoDetails.favourites')}</span>
                </button >
            }            
        </div>
    )
}
export default Favourite
