import React, { useState, useEffect } from 'react'
// import Lottie from 'react-lottie';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Images } from '../../../assets/assets';
import './PaymentSuccessfull.css'
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [params, setParams] = useSearchParams();
    const [invoice, setInvoiceId] = useState('')
    const channelName = localStorage.getItem('channelName')

    useEffect(() => {
        if (params) {
            let invoiceId = params.get('invoice')
            if (invoiceId)
                setInvoiceId(invoiceId)
        }

    }, [params])

    return (
        <>
            <div className='payment-success-sec'>
                <div className='fixedModal payment-success-modal'>
                    <div className='payment-success text-center'>
                        <div className="d-flex justify-content-center">
                            <div>
                                <svg class="animated-check" viewBox="0 0 24 24"><path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
                            </div>
                        </div>
                        <h3>{t('paymentSuccess.heading')}</h3>
                        <p>{t('paymentSuccess.text1')}</p>
                        <div className='go-to-dashboard-btn'>
                            <button
                                type="button"
                                className="theme-btn orange-btn text-uppercase mt-0"
                                onClick={() => navigate(`/publisher-dashboard/${channelName}`)}
                            >
                                {t('paymentSuccess.goToDashboard')}</button>
                        </div>
                        {
                            invoice &&
                            <div className='view-invoice-btn'>
                                <button
                                    type="button"
                                    className="text-uppercase mt-0"
                                    onClick={() => navigate(`/invoice/${invoice}`)}
                                >
                                    {t('paymentSuccess.viewInvoice')}
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentSuccess