import React from 'react'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Row, Col } from 'react-bootstrap'
import Engagement from '../Engagement'
import Audience from '../Audience'
import Reach from '../Reach'
import IdentifiedObjects from '../IdentifiedObjects/IdentifiedObjects'
import Select from 'react-select'
import ReactECharts from 'echarts-for-react'
import { useDispatch, useSelector } from 'react-redux'
import { getVideoAnalytics, beforeVideoAnalytics } from '../../Videos/video.actions'
import moment from 'moment'
import FullPageLoader from '../../../FullPageLoader/FullPageLoader'
import { getDates, getDateIntervals } from '../../../../../utils/Shared Functions/shared'
import { toast } from 'react-toastify'
import SmallLoader from '../../../../../utils/SmallLoader/SmallLoader'
import { engagementTab, reachTab, vidAnalyticsAudience, vidAnalyticsOverview, vidIdentifiedObjTab, vidObjectsRateTab, vidRealtimeSelect } from '../../../ApplicationTour/TourCompIdentifiers'
import { GrPowerReset } from 'react-icons/gr'
import DateTimeFilters from '../../../../../utils/Filters/Date&TimeFilters'
import CustomSearchModal from '../../../../../utils/Filters/CustomSearchModal'
import ObjectsRate from '../ObjectsRate'
import { useTranslation } from 'react-i18next'
import "react-datepicker/dist/react-datepicker.css";
import 'react-clock/dist/Clock.css';
import '../../VideoAnalyticsComponent/siteTabs.css'


const VideoAnalytics = () => {
    const { t } = useTranslation()
    const { videoId } = (useLocation())?.state
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const [clickedStatOption, setClickedOption] = useState(1);
    const [analytics, setAnalytics] = useState({})
    const [realtimeViews, setRealtimeViews] = useState(0)
    const [searchCustom, setCustom] = useState(false)
    const [customModal, setModal] = useState(false)
    const [customDateErr, setDateErr] = useState({})
    const [selectedTab, setTab] = useState(0) // 0- overview, 1-Reach, 2-Engagement, 3-Audience, 4-Identified Objects
    const [loader, setLoader] = useState(true)
    const [realtimeLoader, setRealtimeLoader] = useState(true)
    const [startTime, setStartTime] = useState(moment(new Date()).startOf('day').format('HH:mm'));
    const [endTime, setEndTime] = useState(moment(new Date()).endOf('day').format('HH:mm'));

    const [date, setDate] = useState({
        startDate: '',
        endDate: ''
    })
    const colors = ['#FD6F21'];
    const tabColors = {
        'grey': '#393C48',
        'black': '#0D0F17'
    }
    const [options, setOptions] = useState({
        color: colors,
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                type: 'line',
                color: colors[1],
                symbol: "circle"
            }
        ]
    })

    const videosSelector = useSelector((state) => state.videos)

    const durationList = [
        { value: '24', label: t('common.daysFilter.24hrs'), unit: 'hours' },
        { value: '7', label: t('common.daysFilter.7days'), unit: 'days' },
        { value: '15', label: t('common.daysFilter.15days'), unit: 'days' },
        { value: '30', label: t('common.daysFilter.30days'), unit: 'days' },
        { value: 'custom', label: t('common.daysFilter.custom') }
    ];

    const realtimeDurationList = [
        { value: '60', label: t('common.realtimeSelect.60mins'), unit: 'minutes' },
        { value: '6', label: t('common.realtimeSelect.6hrs'), unit: 'hours' },
        { value: '24', label: t('common.realtimeSelect.24hrs'), unit: 'hours' },
        { value: '7', label: t('common.realtimeSelect.7days'), unit: 'days' },
    ];

    const [selectedObject, setObject] = useState('')
    const [selected, setSelected] = useState(durationList[0])
    const [realtimeSelected, setRealtimeSelected] = useState(realtimeDurationList[0])

    useEffect(() => {
        if (!videoId) {
            navigate('/my-videos')
        }
    }, [])

    useEffect(() => {
        if (videoId) {
            let duration = `${selected.value}-${selected.unit}`
            fetchAnalytics(`duration=${duration}`)
        }
    }, [videoId])

    useEffect(() => {
        if (videosSelector.videoAnalyticsAuth) {
            let data = videosSelector?.videoAnalyticsData
            if (data?.videoAnalytics && data?.statType == 0) {
                setAnalytics(data?.videoAnalytics)
            }
            else if (data?.totalViews !== null) {
                setTimeout(() => setRealtimeLoader(false), 200)
                setRealtimeViews(data?.totalViews)
            }

            setLoader(false)
            dispatch(beforeVideoAnalytics())
        }
    }, [videosSelector.videoAnalyticsAuth])


    useEffect(() => {
        if (videoId && realtimeSelected.value) {
            let duration = `${realtimeSelected.value}-${realtimeSelected.unit}`
            fetchAnalytics(`duration=${duration}`, true)
        }

    }, [realtimeSelected, videoId])

    useEffect(() => {
        if (selectedTab === 0)
            setRealtimeSelected(realtimeDurationList[0])
    }, [selectedTab])

    useEffect(() => {
        if ((selectedTab !== 4 && selectedTab !== 5) && videoId) {
            let duration = `${selected.value}-${selected.unit}`
            fetchAnalytics(`duration=${duration}`)
        }
    }, [selectedTab])

    useEffect(() => {
        if (selected.value) {
            let { startDate, endDate } = getDates(selected)
            setDate({ startDate, endDate })
        }
    }, [selected])

    const onSearchHandler = () => {
        if (selected.value) {
            setClickedOption(1)
            let qs = ''
            if (selectedObject)
                qs = `object=${selectedObject}`

            if (selected.value !== 'custom') {
                setCustom(false)
                if (selectedTab !== 4 && videoId) {
                    let duration = `${selected.value}-${selected.unit}`
                    let payload = `duration=${duration}`
                    if (qs)
                        payload = `${payload}&${qs}`
                    fetchAnalytics(payload)
                }
            }
            else {
                let payload = `startDate=${moment(new Date(date.startDate))}&endDate=${moment(new Date(date.endDate))}`
                if (qs)
                    payload = `${payload}&${qs}`
                fetchAnalytics(payload)
            }
        }
    }

    const resetFiltersHandler = () => {
        let start = moment(new Date()).startOf('day').format('HH:mm')
        let end = moment(new Date()).endOf('day').format('HH:mm')
        let { startDate, endDate } = getDates(durationList[0])
        let duration = `${durationList[0].value}-${durationList[0].unit}`
        let qs = `video=${videoId}&statType=${0}&startTime=${start}&endTime=${end}&duration=${duration}`

        dispatch(getVideoAnalytics(qs))

        setLoader(true)
        setDate({ startDate, endDate })
        setSelected(durationList[0])
        setTab(0)
        setStartTime(start)
        setEndTime(end)
    }

    const fetchAnalytics = (queryString, realtimeStat = false) => {
        let qs = `video=${videoId}&startTime=${startTime}&endTime=${endTime}&${queryString}`

        if (realtimeStat) {
            qs = `${qs}&statType=realtime`
            setRealtimeLoader(true)
        }
        else {
            qs = `${qs}&statType=${selectedTab}`
            setLoader(true)
        }

        dispatch(getVideoAnalytics(qs))

    }

    useEffect(() => {
        if (clickedStatOption && Object.keys(analytics)?.length) {
            setLoader(false)
            let optionsData = { ...options }
            let { views, watchTime, identifiedObjects } = analytics
            let xAxisDates = []
            let yAxisValues = []

            let key = clickedStatOption === 1 ? views : clickedStatOption === 2 ? watchTime : clickedStatOption === 3 ? identifiedObjects : null
            let dateIntervals = []
            let seriesData = []

            if (key)
                if (key.data?.length) {
                    let indexes = []
                    key.data.forEach((stat) => {

                        let statDate = moment(new Date(stat.date)).format('DD-MMM-YY')
                        xAxisDates.push(statDate)
                        if (stat.total > 0)
                            yAxisValues.push(stat.total);

                        dateIntervals = getDateIntervals(date.startDate, date.endDate, yAxisValues.length)
                        seriesData = new Array(dateIntervals.length).fill(0)
                        dateIntervals.forEach((dt, indx) => {
                            if (dt === statDate)
                                indexes.push(indx)
                        })
                    })

                    indexes.forEach((i, ind) => {
                        seriesData[i] = yAxisValues[ind]
                    })
                }
                else {
                    dateIntervals = getDateIntervals(date.startDate, date.endDate)
                    seriesData = new Array(dateIntervals.length).fill(0)
                }

            optionsData.xAxis = { ...optionsData.xAxis, data: dateIntervals }
            optionsData.series = [
                {
                    ...optionsData.series[0],
                    name: clickedStatOption === 1 ? "Views" : clickedStatOption === 2 ? "Watch Time" : clickedStatOption === 3 ? "Recognized Objects" : null,
                    data: seriesData,
                }
            ]

            setOptions(optionsData)
        }
    }, [clickedStatOption, analytics])

    useEffect(() => {
        if (searchCustom && selectedTab !== 4) {
            let qs = `startDate=${moment(new Date(date.startDate))}&endDate=${moment(new Date(date.endDate))}`
            if (selectedObject)
                qs = `object=${selectedObject}`
            fetchAnalytics(qs)
        }
    }, [searchCustom, selectedTab])

    const statOptionsChangeHandler = (option) => {
        if (option !== clickedStatOption) {
            setClickedOption(option)
            setLoader(true)
        }
    }

    const durationChangeHandler = (e) => {
        if ((e.value === 'custom') || e.value !== selected.value) {

            if (e.value === 'custom') {
                setModal(true)
                setCustom(false)
            }

            setSelected({ value: e.value, label: e.label, unit: e.unit })
        }
    }

    const dateTimeFiltersProps = {
        date, startTime, endTime, setStartTime, setEndTime, selected, resetFiltersHandler, onSearchHandler, durationChangeHandler
    }

    const customSearchProps = {
        date, setDate, startTime, endTime, setStartTime, setEndTime, setCustom, setSelected, selectedTab, setLoader, setModal, customModal, durationList
    }


    return (
        <>
            {loader && <FullPageLoader />}
            <div className='dashboardPage'>
                <div className='dashboardHeader pb-0 px40'>
                    <h2>{t('videoAnalytics.heading')}</h2>
                </div>
                <div className='videoAnalytics themeAnalytics'>
                    <DateTimeFilters {...dateTimeFiltersProps} />
                    <Tabs activeKey={selectedTab} onSelect={(k) => setTab(k)}>
                        <div className='tabFlex me-0'>
                            <TabList className='border-0 mb-0'>
                                <Tab><span id={vidAnalyticsOverview}>{t('videoAnalytics.tabs.overview')}</span></Tab>
                                <Tab><span id={reachTab}>{t('videoAnalytics.tabs.reach')}</span></Tab>
                                <Tab><span id={engagementTab}>{t('videoAnalytics.tabs.engagement')}</span></Tab>
                                <Tab><span id={vidAnalyticsAudience}>{t('videoAnalytics.tabs.audience')}</span></Tab>
                                <Tab><span id={vidIdentifiedObjTab}>{t('videoAnalytics.tabs.identifiedObjects')}</span></Tab>
                                <Tab><span id={vidObjectsRateTab}>{t('videoAnalytics.tabs.objectsRate')}</span></Tab>
                            </TabList>
                        </div>
                        <TabPanel>
                            <Row>
                                <Col lg={8} className=''>
                                    <div className='analyticGraph'>
                                        {
                                            analytics &&
                                            <div className='analyticsBar'>
                                                <div className={`analyticsBarBox pointer ${clickedStatOption === 1 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(1)}>
                                                    <span>{t('common.views')}</span>
                                                    <h4>{analytics.views ? analytics.views?.total : 0}</h4>
                                                </div>
                                                <div className={`analyticsBarBox pointer ${clickedStatOption === 2 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(2)}>
                                                    <span>{t('common.overview.watchTime')}</span>
                                                    <h4>{analytics.watchTime ? analytics.watchTime?.total : 0}</h4>
                                                </div>
                                                <div className={`analyticsBarBox pointer ${clickedStatOption === 3 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(3)}>
                                                    <span>{t('common.recognisedObjects')}</span>
                                                    <h4>{analytics.identifiedObjects ? analytics.identifiedObjects?.total : 0}</h4>
                                                </div>
                                            </div>
                                        }
                                        {
                                            options &&
                                            <ReactECharts option={options} />
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className='box h-auto'>
                                        <div className='realtimeBox'>
                                            <div>
                                                <h5>{t('common.realtime')}</h5>
                                                <p>{t('common.updatingLive')}</p>
                                            </div>
                                            <div>
                                                {
                                                    realtimeLoader ?
                                                        <h5>
                                                            <SmallLoader wrapperClass="align-items-center mb-2" />
                                                        </h5>
                                                        :
                                                        <h5>{realtimeViews}</h5>

                                                }
                                                <div id={vidRealtimeSelect} className='searchByDays align-items-center'>
                                                    <div className='md-16 me-5 mb-0'>
                                                        <div className='select-items custom-react-select'>
                                                            <Select classNamePrefix="triage-select" className='iconDD barDD'
                                                                placeholder='Select Type'
                                                                options={realtimeDurationList}
                                                                value={realtimeSelected}
                                                                onChange={(e) => setRealtimeSelected({ value: e.value, label: e.label, unit: e.unit })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='realtimeBox-btm'>
                                            <p className='mb-0'>{`${realtimeSelected.value} (${realtimeSelected.unit})`}</p>
                                            <p className='mb-0'>{t('common.now')}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel>
                            <Reach
                                videoId={videoId}
                                setLoader={setLoader}
                                getDateIntervals={getDateIntervals}
                                date={date}
                                fetchAnalytics={fetchAnalytics}
                            />
                        </TabPanel>
                        <TabPanel>
                            <Engagement
                                setLoader={setLoader}
                                getDateIntervals={getDateIntervals}
                                date={date}
                            />
                        </TabPanel>
                        <TabPanel>
                            <Audience
                                setLoader={setLoader}
                                getDateIntervals={getDateIntervals}
                                date={date}
                            />
                        </TabPanel>
                        <TabPanel>
                            <IdentifiedObjects
                                videoId={videoId}
                                duration={selected?.value !== 'custom' ? `${selected.value}-${selected.unit}` : null}
                                customDate={searchCustom ? date : {}}
                                setCustom={setCustom}
                                setLoader={setLoader}
                            />
                        </TabPanel>
                        <TabPanel>
                            <ObjectsRate
                                setSelectedObject={setObject}
                                videoId={videoId}
                                setLoader={setLoader}
                                getDateIntervals={getDateIntervals}
                                date={date}
                                fetchAnalytics={fetchAnalytics}
                            />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
            <CustomSearchModal {...customSearchProps} />
        </>
    )
}
export default VideoAnalytics