import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import VideoUrlSection from './VideoUrl'
import { ENV } from '../../../../../config/config'
import { BsInfoCircleFill } from 'react-icons/bs'
import { Tooltip } from 'react-tooltip'
import Select from 'react-select'
import '../../Models/Models.css'

const { defaultPlaceholderImage, allowedMediaTypes, subtitleLanguages } = ENV
const VideoEle = (props) => {
    const [subtitleLangOptions, setLangOptions] = useState([])

    useEffect(() => {
        if (subtitleLanguages?.length) {
            let options = subtitleLanguages.map((lang) => {
                return { value: lang.code, label: lang.label }
            })
            setLangOptions(options)
        }
    }, [])

    const [showInputs, setShowInputs] = useState({
        subtitleInput: false,
        // cardInput: false,
        // endScreenInput: false
    })

    useEffect(() => {
        // let cardInput = false
        // let endScreenInput = false
        let subtitleInput = false

        // if (props.videoDetails?.cardImages?.length ||
        //     props.fileInputs?.cards?.length ||
        //     props.errors.cards)
        //     cardInput = true


        // if (props.videoDetails?.endScreenImage ||
        //     props.fileInputs?.endScreen ||
        //     props.errors.endScreen)
        //     endScreenInput = true

        if (props.videoDetails?.subtitleFile ||
            props.fileInputs?.subtitle ||
            props.errors.subtitle)
            subtitleInput = true

        setShowInputs({ subtitleInput/* , endScreenInput, cardInput  */ })

    }, [props.fileInputs, props.videoDetails])

    return (
        <>
            <div className='modalPage'>
                <div className=" themeModal" >
                    <div className='seletFile modalBox'>
                        <Row>
                            <Col md={8} className='mb-md-0 mb-3'>
                                <h4 className='modal-H4'>{props.t('editVideoDetails.videoElements.heading')}</h4>
                                <p className='opacity-40'>{props.t('editVideoDetails.videoElements.cards&EndScreen')}</p>
                                <div className='addGroup'>
                                    <div className='addGroupList'>
                                        <div className='addBlock'>
                                            <h5 className='h5Title'>{props.t('editVideoDetails.videoElements.addSubtitles')}</h5>
                                            <p className='opacity-40 mb-0'>{props.t('editVideoDetails.videoElements.subtitleParagraph')}</p>
                                        </div>
                                        <div className='d-flex align-items-center add-subtitle-holder'>
                                            {
                                                showInputs.subtitleInput ?
                                                    <button className="link orangeLink p-0" onClick={() => props.resetSubtitle(showInputs, setShowInputs)}>x</button> :
                                                    <button className="link orangeLink text-uppercase p-0" onClick={() => setShowInputs({ ...showInputs, subtitleInput: !showInputs.subtitleInput })}>{props.t('editVideoDetails.videoElements.add')}</button>

                                            }
                                            <p className='mb-0 ms-3' id="subtitle-info"><BsInfoCircleFill /></p>
                                            <Tooltip
                                                anchorId="subtitle-info"
                                                place="top"
                                                content={`${props.t('common.supportedTypes')}: ${allowedMediaTypes.subtitles.map((ext) => `.${ext}`).join(', ')}`}
                                            />
                                        </div>
                                    </div>
                                    {
                                        (showInputs.subtitleInput) ?
                                            <>
                                                <Form.Control
                                                    type="file"
                                                    varient="info"
                                                    accept=".png,.jpeg,.jpg"
                                                    onChange={(e) => props.onFileChange(e.target.files[0], 1, e.target.id)}
                                                    id="subtitle"
                                                    className='thumbnail-form-control'
                                                />
                                            </>
                                            : null
                                    }
                                    {
                                        (/* props.videoDetails.subtitleFile ||  */props.fileInputs.subtitle) ?
                                            <>
                                                <div className='thumbnailImg'>
                                                    <img className='videoImg' src={defaultPlaceholderImage} alt='thumbnail' />
                                                </div>
                                                <label id="subtitleLabel" className="text-white thumbnailImg-label">{props.t('editVideoDetails.videoElements.fileSelected')}</label>
                                            </>
                                            : null
                                    }
                                    {
                                        props.errors.subtitle &&
                                        <span className='submit-errors text-danger'>{props.errors.subtitle}</span>
                                    }
                                    {/*  
                                    
                                    // FUNCTIONALITY NOT NEEDED RIGHT NOW

                                    <div className='addGroupList'>
                                        <div className='addBlock'>
                                            <h5 className='h5Title'>Add an end screen</h5>
                                            <p className='opacity-40 mb-0'>Promote related content at the end of your video</p>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {
                                                showInputs.endScreenInput ?
                                                    <button className="link orangeLink p-0" onClick={() => props.resetEndScreen(showInputs, setShowInputs)}>x</button> :
                                                    <button className="link orangeLink text-uppercase p-0" onClick={() => setShowInputs({ ...showInputs, endScreenInput: !showInputs.endScreenInput })}>add</button>

                                            }
                                            <p className='mb-0 ms-3' id="endscreen-info"><BsInfoCircleFill /></p>
                                            <Tooltip
                                                anchorId="endscreen-info"
                                                place="top"
                                                content={`Supported File Types: ${allowedMediaTypes.images.map((ext) => `.${ext}`).join(', ')}`}
                                            />
                                        </div>
                                    </div>
                                    {
                                        showInputs.endScreenInput ?
                                            <>
                                                <Form.Control
                                                    type="file"
                                                    ref={endScreenRef}
                                                    style={{ color: "transparent" }}
                                                    varient="info"
                                                    accept=".png,.jpeg,.jpg"
                                                    onChange={(e) => props.onFileChange(e.target.files[0], 3, e.target.id)}
                                                    id="endScreen"
                                                />
                                            </>
                                            : null
                                    }
                                    {
                                        (props.videoDetails.endScreenImage || props.fileInputs.endScreen) ?
                                            <div className='thumbnailImg'>
                                                <img className='videoImg' src={props.videoDetails.endScreenImage || props.fileInputs.endScreen} alt='endScreen'
                                                    onClick={() => endScreenRef.current.click()} />
                                            </div> :

                                            showInputs.endScreenInput ?
                                                <div className='thumbnailImg'>
                                                    <img className='videoImg' src={defaultPlaceholderImage} alt='end-screen' />
                                                </div> : null
                                    }
                                    {
                                        props.errors.endScreen &&
                                        <span className='submit-errors text-danger'>{props.errors.endScreen}</span>
                                    }
                                    <div className='addGroupList'>
                                        <div className='addBlock'>
                                            <h5 className='h5Title'>Add cards</h5>
                                            <p className='opacity-40 mb-0'>Promote related content during your video</p>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {
                                                showInputs.cardInput ?
                                                    <button className="link orangeLink p-0" onClick={() => props.resetCards(showInputs, setShowInputs)}>x</button> :
                                                    <button className="link orangeLink text-uppercase p-0" onClick={() => setShowInputs({ ...showInputs, cardInput: !showInputs.cardInput })}>add</button>

                                            }
                                            <p className='mb-0 ms-3' id="cards-info"><BsInfoCircleFill /></p>
                                            <Tooltip
                                                anchorId="cards-info"
                                                place="top"
                                                content={`Supported File Types: ${allowedMediaTypes.images.map((ext) => `.${ext}`).join(', ')}`}
                                            />
                                        </div>
                                    </div>
                                    {
                                        showInputs.cardInput ?
                                            <>
                                                <p className='fileLabel'>Upload upto 5 card images.</p>
                                                <Form.Control
                                                    ref={cardsRef}
                                                    type="file"
                                                    style={{ color: "transparent" }}
                                                    varient="info"
                                                    accept=".png,.jpeg,.jpg"
                                                    onChange={(e) => props.onFileChange(e.target.files, 2, e.target.id)}
                                                    id="cards"
                                                    multiple
                                                >
                                                </Form.Control>

                                            </>
                                            : null
                                    }
                                    {
                                        (props.videoDetails?.cardImages?.length || props.fileInputs?.cards?.length) ?
                                            <div className='thumbnailImg'>
                                                {
                                                    (props.videoDetails.cardImages || props.fileInputs.cards).map((card, index) => {
                                                        return <img key={index} className='videoImg' src={card} alt='card'
                                                            onClick={() => {
                                                                cardsRef.current.click(); props.setImgIdx(index)
                                                            }}
                                                        />
                                                    })
                                                }
                                            </div> :
                                            showInputs.cardInput ?
                                                <div className='thumbnailImg'>
                                                    <img className='videoImg' src={defaultPlaceholderImage} alt='card'
                                                        onClick={() => {
                                                            cardsRef.current.click(); props.setImgIdx(0)
                                                        }}
                                                    />
                                                </div> : null

                                    }
                                    {
                                        props.errors.cards &&
                                        <span className='submit-errors text-danger'>{props.errors.cards}</span>
                                    } */ }
                                    <div className='select-items custom-react-select w-100'>
                                        <label style={{ fontSize: '16px' }}>{props.t('editVideoDetails.videoElements.subtitleLanguage')}</label>
                                        <Select classNamePrefix="triage-select" className='iconDD barDD'
                                            placeholder={props.t('editVideoDetails.videoElements.subtitlePlaeholder')}
                                            options={subtitleLangOptions}
                                            value={props.selected}
                                            onChange={(e) => props.setSelected({ value: e.value, label: e.label })}
                                        />
                                    </div>
                                </div>

                            </Col>
                            <Col md={4}>
                                <VideoUrlSection video={props.videoDetails} inputs={props.fileInputs} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}
export default VideoEle