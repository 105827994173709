import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { listInfoPages, beforeInformative } from './informative.actions'
import { useSelector, useDispatch } from 'react-redux'
import FullPageLoader from '../FullPageLoader/FullPageLoader'
import { Link } from 'react-router-dom'
import './informative.css'
import { useTranslation } from 'react-i18next'

const Informative = () => {
    const { t } = useTranslation()
    const [infoData, setInfoData] = useState([])
    const [loader, setLoader] = useState(true)

    const dispatch = useDispatch()
    const informativeSelector = useSelector((state) => state.informative)

    useEffect(() => {
        dispatch(listInfoPages())
    }, [])

    useEffect(() => {
        if (informativeSelector.informativeAuth) {
            let data = informativeSelector.informativeData.cms
            setLoader(false)
            setInfoData(data)
            dispatch(beforeInformative())
        }

    }, [informativeSelector.informativeAuth])

    return (
        loader ? <FullPageLoader /> :
            <>
                <div className='query-sec'>
                    <Container className='customBox'>
                        <Row>
                            <Col lg={12}>
                                <h1 className='mb-0'>Informative <span className='theme'>Pages</span></h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <section className='getintouch-sec informative-sec works'>
                    <Container className='position-relative'>
                        <h1><span className='theme'>{t('informative.heading')}</span></h1>
                        <div className='informative-wrapper'>
                            <div className='informative-links'>
                                <ul className='informative-ul'>
                                    {
                                        infoData && infoData?.length ?
                                            infoData?.map((infoPage, key) => (
                                                <li key={key}>
                                                    <Link to={`/informative/${infoPage.slug}`}>{infoPage.title}</Link>
                                                </li>
                                            )) :
                                            <span className='no-pages'>{t('informative.noInformativePages')}</span>
                                    }

                                </ul>
                            </div>
                        </div>
                    </Container>
                </section>
            </>
    )
}

export default Informative