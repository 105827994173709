import React from 'react'
import CommunityHeader from './CommunityHeader/CommunityHeader';
import CommunityQuestions from './CommunityQuestions/CommunityQuestions';
import Footer from '../Footer/Footer';

function Community() {
	return (
		<div className='community-wrapper'> 
			<CommunityHeader />
			<CommunityQuestions />
			<Footer />
		</div>
	)
}

export default Community;