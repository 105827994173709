import { ENV } from '../../../config/config';
import { emptyError } from '../../../redux/shared/error/error.action'
import { GET_ERRORS, SUBSCRIBE_NEWSLETTER } from '../../../redux/types';
import { toast } from 'react-toastify';

export const subscribeNewsletter = (body) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}newsletter/subsrcibe`;

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: SUBSCRIBE_NEWSLETTER
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};
