import {
    BEFORE_VIDEO, EDIT_VIDEO, LIST_VIDEO, VIDEO_CONTENT_UPLOADED,
    GET_TRENDING_VIDEOS, GET_TOP_VIDEOS_VIEWS,
    BEFORE_VIDEO_CONTENT_UPLOADED, GET_VIDEO, DELETE_VIDEO, PUBLIC_VIDEO,
    BEFORE_PUBLIC_VIDEO_CONTENT, VIDEO_CATEGORIES, VIDEO_STAT, BEFORE_STAT, VIDEO_ANALYTICS, BEFORE_VID_ANALYTICS, RECOMMENDED_VIDEOS, BEFORE_RECOMMENDED_VIDEOS, SEARCHED_VIDEOS, BEFORE_SEARCHED_VIDEOS, CREATE_VIDEO, EDIT_ANNOTATION, BEFORE_ANNOTATION_EDIT, ADD_BOUNDING_OBJECTS, GET_BOUNDING_OBJECTS, BEFORE_BOUNDING_OBJECTS, BEFORE_LIKE_DISLIKE, ADD_REMOVE_LIKE_DISLIKE, LIST_VIDEO_COMMENTS, UPSERT_VIDEO_COMMENT, DELETE_VIDEO_COMMENT, BEFORE_VIDEO_COMMENTS, UPLOAD_SUBTITLE
} from './../../../../redux/types';

const initialState = {
    listVideoContent: null,
    listVideoContentAuth: false,

    editVideoData: null,
    editVideoAuth: false,

    videoUploadedAuth: false,

    createVideoAuth: false,
    createVideoData: false,

    getVideoData: null,
    getVideoAuth: false,

    deleteVideoData: null,
    deleteVideoAuth: false,

    publicVideoContent: null,
    publicVideoContentAuth: false,

    videoCategoriesAuth: false,
    videoCategories: null,

    createVideoStatData: null,
    createVideoStatAuth: false,

    videoAnalyticsData: null,
    videoAnalyticsAuth: false,

    recommendedVideosAuth: false,
    recommendedVideosData: null,

    searchedVideosData: null,
    searchedVideosAuth: false,

    editAnnotation: null,
    editAnnotationAuth: false,

    addBoundingObjects: null,
    addBoundingObjectsAuth: false,

    getBoundingObjects: null,
    getBoundingObjectsAuth: false,

    getTrendingVideos: null,
    getTrendingVideosAuth: false,

    getTopVideosViews: null,
    getTopVideosViewsAuth: false,

    addRemoveLikeDislikeData: null,
    addRemoveLikeDislikeAuth: false,

    // video comments

    listVideoCommentsRes: null,
    listVideoCommentsAuth: false,

    upsertVideoCommentRes: null,
    upsertVideoCommentAuth: false,

    deleteVideoCommentAuth: false,

    uploadSubtitleAuth: false,
    uploadSubtitleData: null

};

export default function videoReducer(state = initialState, action) {

    switch (action.type) {
        case CREATE_VIDEO:
            return {
                ...state,

                createVideoAuth: true,
                createVideoData: action.payload
            }
        case EDIT_VIDEO:
            return {
                ...state,

                editVideoData: action.payload,
                editVideoAuth: true
            }
        case LIST_VIDEO:
            return {
                ...state,

                listVideoContent: action.payload.data,
                listVideoContentAuth: true
            }
        case GET_VIDEO:
            return {
                ...state,

                getVideoAuth: true,
                getVideoData: action.payload
            }
        case VIDEO_STAT:
            return {
                ...state,

                createVideoStatAuth: true,
                createVideoStatData: action.payload
            }
        case DELETE_VIDEO:
            return {
                ...state,

                deleteVideoAuth: true,
                deleteVideoData: action.payload
            }
        case GET_TRENDING_VIDEOS:
            return {
                ...state,

                getTrendingVideosAuth: true,
                getTrendingVideos: action.payload,
            }
        case GET_TOP_VIDEOS_VIEWS:
            return {
                ...state,

                getTopVideosViewsAuth: true,
                getTopVideosViews: action.payload,

            }
        case PUBLIC_VIDEO:
            return {
                ...state,

                publicVideoContent: action.payload.data,
                publicVideoContentAuth: true
            }
        case VIDEO_CATEGORIES:
            return {
                ...state,

                videoCategories: action.payload.data,
                videoCategoriesAuth: true
            }
        case VIDEO_ANALYTICS:
            return {
                ...state,

                videoAnalyticsData: action.payload,
                videoAnalyticsAuth: true
            }
        case BEFORE_VID_ANALYTICS:
            return {
                ...state,

                videoAnalyticsData: null,
                videoAnalyticsAuth: false
            }
        case RECOMMENDED_VIDEOS:
            return {
                ...state,

                recommendedVideosData: action.payload,
                recommendedVideosAuth: true
            }
        case BEFORE_RECOMMENDED_VIDEOS:
            return {

                recommendedVideosData: null,
                recommendedVideosAuth: false
            }
        case BEFORE_STAT:
            return {

                createVideoStatData: null,
                createVideoStatAuth: false
            }
        case BEFORE_VIDEO:
            return {
                ...state,

                listVideoContent: null,
                listVideoContentAuth: false,

                createVideoAuth: false,
                createVideoData: null,

                editVideoData: null,
                editVideoAuth: false,

                getVideoData: null,
                getVideoAuth: false,

                deleteVideoData: null,
                deleteVideoAuth: false,

                videoCategoriesAuth: false,
                videoCategories: null,

                getTrendingVideosAuth: false,
                getTrendingVideos: null,

                getTopVideosViewsAuth: false,
                getTopVideosViews: null,

                uploadSubtitleAuth: false, 
                uploadSubtitleData: null
            }
        case VIDEO_CONTENT_UPLOADED:
            return {
                videoUploadedAuth: true,
            }
        case BEFORE_VIDEO_CONTENT_UPLOADED:
            return {
                videoUploadedAuth: false
            }
        case BEFORE_PUBLIC_VIDEO_CONTENT:
            return {
                publicVideoContent: null,
                publicVideoContentAuth: false
            }
        case SEARCHED_VIDEOS:
            return {
                ...state,

                searchedVideosAuth: true,
                searchedVideosData: action.payload
            }
        case BEFORE_SEARCHED_VIDEOS:
            return {
                searchedVideosAuth: false,
                searchedVideosData: null
            }
        case EDIT_ANNOTATION:
            return {
                ...state,

                editAnnotationAuth: true,
                editAnnotation: action.payload

            }
        case BEFORE_ANNOTATION_EDIT:
            return {
                ...state,
                editAnnotationAuth: false,
                editAnnotation: null

            }

        case ADD_BOUNDING_OBJECTS:
            return {
                ...state,
                addBoundingObjectsAuth: true,
                addBoundingObjects: action.payload

            }
        case GET_BOUNDING_OBJECTS:
            return {
                ...state,
                getBoundingObjectsAuth: true,
                getBoundingObjects: action.payload

            }
        case BEFORE_BOUNDING_OBJECTS:
            return {
                ...state,
                addBoundingObjectsAuth: false,
                addBoundingObjects: null,

                getBoundingObjectsAuth: false,
                getBoundingObjects: null
            }
        case ADD_REMOVE_LIKE_DISLIKE:
            return {
                ...state,
                addRemoveLikeDislikeData: action.payload,
                addRemoveLikeDislikeAuth: true,

            }
        case BEFORE_LIKE_DISLIKE:
            return {

                addRemoveLikeDislikeData: null,
                addRemoveLikeDislikeAuth: false,
            }
        case LIST_VIDEO_COMMENTS:
            return {
                ...state,
                listVideoCommentsRes: action.payload,
                listVideoCommentsAuth: true
            }
        case UPSERT_VIDEO_COMMENT:
            return {
                ...state,
                upsertVideoCommentRes: action.payload,
                upsertVideoCommentAuth: true
            }
        case DELETE_VIDEO_COMMENT:
            return {
                ...state,
                deleteVideoCommentAuth: true
            }
        case BEFORE_VIDEO_COMMENTS:
            return {
                ...state,
                listVideoCommentsRes: null,
                listVideoCommentsAuth: false,

                upsertVideoCommentRes: null,
                upsertVideoCommentAuth: false,

                deleteVideoCommentAuth: false
            }
        case UPLOAD_SUBTITLE:
            return {
                ...state,
                uploadSubtitleAuth: true, 
                uploadSubtitleData: null
            }
        default:
            return state;
    }
}