
// Member Dashboard Sidebar
export const memberDashboardLink = 'publisher-dashboard-step'
export const myVideosLink = 'my-videos-step'
export const exploreVideosLink = 'explore-videos-step'
export const channelAnalyticsLink = 'channel-anlatytics-step'
export const subtitlesLink = 'subtitles-step'
export const settingsLink = 'settings-step'
export const paymentHistoryLink = 'payment-history-step'
export const myChannelLink = 'my-channels-step'
export const packagesLink = 'packages-step'
export const profileLink = 'profile-step'
export const uploadVideoDashboard = 'uploadVideo-dashboard'
export const topVideosSection = 'top-videos-step'
export const summarySection = 'summary-step'
export const notificationsIcon = 'notifications-step'
export const profileMenu = 'profile-menu-step'
export const contactAdmin = 'contact-admin-step'
export const publisherTutorials = 'publisher-tutorials-step'

// Dashboard
export const dashboardCategory = 'dashboard-category-step'
export const becomeMemberSection = 'become-member-step'


// My Videos
export const uploadVideoButton = 'uploadVideo-step'
export const visibilityOptions = 'visibility-step'
export const deleteVideosCheck = 'deleteVideos-step'
export const editVideoIcon = 'editVideo-step'
export const videoAnalyticsIcon = 'videoAnalytics-step'
export const processedVideoIcon = 'processedVideo-step'
export const originalVideoIcon = 'originalVideo-step'
export const editAnnIcon = 'editAnn-step'
export const addObjectsIcon = 'addObjects-step'
export const otherVideoOptions = 'otherVideoOption-step'

// Explore Videos 
export const exploreVideosSelect = 'exploreVideos-select-step'
export const exploreVideosSearch = 'exploreVideos-search-step'

// Channel Analytics
export const overviewTab = 'ch-analytics-overview-step'
export const contentTab = 'ch-analytics-content-step'
export const audienceTab = 'ch-analytics-audience-step'
export const researchTab = 'ch-analytics-research-step'
export const analyticsSelect = 'ch-analytics-filter-step'
export const realtimeSelect = 'ch-analytics-realtime-step'

// Settings 
export const channelSettingsNav = 'channel-settings-step'
export const uploadDefaultSettingsNav = 'upload-default-settings-step'
export const permissionsSettingsNav = 'permissions-settings-step'
export const generalSettingsNav = 'general-settings-step'
export const socialSettingsNav = 'social-settings-step'

// Payment History
export const activePlanSection = 'active-plan-step'
export const cancelSubButton = 'cancel-subscription-step'
export const viewBillingLink = 'view-billing-step'
export const previousPlansSection = 'previous-plans-step'

// Billing & Invoices
export const viewInvoiceIcon = 'view-invoice-step'
export const downloadInvoiceIcon = 'download-invoice-step'

// My Channels
export const myChannelsSection = 'view-channels-step'
export const activeChannelButton = 'active-channel-step'
export const selectChannelButton = 'select-channel-step'

// Packages
export const selectPlanSection = 'select-plan-step'

// Profile
export const userDetailsSection = 'user-details-step'
export const updatePasswordSection = 'update-password-step'

// Channel Analytics
export const vidAnalyticsOverview = 'video-analytics-overview-step'
export const reachTab = 'video-analytics-reach-step'
export const engagementTab = 'video-analytics-engage-step'
export const vidAnalyticsAudience = 'video-analytics-audience-step'
export const vidIdentifiedObjTab = 'video-objects-step'
export const vidObjectsRateTab = 'video-objects-rate-step'
export const vidAnalyticsSelect = 'video-analytics-filter-step'
export const vidRealtimeSelect = 'video-analytics-realtime-step'

// Media Player Tour
export const recommendedVideosSection = 'recommended-videos-step'

// Favourites
export const favouriteVideosSection = 'favourite-videos-step'

// Watch History
export const watchHistorySection = 'watch-history-step'
export const watchHistoryFilterSection = 'watch-history-filter-step'

// Channel Details
export const viewChannelDetails = 'view-channelDetails-step'
export const subscribeChannel = 'subscribe-channel-step'
export const viewChannelVideos = 'channel-videos-step'




