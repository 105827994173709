import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { TiArrowSortedDown } from "react-icons/ti"
import countryLanguage from "country-language";
import { getCategories, beforeVideo } from '../../Videos/video.actions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next'
import '../../Models/Models.css'

const UploadAdvanceSettings = (props) => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    const [categories, setCategories] = useState([])
    const allLanguages = countryLanguage.getLanguages().map((l) => l.name[0])
    const [languages, setLanguages] = useState([])

    const videoSelector = useSelector((state) => state.videos)
    const currentLanguage = localStorage.getItem('selectedLang')

    useEffect(() => {
        if (allLanguages) {
            const items = allLanguages.map(item => ({
                value: item,
                label: item
            }));

            setLanguages(items)
        }
    }, [])

    useEffect(() => {
        if (i18n.language) {
            dispatch(getCategories(`lng=${currentLanguage}`))
        }
    }, [i18n.language]);

    useEffect(() => {
        if (videoSelector.videoCategoriesAuth) {
            const items = videoSelector.videoCategories.categories.map(category => ({
                value: category._id,
                label: category.name
            }));
            setCategories(items);
            dispatch(beforeVideo());
        }
    }, [videoSelector.videoCategoriesAuth]);

    const onChangeHandler = (e) => {
        props.setData({ [e.name]: e.value })
    }

    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div>
                        <div className='general mb-0'>
                            <div>
                                <h4>{t('settings.uploadDefault.advanceSettings.automaticChapters')}</h4>
                                <p>{t('settings.uploadDefault.advanceSettings.chaptersNote')}</p>
                            </div>
                            <div className='fileGroup'>
                                <div className='d-flex justify-content-start align-items-center checkboxText mb-3'>
                                    <div className="form-check checkTable me-2 px-0 modalCheck">
                                        {/* <input className="form-check-input" type="checkbox" checked={props.chaptersAndMoments} onChange={() => props.setData({ chaptersAndMoments: !props.chaptersAndMoments })} name="chaptersAndMoments" id="flexCheckDefault" /> */}
                                        <label className="right-label-checkbox">{t('settings.uploadDefault.advanceSettings.checkboxText')}
                                            <input type="checkbox" checked={props.chaptersAndMoments} onChange={() => props.setData({ chaptersAndMoments: !props.chaptersAndMoments })} name="chaptersAndMoments" id="flexCheckDefault" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    {/* <h4 className='check-H4 mb-0'>Allow automatic chapters and key moments</h4> */}
                                </div>
                                <div className='fileGroupList'>
                                    <Row>
                                        <Col md={6}>
                                            <div className='modalSelect mb-2'>
                                                <div className="custom-react-select flex-fill">
                                                    <Select
                                                        name="videoCategory"
                                                        options={categories}
                                                        value={categories && categories.length > 0 ? categories.find(option => option.value === props.videoCategory) : null}
                                                        // onChange={onChangeHandler} // Update category state with the selected option's value
                                                        onChange={(e) => props.setData({ videoCategory: e.value })}
                                                        classNamePrefix="triage-select"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='fileGroupList'>
                                    <h4>{t('settings.uploadDefault.advanceSettings.languageText')}</h4>
                                    <Row>
                                        <Col md={6}>
                                            <div className='modalSelect mb-2'>
                                                <div className="custom-react-select flex-fill">
                                                    <Select
                                                        name="videoLanguage"
                                                        options={languages}
                                                        value={languages && languages.length > 0 ? languages.find(option => option.value === props.videoLang) : null} // Setting the selected option based on category state
                                                        // onChange={onChangeHandler} // Update category state with the selected option's value
                                                        onChange={(e) => props.setData({ videoLanguage: e.value })}
                                                        classNamePrefix="triage-select"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='modalSelect mb-2'>
                                                <div className="custom-react-select flex-fill">
                                                    <Select
                                                        name="titleDescLanguage"
                                                        options={languages}
                                                        value={languages && languages.length > 0 ? languages.find(option => option.value === props.titleDescLang) : null} // Setting the selected option based on category state
                                                        // onChange={onChangeHandler} // Update category state with the selected option's value
                                                        onChange={(e) => props.setData({ titleDescLanguage: e.value })}
                                                        classNamePrefix="triage-select"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UploadAdvanceSettings