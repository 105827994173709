import { toast } from 'react-toastify';
import { emptyError } from '../../../../../redux/shared/error/error.action';
import { GET_ERRORS, GET_OBJECT, LIST_OBJECTS, BEFORE_OBJECT } from '../../../../../redux/types';
import { ENV } from '../../../../../config/config';

// identified objects
export const beforeObject = () => {
    return {
        type: BEFORE_OBJECT
    }
}

export const listObjects = (qs = '', showToast = true) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}objects/list`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            if (showToast)
                toast.success(data.message, {
                    toastId: "list-obj"
                })
            dispatch({
                type: LIST_OBJECTS,
                payload: data.data
            })
        } else {
            toast.error(data.message, {
                toastId: "list-objCat-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message, {
                    toastId: "list-objCat-error"
                })
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
