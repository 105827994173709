import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Modal, Form } from 'react-bootstrap'
import { editVideo, getVideoContent } from './video.actions'
import { useSelector, useDispatch } from 'react-redux'
import '../Models/Models.css'

const EditDes = ({ video, show, setShow, setLoader, t }) => {

    const dispatch = useDispatch()

    const channelId = localStorage.getItem('channelId')
    const [saveCheck, setCheck] = useState(false)
    const [data, setData] = useState({
        _id: video.videoId,
        title: video.title || '',
        description: video.description || ''
    })
    const [errors, setErrors] = useState({
        title: '',
        description: ''
    })

    const videoSelector = useSelector((state) => state.videos)

    useEffect(() => {
        if (videoSelector.editVideoAuth) {
            setLoader(false)
            dispatch(getVideoContent(`channelId=${channelId}`))
        }
    }, [videoSelector.editVideoAuth])


    const handleClose = () => {
        setShow(false)
        setData({})
    };

    const onChangeHandler = (e) => {

        if (saveCheck) {
            if (!e.target.value)
                setErrors({ ...errors, [e.target.name]: 'This field is required!' })
            else
                setErrors({ ...errors, [e.target.name]: '' })

        }

        setData({ ...data, [e.target.name]: e.target.value })
    }

    const submit = () => {
        setCheck(true)

        let title = ''
        let description = ''
        let payload = { ...data }

        let msg = t('common.fieldRequired')

        if (!payload.title)
            title = msg

        if (!payload.description)
            description = msg

        if (title || description) {
            setErrors({ ...errors, title, description })
            return
        }

        // check to edit only title & description
        payload.editSelective = true
        payload.channelId = channelId || ''
        
        let formData = new FormData()
        for (const key in payload) {
            formData.append(key, payload[key]);
        }

        setLoader(true)
        dispatch(editVideo(formData))
        handleClose()
    }

    return (
        <>
            <div className='modalPage'>
                <div className="modal show" >
                    <Modal show={show} onHide={handleClose} className="narrowModel" centered>
                        <Modal.Header closeButton>
                            <Modal.Title className='mb-0'>{t('myVideos.tooltips.menu.editTitleDesc')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='py-lg-4 py-3'>
                            <div className='fileGroup'>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>{t("editVideoDetails.details.title")}<span className="text-danger danger-asterik">*</span></p>
                                    <div className=''>
                                        <Form.Control
                                            type="text"
                                            value={data.title}
                                            name="title"
                                            onChange={onChangeHandler}
                                        >
                                        </Form.Control>
                                        {
                                            errors && errors.title &&
                                            <span className='submit-errors text-danger'>{errors.title}</span>
                                        }
                                    </div>
                                </div>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>{t("editVideoDetails.details.description")} <span className="text-danger danger-asterik">*</span></p>
                                    <Form.Group
                                        className='w-100'
                                        as="textarea"
                                        name="description"
                                        value={data.description}
                                        rows={3}
                                        placeholder={t("editVideoDetails.descPlaceholder")}
                                        onChange={onChangeHandler}>
                                    </Form.Group>
                                </div>
                                {
                                    errors && errors.description &&
                                    <span className='submit-errors text-danger'>{errors.description}</span>
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='pb-0'>
                            <div className='d-flex align-items-center'>
                                <button className="grey-btn text-uppercase me-lg-3 me-2 mt-0" onClick={handleClose}>{t('common.close')}</button>
                                <Link to='/my-videos' className="m-0 p-0"><button className="orange-btn text-uppercase mx-auto mt-0" onClick={submit}>{t('common.saveBtn')}</button></Link>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default EditDes