import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { getInvoice, beforeBillingHistory } from './billingHistory.actions'
import { Row, Col, Table } from 'react-bootstrap'
import { Images } from '../../../assets/assets'
import { Helmet } from 'react-helmet-async/lib/index.esm'
import { useTranslation } from 'react-i18next'

const ViewInvoice = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { id } = useParams()

    const billingSelector = useSelector((state) => state.billingHistory)
    const currentLanguage = localStorage.getItem('selectedLang')

    const [invoiceData, setData] = useState()

    useEffect(() => {
        if (id) {
            dispatch(getInvoice(`lng=${currentLanguage}`))
        }
    }, [])

    useEffect(() => {
        if (billingSelector.getInvoiceAuth) {
            let { invoice } = billingSelector.getInvoiceData
            setData(invoice)
            dispatch(beforeBillingHistory())
        }

    }, [billingSelector.getInvoiceAuth])

    return (
        invoiceData &&
        <>
            {
                invoiceData?.user?.fullName && invoiceData?.plan?.name &&
                <Helmet>
                    <title>{`${invoiceData?.user?.fullName} - ${invoiceData?.plan?.name} - ${t('invoice.invoiceDetails')}`}</title>
                </Helmet>

            }
            <section className='view-invoce-sec p-0'>
                <div className='invoice-header'>
                    {invoiceData.site && invoiceData.site.logo ?
                        <Link to="/">
                            <img src={Images.siteLogo} alt="biiview" />
                        </Link>
                        :
                        ""
                    }
                </div>
                <div className='view-invoice-p'>
                    <div className="py-4">
                        <Link to="/organization/detail"><span><i className="fa fa-arrow-left" aria-hidden="true"></i></span></Link>
                    </div>
                    <Row className='mb-4'>
                        <Col md={6}>
                            <div className='wrapper-content'>
                                <p className='mb-0'>{`${t('invoice.biiview')} • ${invoiceData.site.address}`}</p>
                                {invoiceData.user && invoiceData.user.fullName ? <p className='mb-0'>{invoiceData.user.fullName}</p> : ''}
                                {invoiceData.user.address ? <p>{invoiceData.user.address}</p> : ''}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='wrapper-content'>
                                <p className='mb-0 border-b'>{t('invoice.heading')}</p>
                                <div className='d-flex justify-content-between align-items-center flex-wrap border-b'>
                                    <p className='mb-0'>{`${t('invoice.biiview')}/ + ${moment(new Date(invoiceData.createdAt)).format("YYYY")}/${invoiceData.invoiceId}`}</p>
                                    <p className='mb-0'>{t('invoice.date')} {moment(new Date(invoiceData.createdAt)).format("DD/MM/YYYY")}</p>
                                </div>
                                <p className='mb-0 border-b'>{invoiceData.user && invoiceData.user.fullName ? invoiceData.user.fullName : ''}</p>
                                <p className='mb-0 border-b'>{t('invoice.biiview')}</p>
                            </div>
                        </Col>
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>{t('invoice.pos')}</th>
                                <th>{t('invoice.plan')}</th>
                                <th>{t('invoice.desc')}</th>
                                <th>{t('invoice.type')}</th>
                                <th>{t('invoice.price')}</th>
                                <th>{t('invoice.paymentStatus')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>{invoiceData.plan.name}</td>
                                <td>{invoiceData.plan.description}</td>
                                <td>{invoiceData.planDuration}</td>
                                <td>{invoiceData.price}</td>
                                <td>{invoiceData.paymentStatus}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className='bill-container mt-4 mb-4'>
                        <div className='bill-wrapper'>
                            <div className='d-flex justify-content-between align-items-center flex-wrap border-b'>
                                <p className='mb-0'>{t('invoice.net')}</p>
                                <p className='mb-0'>{invoiceData.price}$</p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center flex-wrap border-b'>
                                <p className='mb-0'>{t('invoice.total')}</p>
                                <p className='mb-0'>{invoiceData.price}$</p>
                            </div>
                        </div>
                    </div>
                    <table className='invoice-table-holder' >
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                    {t('invoice.heading')} {"BiiView/" + moment(new Date(invoiceData.createdAt)).format("YYYY") + "/" + invoiceData.invoiceId}
                                                    </p>
                                                </td>
                                                <td>&nbsp;</td>
                                                <td className='text-end'>
                                                    <p>{t('invoice.page')}: 1 / 1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='pt-2'>
                                                    <div className='invoice-company-details'>
                                                        <span className='d-block mb-1'>{t('invoice.biiview')}</span>
                                                        <span className='d-block mb-1'>{invoiceData.site.address}</span>
                                                        <span className=' ps-0 d-block mb-1'>{t('common.email')}: <span className='invoice-table-span'>{invoiceData.site.email}</span></span>
                                                        <span className='d-block mb-1'>{t('invoice.website')}: <a href={invoiceData.site.url} target="_blank" rel='noreferrer'>{invoiceData.site.name}</a></span>
                                                        <span className='d-block mb-1'>{t('invoice.companySite')}: <a href={invoiceData.company.url} target="_blank" rel='noreferrer'> {invoiceData.company.name}</a></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}

export default ViewInvoice