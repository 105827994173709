import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { getUserPlanDetails, beforeUserPlan } from '../UserComponents/users.actions';
import { cancelSubscription } from '../Packages/package.actions';
import { useDispatch, useSelector } from 'react-redux';
import IndividualCard from './IndividualCard';
import { Link } from 'react-router-dom';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import Swal from 'sweetalert2';
import { ENV } from '../../../config/config';
import { activePlanSection, cancelSubButton, previousPlansSection, viewBillingLink } from '../ApplicationTour/TourCompIdentifiers';
import { Images } from '../../../assets/assets';
import './PaymentHistory.css';
import { useTranslation } from 'react-i18next';

const PaymentHistory = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const userId = ENV.getUserKeys('_id')?._id

    const [activePlan, setActivePlan] = useState()
    const [previousPlans, setPreviousPlans] = useState([])
    const [pagination, setPagination] = useState({})
    const [loadMore, setLoadMore] = useState(false);
    const [loader, setLoader] = useState(true)
    const currentLanguage = localStorage.getItem('selectedLang')
    const userSelector = useSelector((state) => state.user)
    const plansSelector = useSelector((state) => state.packages)

    useEffect(() => {
        if (userId)
            dispatch(getUserPlanDetails())
    }, [])

    useEffect(() => {
        if (userSelector.userPlanDetailsAuth) {
            let { data } = userSelector.userPlanDetailsData
            setActivePlan(data?.activePlan)
            setPreviousPlans(loadMore ? previousPlans.concat(data?.previousUserPlans) : data?.previousUserPlans)
            setPagination(data?.pagination)
            setLoadMore(false)
            setTimeout(() => {
                setLoader(false)
            }, 2000)
            dispatch(beforeUserPlan())
        }
    }, [userSelector.userPlanDetailsAuth])

    useEffect(() => {
        if (plansSelector.cancelSubscriptionAuth) {
            setTimeout(() => {
                dispatch(getUserPlanDetails())
            }, 5000)
            dispatch(beforeUserPlan())
        }
    }, [plansSelector.cancelSubscriptionAuth])

    const cancelSubscriptionHandler = () => {
        Swal.fire({
            title: t('paymentHistory.cancelSubscription'),
            html: t('paymentHistory.cancelSubMsg'),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no')
        }).then(async (result) => {
            if (result.value) {
                setLoader(true)
                dispatch(cancelSubscription(`lng=${currentLanguage}`))
            }
        })
    }

    const loadMorePlans = () => {
        setLoadMore(true)
        setLoader(true)
        const { page } = pagination
        dispatch(getUserPlanDetails(`page=${page + 1}`))
    }

    return (
        <>
            {loader && <FullPageLoader />}
            <div className='dashboardPage payment-history'>
                <div className='dashboardHeader px40'>
                    <h2 className='text-uppercase'>{t('paymentHistory.cancelSubscription')}</h2>
                </div>
                <div className='content px40'>
                    {
                        activePlan &&
                        <div className='active-plans-details mb-4'>
                            <h2 id={activePlanSection}>{t('paymentHistory.activePlanHeading')}</h2>
                            <Container className='p-0' fluid>
                                {
                                    activePlan.plan ?
                                        <Row>
                                            {
                                                activePlan.hasPlanExpired ?
                                                    activePlan.plan.type !== 1 ?
                                                        <h4 className="text-danger">{t('paymentHistory.pendingPaymentMsg')}</h4> :
                                                        <h4 className="text-danger">{t('paymentHistory.expiredPlanMsg')}</h4> :
                                                    null
                                            }
                                            <Col lg={6} className='mb-3 mb-lg-0'>
                                                <IndividualCard plan={activePlan.plan} />
                                            </Col>
                                            {
                                                activePlan.plan.type !== 1 ?
                                                    <Col lg={6}>
                                                        <div className='billing-and-payment'>
                                                            <h2>{t('paymentHistory.billingHeading')}</h2>
                                                            <div className='details'>
                                                                {
                                                                    Object.keys(activePlan?.cardDetails)?.length ?
                                                                        <div className='credit-card-payment-info d-flex align-items-center'>
                                                                            <img src={Images.CreditCard} alt="credit-card" fluid />
                                                                            <p className='mb-0'>{`${activePlan.cardDetails.brand} ${t('paymentHistory.ending')} ****${activePlan.cardDetails.last4}`}</p>
                                                                        </div> : null
                                                                }
                                                                {
                                                                    Object.keys(activePlan?.subscriptionDetail)?.length ?
                                                                        <div className='billing-details d-flex align-items-start'>
                                                                            <img src={Images.Calender} alt="credit-card" fluid />
                                                                            <p className='mb-0'>{`US $${activePlan.price}/${activePlan.planDuration === 1 ? t('common.month') : t('common.year')}. ${t('paymentHistory.nextPayment')} ${activePlan.subscriptionDetail.nextPaymentDate}`}</p>
                                                                        </div> :
                                                                        null
                                                                }
                                                            </div>
                                                            <div id={viewBillingLink} className='view-billing-history'>
                                                                <Link to={'/invoices'}>{t('paymentHistory.viewHistory')}</Link>
                                                            </div>
                                                            {
                                                                !activePlan?.hasPlanExpired &&
                                                                <div className='edit-billing-and-payment'>
                                                                    <button
                                                                        id={cancelSubButton}
                                                                        type="button"
                                                                        className="theme-btn orange-btn text-uppercase mt-0"
                                                                        onClick={cancelSubscriptionHandler}
                                                                    >
                                                                        {t('paymentHistory.cancelSubscription')}
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Col>
                                                    :
                                                    <Col lg={6}>
                                                        <div id={viewBillingLink} className='billing-and-payment'>
                                                            <div className='view-billing-history'>
                                                                <Link to={'/invoices'}>{t('paymentHistory.viewHistory')}</Link>
                                                            </div>
                                                        </div>
                                                    </Col>
                                            }
                                        </Row>
                                        :
                                        <Row>
                                            <Col><p>No Active Plan</p></Col>
                                            <Col lg={7}>
                                                <div id={viewBillingLink} className='billing-and-payment'>
                                                    <div className='view-billing-history'>
                                                        <Link to={'/invoices'}>{t('paymentHistory.viewHistory')}</Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                }
                            </Container>
                        </div>

                    }
                    <div className='active-plans-details'>
                        <h2 id={previousPlansSection}>{t('paymentHistory.previousPlans')}</h2>
                        <Container className='p-0' fluid>
                            <Row>
                                {
                                    previousPlans && previousPlans.length ?
                                        previousPlans.map((plan, idx) => {
                                            return plan.plan ? <Col className='mb-3' index={idx} lg={6}><IndividualCard plan={plan.plan} /></Col> : null
                                        })
                                        :
                                        <div className='no-record-found d-flex justify-content-center align-items-center'>{t('paymentHistory.noPreviousPlans')}</div>
                                }
                            </Row>
                        </Container>
                        {pagination.total > previousPlans?.length ?
                            <div className='text-center w-100 d-block'>
                                <button className="orange-btn text-uppercase mx-auto" onClick={loadMorePlans}>{t('common.loadMoreBtn')}</button>
                            </div>
                            :
                            null
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default PaymentHistory