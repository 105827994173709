import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { answerUpDownVote, beforeCommunity, deleteAnswer, editAnswer, getQuestionDetails } from '../community.actions'
import FullPageLoader from '../../FullPageLoader/FullPageLoader'
import { ENV } from '../../../../config/config'
import "../CommunityQuestions/CommunityQuestions.css"
import { ckEditorCustomConfig } from '../../CkEditor/ckEditorConfig'
import { useTranslation } from 'react-i18next';

function CommunityAnswers() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const communtitySelector = useSelector((state) => state.community)
	const currentLanguage = localStorage.getItem('selectedLang')

	const [answers, setAnswers] = useState([])
	const [answerData, setAnswerData] = useState(null)
	const [questionTitle, setQuestionTitle] = useState(null)
	const [questionId, setQuestionId] = useState('')
	const [errors, setErrors] = useState({})
	const [loader, setLoader] = useState(true)
	const [pagination, setPagination] = useState({})
	const [show, setShow] = useState(false)
	const [showMore, setShowMore] = useState([])
	const [upvotes, setUpvotes] = useState([])
	const [downvotes, setDownvotes] = useState([])
	const [loadMore, setLoadMore] = useState(false)
	const userId = ENV.getUserKeys('_id')?._id

	useEffect(() => {
		if (communtitySelector.getQuestionDetailsAuth) {
			let { answersList, details } = communtitySelector.getQuestionDetails?.data?.questionDetails
			let paginationData = communtitySelector.getQuestionDetails?.data?.pagination

			setLoader(false)
			setAnswers(loadMore ? answers.concat(answersList) : answersList)

			if (answersList?.length) {
				setShowMore(new Array(answersList.length).fill(false))
				setUpvotes(new Array(answersList.length).fill(false))
				setDownvotes(new Array(answersList.length).fill(false))

			}

			setLoadMore(false)
			setPagination(paginationData)
			setQuestionTitle(details?.title)
			setQuestionId(details?.questionId)
			dispatch(beforeCommunity())

		}
	}, [communtitySelector.getQuestionDetailsAuth])

	const onEditorChange = (event, editor) => {
		let data = editor.getData();
		setAnswerData({ ...answerData, answer: data })
	}

	const editAnswerHandler = () => {
		let errorObject = {}

		if (!answerData.answer)
			errorObject.title = t('common.fieldRequired')

		setErrors({ ...errorObject })

		if (Object.keys(errors)?.length)
			return

		setLoader(true)
		dispatch(editAnswer({ answer: answerData.answer, answerId: answerData._id, lng: currentLanguage }))
	}

	useEffect(() => {
		if (communtitySelector.editAnswerAuth) {
			setLoader(false)
			setShow(false)
			setAnswerData(null)
			dispatch(getQuestionDetails(questionId, `lng=${currentLanguage}`))
		}
	}, [communtitySelector.editAnswerAuth])

	useEffect(() => {
		if (communtitySelector.deleteAnswerAuth) {
			setLoader(false)
			dispatch(getQuestionDetails(questionId, `lng=${currentLanguage}`))
		}
	}, [communtitySelector.deleteAnswerAuth])

	const setShowMoreHandler = (index, val) => {
		let copy = [...showMore]
		copy[index] = val
		setShowMore([...copy])
	}

	const deleteAnswerHandler = (id) => {
		Swal.fire({
			title: t('community.deleteAnswer'),
			html: t('community.deleteAnsConfirm'),
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: t('common.yes'),
			cancelButtonText: t('common.no')
		}).then(async (result) => {
			if (result.value) {
				setLoader(true)
				dispatch(deleteAnswer(id, `lng=${currentLanguage}`))
			}
		})
	}

	const answersVotingHandler = ({ _id, userUpvoted, userDownvoted }, type, value, index) => {

		if (type === 1 && userUpvoted)
			value = false

		if (type === 2 && userDownvoted)
			value = false

		if (type === 1 && (downvotes[index] || userDownvoted))
			return

		if (type === 2 && (upvotes[index] || userUpvoted))
			return

		if (type === 1) {
			let copy = [...upvotes]
			copy[index] = value
			setUpvotes([...copy])
		}
		else if (type === 2) {
			let copy = [...downvotes]
			copy[index] = value
			setDownvotes([...copy])
		}
		dispatch(answerUpDownVote({ id: _id, type, isRemoved: !value, lng: currentLanguage }))
	}

	useEffect(() => {
		if (communtitySelector.answerVoteAuth) {
			let { answerId, totalVotes, type, userVoted } = communtitySelector.answerVoteData?.data
			if (answerId) {
				let answersCopy = [...answers]
				answersCopy = answersCopy.map((q) => {
					if (q._id === answerId)
						return { ...q, [type === 1 ? 'totalUpvotes' : 'totalDownvotes']: { total: totalVotes }, [type === 1 ? 'userUpvoted' : 'userDownvoted']: userVoted }
					else
						return q
				})
				setAnswers([...answersCopy])
			}

			dispatch(beforeCommunity())

		}
	}, [communtitySelector.answerVoteAuth])

	const loadMoreAnswers = () => {
		setLoadMore(true)
		setLoader(true)

		const { page } = pagination
		dispatch(getQuestionDetails(questionId, `page=${page + 1}&lng=${currentLanguage}`))
	}

	return (
		loader ? <FullPageLoader /> :
			<div>
				<Container>
					{
						answers?.length ?
							answers.map((answer, index) => (
								<section id='community-post' key={index}>
									<div className='community-posts-wrapper mx-auto'>
										<div className='community-posts'>
											<div className='community-user-details-wrapper d-flex align-items-center'>
												<div className='community-author-img'>
													<img src={answer.author.profileImage} alt='' className='img-fluid' />
												</div>
												<div className='comunity-author-nameDetails'>
													<h6 className='community-name-follow'>
														{answer.author.name}
													</h6>
													<div className='experience'>
														<span>{answer.author.designation}</span>
														<span>{moment(answer.createdAt).format('DD MMM, YYYY')}</span>
													</div>
												</div>
											</div>
											<div className='post-heading-discription'>
												<div dangerouslySetInnerHTML={{ __html: showMore[index] ? answer.answer : answer.answer.length > 100 ? `${answer.answer.slice(0, 20)}...` : answer.answer }}></div>
												<div className='show-more-less-btn-holder'>
													{
														showMore[index] ?
															<button className='btn btn-primary d-inline-block' onClick={() => setShowMoreHandler(index, false)}>{t('common.showMore')}</button> :
															answer.answer.length > 100 &&
															<button className='btn btn-primary d-inline-block' onClick={() => setShowMoreHandler(index, true)}>{t('common.showLess')}</button>
													}
												</div>
											</div>
										</div>
										<div className='community-posts'>
											<div className='votes-btn-wrapper d-flex justify-content-between'>
												<div>
													<button disabled={answer?.author?._id === userId} className={`d-inline-block`} style={{ backgroundColor: upvotes[index] === true || answer?.userUpvoted ? '#FD6F21' : 'transparent', color: upvotes[index] === true || answer?.userUpvoted ? '#fff' : '#FD6F21', cursor: answer?.author?._id === userId ? 'not-allowed' : 'pointer' }} onClick={() => answersVotingHandler(answer, 1, upvotes[index] ? false : true, index)}> <FontAwesomeIcon icon={faArrowUp} /><span className={`d-inline-block ms-2`}>{answer?.totalUpvotes?.total || ''}</span></button>

													<button disabled={answer?.author?._id === userId} className={`d-inline-block`} style={{ backgroundColor: downvotes[index] === true || answer?.userDownvoted ? '#FD6F21' : 'transparent', color: downvotes[index] === true || answer?.userDownvoted ? '#fff' : '#FD6F21', cursor: answer?.author?._id === userId ? 'not-allowed' : 'pointer' }} onClick={() => answersVotingHandler(answer, 2, downvotes[index] ? false : true, index)}> <FontAwesomeIcon icon={faArrowDown} /><span className={`d-inline-block ms-2`}>{answer?.totalDownvotes?.total || ''}</span></button>

												</div>
												<div>
													<button className='btn btn-primary d-inline-block theme-btn orange-btn' onClick={() => { setAnswerData(answer); setShow(true) }}> <FontAwesomeIcon icon={faEdit} /></button>
													{answer.author?._id === userId && <button className='btn btn-primary d-inline-block theme-btn orange-btn' onClick={() => deleteAnswerHandler(answer._id)}><FontAwesomeIcon icon={faTrash} /></button>}
												</div>
											</div>
										</div>
									</div>
								</section>
							)) : null
					}
					{
						answerData &&
						<Modal className='ask-answer-modal' show={show} onHide={() => setShow(false)} centered>
							<Modal.Header closeButton>
								<Modal.Title>
									<h3>{questionTitle}</h3>
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form className='Community-answer-form'>
									<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										<Form.Label>{t('community.writeAnswer')}</Form.Label>
										<CKEditor
											id="blogpost-editor"
											editor={ClassicEditor}
											config={ckEditorCustomConfig}
											data={answerData.answer || ''}
											content={answerData.answer || ''}
											onChange={(event, editor) => onEditorChange(event, editor)}
										/>
										{
											errors && errors.answer &&
											<span className='text-danger'>{errors.answer}</span>
										}
									</Form.Group>
								</Form>
							</Modal.Body>
							<Modal.Footer>
								<div className='answer-modal-footer	d-flex align-items-center justify-content-between w-100'>
									<Button className='orange-main-button text-center' variant="primary" onClick={editAnswerHandler}>
										{t('community.editAnswer')}
									</Button>
								</div>
							</Modal.Footer>
						</Modal>
					}
				</Container>
				{(pagination.total > answers?.length) ?
					(<div className='text-center w-100 d-block'>
						<button className="orange-btn load-more-btn text-uppercase mx-auto" onClick={loadMoreAnswers}>{t('common.loadMoreBtn')}</button>
					</div>)
					:
					null}
			</div >
	)
}

export default CommunityAnswers