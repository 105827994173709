import { toast } from 'react-toastify';
import { ENV } from '../../../config/config';
import { GET_ERRORS, INVITE, BEFORE_INVITE, INVITE_DETAILS, USER_SIGNUP, GET_CHANNEL_USERS, DELETE_CHANNEL_USER } from '../../../redux/types';
import { emptyError } from '../../../redux/shared/error/error.action';

export const beforeInvite = () => {
    return {
        type: BEFORE_INVITE
    }
}

export const inviteUser = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}invite/member`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)

    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: "signup-success" })
            dispatch({
                type: INVITE,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "signup-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getInviteDetails = (payload, qs = '') => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}invite/invite-user/${payload}`;
    if (qs) url += `?${qs}`
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            if (data.message)
                toast.success(data.message)
            dispatch({
                type: INVITE_DETAILS,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "signup-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getChannelUsers = (qs = '') => dispatch => {
    dispatch(emptyError());
    const accessToken = ENV.getUserKeys('accessToken')?.accessToken
    let url = `${ENV.url}invite/channel-users`;
    if (qs)
        url = `${url}?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_CHANNEL_USERS,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "signup-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};


export const inviteSignup = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}invite/register/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)

    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: "signup-success" })
            dispatch({
                type: USER_SIGNUP,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "signup-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};


export const deleteUser = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}invite/delete`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)

    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: "signup-success" })
            dispatch({
                type: DELETE_CHANNEL_USER,
            })
        } else {
            toast.error(data.message, { toastId: "signup-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};
