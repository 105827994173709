import { toast } from 'react-toastify';
import { emptyError } from '../../../redux/shared/error/error.action';
import { ENV } from '../../../config/config';
import { BEFORE_TOUR, EDIT_TOUR_STATUS, GET_ERRORS, GET_TOUR_STATUS } from '../../../redux/types';

export const beforeTourStatus = () => {
    return {
        type: BEFORE_TOUR
    }
}

export const getUserTourStatus = (userId, qs) => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}app-tour/get/${userId}`;
    if(qs)
        url += `?${qs}`
        
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_TOUR_STATUS,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: "tour-status-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const upsertUserTourStatus = (payload) => dispatch => {
    toast.dismiss();
    dispatch(emptyError());

    let url = `${ENV.url}app-tour/upsert`;

    fetch(url, {
        method: 'PUT',  
        headers: {
            'content-type' : 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: EDIT_TOUR_STATUS,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: "tour-status-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
