import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import BasicInfo from './BasicInfo'
import AdvanceSettings from './AdvanceSettings'
import SmallLoader from '../../../../../utils/SmallLoader/SmallLoader'
import { useTranslation } from 'react-i18next'
import '../../Models/Models.css'


const Channel = (props) => {
    const { t } = useTranslation()
    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabs" >
                    {
                        <Tabs activeKey={props.channelKey} onSelect={(k) => props.setKey(k == '0' ? 'channel-basic' : 'channel-advance')}>
                            <TabList >
                                <Tab eventKey={'channel-basic'}>
                                    {t('settings.basicInfo')}
                                </Tab>
                                <Tab eventKey={'channel-advance'}>
                                    {t('settings.advanceSettings')}
                                </Tab>
                            </TabList>
                            <>
                                <TabPanel>
                                    <BasicInfo
                                        country={props.channelDetails.countryResidence}
                                        keywords={props.channelDetails.keywords}
                                        aboutChannel={props.channelDetails.aboutChannel}
                                        setData={props.setChannelData}
                                        errors={props.errors}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <AdvanceSettings
                                        audience={props.channelDetails.audience}
                                        setData={props.setChannelData}
                                        setLoader={props.setLoader}
                                    />
                                </TabPanel>
                            </>
                        </Tabs>
                    }

                </div>
            </div>
        </>
    )
}
export default Channel