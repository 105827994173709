import React from "react";
import { ENV } from "../../../config/config";
class uploadAdapter {
    constructor(props) {
        this.loader = props;
        // URL where to send files.
        this.url = `${ENV.url}ck-editor/upload`;

    }

    // Starts the upload process.
    upload() {
        return new Promise((resolve, reject) => {
            this.initRequest();
            this.initListeners(resolve, reject);
            this.sendRequest();
        });
    }
    // Aborts the upload process.
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    // Example implementation using XMLHttpRequest.
    initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open('POST', this.url, true);
        xhr.responseType = 'json';
        xhr.setRequestHeader('Authorization', ENV.Authorization)
        xhr.setRequestHeader('x-auth-token', ENV.x_auth_token)
    }

    // Initializes XMLHttpRequest listeners.
    initListeners(resolve, reject) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = 'Couldn\'t upload file:' + ` ${loader.file.name}.`;

        xhr.addEventListener('error', () => reject(genericErrorText));
        xhr.addEventListener('abort', () => reject());
        xhr.addEventListener('load', () => {
            const response = xhr.response;

            if (!response || response.error) {
                return reject(response && response.error ? response.error.message : genericErrorText);
            }

            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            resolve({
                default: `${ENV.assetUrl}${response.imageData.filename}`
            });
        });

        if (xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    // Prepares the data and sends the request.
    sendRequest() {
        const data = new FormData();

        this.loader.file.then(result => {
            data.append('files', result);
            this.xhr.send(data);
        }
        )
    }
}

export function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new uploadAdapter(loader)
    }
}