import React from 'react'
import { Form } from 'react-bootstrap'
import { TiArrowSortedDown } from "react-icons/ti"
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import '../../Models/Models.css'

const UploadBasicInfo = (props) => {
    const { t } = useTranslation()

    const onChangeHandler = (e) => {
        props.setData({ [e.target.name]: e.target.value })
    }
    
    const options = [
        { value: '1', label: t('myVideos.visibilityTypes.private') },
        { value: '2', label: t('myVideos.visibilityTypes.unlisted') },
        { value: '3', label: t('myVideos.visibilityTypes.public') },
        { value: '4', label: t('myVideos.visibilityTypes.draft') }
    ];
    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div>
                        <div className='general mb-0'>
                            <div className='fileGroup'>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>{t('settings.uploadDefault.basicInfo.title')}</p>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('settings.uploadDefault.basicInfo.titlePlaceholder')}
                                        value={props.title || ''}
                                        name="videoTitle"
                                        onChange={onChangeHandler}
                                    >
                                    </Form.Control>
                                </div>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>{t('settings.uploadDefault.basicInfo.description')}</p>
                                    <Form.Group
                                        className='w-100'
                                        as="textarea"
                                        name="videoDescription"
                                        value={props.description || ''}
                                        rows={3}
                                        placeholder={t('settings.uploadDefault.basicInfo.descPlaceholder')}
                                        onChange={onChangeHandler}>
                                    </Form.Group>
                                </div>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>{t('settings.uploadDefault.basicInfo.visibility')}</p>
                                    <div className='settings-select-holder'>
                                        <div className='modalSelect mb-2'>
                                            <div className="custom-react-select flex-fill">
                                                {options && options.length ?
                                                    <Select
                                                        options={options}
                                                        name="videoVisibility"
                                                        value={options && options.length > 0 ? options.find(option => option.value === props.visibility) : null}
                                                        // onChange={onChangeHandler}
                                                        onChange={(e) => props.setData({ visibility: e.value })}
                                                        classNamePrefix="triage-select"
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>{t('settings.uploadDefault.basicInfo.tags')}</p>
                                    <Form.Group
                                        className='w-100'
                                        as="textarea"
                                        name="videoTags"
                                        rows={3}
                                        value={props.tags || ''}
                                        onChange={onChangeHandler}
                                        placeholder='#myVideo, #biiview'>
                                    </Form.Group>
                                </div>
                                {
                                    props.errors && props.errors.videoTags &&
                                    <span className='submit-errors text-danger'>{props.errors.videoTags}</span>
                                }
                                <p>{t('common.commaSeparated')}</p>
                                <p>{t('settings.uploadDefault.basicInfo.note')}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UploadBasicInfo 