import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import $ from "jquery";
import { ENV } from "../../../../config/config";
import { getVideoMetadata, beforeVideoMetadata } from "../../Player/player.action";
import VideoJS from "../../Player/VideoJS";
import { Rnd } from "react-rnd";
import { editAnnotation, beforeEditAnnotation } from "./video.actions";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { AiFillCaretLeft, AiFillCaretRight, AiFillEdit, AiFillForward, AiFillBackward } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'
import { useTranslation } from "react-i18next";

const UpdateVideoAnnotation = (props) => {
  const { t } = useTranslation()
  const [metadata, setMetadata] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);
  const [metadataDummy, setMetadataDummy] = useState(null);

  const [indexNumber, setIndexNumber] = useState(0);
  const [frameNumber, setFrameNumber] = useState(null);
  const [data, setData] = useState(null)
  const [videoOptions, setVideoOptions] = useState({
    autoplay: true,
    controls: false,
    loop: false,
    responsive: true,
    metadata: true,
    sources: []
  })
  const [updatedFrameObjects, setUpdatedObjects] = useState({}) // frame details with converted dimensions
  const [frameMetadata, setFrameMetadata] = useState(null) // inital frame details
  const [updatedMetadata, setUpdated] = useState({}) // formatted frame details to update in metadata
  const [objectId, setId] = useState('') // id of object in current frame
  const [videoDimensions, setVidDimensions] = useState({}) // video width & height
  const [showDelIcon, setShowDel] = useState(false)
  const [showEditField, setShowEdit] = useState(false)
  const [showEditIcon, setShowEditIcon] = useState(false)
  const [vidForwardSec, setSeconds] = useState('')
  const [vidDuration, setDuration] = useState('')
  const [errors, setErrors] = useState({})
  const [isVideoReady, setVidReady] = useState(false)
  const [allLinksUpdated, setLinksUpdated] = useState(false)
  const [allDescUpdated, setDescUpdated] = useState(false)
  const [allPreviewImgsUpdated, setPreviewImgUpdated] = useState(false)
  const [allTitleUpdated, setTitleUpdated] = useState(false)
  const [frameTitle, setFrameTitle] = useState('')
  const currentLanguage = localStorage.getItem('selectedLang')

  let playerRef = React.useRef(null);

  useEffect(() => {
    if (isVideoReady && playerRef.current) {
      playerRef.current?.pause()
      props.setLoader(false)
    }

  }, [isVideoReady])

  useEffect(() => {
    if (props.video.getVideoMetadataAuth) {
      let videoMetadata = props.video.getVideoMetadata.metadata["meta-data"]
      let title = props.video.getVideoMetadata.metadata["video-title"]

      if (Object.keys(videoMetadata)?.length) {
        title = title?.split(".")[0]
        let frameValue = Object.keys(videoMetadata)[0]

        setVideoTitle(title);
        setMetadata(videoMetadata);
        setMetadataDummy(videoMetadata)
        setVideoOptions({
          ...videoOptions,
          sources: [{
            src: `${ENV.streamingUrl}${title}/output.m3u8`,
            type: 'application/x-mpegURL'
          }]
        })
        setFrameNumber(frameValue)
        const initialObjects = videoMetadata[0] ? videoMetadata[0]["objects"] : []
        setFrameMetadata({ ...frameMetadata, frame: frameValue, objects: [...initialObjects] })

      }
      props.beforeVideoMetadata()
    }
  }, [props.video.getVideoMetadataAuth]);


  useEffect(() => {
    if (props.annotation.editAnnotationAuth) {
      props.setLoader(false)
      props.beforeEditAnnotation()
      handleClose()
    }
  }, [props.annotation.editAnnotationAuth])

  useEffect(() => {
    let videoELem = $("video")[0];
    if (videoELem) {
      if (videoELem.readyState >= 2 && !isVideoReady) {
        setVidReady(true)
      }
    }
  })

  // calculate dimensions on window resizing
  useEffect(() => {
    window.onresize = function () {

      let videoELem = $("video")[0];
      if (videoELem && videoELem.src) {

        const width = videoELem.videoWidth
        const height = videoELem.videoHeight

        setVidDimensions({
          width,
          height
        })
      }
    }
  }, [])

  useEffect(() => {
    if (frameNumber !== null && isVideoReady) {

      let timeToSet = frameNumber / 1000;
      playerRef.current.currentTime(timeToSet);

    }
  }, [isVideoReady, frameNumber])

  useEffect(() => {
    if (videoOptions?.sources[0]?.src && Object.keys(videoDimensions)?.length) {
      calculateDimensions()
    }

  }, [videoOptions, videoDimensions]);

  useEffect(() => {
    if (data && frameMetadata) {
      getCurrentFrameObjects()
    }

  }, [data, frameMetadata])

  const calculateDimensions = () => {

    let playerWidth = ''
    let playerHeight = ''

    playerWidth = $("video-js").width();
    playerHeight = $("video-js").height();

    let rendered_height = playerHeight
    const videoWidth = videoDimensions.width;
    const videoHeight = videoDimensions.height;

    let heightDifference = videoHeight - rendered_height;
    let calRenderedWidth = 0

    if (heightDifference < 0)
      heightDifference *= -1;

    let percentChangeInHeight = (heightDifference / videoHeight) * 100;

    if (rendered_height > videoHeight) {
      calRenderedWidth = videoWidth + (percentChangeInHeight * videoWidth) / 100;
    }
    else {
      calRenderedWidth = videoWidth - (percentChangeInHeight * videoWidth) / 100;
    }

    let verticalBlanckSpaceEachSide = (playerWidth - calRenderedWidth) / 2;

    setData({
      renderedWidth: calRenderedWidth,
      renderedHeight: rendered_height,
      offsetX: verticalBlanckSpaceEachSide,
      offsetY: 0
    })

  }

  const getCurrentFrameObjects = async () => {

    let objects = [...frameMetadata.objects]
    let frame = frameMetadata.frame

    if (objects?.length) {

      let frameObjects = []

      for (let i = 0; i < objects.length; i++) {
        let x = objects[i].x;
        let y = objects[i].y;

        let w = objects[i].w;
        let h = objects[i].h;


        let { elemWidth, elemHeight, startX, startY } = getObjectData({ x, y, w, h })

        let obj = { id: objects[i].id, la: objects[i].la, elemWidth, elemHeight, startX, startY }
        if (objects[i].ds)
          obj.ds = objects[i].ds
        if (objects[i].lk)
          obj.lk = objects[i].lk
        if (objects[i].im)
          obj.im = objects[i].im

        frameObjects.push({ ...obj })

      }

      setUpdatedObjects({ frame, objects: [...frameObjects] })

    }
    else {
      setUpdatedObjects({ frame, objects: [] })
    }
  }

  const getObjectData = (payload, type = 1) => {

    let { renderedWidth, renderedHeight, offsetX, offsetY } = data
    let returnObj = {}

    if (type === 1) {

      payload.x = parseFloat(payload.x)
      payload.y = parseFloat(payload.y)
      payload.w = parseFloat(payload.w)
      payload.h = parseFloat(payload.h)

      let startX = parseFloat((renderedWidth * payload.x) + offsetX).toFixed(3)
      let startY = parseFloat((renderedHeight * payload.y) + offsetY).toFixed(3);


      let endX = (renderedWidth * payload.w) + offsetX
      let endY = (renderedHeight * payload.h) + offsetY

      let elemWidth = parseFloat(endX - startX).toFixed(3)
      let elemHeight = parseFloat(endY - startY).toFixed(3)

      returnObj = { elemWidth, elemHeight, startX, startY }

    }
    else if (type === 2) {

      payload.startX = parseFloat(payload.startX)
      payload.startY = parseFloat(payload.startY)
      payload.elemWidth = parseFloat(payload.elemWidth)
      payload.elemHeight = parseFloat(payload.elemHeight)

      let x = parseFloat((payload.startX - offsetX) / renderedWidth).toFixed(3)
      let y = parseFloat((payload.startY - offsetY) / renderedHeight).toFixed(3)


      let endX = payload.elemWidth + payload.startX
      let endY = payload.elemHeight + payload.startY

      let w = parseFloat((endX - offsetX) / renderedWidth).toFixed(3)
      let h = parseFloat((endY - offsetY) / renderedHeight).toFixed(3)

      returnObj = { w, h, x, y }

    }

    return returnObj
  }

  const handlePrevButton = (value = '', frameIndex = '') => {

    if (errors && errors.link)
      return

    resetStates()

    // save changes in metadata if any
    if (updatedMetadata[frameNumber]) {
      let meta_data = { ...metadata }
      meta_data[frameNumber] = { 'key-frame': updatedMetadata[frameNumber]['key-frame'], objects: [...updatedMetadata[frameNumber].objects] }
      setMetadata(meta_data)
    }

    let frameValue = value ? value : Object.keys(metadata)[indexNumber - 1]
    if (frameValue) {
      let objects = updatedMetadata[frameValue] ? updatedMetadata[frameValue]?.objects : [...metadata[frameValue]["objects"]]

      setFrameNumber(frameValue)
      setFrameMetadata({ ...frameMetadata, frame: frameValue, objects: [...objects] })
      setIndexNumber(frameIndex ? frameIndex : indexNumber - 1)
    }
  }

  const handleNextButton = (value = '', frameIndex = '') => {

    if (errors && errors.link)
      return

    resetStates()

    // save changes in metadata if any
    if (updatedMetadata[frameNumber]) {
      let meta_data = { ...metadata }
      meta_data[frameNumber] = { 'key-frame': updatedMetadata[frameNumber]['key-frame'], objects: [...updatedMetadata[frameNumber].objects] }
      setMetadata(meta_data)
    }

    let frameValue = value ? value : Object.keys(metadata)[indexNumber + 1]
    if (frameValue) {
      let objects = updatedMetadata[frameValue] ? updatedMetadata[frameValue]?.objects : [...metadata[frameValue]["objects"]]

      setFrameNumber(frameValue)
      setFrameMetadata({ ...frameMetadata, frame: frameValue, objects: [...objects] })
      setIndexNumber(frameIndex ? frameIndex : indexNumber + 1)
    }
  }

  const resetStates = () => {
    setId('')
    setShowDel(false)
    setShowEditIcon(false)
    setShowEdit(false)
    setErrors({})
    setSeconds('')
  }

  const forwardRewindVideo = (type) => {

    if (vidForwardSec) {
      let seconds = parseFloat(vidForwardSec)
      let currentTime = frameNumber / 1000

      if ((seconds < 0)) {
        seconds = 0
      }

      if ((seconds > vidDuration) && type === 1) {
        let diff = vidDuration - currentTime
        seconds = Number(parseFloat(diff).toFixed(3))
      }

      setSeconds(seconds)

      if (seconds > 0) {
        let calculatedFrame = (type === 1 ? (currentTime + seconds) : (currentTime - seconds)) * 1000
        let nearestFrame = Object.keys(metadata).reduce((prev, curr) => Math.abs(curr - calculatedFrame) < Math.abs(prev - calculatedFrame) ? curr : prev);
        let frameIndex = Object.keys(metadata).indexOf(nearestFrame.toString())

        if (type === 1)
          handleNextButton(nearestFrame, frameIndex)
        else if (type === 2)
          handlePrevButton(nearestFrame, frameIndex)

      }
    }
  }

  const handleClose = () => {

    setData({})
    setMetadata(null)
    setFrameNumber(null)
    setIndexNumber(0)
    setVidReady(false)
    setUpdated({})

    props.setShow(false);

  };

  // change selected object title 
  const onChangeHandler = (e) => {
    let name = e.target.name
    let value = e.target.value

    if ((name === 'lk' || name === 'im') && value) {
      if (value) {
        let validUrl = isValidUrl(value)
        if (!validUrl)
          setErrors({ ...errors, [name === 'lk' ? 'link' : 'image']: t('editAnnotation.invalidLink') })
        else
          setErrors({ ...errors, [name === 'lk' ? 'link' : 'image']: '' })
      }
      else
        setErrors({ ...errors, [name === 'lk' ? 'link' : 'image']: '' })
    }

    let framesObject = [...updatedFrameObjects.objects]
    let object = framesObject[objectId]
    object[name] = value
    framesObject[objectId] = object

    setUpdatedObjects({ ...updatedFrameObjects, objects: [...framesObject] })

    let updateObject = {}
    if (updatedMetadata && updatedMetadata[frameNumber]?.objects?.length) {
      framesObject = [...updatedMetadata[frameNumber].objects]
      let object = framesObject[objectId]
      object[name] = value
      framesObject[objectId] = object
      updateObject = {
        ...updatedMetadata,
        [frameNumber]: {
          ...updatedMetadata[frameNumber],
          objects: [...framesObject]
        }
      }
    }
    else {
      let data = getUpdatedFramesData(framesObject)
      updateObject = {
        ...updatedMetadata,
        [frameNumber]: {
          'key-frame': data.keyFrame,
          objects: [...data.frameObjects]
        }
      }
    }

    setUpdated({ ...updateObject })
    setMetadataDummy({ ...metadataDummy, [frameNumber]: { 'key-frame': updateObject[frameNumber]['key-frame'], objects: [...updateObject[frameNumber]?.objects] } })
  }


  const getUpdatedFramesData = (frameObjects) => {
    let keyFrame = metadata[updatedFrameObjects.frame]['key-frame']
    let returnObj = {}

    frameObjects = frameObjects.map((obj) => {
      let values = getObjectData({ startX: obj.startX, startY: obj.startY, elemWidth: obj.elemWidth, elemHeight: obj.elemHeight }, 2)
      returnObj = { id: obj.id, la: obj.la, x: values.x, y: values.y, w: values.w, h: values.h }
      if (obj.ds)
        returnObj.ds = obj.ds
      if (obj.lk)
        returnObj.lk = obj.lk
      if (obj.im)
        returnObj.im = obj.im

      return returnObj
    })

    return { frameObjects, keyFrame }
  }

  const saveDetails = () => {

    if (updatedMetadata && Object.keys(updatedMetadata).length) {
      let payload = {
        updatedFrames: updatedMetadata,
        videoId: videoTitle.split('.')[0],
        lng: currentLanguage
      }
      props.editAnnotation(props.videoId, payload)
      props.setLoader(true)
    }
    else
      toast.info(t('editAnnotation.noChanges'))
  }

  const handleDragEnd = async (e, positions, id) => {

    let { x, y } = positions

    let frameObjects = [...updatedFrameObjects.objects]
    let object = { ...frameObjects[id] }
    object.startX = x
    object.startY = y
    frameObjects[id] = object
    setUpdatedObjects({ ...updatedFrameObjects, objects: [...frameObjects] })

    let converted = getUpdatedFramesData(frameObjects)
    setUpdated({ ...updatedMetadata, [updatedFrameObjects.frame]: { 'key-frame': converted.keyFrame, objects: [...converted.frameObjects] } })

  }


  const handleResizeStop = (ref, position, id) => {

    let { width, height } = ref.style
    let { x, y } = position

    let frameObjects = [...updatedFrameObjects.objects]
    let object = { ...frameObjects[id] }
    object.startX = x
    object.startY = y
    object.elemWidth = parseFloat(width)
    object.elemHeight = parseFloat(height)
    frameObjects[id] = object

    setUpdatedObjects({ ...updatedFrameObjects, objects: [...frameObjects] })

    frameObjects[id] = object
    let converted = getUpdatedFramesData(frameObjects)
    setUpdated({ ...updatedMetadata, [updatedFrameObjects.frame]: { 'key-frame': converted.keyFrame, objects: [...converted.frameObjects] } })
  }

  const handlePlayerReady = (player) => {

    playerRef.current = player;

    player.on("loadedmetadata", function (e) {
      setVidDimensions({
        height: player.videoHeight(),
        width: player.videoWidth()
      })
    })

    player.on('timeupdate', () => {
      setDuration(playerRef.current.duration())
    });

  }

  const deleteObjectHanlder = () => {

    Swal.fire({
      title: t('editAnnotation.deleteObject'),
      html: t('editAnnotation.deleteObjectMsg'),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('editAnnotation.deleteOne'),
      cancelButtonText: t('editAnnotation.deleteAll')
    }).then(async (result) => {
      if (result.value) { // delete one occurence
        let filteredObjects = []

        // remove from current frame
        if (updatedFrameObjects?.objects) {
          filteredObjects = updatedFrameObjects.objects.filter((o, ind) => o.id != objectId).map((ob, ind) => { return { ...ob, id: ind.toString() } })
          setUpdatedObjects({ ...updatedFrameObjects, objects: [...filteredObjects] })

        }

        // remove from updated metadata object ( if found ) else set in updated metadata objects
        if (updatedMetadata[frameNumber]?.objects) {
          filteredObjects = updatedMetadata[frameNumber]?.objects.filter((o) => o.id != objectId).map((ob, ind) => { return { ...ob, id: ind.toString() } })
          setUpdated({ ...updatedMetadata, [frameNumber]: { ...updatedMetadata[frameNumber], objects: [...filteredObjects] } })
        }
        else {
          let frameObjects = [...filteredObjects]
          let data = getUpdatedFramesData(frameObjects)
          setUpdated({ ...updatedMetadata, [updatedFrameObjects.frame]: { 'key-frame': data.keyFrame, objects: [...data.frameObjects] } })
        }

        resetStates()
      }
      else { // delete all occurences
        let newObjectsInFrame = {}
        Object.keys(metadataDummy).map((frame) => {
          let filteredObjects = metadataDummy[frame].objects.filter((fO) => fO.id !== objectId)
          newObjectsInFrame = { ...newObjectsInFrame, [frame]: { 'key-frame': metadataDummy[frame]['key-frame'], objects: [...filteredObjects] } }
        })

        if (Object.keys(newObjectsInFrame)?.length) {
          setUpdated({ ...newObjectsInFrame })
          setMetadataDummy({ ...newObjectsInFrame })
          let updatedFrame = updatedFrameObjects.objects?.filter((frameObjs) => frameObjs.id !== objectId)
          setUpdatedObjects({ ...updatedFrameObjects, objects: [...updatedFrame] })

        }

        resetStates()
      }
    })

  }

  const secondsChangeHandler = (e) => {
    let value = e.target.value
    let err = ""

    if (value) {
      if (value < 0)
        err = t('editAnnotation.time')
      else
        err = ""
    }

    setErrors({ ...errors, seconds: err })
    setSeconds(value)

  }

  const isValidUrl = (url) => {
    // url = url.trim()
    if (url.includes('www')) {
      let value = url.split('www.')[1]
      return isValidUrl(value ? value : '')
    }
    var res = url.match(/^(http(s)?:\/\/)?(www.)?([a-zA-Z0-9])+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/[^\s]*)?$/gm);
    if (res == null)
      return false;
    else
      return true;
  }

  const updateAllHandler = (type) => {
    let newFrameObjects = {}

    Object.keys(metadataDummy).map((m) => {
      let frameObjects = metadataDummy[m].objects
      let isObjectPresent = frameObjects.findIndex((o) => (o.id === objectId) || (o.id === updatedFrameObjects.objects[objectId]?.id))

      if (isObjectPresent > -1) {
        return { index: isObjectPresent, frame: m, ...metadataDummy[m] }
      }
    }).filter((objs) => {
      if (objs)
        return objs
    })
      .map((filteredObjs) => {
        let objs = updatedMetadata[filteredObjs.frame] ? [...updatedMetadata[filteredObjs.frame].objects] : [...filteredObjs.objects]
        let obj = {}
        if (type === 0)
          obj = { ...objs[filteredObjs.index], la: updatedFrameObjects.objects[objectId]?.la }
        if (type === 1)
          obj = { ...objs[filteredObjs.index], lk: updatedFrameObjects.objects[objectId]?.lk }
        else if (type === 2)
          obj = { ...objs[filteredObjs.index], ds: updatedFrameObjects.objects[objectId]?.ds }
        else if (type === 3)
          obj = { ...objs[filteredObjs.index], im: updatedFrameObjects.objects[objectId]?.im }

        objs[filteredObjs.index] = obj
        newFrameObjects = { ...newFrameObjects, [filteredObjs.frame]: { 'key-frame': filteredObjs['key-frame'], objects: [...objs] } }

        return newFrameObjects
      })

    if (Object.keys(newFrameObjects)?.length) {

      setUpdated({ ...newFrameObjects })
      setTimeout(() => type === 0 ? setTitleUpdated(true) : type === 1 ? setLinksUpdated(true) : type === 2 ? setDescUpdated(true) : setPreviewImgUpdated(true), 500)

      let newMetadataDummy = { ...metadataDummy }

      for (let key in newFrameObjects) {
        for (let mKey in metadataDummy) {
          if (key === mKey)
            newMetadataDummy = { ...newMetadataDummy, [mKey]: { ...newFrameObjects[mKey] } }
        }

      }

      setMetadataDummy({ ...newMetadataDummy })
    }
    else
      toast.error(t('editAnnotation.objectsNotFound'))

  }

  const closeModalHandler = () => {
    if (Object.keys(updatedMetadata)?.length) {
      Swal.fire({
        title: t('editAnnotation.saveChanges'),
        html: t('editAnnotation.saveChangesMessage'),
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('common.saveBtn'),
        cancelButtonText: t('common.cancelButtonText')
      }).then(async (result) => {
        if (result.value) {
          saveDetails()
        }
        else {
          handleClose()
        }
      })
    }
    else {
      handleClose()
    }
  }


  useEffect(() => {
    if (allLinksUpdated)
      setTimeout(() => setLinksUpdated(false), 1000)

    if (allDescUpdated)
      setTimeout(() => setDescUpdated(false), 1000)

    if (allTitleUpdated)
      setTimeout(() => setTitleUpdated(false), 1000)

    if (allPreviewImgsUpdated)
      setTimeout(() => setPreviewImgUpdated(false), 1000)


  }, [allLinksUpdated, allDescUpdated, allTitleUpdated, allPreviewImgsUpdated])


  return (
    <>
      <div className="modalPage">
        <div className="modal themeModal show">
          <Modal
            show={props.show}
            onHide={closeModalHandler}
            className="medium-Model video-modal"
            centered
          >
            <div className="animatedTab">
              <Modal.Header closeButton>
                <Modal.Title>{t('editAnnotation.videoTime')} {frameNumber / 1000} {t('editAnnotation.seconds')}</Modal.Title>
              </Modal.Header>
              <div className="videoModal">
                {
                  isVideoReady ?
                    <Row className="text-white video-dragger-holder position-relative">
                      <Col className="d-flex align-items-center mb-3 ">
                        <label className="mb-0"><span className="text-white">{t('editAnnotation.seconds')}</span></label>
                        {
                          (frameNumber / 1000) > 0 &&
                          <span className="icon-holder cursor-pointer">
                            <AiFillBackward className={!vidForwardSec || vidForwardSec <= 0 ? 'disabled-icon' : ''} onClick={() => forwardRewindVideo(2)} />
                          </span>
                        }
                        <input className="form-control" type="number" value={vidForwardSec} onChange={secondsChangeHandler} placeholder="seconds"></input>
                        <span className="icon-holder cursor-pointer">
                          {Math.abs(vidDuration - (frameNumber / 1000)) > 0.1 &&
                            <AiFillForward className={!vidForwardSec || vidForwardSec <= 0 ? 'disabled-icon' : ''} onClick={() => forwardRewindVideo(1)} />
                          }
                        </span>
                        {
                          errors && errors.seconds ?
                            <span className="text-danger error-span">{errors.seconds}</span> :
                            null
                        }
                      </Col>
                    </Row> :
                    null
                }
                <Row className="">
                  <Col lg={12}>
                    <div className="d-flex justify-content-between align-items-center">

                      <div className="for-rev-icons">
                        {
                          metadata && isVideoReady ?
                            Object.keys(metadata).indexOf(frameNumber) != 0 &&
                            <AiFillCaretLeft className="cursor-pointer" onClick={() => { handlePrevButton() }} />
                            : null
                        }
                      </div>
                      <div id="custom-player" className="v-custom-player mb-4 mx-2">
                        {
                          isVideoReady && updatedFrameObjects && updatedFrameObjects.objects?.length ?
                            updatedFrameObjects.objects.map((d) => {
                              return (
                                <Rnd
                                  onClick={() => { setId(d.id); setShowDel(true); setShowEditIcon(true); setFrameTitle(frameMetadata.objects[d.id]?.la) }}
                                  id={d.id}
                                  bounds="parent"
                                  size={{ width: d.elemWidth > 0 ? parseFloat(d.elemWidth) : 0, height: d.elemHeight > 0 ? parseFloat(d.elemHeight) : 0 }}
                                  position={{ x: d.startX > 0 ? parseFloat(d.startX) : 0, y: d.startY > 0 ? parseFloat(d.startY) : 0 }}
                                  style={{
                                    // zIndex: 100,
                                    zIndex: Math.round(200 - ((parseFloat(frameMetadata?.objects[d.id]?.w) * 100) + (parseFloat(frameMetadata?.objects[d.id]?.h) * 100))),
                                    position: 'absolute',
                                    outline: d.id == objectId ? "green solid 2px" : "#dc3545 solid 2px",
                                  }}

                                  onDrag={(e, positions) => handleDragEnd(e, positions, d.id)}
                                  onResize={(e, direction, ref, delta, position, id) => handleResizeStop(ref, position, d.id)}
                                >
                                  <span style={{ position: "absolute", left: 0, top: 0, background: d.id == objectId ? "green" : "red", color: "#fff", fontWeight: "bold", fontSize: '14px' }}>{d.la}</span>
                                </Rnd>
                              )
                            })
                            : null
                        }
                        <div id="custom-video">
                          <VideoJS className="video-js " options={videoOptions} onReady={(player) => handlePlayerReady(player)} />
                        </div>
                      </div>

                      <div className="for-rev-icons">
                        {
                          metadata && isVideoReady ?
                            Object.keys(metadata).indexOf(frameNumber) != (Object.keys(metadata).length - 1) &&
                            <AiFillCaretRight className="cursor-pointer" onClick={() => { handleNextButton() }} />
                            : null
                        }
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4 flex-column px-4 edit-delete-icons">
                      <div className="d-flex justify-content-end align-items-center w-100">
                        {
                          showEditIcon ?
                            <span className="icon-holder cursor-pointer" onClick={() => setShowEdit(true)}>
                              <AiFillEdit />
                            </span> : null
                        }
                        {
                          showDelIcon ?
                            <span className="icon-holder cursor-pointer" onClick={() => deleteObjectHanlder()}>
                              <FaTrash />
                            </span> : null
                        }
                      </div>
                      <div className="mb-3 mb-sm-0 mr-sm-3 w-100 py-4">
                        {
                          showEditField ?
                            <>
                              <Form.Group className="mb-3">
                                <Form.Label className="d-block mb-2">{t('settings.uploadDefault.basicInfo.title')} </Form.Label>
                                <Form.Control type="text" name="la" onChange={onChangeHandler} value={objectId && updatedFrameObjects.objects[objectId]?.la ? updatedFrameObjects.objects[objectId].la : ''}></Form.Control>
                                {
                                  objectId && updatedFrameObjects.objects[objectId]?.la &&
                                  <Button className="orange-btn text-uppercase btn-filled" onClick={() => updateAllHandler(0)}>{t('editAnnotation.updateAll')}</Button>

                                }
                                {
                                  allTitleUpdated &&
                                  <span className="updated-span">{t('editAnnotation.updated')}</span>
                                }
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="d-block mb-2">{t('editAnnotation.link')}</Form.Label>
                                <Form.Control type="text" name="lk" onChange={onChangeHandler} value={objectId && updatedFrameObjects.objects[objectId]?.lk ? updatedFrameObjects.objects[objectId].lk : ''}></Form.Control>
                                {
                                  errors && errors.link ? <span className="pl-1 text-danger">{errors.link}</span> : null
                                }
                                <div className="update-all-btn-holder d-flex align-items-center">
                                  {
                                    !errors?.link && updatedFrameObjects.objects[objectId] &&
                                    <Button className="orange-btn text-uppercase btn-filled" onClick={() => updateAllHandler(1)}>{t('editAnnotation.updateAll')}</Button>
                                  }
                                  {
                                    allLinksUpdated &&
                                    <span className="updated-span">{t('editAnnotation.updated')}</span>
                                  }
                                </div>
                              </Form.Group>

                              <Form.Group>
                                <Form.Label className="d-block mb-2">{t('settings.uploadDefault.basicInfo.description')}</Form.Label>
                                <Form.Control type="text" name="ds" onChange={onChangeHandler} value={objectId && updatedFrameObjects.objects[objectId]?.ds ? updatedFrameObjects.objects[objectId].ds : ''}></Form.Control>
                                <div className="update-all-btn-holder d-flex align-items-center">
                                  {
                                    updatedFrameObjects.objects[objectId] &&
                                    <Button className="orange-btn text-uppercase btn-filled" onClick={() => updateAllHandler(2)}>{t('editAnnotation.updateAll')}</Button>
                                  }
                                  {
                                    allDescUpdated &&
                                    <span className="updated-span">{t('editAnnotation.updated')}</span>
                                  }
                                </div>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="d-block mb-2">{t('editAnnotation.previewImg')}</Form.Label>
                                <Form.Control type="text" name="im" onChange={onChangeHandler} value={objectId && updatedFrameObjects.objects[objectId]?.im ? updatedFrameObjects.objects[objectId].im : ''}></Form.Control>
                                {
                                  errors && errors.image ? <span className="pl-1 text-danger">{errors.image}</span> : null
                                }
                                <div className="update-all-btn-holder d-flex align-items-center">
                                  {
                                    !errors?.image && updatedFrameObjects.objects[objectId] &&
                                    <Button className="orange-btn text-uppercase btn-filled" onClick={() => updateAllHandler(3)}>{t('editAnnotation.updateAll')}</Button>
                                  }
                                  {
                                    allPreviewImgsUpdated &&
                                    <span className="updated-span">{t('editAnnotation.updated')}</span>
                                  }
                                </div>
                              </Form.Group>
                            </>
                            : null
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Modal.Footer className="py-0 px-4 justify-content-end">
                <button className="ml-2 orange-btn text-uppercase btn-filled btn-save" onClick={saveDetails}>
                  {t('common.saveBtn')}
                </button>
                <button type="button" className="orange-btn text-uppercase ml-2 btn-filled" onClick={closeModalHandler}>
                  {t('common.close')}
                </button>
              </Modal.Footer>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  video: state.player,
  annotation: state.videos
});

export default connect(mapStateToProps, {
  getVideoMetadata,
  beforeVideoMetadata,
  editAnnotation,
  beforeEditAnnotation
})(UpdateVideoAnnotation);