import { useEffect, useState } from 'react';
import { Container, Nav, Navbar, ListGroup, Row, Col, Button } from 'react-bootstrap'
import { Images } from '../../../assets/assets';
import { FaFontAwesome, FaSearch } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import UserNotifications from '../Notifications/UserNotifications';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getSearchedVideos, beforeSearchedVideos } from '../MemberDashboard/Videos/video.actions';
import { ENV } from '../../../config/config';
import ProfileComponent from '../Layouts/ProfileComponent';
import SmallLoader from '../../../utils/SmallLoader/SmallLoader';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUpload, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons';
import { beforeCreator, getChannelCreator } from '../UserComponents/users.actions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import UploadVideo from '../MemberDashboard/Videos/UploadVideo/UploadVideo';
import { beforeChannelRole } from '../ChannelRoles/channelRoles.actions';
import { beforeBlog, listSearchedBlogs } from '../Blogs/blogs.actions';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import './Header.css'
const { defaultPlaceholderImage, getUserKeys, languages } = ENV

const Header = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch()
	const navigate = useNavigate()

	let userData = getUserKeys();
	const userId = ENV.getUserKeys('_id')?._id
	const channelId = localStorage.getItem('channelId')

	const [memberRole, setRole] = useState()
	const [search, setSearch] = useState(false)
	const [loader, setLoader] = useState(false)
	const [searched, setSearched] = useState(false)
	const [input, setInput] = useState('')
	const [videos, setVideos] = useState([])
	const [listCheck, setCheck] = useState(false)
	const [bar, setBar] = useState(false)
	const [userlinks, setUserLinks] = useState(false)
	const [showSearchBar, setshowSearchBar] = useState(false);
	const [user, setUser] = useState()
	const [upload, setUpload] = useState(false)
	const [blogs, setBlogs] = useState([])
	const [selectedLang, setSelectedLang] = useState(localStorage.getItem('selectedLang'))
	let location = window.location.pathname.split('/')[1]

	const videoSelector = useSelector((state) => state.videos)
	const userSelector = useSelector((state) => state.user)
	const roleSelector = useSelector((state) => state.roles)
	const blogsSelector = useSelector((state) => state.blogs)

	useEffect(() => {
		if (channelId && channelId !== "null")
			dispatch(getChannelCreator(`channelId=${channelId}`))
	}, [])

	useEffect(() => {
		if (localStorage.getItem('selectedLang'))
			i18n.changeLanguage(localStorage.getItem('selectedLang'));
	}, [])

	useEffect(() => {
		if (i18n.language) {
			setLoader(false)
			setUserLinks(false)
			setBar(false)
		}
	}, [i18n.language]);

	useEffect(() => {
		if (location === 'blogs' || location === 'blog')
			setVideos([])
		else
			setBlogs([])
	}, [location])

	useEffect(() => {
		if (!input) {
			setVideos([])
			setBlogs([])
		}
	}, [input])

	useEffect(() => {
		if (roleSelector.channelRoleAuth) {
			setRole(roleSelector.channelRoleData?.memberRole)
			dispatch(beforeChannelRole())
		}
	}, [roleSelector.channelRoleAuth])

	useEffect(() => {
		if (userSelector.getChannelCreatorAuth) {
			let data = userSelector.getChannelCreatorData?.data?.user
			setUser(data)
			dispatch(beforeCreator())
		}
	}, [userSelector.getChannelCreatorAuth])

	useEffect(() => {
		if (videoSelector.searchedVideosAuth) {
			setLoader(false)
			setSearched(false)
			setVideos(videoSelector.searchedVideosData.videos)
			dispatch(beforeSearchedVideos());
		}
	}, [videoSelector.searchedVideosAuth]);

	useEffect(() => {
		if (blogsSelector.searchedBlogsAuth) {
			let { data } = blogsSelector.searchedBlogsRes
			setLoader(false)
			setSearched(false)
			setBlogs(data)
			dispatch(beforeBlog());
		}
	}, [blogsSelector.searchedBlogsAuth]);


	useEffect(() => {
		setInput('')
		setSearch(false)
		setCheck(false)
	}, [window.location.pathname])

	const searchVideos = () => {
		if (input) {
			if (location === 'blogs' || location === 'blog')
				dispatch(listSearchedBlogs(`searchTerm=${encodeURIComponent(input)}&lng=${selectedLang}`))
			else
				dispatch(getSearchedVideos(`searchTerm=${encodeURIComponent(input)}&all=${true}`))

			setCheck(true)
			setLoader(true)
			setSearched(true)
		}
		setSearchF(true)
	}


	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			searchVideos()
		}
	}

	const handleClose = () => {
		setCheck(false)
	}

	const renderList = () => {
		if (listCheck) {
			return (
				<ListGroup className={!listCheck ? `d-none` : userId ? 'search-results' : 'search-results non-active-user'} id="headerSearchList">
					<button onClick={handleClose} className='search-popup-close-btn close-button'><span><FontAwesomeIcon icon={faXmark} /></span></button>

					{
						loader ?
							<SmallLoader /> :
							<ListGroup>
								{
									(videos && videos.length) || (blogs && blogs?.length) ?
										<ListGroup.Item className='heaader-list-items'>{videos?.length ? 'Videos' : 'Blogs & Posts'}</ListGroup.Item>
										: ''
								}
								<div className='search-result-items'>
									{
										videos && videos.length ?
											videos.map((item, index) => {
												return (
													<ListGroup.Item>
														<Container>
															<Row>
																<Col className='col-4 px-sm-2 px-0'>
																	<div className='thumbnailImg search-img'>
																		<img className='videoImg' src={item.thumbnails[0].localUrl || defaultPlaceholderImage} alt='thumbnail' />
																	</div>
																</Col>
																<Col className='col-8'>
																	<Link to={`/media-player/${item?.slug}/${item?.channelName}`} key={index}><ListGroup.Item key={index}>{item.title}</ListGroup.Item></Link>
																</Col>

															</Row>
														</Container>
													</ListGroup.Item>
												)
											}) :
											blogs?.length ?
												blogs.map((item, index) => {
													return (
														<ListGroup.Item>
															<Container>
																<Row>
																	<Col className='col-4 px-sm-2 px-0'>
																		<div className='thumbnailImg search-img'>
																			<img className='videoImg' src={item.featuredImage} alt='blog' />
																		</div>
																	</Col>
																	<Col className='col-8'>
																		<Link to={`/blog/${item?.blogPost?.customUrl || item?.customUrl}`} key={index}><ListGroup.Item key={index}>{item.title}</ListGroup.Item></Link>
																	</Col>

																</Row>
															</Container>
														</ListGroup.Item>
													)
												})
												:
												<ListGroup.Item>
													<Container>
														<Row>
															<Col className='col-8'>
																{t('homepage.header.noResultFound')}
															</Col>

														</Row>
													</Container>
												</ListGroup.Item>
									}
								</div>
							</ListGroup>
					}

				</ListGroup>)
		}
	}

	const setSearchF = (bool) => {
		setSearch(bool)
		if ($('.navbar-collapse.collapse').hasClass('show')) {
			$('.navbar-toggler').click();
			setBar(false);
		}
	}

	const setSearchAndBar = () => {
		setBar(!bar);
		setUserLinks(!userlinks);
	};

	const toggleSearch = () => {
		setshowSearchBar(!showSearchBar);
	};

	const setUploadHandler = () => {
		if (!user?.subscribedPlan?.plan ||
			!Object.keys(user.subscribedPlan?.plan).length) {

			toast.info(t('homepage.header.info'))
			if (userId === user._id)
				navigate('/packages')
		}
		else if (user?.hasPlanExpired) {
			if (user.subscribedPlan.plan.type !== 1)
				toast.info(t('homepage.header.completePaymentMsg'))
			else
				toast.info(t('homepage.header.freePlanExpired'))
		}
		else
			setUpload(true)
	}

	const rootElement = document.getElementById('root');

	window.onscroll = function () {
		if (rootElement) {
			const headerHeight = document.getElementById('header-navbar')?.offsetHeight;
			let scrolledValue = document.documentElement.scrollTop;
			if (scrolledValue > headerHeight) {
				rootElement.style.paddingTop = `${headerHeight}px`
				rootElement.classList.add("scrolled");
			}
			else {
				rootElement.classList.remove("scrolled");
				rootElement.style.paddingTop = '0px'
			}
		}

	}
	const [isActive, setActive] = useState(false);

	const toggleClass = () => {
		setActive(!isActive);
	};

	const toggleLanguage = (lng) => {
		setLoader(true)
		i18n.changeLanguage(lng);
		setSelectedLang(lng)
		localStorage.setItem('selectedLang', lng)
		setActive(false)
	}

	return (
		loader ? <FullPageLoader /> :
			<>
				<div className={`language-selector ${isActive ? "active" : ""}`}>
					<span className='language-icon' onClick={toggleClass}><FontAwesomeIcon icon={faGlobe} /></span>
					<div className='languages-holder'>
						<label class="right-label-radio">{languages.english.label}
							<input type="radio" value={languages.english.code} checked={selectedLang === languages.english.code} onChange={(e) => toggleLanguage(e.target.value)} name="radio" />
							<span class="checkmark"></span>
						</label>
						<label class="right-label-radio">{languages.arabic.label}
							<input type="radio" value={languages.arabic.code} checked={selectedLang === languages.arabic.code} onChange={(e) => toggleLanguage(e.target.value)} name="radio" />
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
				<div id="header-navbar" className={userId ? 'header active-user' : 'header'}>
					<Navbar expand="lg">
						<Container fluid className={search ? 'customBox parentOpenSearch' : 'customBox'}>
							<div className='wrapHeaderBar'>
								<div className='nav-flex'>
									<div className="logo-search-holder d-flex align-items-center">
										<Link to="/" className='navbar-brand'>
											<img className='img-fluid' src={Images.siteLogo} alt='BiiView' />
										</Link>
										<div className={`search-form align-items-center ${showSearchBar ? "show" : ""}`}>
											<span className="search-icon">
												<FontAwesomeIcon icon={faMagnifyingGlass} />
											</span>
											{
												<div className="input-holder flex-fill">
													<input className="w-100 h-100" type='text' placeholder={t('homepage.header.searchPaceholder')} onChange={(e) => setInput(e.target.value)} onKeyDown={handleKeyPress} />
												</div>
											}
											{
												renderList()
											}
										</div>
									</div>
									<div className='nav-right-bar d-flex align-items-center'>
										<div className={userlinks ? 'user-links-block d-flex align-items-center hide' : 'user-links-block d-flex align-items-center'}>
											{
												userData?.type === 2 && memberRole && memberRole?.createVideo &&
												<Button onClick={setUploadHandler} className='upload-btn'>
													<span className="d-none d-md-block">{t('homepage.header.uploadVideoBtn')}</span>
													<span className="d-block d-md-none">
														<FontAwesomeIcon icon={faUpload} />
													</span>
												</Button>
											}

											<span className="header-user-btn user-login d-flex justify-content-center align-items-center ms-1  ms-sm-2 ms-md-4 d-block d-xl-none" onClick={toggleSearch}>
												<FontAwesomeIcon icon={faMagnifyingGlass} />
											</span>
											{!userId &&
												<Link className="header-user-btn user-login d-flex justify-content-center align-items-center ms-1 ms-sm-2 ms-md-4" to="/login">
													<img src={Images.iconUser} alt="User Icon" />
												</Link>
											}
										</div>
										<Navbar.Toggle aria-controls="basic-navbar-nav" className='ms-md-3' >
											<span className='header-user-btn navBtns'
												onClick={() => setSearchAndBar()}
											>
												<div className={bar ? 'faBar faCross' : 'faBar'} >
													<span></span><span></span><span></span>
												</div>
											</span>
										</Navbar.Toggle>
										{userId ?
											<div className=" ms-1 ms-sm-2 d-flex align-items-center">
												{user?.type === 2 && <UserNotifications />}
												<ProfileComponent />
											</div>
											:
											null
										}
									</div>
								</div>
								<Navbar.Collapse>
									<Nav className="ms-auto">
										<Link className="nav-link" to="/about-us">{t('homepage.header.nav.about')}</Link>
										<Link className="nav-link" to="/blogs">{t('homepage.header.nav.blogs')}</Link>
										<Link className="nav-link" to="/contact-us">{t('homepage.header.nav.contact')}</Link>
										<Link className='nav-link' to='/Community'>{t('homepage.header.nav.community')}</Link>
										<Link className='nav-link' to='/vlogs'>{t('homepage.header.nav.vlogs')}</Link>
									</Nav>
								</Navbar.Collapse>
							</div>
						</Container>
					</Navbar>
				</div>
				<UploadVideo setShow={setUpload} show={upload} setLoader={setLoader} />

			</>

	)
}
export default Header