import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Navbar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch, useSelector } from 'react-redux'
import { listQuestions, postQuestion } from '../community.actions'
import FullPageLoader from '../../FullPageLoader/FullPageLoader'
import { Images } from '../../../../assets/assets'
import { ckEditorCustomConfig } from '../../CkEditor/ckEditorConfig'
import "./CommunityHeader.css"
import { useTranslation } from 'react-i18next'
import Header from '../../Header/Header'

function CommunityHeader() {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const communtitySelector = useSelector((state) => state.community)
	const currentLanguage = localStorage.getItem('selectedLang')

	const [show, setShow] = useState(false);
	const [loader, setLoader] = useState(false);
	const [questionData, setQuestionData] = useState({})
	const [errors, setErrors] = useState({})

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const onEditorChange = (event, editor) => {
		let data = editor.getData();
		setQuestionData({ ...questionData, question: data })
	}

	useEffect(() => {
		if (communtitySelector.postQuestionAuth) {
			setLoader(false)
			setShow(false)
			setQuestionData({})
			dispatch(listQuestions())
		}
	}, [communtitySelector.postQuestionAuth])


	const postQuestionHandler = () => {
		let errorsObject = {}

		if (!questionData.title)
			errorsObject.title = t('common.fieldRequired')

		if (!questionData.question)
			errorsObject.title = t('common.fieldRequired')

		setErrors({ ...errorsObject })

		if (Object.keys(errors)?.length)
			return

		setLoader(true)
		dispatch(postQuestion({ ...questionData, lng: currentLanguage }))
	}

	return (
		loader ? <FullPageLoader /> :
			<header className="header">
				<div className='customBox container-fluid'>
					<div className='community-header-wrapper'>
						<Header />
					</div>
				</div>
				<div className='customBox container-fluid'>
					<div className='ask-question-modal-wrapper'>
						<Button className='upload-btn btn btn-primary' variant="primary" onClick={handleShow}>
							{t('community.askQuestion')}
						</Button>
						<Modal className='ask-question-modal' show={show} onHide={handleClose} centered>
							<Modal.Header closeButton>
								<Modal.Title>{t('community.askQuestion')}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form className='community-ask-question-form'>
									<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
										<Form.Label>{t('common.title')} *</Form.Label>
										<Form.Control type="text" value={questionData.title} onChange={(e) => setQuestionData({ ...questionData, title: e.target.value })} placeholder="Question Title" />
										{
											errors && errors.title &&
											<span className='text-danger'>{errors.title}</span>
										}
									</Form.Group>
									<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										<Form.Label>{t('common.description')}</Form.Label>
										<CKEditor
											id="blogpost-editor"
											editor={ClassicEditor}
											config={ckEditorCustomConfig}
											data={questionData.question || ''}
											content={questionData.question || ''}
											onChange={(event, editor) => onEditorChange(event, editor)}
										/>
										{
											errors && errors.question &&
											<span className='text-danger'>{errors.question}</span>
										}
									</Form.Group>
								</Form>
							</Modal.Body>
							<Modal.Footer>
								<Button className='theme-btn orange-btn d-inline-block' variant="secondary" onClick={handleClose}>
									{t('common.close')}
								</Button>
								<Button className='orange-main-button d-inline-block' variant="primary" onClick={postQuestionHandler}>
									{t('community.addQuestion')}
								</Button>
							</Modal.Footer>
						</Modal>
					</div>
				</div>
			</header>
	)
}

export default CommunityHeader