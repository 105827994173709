import React, { useState, useEffect } from 'react';
import { Container, } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { beforeChannels, getChannel, listChannels } from './channels.actions';
import { ENV } from '../../../config/config';
import { toast } from 'react-toastify';
import { FaUserAlt, FaUpload } from "react-icons/fa";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { GiSandsOfTime } from "react-icons/gi";
import { activeChannelButton, myChannelsSection, selectChannelButton } from '../ApplicationTour/TourCompIdentifiers';
import { listSubtitles } from '../MemberDashboard/Subtitles/subtitles.actions';
import '../Packages/Package/Package.css'
import './MyChannels.css';
import { useTranslation } from 'react-i18next'
const { defaultPlaceholderImage } = ENV

const MemberChannels = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const currentLanguage = localStorage.getItem('selectedLang')

    const userId = ENV.getUserKeys('_id')?._id

    const [loader, setLoader] = useState(true)
    const [channels, setChannels] = useState([])

    const channelsSelector = useSelector((state) => state.channels)
    const errorSelector = useSelector((state) => state.errors)

    useEffect(() => {
        if (errorSelector) {
            setLoader(false)
        }
    }, [errorSelector])

    useEffect(() => {
        if (userId) {
            dispatch(listChannels(`lng=${currentLanguage}`))
        }
    }, [])


    useEffect(() => {
        if (channelsSelector.listChannelsAuth) {
            let { memberChannels } = channelsSelector.listChannels.data
            setChannels(memberChannels)
            setLoader(false)
            dispatch(beforeChannels())
        }
    }, [channelsSelector.listChannelsAuth])

    const switchChannel = (id, name) => {
        setLoader(true)
        localStorage.setItem('channelId', id)
        navigate(`/publisher-dashboard/${name.toLowerCase().replace(/\W+/g, '-')}`)
        dispatch(getChannel(`channelId=${id}&lng=${currentLanguage}`))
        toast.success(`${t('myChannels.switchedTo')} ${name}`)
    }

    return (
        <>
            {loader && <FullPageLoader />}
            <section className='channel-sec pt-0'>
                <div className=''>
                    <Container fluid className=''>
                        <div className='dashboardHeader px40'>
                            <h2 id={myChannelsSection} className='mb-2 text-uppercase'>{t('memberDashboardSidebar.myChannels')}</h2>
                        </div>
                        <div className='px40'>
                            <div className='channel-package-main'>
                                {
                                    channels && channels.length ?
                                        channels.map((c, index) => {
                                            let channel = c.channel
                                            return (
                                                <div key={index} className='channel-package'>
                                                    <div className='package-card'>
                                                        <div>
                                                            <div className='d-flex justify-content-center'>
                                                                <div className={channel?.image ? "thumbnailImg my-channel-img" : "thumbnailImg"}>
                                                                    <img className='img-fluid' src={channel?.image ? channel.image : defaultPlaceholderImage} alt={channel?.name} />
                                                                </div>
                                                            </div>
                                                            <h4 className="text-uppercase text-center" style={{ color: '#FD6F21' }}>{channel?.name}</h4>
                                                            <ul className='list-unstyled channel-stats'>
                                                                <li className="d-flex align-items-center mb-3">
                                                                    <span className="me-2">
                                                                        <FaUserAlt />
                                                                    </span>
                                                                    <span>
                                                                        <strong>{t('myChannels.listingKeys.owner')} :</strong>  {channel.owner?.fullName}
                                                                    </span>
                                                                </li>
                                                                <li className="d-flex align-items-center mb-3">
                                                                    <span className="me-2">
                                                                        <BsFillPlayCircleFill />
                                                                    </span>
                                                                    <span>
                                                                        <strong>{t('myChannels.listingKeys.allowedVideos')} :</strong>  {channel.owner?.plan?.numberOfVideos || 0}
                                                                    </span>
                                                                </li>
                                                                <li className="d-flex align-items-center mb-3">
                                                                    <span className="me-2">
                                                                        <GiSandsOfTime />
                                                                    </span>
                                                                    <span>
                                                                        <strong>{t('myChannels.listingKeys.duration')} :</strong>  {channel.owner?.plan?.videoLengthMins || 0} {t('common.minutes')}
                                                                    </span>
                                                                </li>
                                                                <li className="d-flex align-items-center mb-3">
                                                                    <span className="me-2">
                                                                        <FaUpload />
                                                                    </span>
                                                                    <span>
                                                                        <strong>{t('myChannels.listingKeys.videoSize')} :</strong>  {channel.owner?.plan?.videoSizeMbs || 0} {t('common.mbs')}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='d-flex justify-content-center w-100'>
                                                            {
                                                                channel._id === localStorage.getItem('channelId') ?
                                                                    <button id={activeChannelButton} disabled={true} className='text-uppercase mt-0'>{t('myChannels.listingKeys.active')}</button> :
                                                                    <button id={selectChannelButton} className='orange-btn text-uppercase mt-0' onClick={() => switchChannel(channel?._id, channel?.name)}>{t('common.select')}</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <div className='d-flex justify-content-center align-items-center no-record-found'>No Channel Found</div>
                                }
                            </div>
                        </div>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default MemberChannels