import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { beforeTeam, listTeamMembers } from './aboutUs.actions'
import { useTranslation } from 'react-i18next'
const Team = () => {

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [team, setTeam] = useState([])
  const currentLanguage = localStorage.getItem('selectedLang')
  const teamSelector = useSelector((state) => state.aboutUs)

  useEffect(() => {
    if (i18n.language)
      dispatch(listTeamMembers(`lng=${currentLanguage}`))
  }, [i18n.language])

  useEffect(() => {
    if (teamSelector.listTeamMembersAuth) {
      let { teamMembers } = teamSelector.listTeamMembersData.data
      setTeam(teamMembers)
      dispatch(beforeTeam())
    }
  }, [teamSelector.listTeamMembersAuth])

  return (
    <>
      <section className='team-sec'>
        <Container className='customBox'>
          <h1>{t('aboutUs.team.meet')} <span className='theme d-block'>{t('aboutUs.team.team')}</span></h1>
          <div className='teams row d-flex flex-wrap'>
            {
              team && team.length ?
                team.map((member, idx) => {
                  return (
                    <div key={idx} className='team px-2'>
                      <div className='img'>
                        <img src={member.profileImage} className="img-fluid w-100 h-100" alt='member' />
                      </div>
                      <strong className='team-name mb-2'>{member.name}</strong>
                      <p className='team-designation mb-0'>{member.designation}</p>
                    </div>
                  )
                }) :
                <div className="px-3">
                  <div className="no-record-found d-flex justify-content-center align-items-center">{t('aboutUs.noTeam')}</div>
                </div>
            }
          </div>
        </Container>
      </section>
    </>
  )
}

export default Team