exports.validateName = (t, name) => {
    name = name.replace(/\s+/g, "").trim()
    let error = ''
    let regex = /^[a-zA-Z ]*$/;

    if (!name.length)
        error = t('common.fieldRequired')
    else if (name.length < 3)
        error = t('validations.name.tooShort')
    else if (name.length > 30)
        error = t('validations.name.tooLong')
    else if (!name.match(regex))
        error = t('validations.name.convention1')

    return error
}

exports.validateEmail = (t, email) => {
    let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let error = ''
    if (!emailRegex.test(email))
        error = t('validations.invalidEmail')

    return error
}

exports.validatePhone = (t, phone) => {
    let phoneRegex = /^\+?\d{10,12}$/
    let error = ''

    if (!phoneRegex.test(phone))
        error = t('validations.invalidContact')

    return error
}

exports.validateAge = (t, selectedDate) => {
    const moment = require('moment')
    let date = moment(new Date(selectedDate)).format('YYYY')
    let selectedYear = new Date(selectedDate).getFullYear()
    let currentYear = new Date().getFullYear()
    let error = ''

    if (date.length > 4 || selectedYear > currentYear)
        error = t('validations.ageValidation.invalidDate')
    else {
        let presentDate = moment(new Date()).format('YYYY')
        let dateDifference = presentDate - date

        if (dateDifference < 0)
            error = t('validations.ageValidation.minAgeMsg')
        else if (dateDifference > 100)
            error = t('validations.ageValidation.invalidDate')
        else if (dateDifference < 12)
            error = t('validations.ageValidation.minAgeMsg')
    }

    return error
}

exports.validatePassword = (t, password) => {
    let alphaRegex = /^.*[a-zA-Z].*$/
    let numericRegex = /\d/
    let error = ''

    if (!password.trim() || password.length < 8 || !numericRegex.test(password) || !alphaRegex.test(password))
        error = t('validations.password')

    return error
}

exports.validateUrl = (url) => {
    // url = url.trim()
    if (url.includes('www')) {
        let value = url.split('www.')[1]
        return this.validateUrl(value ? value : '')
    }
    var res = url.match(/^(http(s)?:\/\/)?(www.)?([a-zA-Z0-9])+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/[^\s]*)?$/gm);
    if (res == null)
        return false;
    else
        return true;
}