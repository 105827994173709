import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { beforeChat, getChat, sendMessage } from './contactAdmin.actions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ContactAdmin = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const channelId = localStorage.getItem('channelId')
    const currentLanguage = localStorage.getItem('selectedLang')

    const [message, setMessage] = useState('')
    const [channelDetails, setChannel] = useState({})
    const [loader, setLoader] = useState(true)
    const [chatDetails, setChat] = useState([])
    const [errors, setErrors] = useState({})
    const contactAdminSelector = useSelector((state) => state.chat)

    useEffect(() => {
        if (channelId)
            dispatch(getChat(`channelId=${channelId}&lng=${currentLanguage}`))
    }, [])

    useEffect(() => {
        if (contactAdminSelector.getChatAuth) {
            let { chat, channel } = contactAdminSelector.getChatData
            setChat(chat)
            setLoader(false)
            setChannel({ ...channel })
            dispatch(beforeChat())
        }
    }, [contactAdminSelector.getChatAuth])

    const submit = () => {
        if (message) {
            setErrors({})
            setChat([...chatDetails, { message, sentBy: 1, createdAt: new Date() }])
            dispatch(sendMessage({ channelId, message, lng: currentLanguage }))
            setMessage('')
        }
        else {
            setErrors({ ...errors, message: t('contactAdmin.typeMessage') })
        }
    };

    return (
        <>
            {loader && <FullPageLoader />}
            <div className='position-relative contact-main'>
                <div className='contact-wrapper'>
                    {
                        chatDetails?.length ?
                            chatDetails.map((message) => {
                                return (
                                    <>
                                        {
                                            message?.sentBy === 0 ?
                                                <div>
                                                    <p>{message?.message}</p>
                                                    <div className='userImg me-2'>
                                                        <img src={message?.admin?.image} className="img-fluid" alt={`${message?.admin?.name}`} />
                                                    </div>

                                                    <p>{message?.admin?.name}</p>
                                                    <p>{moment(message?.createdAt).format("DD-MM-YYYY HH:mm")}</p>
                                                </div> :
                                                message?.sentBy === 1 ?
                                                    <div className='comment-holder'>
                                                        <p>{message?.message}</p>
                                                        <p>{channelDetails?.name}</p>
                                                        <div className='userImg me-2'>
                                                            <img src={channelDetails?.channelImageLocal} className="img-fluid" alt={'sender-channel'} />
                                                        </div>

                                                        <p>{moment(message?.createdAt).format("DD-MM-YYYY HH:mm")}</p>
                                                    </div> :
                                                    null
                                        }

                                    </>
                                )
                            }) :
                            <p>{t('contactAdmin.noMessages')}</p>
                    }
                </div>
                <Form className='send-message-from'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>{t('contactAdmin.message')} <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control as="textarea" type="text" placeholder="Type Message" name='name' value={message} onChange={(e) => setMessage(e.target.value)} />
                                        {
                                            message?.sentBy === 0 ?
                                                <div>
                                                    <p>{message?.message}</p>
                                                    <div className='userImg me-2'>
                                                        <img src={message?.admin?.image} className="img-fluid" alt={`${message?.admin?.name}`} />
                                                    </div>

                                                    <p>{message?.admin?.name}</p>
                                                    <p>{moment(message?.createdAt).format("DD-MM-YYYY HH:mm")}</p>
                                                </div> :
                                                message?.sentBy === 1 ?
                                                    <div>
                                                        <p>{message?.message}</p>
                                                        <p>{channelDetails?.name}</p>
                                                        <div className='userImg me-2'>
                                                            <img src={channelDetails?.channelImageLocal} className="img-fluid" alt={'sender-channel'} />
                                                        </div>

                                                        <p>{moment(message?.createdAt).format("DD-MM-YYYY HH:mm")}</p>
                                                    </div> :
                                                    null
                                        }
                                    </Form.Group>
                                    <div className='submit-btn'>
                                        <Button variant="" onClick={submit}>{t('contactAdmin.send')}</Button>
                                    </div>
                                </Form>
           </div>
        </>
    )
}

export default ContactAdmin