import { combineReducers } from 'redux'
import userReducer from '../components/Frontend/UserComponents/users.reducer'
import BlogsReducer from '../components/Frontend/Blogs/blogs.reducer'
import VlogsReducer from '../components/Frontend/Vlogs/vlogs.reducer'
import errorReducer from './shared/error/error.reducer'
import siteSettingsReducer from '../components/Frontend/SiteSettings/siteSettings.reducer'
import aboutUsReducer from '../components/Frontend/About/aboutUs.reducer'
import packageReducer from '../components/Frontend/Packages/package.reducer'
import videoReducer from '../components/Frontend/MemberDashboard/Videos/video.reducer'
import playerReducer from '../components/Frontend/Player/player.reducer'
import dashboardReducer from '../components/Frontend/MemberDashboard/DashboardPage/dashboard.reducer'
import invitesReducer from '../components/Frontend/Invites/invites.reducer'
import billingHistoryReducer from '../components/Frontend/BillingAndInvoices/billingHistory.reducer'
import contactReducer from '../components/Frontend/Contact/contact.reducer'
import objectsReducer from '../components/Frontend/MemberDashboard/VideoAnalyticsComponent/IdentifiedObjects/objects.reducer'
import channelsReducer from '../components/Frontend/MyChannels/channels.reducer'
import channelRolesReducer from '../components/Frontend/ChannelRoles/channelRoles.reducer'
import faqsReducer from '../components/Frontend/FAQs/faqs.reducer'
import positionReducer from '../components/Frontend/Positions/positions.reducer'
import notificationsRed from '../components/Frontend/Notifications/notifications.reducer'
import informativeRed from '../components/Frontend/Informative/informative.reducer'
import { appTourReducer } from '../components/Frontend/ApplicationTour/applicationTour.reducer'
import subtitlesRed from '../components/Frontend/MemberDashboard/Subtitles/subtitles.reducer'
import contactAdminRed from '../components/Frontend/Contact Admin/contactAdmin.reducer'
import communityRed from '../components/Frontend/Community/community.reducer'
import publisherTutorialsReducer from '../components/Frontend/PublisherTutorials/publisherTutorials.reducer'
import newsletterRed from '../components/Frontend/Newsletter/newsletter.reducer'
export default combineReducers({

    error: errorReducer,
    siteSettings: siteSettingsReducer,
    contact: contactReducer,
    user: userReducer,
    blogs: BlogsReducer,
    vlogs: VlogsReducer,
    aboutUs: aboutUsReducer,
    packages: packageReducer,
    videos: videoReducer,
    dashboard: dashboardReducer,
    invites: invitesReducer,
    billingHistory: billingHistoryReducer,
    objects: objectsReducer,
    channels: channelsReducer,
    roles: channelRolesReducer,
    player: playerReducer,
    faqs: faqsReducer,
    positions: positionReducer,
    notifications: notificationsRed,
    informative: informativeRed,
    appTour: appTourReducer,
    subtitles: subtitlesRed,
    chat: contactAdminRed,  
    tutorials: publisherTutorialsReducer, 
    newsletter: newsletterRed
})