import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ENV } from './config/config';
import { useSelector, useDispatch } from "react-redux";
import { channelRolesComponent } from './utils/MemberRoles&Components/roles&components';
import { getChannelRole, beforeChannelRole } from './components/Frontend/ChannelRoles/channelRoles.actions';
import { clearStorage } from './utils/Shared Functions/shared';
import { useTranslation } from 'react-i18next';

const Getter = () => {
    const { i18n } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const userData = ENV.getUserKeys()
    const channel = localStorage.getItem('channelId')
    const [memberRole, setRole] = useState()

    const errorSelector = useSelector((state) => state.error)
    const rolesSelector = useSelector((state) => state.roles)

    // can be viewed by all
    const excludedRoutes = [
        'signup',
        'forgot-password',
        'reset-password',
        'about-us',
        'contact-us',
        'terms-and-conditions',
        'privacy-policy',
        'blog',
        'blogs',
        'invite-user',
        'faqs',
        'informative',
        'media-player',
        'how-it-works',
        'explore',
        'vlogs',
        'vlog',
        'channel-details',
        'packages'
    ]

    // member sidebar 
    const sidebarRoutes = [
        'publisher-dashboard',
        'my-videos',
        'channel-analytics',
        'video-analytics',
        'subtitles',
        'channels',
        'invoices',
        'payment-history',
        'contact-admin'
    ]

    // only accessible by members
    const memberRoutes = [
        ...sidebarRoutes,
        'invoice',
        'payment-successful',
    ]

    // common user & member routes ( not accessible by all )
    const commonRoutes = [
        'profile',
        'watch-history',
        'my-favourites'
    ]

    useEffect(() => {
        if (i18n.language) {
            let element = document.getElementById('app-component-div')
            if (element) {
                if (i18n.language === ENV.languages.arabic.code)
                    element.setAttribute("class", ENV.languages.arabic.orientation)
                else
                    element.removeAttribute("class")
            }
        }
    }, [i18n.language]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.pathname])

    useEffect(() => {
        let location = window.location.pathname.split('/')[1]

        // fetch member role for routes accessible by member
        if (sidebarRoutes.indexOf(location) > -1 || commonRoutes.indexOf(location) > -1 || location === '') {
            if (channel && userData && userData._id && userData.type !== 1) {
                dispatch(getChannelRole(`channelId=${channel}&userId=${userData._id}`))
            }
        }
    }, [window.location.pathname])

    useEffect(() => {
        if (rolesSelector.channelRoleAuth) {
            let role = rolesSelector.channelRoleData.memberRole
            if (role)
                setRole(role)

            dispatch(beforeChannelRole())
        }
    }, [rolesSelector.channelRoleAuth])

    // redirection based on member role
    useEffect(() => {
        if (memberRole && Object.keys(memberRole).length) {
            let location = window.location.pathname.split('/')[1]
            for (let key in memberRole) {
                channelRolesComponent.forEach((channelRole) => {
                    if (location === channelRole.url && key !== 'role' && channelRole.role === key && memberRole[key] === false) {
                        if (channelRole.navigateTo === '/login')
                            clearStorage()

                        navigate(channelRole.navigateTo)
                    }
                })
            }
        }
    }, [memberRole])

    useEffect(() => {

        if (window.location.pathname) {

            let { type, _id } = userData
            let location = window.location.pathname.split('/')[1]

            // if user is not logged in and is visiting any protected route, redirect him to login 

            if (!_id || !localStorage.getItem('encuse') || !type) {

                if ((window.location.pathname !== '/' && window.location.pathname !== '/login') && excludedRoutes.indexOf(location) === -1)
                    navigate('/login')
            }
            else {

                // if user is logged in and visits login/signup screen, 
                // redirect him to respective dashboard

                if (location.indexOf('login') > -1 || location.indexOf('signup') > -1)
                    navigate(type === 2 && channel ? `/publisher-dashboard/${channel}` : '/dashboard')

                // user cannot access member routes and vice versa
                if (type === 1 && memberRoutes.indexOf(location) > -1)
                    navigate('/')
                else if (type === 2) {
                    if (channel) {
                        // if (userRoutes.indexOf(location) > -1)
                        //     navigate(`/publisher-dashboard/${channel}`)
                    }
                    else {
                        clearStorage()
                        navigate('/login')
                    }
                }
            }
        }

    }, [window.location.pathname])

    useEffect(() => {
        // when an error is received
        if (errorSelector) {
            // if any of this error occurs, clear storage and redirect to login screen
            if (errorSelector.user404 || errorSelector.userDisabled || errorSelector.userInactive ||
                errorSelector.channelRoleError || errorSelector.channelIdMissing) {
                clearStorage()
                navigate('/login')
            }
        }
    }, [errorSelector])

    return (
        <>
        </>
    )
}

export default Getter
