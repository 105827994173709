import React from 'react';
import { Link } from 'react-router-dom';
import { Images } from '../../../assets/assets';
import { useTranslation } from 'react-i18next'
import './UnderConstruction.css'

function UnderConstruction() {
    const { t } = useTranslation()
    return (
        <div id="under-construction" className='d-flex justify-content-center align-items-center'>
            <div className="under-construction">
                <div className="under-construction-404">
                    <div className="img-holder">
                        <img className="img-fluid" src={Images.imgComingSoon} alt='Under Construction' />
                    </div>
                </div>
                <h4 className="mb-3">{t('common.pageNotReady')}</h4>
                <Link className="d-inline-block align-top orange-main-button" to="/">{t('common.goToHome')}</Link>
            </div>
        </div>
    );
}
export default UnderConstruction;