import React, { useEffect, useState } from 'react'
import { ENV } from '../../../config/config'
import { FaShareAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { Button, Modal } from 'react-bootstrap'
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, PinterestIcon, PinterestShareButton, TwitterIcon, TwitterShareButton } from 'react-share'
import { MdCancel } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { ImEmbed } from "react-icons/im";
import { HiOutlineClipboardDocument } from 'react-icons/hi2';

const SocialShare = (props) => {

    const [showIcons, setShowIcons] = useState(false)
    const [openModal, setModal] = useState(false)
    const { t } = useTranslation()
    const [copied, setCopied] = useState('')
    const copyContent = `<iframe width="560" height="315" src="${ENV.dedicatedPlayerDomain}${props.slug}" title="${props.title}" frameborder="0"></iframe>`

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => {
                setCopied('')

            }, 1000);
            return () => clearTimeout(timer)
        }
    }, [copied])

    return (
        <div className='channel-details-btns share-btn d-flex align-items-center justify-content-start position-relative'>
            {showIcons && props.shareUrl ?
                <div className='social-icons-blog-post d-flex align-items-center'>
                    <FacebookShareButton
                        url={`${ENV.url}/blog/${props.shareUrl}`}
                        title={props.title}
                    >
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton
                        url={`${props.shareUrl}`}
                        title={props.title}
                    >
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                        url={`${props.shareUrl}`}
                        title={props.title}
                    >
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <PinterestShareButton
                        url={`${props.shareUrl}`}
                        media={props.shareUrl}
                        title={props.title}
                    >
                        <PinterestIcon size={32} round={true} />
                    </PinterestShareButton>
                    <button className='embed-btn'><ImEmbed className='embed-icon' onClick={() => setModal(true)} /></button>
                </div>
                : null}
            {
                !showIcons ?
                    props.blogPostShare ?
                        <Button className={`blog-share-btn ${showIcons ? "active" : ""}`} onClick={() => setShowIcons(true)} variant="">
                            <FaShareAlt className='me-2' />{t('videoDetails.share')}
                        </Button> :
                        <button className='share-button' onClick={() => setShowIcons(true)}>
                            <span><FontAwesomeIcon icon={faShare} /> {t('videoDetails.share')}</span>
                        </button> :
                    <Button className={showIcons ? "active cross-btn" : ""} onClick={() => setShowIcons(false)} variant="">
                        <MdCancel className='' />
                    </Button>
            }
            {
                openModal &&
                <Modal className='' show={openModal} onHide={() => setModal(false)}>
                    <div className='embed-video-modal'>
                        <Modal.Header closeButton className='abc'>
                            <Modal.Title>
                                <h2>{props.title}</h2>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span className='embed-video-link d-block'>{copyContent}</span>
                                <div className='d-flex justify-content-end mb-1'>
                                    <button data-tooltip-id="my-tooltip" style={{ minHeight: "unset" }} className='p-2 position-relative orange-main-button' type="button" onClick={() => copy(copyContent, { onCopy: setCopied('copied') })}>
                                        <HiOutlineClipboardDocument />
                                        {t('common.copy')}
                                    </button>
                                </div>
                            {copied && <p className='text-copied text-center'>{t('common.copied')}</p>}
                            {/* <p className='text-copied text-center'>{t('common.copied')}</p> */}
                        </Modal.Body>
                    </div>
                </Modal>
            }
        </div>
    )
}

export default SocialShare