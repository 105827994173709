import React, { useState, useEffect } from 'react';
import { Container, Form } from 'react-bootstrap'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { resetPassword, beforeUser } from '../users.actions';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { validatePassword } from '../../../../utils/Validations/validations';
import { Images } from '../../../../assets/assets';
import { useTranslation } from 'react-i18next';
import '../../Forms/Forms.css'

const ResetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [loader, setLoader] = useState(false)
    const { token } = useParams();


    const [password, setPassword] = useState('')
    const [verifyPassword, setVerifyPassword] = useState('')
    const [errors, setErrors] = useState({})

    const userSelector = useSelector((state) => state.user)
    const errorSelector = useSelector((state) => state.error)

    useEffect(() => {
        if (userSelector.resetPasswordAuth) {
            if (userSelector.resetPasswordData.success) {
                setLoader(false)
                navigate('/login')
                dispatch(beforeUser())
            }
        }
    }, [userSelector.resetPasswordAuth])

    useEffect(() => {
        if (errorSelector) {
            setLoader(false)
        }
    }, [errorSelector])


    const submit = (e) => {
        e.preventDefault()

        let passwordErr = ''
        let verifyPassErr = ''

        if (!password || !password.trim())
            passwordErr = t('common.fieldRequired')
        else {
            passwordErr = validatePassword(t, password)
        }

        if (!verifyPassword)
            verifyPassErr = t('common.fieldRequired')

        if (password !== verifyPassword)
            verifyPassErr = t('common.passwordsUnmatchErr')

        setErrors({ password: passwordErr, verifyPassword: verifyPassErr })

        if (passwordErr || verifyPassErr)
            return

        setLoader(true)
        dispatch(resetPassword({ password, resetPasswordToken: token, lng: localStorage.getItem('selectedLang') }))
    }

    return (
        <>
            {loader && <FullPageLoader />}
            <div className='theme-form Login'>
                <div className='biiview-form'>
                    <Container fluid className='formContainer'>
                        <div className='wrapForm'>
                            <Form className='formStyle position-relative' onSubmit={submit}>
                                <span className="auth-login-logo">
                                    <img className="img-fluid" src={Images.authPageLogo} alt='BiiView Logo' />
                                </span>
                                <div className='formHeader'>
                                    <h2>{t('resetPassword.heading')}</h2>
                                    <div className='d-flex align-items-end justify-content-center'>
                                        <p className='mb-0'>{t('resetPassword.enterPasswords')}</p></div>
                                </div>
                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                    <Form.Label>Password</Form.Label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder={t('resetPassword.enterPassword')}
                                        name='password'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    >
                                    </input>
                                    {
                                        errors && errors.password &&
                                        <span className='submit-errors text-danger'>{errors.password}</span>
                                    }
                                </Form.Group>

                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                    <Form.Label>{t('common.verifyPassword')}</Form.Label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder={t('resetPassword.reEnterPassword')}
                                        name='verifyPassword'
                                        value={verifyPassword}
                                        onChange={(e) => setVerifyPassword(e.target.value)}
                                    >
                                    </input>
                                    {
                                        errors && errors.verifyPassword &&
                                        <span className='submit-errors text-danger'>{errors.verifyPassword}</span>
                                    }
                                </Form.Group>
                                <div className='theme-capcha'>
                                    <div className="submit-btn-holder">
                                        <button type="button" className='orange-main-button text-uppercase end-mb' onClick={submit}>{t('resetPassword.reset')}</button>
                                    </div>
                                </div>
                                <div className='d-flex align-items-end justify-content-center'><p className='mb-0 outer-p link' onClick={() => navigate('/forgot-password')}>{t('resetPassword.backToForgotPass')}</p></div>
                            </Form>
                        </div>
                    </Container>
                </div>
            </div>

        </>
    )
}
export default ResetPassword