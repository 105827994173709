import { activeChannelButton, activePlanSection, addObjectsIcon, analyticsSelect, audienceTab, becomeMemberSection, cancelSubButton, channelAnalyticsLink, channelSettingsNav, contactAdmin, contentTab, dashboardCategory, deleteVideosCheck, downloadInvoiceIcon, editAnnIcon, editVideoIcon, engagementTab, exploreVideosLink, exploreVideosSearch, exploreVideosSelect, favouriteVideosSection, generalSettingsNav, myChannelLink, myChannelsSection, myVideosLink, notificationsIcon, originalVideoIcon, otherVideoOptions, overviewTab, packagesLink, paymentHistoryLink, permissionsSettingsNav, previousPlansSection, processedVideoIcon, profileLink, profileMenu, publisherTutorials, reachTab, realtimeSelect, recommendedVideosSection, researchTab, selectChannelButton, selectPlanSection, settingsLink, socialSettingsNav, subscribeChannel, summarySection, topVideosSection, updatePasswordSection, uploadDefaultSettingsNav, uploadVideoButton, uploadVideoDashboard, userDetailsSection, vidAnalyticsAudience, vidAnalyticsOverview, vidAnalyticsSelect, videoAnalyticsIcon, vidIdentifiedObjTab, vidRealtimeSelect, viewBillingLink, viewChannelDetails, viewChannelVideos, viewInvoiceIcon, visibilityOptions, watchHistoryFilterSection, watchHistorySection } from "./TourCompIdentifiers"

export const getPageTourSteps = (key, t) => {
    const prop = { disableBeacon: true }
    switch (key) {
        case 'memberDashboardTour':
            return [
                {
                    content: <><h6>{t('common.body.text1')}</h6><br /><p>{t('common.body.text2')}</p></>,
                    target: 'body',
                    placement: 'center',
                    ...prop
                },
                {
                    content: t('applicationTours.memberDashboard.uploadVideo'),
                    target: `#${uploadVideoDashboard}`,
                    ...prop
                },
                {
                    content: t('applicationTours.memberDashboard.manageVideos'),
                    target: `#${myVideosLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.memberDashboard.exporeVideos'),
                    target: `#${exploreVideosLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.memberDashboard.channelAnalytics'),
                    target: `#${channelAnalyticsLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.memberDashboard.settings'),
                    target: `#${settingsLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.memberDashboard.billing'),
                    target: `#${paymentHistoryLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.memberDashboard.myChannels'),
                    target: `#${myChannelLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.memberDashboard.plans'),
                    target: `#${packagesLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.memberDashboard.profile'),
                    target: `#${profileLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.memberDashboard.contactAdmin'),
                    target: `#${contactAdmin}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.memberDashboard.publisherTutorials'),
                    target: `#${publisherTutorials}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.memberDashboard.summary'),
                    target: `#${summarySection}`,
                    ...prop,
                },
                {
                    content: t('applicationTours.memberDashboard.topVideos'),
                    target: `#${topVideosSection}`,
                    ...prop,
                },
                {
                    content: t('applicationTours.memberDashboard.notifications'),
                    target: `#${notificationsIcon}`,
                    ...prop,
                },
                {
                    content: t('applicationTours.memberDashboard.userMenu'),
                    target: `#${profileMenu}`,
                    ...prop,
                },


            ]
        case 'userDashboardTour':
            return [
                {
                    content: <><h6>{t('common.body.text1')}</h6><br /><p>{t('common.body.text2')}</p></>,
                    target: 'body',
                    placement: 'center',
                    ...prop
                },
                // {
                //     content: t('applicationTours.userDashboard.exporeVideos'),
                //     target: `body`,
                //     placement: 'center',
                //     ...prop
                // },
                {
                    content: t('applicationTours.userDashboard.browseCategories'),
                    target: `#${dashboardCategory}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: t('applicationTours.userDashboard.profileMenu'),
                    target: `#${profileMenu}`,
                    ...prop,
                },
                {
                    content: t('applicationTours.userDashboard.becomePublisher'),
                    target: `#${becomeMemberSection}`,
                    ...prop,
                    placement: 'right',
                },

            ]
        case 'myVideosTour':
            return [
                {
                    content: t('applicationTours.myVideos.body'),
                    target: 'body',
                    ...prop
                },
                {
                    content: t('applicationTours.myVideos.upload'),
                    target: `#${uploadVideoButton}`,
                    ...prop
                },
                {
                    content: t('applicationTours.myVideos.deleteVideos'),
                    target: `#${deleteVideosCheck}`,
                    ...prop
                },
                {
                    content: t('applicationTours.myVideos.editDetails'),
                    target: `#${editVideoIcon}`,
                    ...prop
                },
                {
                    content: t('applicationTours.myVideos.videoStats'),
                    target: `#${videoAnalyticsIcon}`,
                    ...prop
                },
                {
                    content: t('applicationTours.myVideos.processedVideo'),
                    target: `#${processedVideoIcon}`,
                    ...prop
                },
                {
                    content: t('applicationTours.myVideos.originalVideo'),
                    target: `#${originalVideoIcon}`,
                    ...prop
                },
                {
                    content: t('applicationTours.myVideos.editAnn'),
                    target: `#${editAnnIcon}`,
                    ...prop
                },
                {
                    content: t('applicationTours.myVideos.addObjects'),
                    target: `#${addObjectsIcon}`,
                    ...prop
                },
                {
                    content: t('applicationTours.myVideos.menu'),
                    target: `#${otherVideoOptions}`,
                    ...prop
                },
                {
                    content: t('applicationTours.myVideos.visibility'),
                    target: `#${visibilityOptions}`,
                    ...prop
                }
            ]
        case 'exploreVideosTour':
            return [
                {
                    content: t('applicationTours.exploreVideos.exploreVideos'),
                    target: 'body',
                    placement: 'center',
                    ...prop
                },
            ]
        case 'channelAnalyticsTour':
            return [
                {
                    content: t('applicationTours.channelAnalytics.overview'),
                    target: `#${overviewTab}`,
                    ...prop
                },
                {
                    content: t('applicationTours.channelAnalytics.reach'),
                    target: `#${contentTab}`,
                    ...prop
                },
                {
                    content: t('applicationTours.channelAnalytics.audience'),
                    target: `#${audienceTab}`,
                    ...prop
                },
                {
                    content: t('applicationTours.channelAnalytics.research'),
                    target: `#${researchTab}`,
                    ...prop
                },
                {
                    content: t('applicationTours.channelAnalytics.filters'),
                    target: `#${analyticsSelect}`,
                    ...prop
                },
                {
                    content: t('applicationTours.channelAnalytics.realtime'),
                    target: `#${realtimeSelect}`,
                    ...prop
                }
            ]
        case 'settingsTour':
            return [
                {
                    content: t('applicationTours.settings.channel'),
                    target: `#${channelSettingsNav}`,
                    placement: "right",
                    ...prop
                },
                {
                    content: t('applicationTours.settings.uploadDefault'),
                    target: `#${uploadDefaultSettingsNav}`,
                    placement: "right",
                    ...prop
                },
                {
                    content: t('applicationTours.settings.permissions'),
                    target: `#${permissionsSettingsNav}`,
                    placement: "right",
                    ...prop
                },
                {
                    content: t('applicationTours.settings.general'),
                    target: `#${generalSettingsNav}`,
                    placement: "right",
                    ...prop
                },
                {
                    content: t('applicationTours.settings.social'),
                    target: `#${socialSettingsNav}`,
                    placement: "right",
                    ...prop
                },
            ]
        case 'paymentHistoryTour':
            return [
                {
                    content: t('applicationTours.paymentHistory.activePlan'),
                    target: `#${activePlanSection}`,
                    ...prop
                },
                {
                    content: t('applicationTours.paymentHistory.cancelSubscription'),
                    target: `#${cancelSubButton}`,
                    ...prop
                },
                {
                    content: t('applicationTours.paymentHistory.invoices'),
                    target: `#${viewBillingLink}`,
                    ...prop
                },
                {
                    content: t('applicationTours.paymentHistory.prevPlans'),
                    target: `#${previousPlansSection}`,
                    ...prop
                },
            ]
        case 'billingInvoicesTour':
            return [
                {
                    content: t('applicationTours.billingInvoices.view'),
                    target: `#${viewInvoiceIcon}`,
                    ...prop
                },
                {
                    content: t('applicationTours.billingInvoices.download'),
                    target: `#${downloadInvoiceIcon}`,
                    ...prop
                },
            ]
        case 'myChannelsTour':
            return [
                {
                    content: t('applicationTours.myChannels.view'),
                    target: `#${myChannelsSection}`,
                    placement: 'auto',
                    ...prop
                },
                {
                    content: t('applicationTours.myChannels.switch'),
                    target: `#${selectChannelButton}`,
                    ...prop
                },
                {
                    content: t('applicationTours.myChannels.active'),
                    target: `#${activeChannelButton}`,
                    ...prop
                },
            ]
        case 'packagesTour':
            return [
                {
                    content: t('applicationTours.packages.body'),
                    target: `#${selectPlanSection}`,
                    placement: 'right',
                    ...prop
                }
            ]
        case 'profileTour':
            return [
                {
                    content: t('applicationTours.profile.updateDetails'),
                    target: `#${userDetailsSection}`,
                    ...prop
                },
                {
                    content: t('applicationTours.profile.updatePassword'),
                    target: `#${updatePasswordSection}`,
                    ...prop
                }
            ]
        case 'videoAnalyticsTour':
            return [
                {
                    content: t('applicationTours.videoAnalytics.overview'),
                    target: `#${vidAnalyticsOverview}`,
                    ...prop
                },
                {
                    content: t('applicationTours.videoAnalytics.reach'),
                    target: `#${reachTab}`,
                    ...prop
                },
                {
                    content: t('applicationTours.videoAnalytics.engagement'),
                    target: `#${engagementTab}`,
                    ...prop
                },
                {
                    content: t('applicationTours.videoAnalytics.audience'),
                    target: `#${vidAnalyticsAudience}`,
                    ...prop
                },
                {
                    content: t('applicationTours.videoAnalytics.identifiedObjects'),
                    target: `#${vidIdentifiedObjTab}`,
                    ...prop
                },
                {
                    content: t('applicationTours.videoAnalytics.analytics'),
                    target: `#${vidAnalyticsSelect}`,
                    ...prop
                },
                {
                    content: t('applicationTours.videoAnalytics.realtime'),
                    target: `#${vidRealtimeSelect}`,
                    ...prop
                }
            ]
        case 'watchHistoryTour':
            return [
                {
                    content: t('applicationTours.watchHistory.body'),
                    target: `#${watchHistorySection}`,
                    ...prop
                },
                {
                    content: t('applicationTours.watchHistory.filters'),
                    target: `#${watchHistoryFilterSection}`,
                    ...prop
                },
            ]
        case 'processedVideoTour':
            return [
                {
                    content: t('applicationTours.processedVideo.identifiedObjects'),
                    target: `body`,
                    ...prop
                },
                {
                    content: t('applicationTours.processedVideo.objectDetails'),
                    target: `body`,
                    ...prop
                },
                {
                    content: t('applicationTours.processedVideo.relatedVideos'),
                    target: `#${recommendedVideosSection}`,
                    ...prop
                },
            ]
        case 'favouritesTour':
            return [
                {
                    content: t('applicationTours.favourites.heading'),
                    target: `#${favouriteVideosSection}`,
                    ...prop
                },
            ]
        case 'channelDetailsTour':
            return [
                {
                    content: t('applicationTours.channelDetails.detailsBtn'),
                    target: `#${viewChannelDetails}`,
                    ...prop
                },
                {
                    content: t('applicationTours.channelDetails.subscribeChannel'),
                    target: `#${subscribeChannel}`,
                    ...prop
                },
                {
                    content: t('applicationTours.channelDetails.videos'),
                    target: `#${viewChannelVideos}`,
                    ...prop
                },
            ]
        default:
            return []
    }

} 
